import styled from 'styled-components';
import { breakpointsNumber } from 'theme/media-queries';

const minTabletWidth = breakpointsNumber.desktop;

export const SideBarSectionContainer = styled.div`
	@media only screen and (max-width: ${minTabletWidth}px) {
		margin-inline: 1rem;
		margin-block: 1rem;
	}

	background-color: ${(props) => props.theme.colors.white};
	padding: 1.5rem 1.5rem 2.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	div > div > p {
		margin-top: 1.5rem;
		font-family: 'Akkurat Pro',sans-serif;
		font-size: 1rem;
	}

	div > div > h3 + p {
		margin-top: .5rem;
	}
`;

export const SideBarSectionTitle = styled.h2`
	font-weight: 400;
`;

export const SectionTitleUnderline = styled.hr`
	margin-inline-start: 0;
	width: 25%;
	padding-block: 1px;
	background-color: ${(props) => props.theme.colors.secondaryGreen};
`;

export const SectionItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3rem;
`;

export const SectionItem = styled.div`
	h3 {
		font-family: 'Akkurat Pro', sans-serif;
		font-weight: 600;
		margin-block-end: 0;
	}

	p {
		${(props) => props.theme.typography.style9.getStyle()}
		margin-block-start: 0.5rem;
	}
`;
