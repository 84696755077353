import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ShowAllStyle } from './ShowMoreButton.styles';

type ShowMoreButtonProps = {
	header: string | null;
	onClick?: () => void;
};

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
	header,
	onClick,
}) => {
	const themecontext = useContext(ThemeContext);
	return (
		<ShowAllStyle>
			<Button
				variant={
					themecontext.isMicrositeActive
						? ButtonVariant.Microsite
						: ButtonVariant.Link
				}
				onClick={onClick}
			>
				{header}
			</Button>
		</ShowAllStyle>
	);
};
