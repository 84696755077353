/**
 * Loader
 */

import styled from 'styled-components';

export const Loader = styled.div`
	border: 10px solid #ffffffff;
	border-top-color: ${(props) =>
		props.theme?.colors?.primary ? props.theme?.colors.primary : '#000000'};

	border-radius: 50%;
	width: 80px;
	height: 80px;
	box-shadow: 0px 0px 3px 1px #00000020;

	animation: fadeInAnimation 100ms ease-in 0s 1, spin 1s linear infinite;

	@keyframes fadeInAnimation {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const LoaderStatic = styled(Loader)`
	position: fixed;
	top: 35vh;
	left: 50vw;
	margin: -40px 0 0 -40px;
	z-index: 2;
	cursor: progress;
`;
