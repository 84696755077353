/**
 * BannerPuffText
 */
import React from 'react';
import { BannerPuffTextStyle } from './BannerPuffText.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import BannerPanel from 'components/BannerPanel';

export interface BannerPuffTextProps {
	themeName?: PuffTheme;
	/**
	 * Heading level
	 */
	headingLevel?: number;
	/**
	 * Heading text
	 */
	heading?: string;
	preamble?: string | null;
	link?: LinkType | null;
	decal?: string;
	center?: boolean;
}

/** This is the banner component */
const BannerPuffText: React.FC<BannerPuffTextProps> = ({
	themeName,
	headingLevel,
	heading,
	preamble,
	link,
	decal,
	center = true,
}) => {
	return (
		<BannerPuffTextStyle themeName={themeName}>
			<BannerPanel
				headingLevel={headingLevel}
				center={center}
				decal={decal}
				themeName={themeName}
				heading={heading}
				text={preamble}
				link={link}
			></BannerPanel>
		</BannerPuffTextStyle>
	);
};

export default BannerPuffText;
