import EpiFragments, {
	BlockType,
	Options,
	wrapElementInGrid,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import Typography from 'components/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FragmentListModel, FragmentModelTypes } from 'types/fragments';
import { MicrositeLinkStyle } from './FragmentPuffList.styles';
import EditableField from 'components/EditableField/EditableField';

/**
 * ColumnPageWithSidebar
 */
type ColumnPageWithSidebarProps = {
	textAsModel: FragmentListModel;
	link: LinkType | null;
	bottomItems: FragmentModelTypes[];
	previousBlockType?: BlockType | null;
	options: Options;
	preamble?: string | null;
	preamble_htmlAttributes?: any;
	mainBody_htmlAttributes?: any;
	textAsModel_htmlAttributes?: any;
	disableCustomHeadingLogic: boolean;
};

export const ColumnPageWithSidebar: React.FC<ColumnPageWithSidebarProps> = ({
	options,
	textAsModel,
	link,
	bottomItems,
	children,
	previousBlockType,
	preamble,
	preamble_htmlAttributes,
	textAsModel_htmlAttributes,
	mainBody_htmlAttributes,
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);
	let secondPreviousBlockType =
		textAsModel.fragments.length > 0 || link ? BlockType.Element : undefined;

	// TODO: do not create cell or grid if we already are in one
	let mainContent = (
		<Grid inner={true}>
			<Cell span={8} tablet={8}>
				<EditableField opeProperty={preamble_htmlAttributes}>
					{preamble && (
						<Typography as="preamble" {...preamble_htmlAttributes}>
							{preamble}
						</Typography>
					)}
				</EditableField>

				<EditableField opeProperty={mainBody_htmlAttributes}>
					{textAsModel.fragments.length > 0 && (
						<EpiFragments
							previousBlockType={previousBlockType}
							insideGrid={true}
							insideCell={true}
							insideInnerGrid={true}
							headingLevel={options.headingLevel}
							fragments={textAsModel.fragments}
							htmlAttributes={textAsModel_htmlAttributes}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)}
				</EditableField>

				{link && (
					<>
						{themeContext.isMicrositeActive && link ? (
							<Space top={themeContext.spacing.space2}>
								<div>
									<MicrositeLinkStyle to={link.url} external={link.isFile}>
										{link.heading}
									</MicrositeLinkStyle>
								</div>
							</Space>
						) : (
							<Space
								top={
									textAsModel.fragments.length > 0
										? themeContext.spacing.space5
										: 0
								}
							>
								<Button
									variant={ButtonVariant.Tertiary}
									to={link.url}
									external={link.isFile}
								>
									{link.heading}
								</Button>
							</Space>
						)}
					</>
				)}
			</Cell>

			{children && (
				<>
					<Cell span={4}>{children}</Cell>
				</>
			)}
		</Grid>
	);

	if (!options.insideGrid) {
		mainContent = wrapElementInGrid(mainContent);
	}

	return (
		<>
			{mainContent}

			{bottomItems && bottomItems.length > 0 && (
				<EpiFragments
					previousBlockType={secondPreviousBlockType}
					insideGrid={options.insideGrid}
					insideCell={options.insideCell}
					insideInnerGrid={options.insideInnerGrid}
					headingLevel={options.headingLevel}
					fragments={bottomItems}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}
		</>
	);
};
