/**
 * YouTube
 */

import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useEffect, useState } from 'react';
import {
	YoutubeContainer,
	PlayIconArea,
	PlayIcon,
	Overlay,
} from './YouTube.styles';
import Icon from 'components/Boilerplate/Icon';

export interface YouTubeProps {
	videoId: string;
	title: string;
	allowfullscreen: boolean;
}

/** A YouTube / link component. */
const YouTube: React.FC<YouTubeProps> = ({
	videoId = 'Kuh2um0DS8E',
	title = 'Movie',
	allowfullscreen = true,
}) => {
	const getSourceUrl = (videoId: string): string => {
		return `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`;
	};

	const getImageForOverlay = (videoId: string): string => {
		return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
	};

	const [videoSource, setVideoSource] = useState(getSourceUrl(videoId));
	const [titleState, setTitleState] = useState(title);
	const [allowfullscreenState, setAllowfullscreenState] = useState(
		allowfullscreen
	);
	const [playVideo, setPlayVideo] = useState<boolean>(false);
	const [onHover, setOnHover] = useState<boolean>(false);

	useEffect(() => {
		setVideoSource(getSourceUrl(videoId));
		setTitleState(title);
		setAllowfullscreenState(allowfullscreen);
	}, [videoId, title, allowfullscreen]);

	const onClick = () => {
		setPlayVideo(true);
	};
	const btnText = `Spela video`;

	return (
		<YoutubeContainer>
			{playVideo ? (
				<iframe
					title={titleState}
					src={videoSource}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen={allowfullscreenState}
				></iframe>
			) : (
				<div
					onClick={onClick}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				>
					<Overlay
						src={getImageForOverlay(videoId)}
						alt="Youtube video"
					></Overlay>

					<PlayIconArea hover={onHover}>
						<Button variant={ButtonVariant.Secondary}>
							<span>{btnText}</span>
							<PlayIcon icon="play" size={1} aria-hidden="true" />
						</Button>
					</PlayIconArea>
				</div>
			)}
		</YoutubeContainer>
	);
};

export default YouTube;
