import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Checkbox from 'components/Checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { GrantPuffModel } from 'types/fragments';
import { NVseGrantsListingPageModel } from './NVseGrantsListingPage.model';
import { FilterContainer } from './NVseGrantsListingPage.styles';
import { ViewType } from 'types/enums';
import {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { formatText } from 'utils/helper-utils';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { SubHeader } from 'components/Panels/SubHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';

type filterModel = {
	targetGroups: number[];
};

type filteredItemsModel = {
	items: GrantPuffModel[];
	qty: number;
	total: number;
};

/**
 * # Listningsida för bidrag 
 * Modeltype:<code>NVseGrantsListingPage</code>
 * 
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2091384865/NVseStatisticsListingPage)
 * 
 * Landningssida för webbplatsens bidrag
 */
const NVseGrantsListingPage: React.FC<NVseGrantsListingPageModel> = ({
	heading,
	preamble,
	targetGroups,
	grants,
	displayedGrantsText,
	pastGrantsHeading,
	pastGrantsLink,
	epi,
	_properties = {},
	disableCustomHeadingLogic
}) => {
	const [filter, setFilter] = useState({
		qty: 0,
		total: 0,
		targetGroups: [],
	} as filterModel);

	const [filteredGrants, setFilteredGrants] = useState({
		qty: 0,
		total: 0,
		items: [],
	} as filteredItemsModel);

	const [endedGrants, setEndedGrants] = useState<GrantPuffModel[]>([]);

	const themeContext = useContext(ThemeContext);

	// TODO: Hardcoded
	const showMoreLabel = 'Visa fler';

	useEffect(() => {
		// Grants filter

		// Get all Current and Future
		// 0 = kommande, 1 = aktuell, 2 = avslutad
		const currentAndComming = grants.filter((item: GrantPuffModel) => {
			// Apply status filter
			return item.statusId === 0 || item.statusId === 1;
		});

		let currentAndCommingTotal = currentAndComming.length;

		// Applay checkboxes (targetGroups) filter
		const filtered = currentAndComming.filter((item: GrantPuffModel) => {
			if (filter.targetGroups.length === 0) return true;
			return item.targetGroupIds.some((c) => {
				return filter.targetGroups.includes(c);
			});
		});

		// Sort by prio and date
		const sorted = filtered.sort((a, b) => {
			if (a.isPrioritized !== b.isPrioritized) {
				return a.isPrioritized ? -1 : 1;
			}

			const maxDate = 8640000000000000;
			let date1 = a.applicationEndDate ? a.applicationEndDate : maxDate;
			let date2 = b.applicationEndDate ? b.applicationEndDate : maxDate;
			return new Date(date1).getTime() - new Date(date2).getTime();
		});

		const ended = grants.filter((item: GrantPuffModel) => {
			// Apply status filter
			return item.statusId === 2;
		});

		setEndedGrants(ended);

		setFilteredGrants({
			qty: filtered.length,
			total: currentAndCommingTotal,
			items: sorted,
		} as filteredItemsModel);
	}, [grants, filter.targetGroups]);

	// A Checkbox (targetgroup) value changed
	const filterCheckedChanged = (id: number, value: boolean) => {
		var newfilterList;

		if (value) {
			if (filter.targetGroups.includes(id)) {
				return;
			}
			// Add id to filter
			newfilterList = [...filter.targetGroups, id];
		} else {
			if (!filter.targetGroups.includes(id)) {
				return;
			}
			// Remove id from filter;
			newfilterList = filter.targetGroups.filter((item) => item !== id);
		}

		const newFilter: filterModel = { ...filter };
		newFilter.targetGroups = newfilterList;
		setFilter(newFilter);
	};

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_filteredGrants: BlockType = lastBlockType;
	let blockTypeBefore_endedGrants: BlockType = lastBlockType;

	if (targetGroups) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_filteredGrants = lastBlockType;
		blockTypeBefore_endedGrants = lastBlockType;
	}
	if (displayedGrantsText) {
		lastBlockType = BlockType._Reserved_Heading;
		blockTypeBefore_filteredGrants = lastBlockType;
		blockTypeBefore_endedGrants = lastBlockType;
	}
	if (filteredGrants.items.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_endedGrants = lastBlockType;
	}
	if (endedGrants.length > 0) {
		lastBlockType = BlockType.Element;
	}

	const spaceBefore_filteredGrants = getSpaceToAddBefore(
		blockTypeBefore_filteredGrants,
		BlockType.Element,
		themeContext
	);
	const spaceBefore_endedGrants = getSpaceToAddBefore(
		blockTypeBefore_endedGrants,
		BlockType.Element,
		themeContext
	);
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space top={themeContext.spacing.getPageTopPadding()} bottom={spaceAfterLast}>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					columns={8}
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>

				{targetGroups && (
					<Space top={themeContext.spacing.getElement()}>
						<FilterContainer>
							<label>{targetGroups.heading}</label>
							{targetGroups.groups.map((item, index) => {
								const defaultValue = filter.targetGroups.includes(item.id);
								return (
									<Checkbox
										checked={defaultValue}
										onCheckedChanged={(value: boolean) => {
											filterCheckedChanged(item.id, value);
										}}
										key={index}
									>
										{item.text}
									</Checkbox>
								);
							})}
						</FilterContainer>
					</Space>
				)}

				{displayedGrantsText && (
					<Space top={themeContext.spacing.getElement()}>
						<SubHeader
							headingLevel={2}
							heading={formatText(
								displayedGrantsText,
								filteredGrants.total,
								filteredGrants.qty
							)}
						></SubHeader>
					</Space>
				)}

				{filteredGrants.items.length > 0 && (
					<Space top={spaceBefore_filteredGrants}>
						<FragmentPuffList
							initialMaxCount={6}
							options={{
								insideCell: false,
								insideInnerGrid: false,
								insideGrid: true,
								headingLevel: 2, // TODO
								view: ViewType.Card,
								themeContext: themeContext
							}}
							showMoreLabel={showMoreLabel}
							items={filteredGrants.items}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						></FragmentPuffList>
					</Space>
				)}

				{endedGrants.length > 0 && (
					<Space top={spaceBefore_endedGrants}>
						<FragmentPuffList
							heading={pastGrantsHeading}
							link={pastGrantsLink}
							initialMaxCount={6}
							showMoreLabel={showMoreLabel}
							options={{
								insideCell: false,
								insideInnerGrid: false,
								insideGrid: true,
								headingLevel: 2, // TODO
								view: ViewType.Card,
								themeContext: themeContext
							}}
							items={endedGrants}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						></FragmentPuffList>
					</Space>
				)}
			</Grid>
		</Space>
	);
};

export default NVseGrantsListingPage;
