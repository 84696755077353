/**
 * Grid
 */

import React from 'react';
import styled from 'styled-components';
import { math } from 'polished';
import { Grid as GridType, Columns, grid } from 'theme/grid';

// Interfaces
interface GridProps {
	/** Mandatory, when wrapping grid cells */
	inner?: boolean;

	/** Option to remove default grid padding */
	padding?: boolean;

	/** Option to remove default grid top padding */
	paddingTop?: boolean;

	/** Option to remove default grid right padding */
	paddingRight?: boolean;

	/** Option to remove default grid bottom padding */
	paddingBottom?: boolean;

	/** Option to remove default grid left padding */
	paddingLeft?: boolean;

	/** Specifies the alignment of the whole grid */
	align?: 'left' | 'right';

	/** Pass optional classnames for the html */
	className?: string;

	/** Pass optional style-object (TRY don't use this) */
	style?: any;
}

interface CellProps {
	/** Specifies the order of the cell (not currently working) */
	order?: number;

	/** Specifies the alignment of cell */
	align?: 'top' | 'middle' | 'bottom';

	/** Specifies the number of columns the cell spans */
	span?: number;

	/** Specifies the number of columns the cell spans on a type of device (desktop) */
	desktop?: number;

	/** Specifies the number of columns the cell spans on a type of device (tablet) */
	tablet?: number;

	/** Specifies the number of columns the cell spans on a type of device (phone) */
	phone?: number;

	/** Pass optional classnames for the html */
	className?: string;

	/** Pass optional style-object (don't use this) */
	style?: any;
}

// Variables
const CellPropsList = {
	span: 1,
	desktop: 1,
	tablet: 1,
	phone: 1,
};

// Functions
const breakpointMin = (size: string, gridConf: GridType): string | null => {
	const min = gridConf.breakpoints[size];
	if (parseInt(min, 10) === 0) {
		return null;
	}

	return min;
};

const breakpointMax = (size: string, gridConf: GridType): string | null => {
	const sizeIndex = Object.keys(gridConf.columns).indexOf(size);

	if (sizeIndex > 0) {
		return math(
			`${
				gridConf.breakpoints[Object.keys(gridConf.columns)[sizeIndex - 1]]
			} - 1px`
		);
	}

	return null;
};

const getDeviceProps = (props: any) => {
	const deviceProps: Columns = {};

	Object.keys(CellPropsList).forEach((key: string) => {
		if (
			key !== undefined &&
			props.hasOwnProperty(key) &&
			props[key] !== undefined
		) {
			deviceProps[key] = props[key];
		}
	});

	return deviceProps;
};

// Css generation methods
const getGridCss = (size: string, gridConf: GridType, props: any): string => {
	const margins = gridConf.margin[size];
	let paddingTop =
		props.paddingTop === false || props.padding === false ? 0 : margins;
	let paddingRight =
		props.paddingRight === false || props.padding === false ? 0 : margins;
	let paddingBottom =
		props.paddingBottom === false || props.padding === false ? 0 : margins;
	let paddingLeft =
		props.paddingLeft === false || props.padding === false ? 0 : margins;

	return `
		box-sizing: border-box;
		padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};

		${gridConf.maxWidth && `max-width: ${gridConf.maxWidth};`}
  `;
};

const getInnerGridCss = (size: string, gridConf: GridType): string => {
	const gutters = gridConf.gutter[size];

	return `
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		margin: -${math(`${gutters} / 2`)};

		@supports (display: grid) {
			display: grid;
			margin: 0;
			grid-gap: ${gutters};
			grid-template-columns: repeat(${gridConf.columns[size]}, minmax(0, 1fr));
		}
  `;
};

const getMediaQueriesCss = (
	size: string,
	fn: any,
	gridConf: GridType,
	...props: any
): string => {
	let data = '';

	const min = breakpointMin(size, gridConf);
	const max = breakpointMax(size, gridConf);

	if (min === null && max !== null) {
		data += `
				@media (max-width: ${max}) {
					${fn(size, gridConf, ...props)}
				}
			`;
	} else if (min !== null && max !== null) {
		data += `
				@media (min-width: ${min}) and (max-width: ${max}) {
					${fn(size, gridConf, ...props)}
				}
			`;
	} else if (min !== null && max === null) {
		data += `
				@media (min-width: ${min}) {
					${fn(size, gridConf, ...props)}
				}
			`;
	} else {
		data += `
				${fn(size, gridConf, ...props)}
			`;
	}

	return data;
};

const getMediaQueries = (
	fn: any,
	gridConf: GridType,
	...props: any
): string => {
	let data = '';

	for (let key in gridConf.columns) {
		const size = key;
		data += getMediaQueriesCss(size, fn, gridConf, ...props);
	}

	return data;
};

const getCellCss = (size: string, gridConf: GridType, devices: any): string => {
	const gutters = gridConf.gutter[size];
	let span = devices[size] || gridConf.defaultColumnSpan;

	if (devices[size] === 0) {
		return `display: none;`;
	}

	if (!devices[size]) {
		const dev = Object.keys(devices)[0];
		if (dev === 'span') {
			span = devices[dev];
		}
	}

	let percent = (span / gridConf.columns[size]) * 100;

	if (percent > 100) {
		percent = 100;
	}

	return `
		width: calc(${percent}% - ${gutters});

		@supports (display: grid) {
			width: auto;
			grid-column-end: span ${
				span < gridConf.columns[size] ? span : gridConf.columns[size]
			};
		}

		box-sizing: border-box;
		margin: ${math(`${gutters} / 2`)};

		@supports (display: grid) {
			margin: 0;
		}

	`;
};

const getAlignCellCss = (align: string) => {
	let data = '';

	if (align === 'top') {
		data = `
			align-self: flex-start;
			@supports (display: grid) {
				align-self: start;
			}
		`;
	} else if (align === 'middle') {
		data = `align-self: center;`;
	} else if (align === 'bottom') {
		data = `
			align-self: flex-end;
			@supports (display: grid) {
				align-self: end;
			}
		`;
	}

	return data;
};

const getAlignGridCss = (align: string) => {
	let data = '';

	if (align === 'left') {
		data = `
			margin-right: auto;
			margin-left: 0;
		`;
	} else if (align === 'right') {
		data = `
			margin-right: 0;
			margin-left: auto;
		`;
	}

	return data;
};

// Styled components
const GridComponent: React.FunctionComponent<GridProps> = (props) => (
	<div className={props.className} style={props.style}>
		{props.children}
	</div>
);
/** This grid is an Styled Components variant of the [Material Design Grid](https://material.io/develop/web/components/layout-grid/). */
export const Grid = styled(GridComponent)`
	margin: 0 auto;
	${({ inner, ...props }) =>
		getMediaQueries(inner ? getInnerGridCss : getGridCss, grid, props)}
	${(props) => props.align && getAlignGridCss(props.align)}
`;

const CellComponent: React.FunctionComponent<CellProps> = (props) => (
	<div className={props.className} style={props.style}>
		{props.children}
	</div>
);

export const Cell = styled(CellComponent)`
	${(props) => getMediaQueries(getCellCss, grid, getDeviceProps(props))}
	${(props) => props.order && `order: ${props.order};`}
	${(props) => props.align && getAlignCellCss(props.align)}
`;
