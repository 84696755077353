/**
 * Cookiebar
 */

import React, { useState } from 'react';
import Link from 'components/Boilerplate/Link';
import { useCookie } from 'hooks';
import {
	MainContainer,
	Content,
	ContentWrapper,
	CloseButton,
} from './CookieBar.styles';
import { RawFragmentModel } from 'types/fragments';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import EpiFragments from 'components/Boilerplate/EpiFragments';

interface Props {
	/** Cookiebar content */
	text: any;

	/** Cookiebar acceptance text */
	buttonText?: string;

	/** Read more link text */
	readMoreText?: string;

	/** Read more link url */
	readMoreUrl?: string;
}

/** CookieBar component for accepting cookies. */
const CookieBar = ({ text, buttonText, readMoreText, readMoreUrl }: Props) => {
	const [cookieIsAccepted, setCookieIsAccepted] = useState(false);
	const [cookie, setCookie] = useCookie('NVse-CONSENT', '');

	if (cookie || cookieIsAccepted) {
		return null;
	}

	return (
		<MainContainer>
			<ContentWrapper>
				<div>
					{text && (
						<EpiFragments
							insideGrid={true}
							insideInnerGrid={true}
							fragments={text?.fragments}
						/>
					)}
					{readMoreUrl && <Link href={readMoreUrl}>{readMoreText}</Link>}
				</div>
				<Button
					onClick={() => setCookie('NVse-CONSENT', { days: 365 })}
					variant={ButtonVariant.Secondary}
				>
					{buttonText}
				</Button>
			</ContentWrapper>
			<CloseButton onClick={() => setCookieIsAccepted(true)}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="-2 -2 24 24"
					width="24"
					height="24"
					preserveAspectRatio="xMinYMin"
				>
					<path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
					<path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path>
				</svg>
			</CloseButton>
		</MainContainer>
	);
};

export default CookieBar;
