/**
 * Icon - styles
 */

import styled from 'styled-components';

interface IconProps {
	/** Set the size of the icon. <br/>1 = 24px<br/> 2 = 32px<br/> 3 = 40px <br/> 4 = 48px*/
	size: 1 | 2 | 3 | 4;

	/** Set the color of the icon */
	color: string;

	/** Set the direction of the icon. */
	direction: 'left' | 'up' | 'right' | 'down';

	/** Animate the icon. */
	animate?: 'spin';
}

export const iconSize: any = {
	1: '16px',
	2: '24px',
	3: '32px',
	4: '48px',
};

export const iconSizes: any = {
	1: '1.5rem',
	2: '2rem',
	3: '2.5rem',
	4: '3rem',
};

const iconDirections: any = {
	right: '1turn',
	down: '0.25turn',
	left: '0.5turn',
	up: '0.75turn',
};

// CSS för :hover på ikoner, färg white?

export const StyledIcon = styled.div<IconProps>`
	vertical-align: middle;
	fill: ${(props) => props.color};
	width: ${(props) => iconSizes[props.size]};
	height: ${(props) => iconSizes[props.size]};
	transform: rotate(${(props) => iconDirections[props.direction]});
	transition: all 0.2s;
	animation: ${(props) =>
		props.animate === 'spin' ? 'spin 2000ms infinite linear' : 'none'};

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const StyledIcon2 = styled.div<IconProps>`
	vertical-align: middle;
	fill: ${(props) => props.color};
	width: ${(props) => iconSizes[props.size]};
	height: ${(props) => iconSizes[props.size]};
	transform: rotate(${(props) => iconDirections[props.direction]});
	transition: all 0.2s;
	animation: ${(props) =>
		props.animate === 'spin' ? 'spin 2000ms infinite linear' : 'none'};

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
