/**
 * NVsePublicationPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Div = styled.div``;


export const MetadataGridContainer = styled.div`
	border-collapse: collapse;
	${props => props.theme.typography.style6.getStyle()};		
	border-top: 1px solid ${(p) => p.theme.colors.black}15;

	display: grid;
	grid-template-columns: auto auto;

	> div {
		vertical-align: top;
		border-bottom: 1px solid ${(p) => p.theme.colors.black}15;
		padding: ${props => props.theme.spacing.space1}rem 0;
	}

	> div:nth-child(odd) {
		font-weight: bold;
		${mediaQueries.phone} {
			font-weight: bold;
		}
		padding-right: ${props => props.theme.spacing.space3}rem;
	}
`;
