import Space from 'components/Boilerplate/Space';
import ListHeader from 'components/ListHeader';
import React from 'react';
import { CollapsibleContainerModel } from 'types/fragments';
import EpiFragments, { Options, wrapElementInGrid } from '../EpiFragments';

export const GetCollapsibleContainerElement = (
	collapsibleContainer: CollapsibleContainerModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	const colorHeading = options.themeContext.isMicrositeActive
		? options.themeContext.palette.text.primary
		: undefined;
	let header: JSX.Element | null = null;
	if (collapsibleContainer.heading) {
		header = (
			<Space bottom={options.themeContext.spacing.space3}>
				<ListHeader
					sectionStyle
					heading={collapsibleContainer.heading}
					headingLevel={options.headingLevel}
					color={colorHeading}
				></ListHeader>
			</Space>
		);

		if (!options.insideCell) {
			header = wrapElementInGrid(header);
		}
	}

	// TODO: use previousBlockType instead of <Space bottom>
	return (
		<>
			{header}
			<EpiFragments
				overrideSpacing={options.themeContext.spacing.space2}
				fragments={collapsibleContainer.items}
				insideCell={options.insideCell}
				insideGrid={options.insideGrid}
				insideInnerGrid={options.insideInnerGrid}
				headingLevel={options.headingLevel}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			></EpiFragments>
		</>
	);
};
