/**
 * ErrorHandler404Page
 */

import BannerLinks from 'components/Banners/BannerLinks';
import EpiFragments, {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { ErrorHandler404PageModel } from './ErrorHandler404Page.model';
import Button from 'components/Button';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { ThemeContext } from 'styled-components';

const ErrorHandler404Page: React.FC<ErrorHandler404PageModel> = ({
	heading,
	preamble,
	homeLink,
	textAsModel,
	bottomItems,
	popularList,
	epi,
	_properties,
	disableCustomHeadingLogic
}) => {

	const themeContext = useContext(ThemeContext);

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;
	let blockTypeBefore_popularList: BlockType = lastBlockType;

	if (textAsModel != null && textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(textAsModel.fragments, themeContext, disableCustomHeadingLogic);
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_popularList = lastBlockType;
	}

	if (bottomItems != null && bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(bottomItems, themeContext, disableCustomHeadingLogic);
		blockTypeBefore_popularList = lastBlockType;
	}

	if (popularList && popularList.items.length > 0) {
		lastBlockType = BlockType.SectionBanner;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	const spaceBefore_popularList = getSpaceToAddBefore(
		blockTypeBefore_popularList,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space top={themeContext.spacing.getPageTopPadding()} bottom={spaceAfterLast}>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			{textAsModel != null && textAsModel.fragments.length > 0 && (
				<EpiFragments
					previousBlockType={BlockType.Element}
					headingLevel={2}
					epi={epi}
					fragments={textAsModel.fragments}
					htmlAttributes={_properties?.mainBody}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}

			{homeLink && homeLink.url && homeLink.heading && (
				<Grid paddingTop={true} paddingBottom={false}>
					<Button variant={ButtonVariant.Primary} to={homeLink.url}>
						{homeLink.heading}
					</Button>
				</Grid>
			)}

			{bottomItems != null && bottomItems.length > 0 && (
				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}

			{popularList != null && popularList.items.length > 0 && (
				<Space top={spaceBefore_popularList}>
					<BannerLinks
						headingLevel={2}
						links={popularList.items}
						heading={popularList.heading}
					></BannerLinks>
				</Space>
			)}
		</Space>
	);
};

export default ErrorHandler404Page;
