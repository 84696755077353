import { useEffect } from 'react';

interface UseOutsideClick {
	(ref: any, callback: any): void;
}

export const useOutsideClick: UseOutsideClick = (ref, callback) => {
	useEffect(() => {
		const listener = (e: any) => {
			if (ref.current && !ref.current.contains(e.target)) {
				callback(e);
			}
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		document.addEventListener('focusin', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
			document.removeEventListener('focusin', listener);
		};
	}, [ref, callback]);
};
