import ExpanderPrototype from 'components/ExpanderPrototype';
import { Heading } from 'components/Typography/Typography';
import { PuffTheme, getThemeHeadingColor } from 'pages/sharedModelTypes';
import { CollapsibleBlockModel } from 'types/fragments';
import EpiFragments, { Options } from '../EpiFragments';

export const GetCollapsibleBlockModelElement = (
	model: CollapsibleBlockModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	const colorHeading =
		model.colorTheme != null
			? getThemeHeadingColor(
					options.themeContext,
					model.colorTheme as PuffTheme
			  )
			: options.themeContext.isMicrositeActive
			? options.themeContext.colors.grey3
			: undefined;

	const headingElement = (
		<Heading
			level={
				options.headingLevel + (options.themeContext.isMicrositeActive ? 2 : 1)
			}
			styleLevel={
				options.headingLevel + (options.themeContext.isMicrositeActive ? 2 : 1)
			}
			zeroBottom={true}
			color={colorHeading}
		>
			{model.heading}
		</Heading>
	);

	const expanderId = `expander${options.index}`;

	const themeStyles =
		model.colorTheme != null
			? (model.colorTheme as PuffTheme)
			: options.themeContext.isMicrositeActive
			? PuffTheme.MicrositeMainTheme
			: PuffTheme.BlueLight;

	return (
		<ExpanderPrototype
			id={expanderId}
			isOpen={!model.showAsClosed}
			heading={headingElement}
			themeHeader={themeStyles}
			themeContent={themeStyles}
		>
			{model.textAsModel && model.textAsModel.fragments.length > 0 && (
				<EpiFragments
					fragments={model.textAsModel.fragments}
					insideCell={options.insideCell}
					insideGrid={options.insideGrid}
					insideInnerGrid={options.insideInnerGrid}
					headingLevel={options.headingLevel + 1}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				></EpiFragments>
			)}
		</ExpanderPrototype>
	);
};
