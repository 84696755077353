/**
 * BannerLinks - styles
 * https://styled-components.com/docs/advanced#referring-to-other-components
 */

import { BannerPanelStyle } from 'components/BannerPanel/BannerPanel.styles';
import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { BannerLinksProps } from './BannerLinks';

export const BannerLinksStyle = styled.div<BannerLinksProps>`
	padding: ${props => props.theme.spacing.space6}rem 0rem;
	${mediaQueries.phoneOrTablet} {
		padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem;
	}

	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};

	${BannerPanelStyle as any} {
		margin-bottom: ${props => props.theme.spacing.space4}rem;
	}
`;

export const BannerLinksListStyle = styled.ul<BannerLinksProps>`
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	${(p) => p.columns && 'column-count:' + p.columns};

	${mediaQueries.phone} {
		column-count: 1;
	}

	> li:last-child {
		margin-bottom: ${0}rem;
	}
`;

export const BannerLinksListItemStyle = styled.li<BannerLinksProps>`
	a {
		color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	}
	margin-bottom: ${props => props.theme.spacing.space2}rem;
`;
