/**
 * EnvironmentToaster
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { EnvironmentId } from 'types/epi';
import { EnvironmentToasterContainer } from './EnvironmentToaster.styles';

type EnvironmentToasterProps = {
	env: string;
	buildDate?: string | null;
};

const translateEnvironment = (env: string): string => {
	switch (env.toUpperCase()) {
		case EnvironmentId.DEV:
			return 'Development';
		case EnvironmentId.SMOKE:
			return 'Smoke';
		case EnvironmentId.INT:
			return 'Integration';
		case EnvironmentId.PREP:
			return 'Pre-Production';
		case EnvironmentId.PROD:
			return 'Production';
		default:
			return env;
	}
};

const prittfyDate = (dateStr: string | null | undefined) => {
	if (dateStr) {
		return new Date(dateStr ? dateStr : 0);
	}
	return dateStr;
};

/** A EnvironmentToaster */
export const EnvironmentToaster = ({
	env,
	buildDate,
}: EnvironmentToasterProps) => {
	let environment = translateEnvironment(env);

	const buildStr = prittfyDate(buildDate);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.desktop - 1,
	});
	const isDesktop = useMediaQuery({ minWidth: breakpointsNumber.desktop });

	return (
		<EnvironmentToasterContainer>
			<div>
				Environment: {environment}
				{isPhone && ' / Phone'}
				{isTablet && ' / Tablet'}
				{isDesktop && ' / Desktop'}
			</div>
			{buildStr && <div>Build: {buildStr.toLocaleString()}</div>}
		</EnvironmentToasterContainer>
	);
};

export default EnvironmentToaster;
