/**
 * BannerPuffText - styles
 */

import { getThemeBackground } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { BannerPuffTextProps } from './BannerPuffText';

export const BannerPuffTextStyle = styled.div<BannerPuffTextProps>`
	display: flex;
	justify-content: center;

	padding: ${props => props.theme.spacing.space7}rem ${props => props.theme.spacing.space6}rem;
	${mediaQueries.phoneOrTablet} {
		padding: ${props => props.theme.spacing.space6}rem ${props => props.theme.spacing.space2}rem;
	}

	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
`;
