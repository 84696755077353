/**
 * FaqPuff
 */

import { RichText } from 'components/Typography';
import React from 'react';
import {
	AnswerContainer,
	QuestionContainer,
	StandardFaqPuff,
} from './FaqPuff.styles';

export type FaqPuffProps = {
	question: string;
	answerRaw: string;
};

/** A FaqPuff */
const FaqPuff = ({ question, answerRaw }: FaqPuffProps) => {
	return (
		<StandardFaqPuff>
			<QuestionContainer>{question}</QuestionContainer>
			<AnswerContainer>
				<RichText>
					<span dangerouslySetInnerHTML={{ __html: answerRaw }} />
				</RichText>
			</AnswerContainer>
		</StandardFaqPuff>
	);
};

export default FaqPuff;
