/**
 * NVseRegulationPage - styles
 */

import styled from 'styled-components';

export const Div = styled.div``;

export const SidebarContainer = styled.aside`
	background-color: ${props => props.theme.colors.white};
	padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space4}rem;

	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;

		> li:last-child {
			margin-bottom: 0rem;
		}
	}

	li {
		margin-bottom: ${props => props.theme.spacing.space2}rem;
	}
`;
