/**
 * NVseJobDetailPage
 */

import BannerLinks from 'components/Banners/BannerLinks';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import PageTopBanner from 'components/PageTopBanner';
import JobDetailSideBarItem from 'pages/NVseJobDetailPage/components/JobDetailSideBarItem/JobDetailSideBarItem';
import { SectionItemContainer, SectionTitleUnderline, SideBarSectionContainer, SideBarSectionTitle } from 'pages/NVseJobDetailPage/components/JobDetailSideBarItem/JobDetailSideBarItem.styles';
import { NVseJobDetailPageModel } from 'pages/NVseJobDetailPage/NVseJobDetailPage.model';
import { PuffTheme } from 'pages/sharedModelTypes';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ContentBodyCell, DetailGrid } from './NVseJobDetailPage.styles';

/** Main description for this component. */
const NVseJobDetailPage: React.FC<NVseJobDetailPageModel> = ({
	heading,
	decal,
	textAsModel,
	contactInfo,
	notification,
	bottomItems,
	siblingsList,
	summary,
}) => {
	const isDesktopOrLarger = useMediaQuery({
		minWidth: breakpointsNumber.desktop,
	});

	const mainItems = () => {
		let items = textAsModel.fragments;
		if (notification) {
			items = [notification, ...textAsModel.fragments];
		}

		return items;
	};

	return (
		<article>
			<PageTopBanner
				heading={heading}
				decal={decal}
				themeTop={PuffTheme.GreenDark}
			/>
			<DetailGrid>
				<Grid inner>
					<Cell span={4} phone={12} tablet={12} desktop={4}>
						<SectionItemContainer>
							<JobDetailSideBarItem
								title={summary.title}
								items={summary.items}
								button={summary.button}
							/>
							{isDesktopOrLarger && contactInfo && (
								<SideBarSectionContainer>
									<div>
										<SideBarSectionTitle>{contactInfo.heading}</SideBarSectionTitle>
										<SectionTitleUnderline />
										<EpiFragments
											fragments={contactInfo.textAsModel.fragments}
											insideInnerGrid
											insideCell={true}
											insideGrid={true}
											overrideSpacing={4}
										/>
									</div>
								</SideBarSectionContainer>
							)}
						</SectionItemContainer>
					</Cell>
					<ContentBodyCell span={8} phone={12} tablet={8} desktop={8}>
						<EpiFragments
							fragments={mainItems()}
							insideInnerGrid
							overrideSpacing={4}
						/>
						{!isDesktopOrLarger && contactInfo && (
							<SideBarSectionContainer>
								<div>
									<SideBarSectionTitle>{contactInfo.heading}</SideBarSectionTitle>
									<SectionTitleUnderline />
									<EpiFragments
										fragments={contactInfo.textAsModel.fragments}
										insideInnerGrid
										overrideSpacing={4}
									/>
								</div>
							</SideBarSectionContainer>
						)}
					</ContentBodyCell>
				</Grid>
			</DetailGrid>
			<DetailGrid>
				<EpiFragments fragments={bottomItems} insideGrid />
			</DetailGrid>
			{siblingsList.items?.length > 0 && (
				<Space>
					<BannerLinks
						headingLevel={2}
						heading={siblingsList.heading}
						links={siblingsList.items}
					></BannerLinks>
				</Space>
			)}
		</article>
	);
};

export default NVseJobDetailPage;
