import BannerPuffText from 'components/Banners/BannerPuffText';
import BannerPuffTextImage from 'components/Banners/BannerPuffTextImage';
import BannerPuffTextYoutube from 'components/Banners/BannerPuffTextYoutube';
import { LinkType } from 'pages/sharedModelTypes';
import React from 'react';
import {
	ImageFragmentModel,
	MediaBannerFragmentModel,
	YouTubeFragmentModel,
} from 'types/fragments';
import { Options, wrapElementInGrid } from '../EpiFragments';

export const GetMediaBannerElement = (
	mediaBannerData: MediaBannerFragmentModel,
	options: Options
) => {
	let link: LinkType | null = null;
	let element = null;

	if (
		mediaBannerData.link &&
		mediaBannerData.link.heading &&
		mediaBannerData.link.url
	) {
		link = {
			heading: mediaBannerData.link.heading,
			url: mediaBannerData.link.url,
			isFile: mediaBannerData.link.isFile,
		} as LinkType;
	}

	if (mediaBannerData.mediaContent?.modelType === 'Image') {
		element = (
			<BannerPuffTextImage
				textToRight={mediaBannerData.textToRight}
				themeName={mediaBannerData.theme}
				decal={mediaBannerData.title}
				headingLevel={options.headingLevel}
				heading={mediaBannerData.heading}
				text={mediaBannerData.text}
				link={link}
				image={mediaBannerData.mediaContent as ImageFragmentModel}
			></BannerPuffTextImage>
		);
	}

	if (mediaBannerData.mediaContent?.modelType === 'YouTubeModel') {
		element = (
			<BannerPuffTextYoutube
				textToRight={mediaBannerData.textToRight}
				themeName={mediaBannerData.theme}
				decal={mediaBannerData.title}
				headingLevel={options.headingLevel}
				heading={mediaBannerData.heading}
				text={mediaBannerData.text}
				link={link}
				youtube={mediaBannerData.mediaContent as YouTubeFragmentModel}
			></BannerPuffTextYoutube>
		);
	}

	if (!mediaBannerData.mediaContent) {
		element = (
			<BannerPuffText
				themeName={mediaBannerData.theme}
				decal={mediaBannerData.title}
				headingLevel={options.headingLevel}
				heading={mediaBannerData.heading}
				preamble={mediaBannerData.text}
				link={link}
			></BannerPuffText>
		);
	}

	if (element === null) {
		return null;
	}

	if (options?.insideGrid) {
		return element;
	}

	return wrapElementInGrid(element);
};
