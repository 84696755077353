/**
 * Checkbox - styles
 */

import styled from 'styled-components';
import { CheckboxProps } from './Checkbox';
import { accessibilityFocus } from 'theme/styles';
import { mediaQueries } from 'theme';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;
export const Icon = styled.svg`
	fill: none;
	stroke: ${(props) => props.theme.colors.primary};
	stroke-width: 3px;
`;

export const CheckboxContainer = styled.div`
	${mediaQueries.phone} {
		padding: 12px 1rem;
	}

	label {
		cursor: pointer;

		display: inline-flex;

		${(props) => props.theme.typography.style6.getStyle()};
		${mediaQueries.phone} {
			align-items: center;
			font-size: 0.125rem;
		}
	}

	label:focus-within {
		${accessibilityFocus()};
	}

	span {
		${(props) => props.theme.typography.style9.getStyle()};
		color: ${(props) => props.theme.palette.text.primary};
	}
`;
export const StyledCheckbox = styled.div<CheckboxProps>`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	border: 2px solid ${(props) => props.theme.colors.primary};
	border-radius: 0px;
	margin-right: 10px;

	background-color: ${(props) => props.theme.colors.white};

	${HiddenCheckbox}:hover + & {
		outline: 1px solid ${(props) => props.theme.colors.primary};
	}
	${Icon} {
		visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
	}

	${mediaQueries.phone} {
		width: 20px;
		height: 20px;
	}
`;
