import { createGlobalStyle } from 'styled-components';

// Tiempos Fine Light	:	H1,H2,H3
// Tiempos Text Regular	:	H4, H5, body
// Akkurat Pro Regular	:	a,p,p2,byline
import akkuratRegularWoff from 'fonts/AkkuratLLWeb-Regular.woff';
import akkuratRegularWoff2 from 'fonts/AkkuratLLWeb-Regular.woff2';
import akkuratBoldWoff from 'fonts/AkkuratLLWeb-Bold.woff';
import akkuratBoldWoff2 from 'fonts/AkkuratLLWeb-Bold.woff2';

import tiemposFineLightWoff from 'fonts/TiemposFineWeb-Light.woff';
import tiemposFineLightWoff2 from 'fonts/TiemposFineWeb-Light.woff2';

import tiemposTextRegulatWoff from 'fonts/TiemposText-Regular.woff';
import tiemposTextRegulatWoff2 from 'fonts/TiemposText-Regular.woff2';
import {
	styleForInput,
	styleForLabel,
	styleForP,
	styleForSelect,
} from 'components/Typography';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from './styles';

// How To Set Weights And Styles With The @font-face Declaration
// https://www.smashingmagazine.com/2013/02/setting-weights-and-styles-at-font-face-declaration/

export type modalWindow = {
	isOpen?: boolean;
};

export const GlobalStyle = createGlobalStyle<any>`

	@font-face {
		font-family: 'Akkurat Pro';
		src: url(${akkuratRegularWoff2}) format('woff2'),
		 	url(${akkuratRegularWoff}) format('woff');
		font-weight: 400;
		font-style: normal;		
	}

	@font-face {
		font-family: 'Akkurat Pro';
		src: url(${akkuratBoldWoff2}) format('woff2'),
		 	url(${akkuratBoldWoff}) format('woff');
		font-weight: 700;
		font-style: normal;		
	}

	@font-face {
		font-family: 'Tiempos Fine';
		src: url(${tiemposFineLightWoff2}) format('woff2'),
		 	url(${tiemposFineLightWoff}) format('woff');
		font-weight: 300;
		font-style: normal;		
	}	

	@font-face {
		font-family: 'Tiempos Text';
		src: url(${tiemposTextRegulatWoff2}) format('woff2'),
		 	url(${tiemposTextRegulatWoff}) format('woff');
		font-weight: 400;
		font-style: normal;		
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
	html {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		width: 100vw;
		overflow-x: hidden;

		/* Font smoothing */
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smooth: antialiased;
		-webkit-font-smoothing: antialiased;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
	}
	body {
		margin: 0;
		padding: 0;
		background-color: ${(props) => props.theme.colors.grey5};
		
		color: ${(props) => props.theme.palette.text.primary};

		${(p) => p.isOpen && `top: -${window.scrollY}px`};
		${(p) => p.isOpen && 'position: fixed; right:0;left:0; overflow-y: hidden'};

		& label,& input, & textarea, & a, & select, & button, & li {
			outline-color:transparent;
		}

		& input:focus, & textarea:focus, & a:focus, & select:focus, & button:focus {
			${accessibilityFocus()};
		}

	}
	h1,h2,h3,h4,h5,h6,a,p {
		word-break: normal;
		overflow-wrap: break-word;
		hyphens: manual;
	}

	img {
		border: 0 none;
		height: auto;
		max-width: 100%;
		vertical-align: middle;
	}
	.usability--tab-focus {
		outline: 0.2rem solid ${(props) => props.theme.colors.accentBlue};
	}
				
					
	.skip-to-content-link {
		${(props) => props.theme.typography.style8.getStyle()};
		color: ${(props) => props.theme.palette.text.secondary};
		padding: 8px;
		position: absolute;
		background-color: ${(props) => props.theme.colors.primary};
		left: 0%;
		transform: translateY(-100%);
		transition: transform 200ms;
		clip: rect(0 0 0 0);
		outline: none;
	}	
	.skip-to-content-link:focus {
  		transform: translateY(0%);
		 width: 100%;
    	text-align: center;		  
	    z-index: 1;		  
		clip: auto;
	}						

	form {

		header {
			margin-bottom: ${(props) => props.theme.spacing.space3}rem;
		}


		button {
			${(props) => props.theme.typography.style11.getStyle()};
			border-radius: 24px;
			border: 1px solid transparent;
			padding:0;

			padding-top: 16px;
			padding-bottom: 11px;

			padding-left: 20px;
			padding-right: 20px;
			background-color: ${(props) => props.theme.colors.secondaryBlue};
			color: ${(props) => props.theme.palette.text.secondary};

			&:hover {
				background-color: ${(props) => props.theme.colors.primary};
			}
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}

		input, textarea {
			${styleForInput}
		}

		input[type="file"] {
			${styleForInput}
			border: none;
			padding: 0;
			width: 100%;
		}		

		fieldset {
			border: none;
	    	padding: 0;		
		}

		abbr[title], acronym[title] {
			text-decoration: unset;
		}

		label, legend { 
			display: block;
			text-align: left;
			${styleForLabel}
			font-weight: bold;
			margin-right: ${(props) => props.theme.spacing.space1}rem;
			margin-bottom: ${(props) => props.theme.spacing.space1}rem;
		}

		select {
			${styleForSelect}
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}

		.usability--tab-focus {

		}

		.radio {
		
			position: relative;
			display: flex;

			&:focus-within {
				${accessibilityFocus()};
			}

			label {
				${(props) => props.theme.typography.style6.getStyle()};
    			display: block;
    			padding: 0px 0 0px 32px;
				margin:0px 0px 5px 0px;
	
			}
	


			/*style and hide original checkbox*/
			input {
				height: 10px;
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				width: 10px;
			}

			/*position new box*/
			input + label::before {
				border: 2px solid ${(props) => props.theme.colors.primary};
				content: "";
				height: 24px;
				left: 0;
				position: absolute;
				top: 0;
				width: 24px;
				border-radius: 50%;
			}

			/*radio pseudo element styles*/
			input + label::after {
				content: "";
				opacity: 0;
				border: 5px solid ${(props) => props.theme.colors.primary};
				border-radius: 50%;
				position: absolute;
				left: 7px;
				top: 7px;
				transition: opacity 0.2s ease-in-out;
			}

			/*reveal check for 'on' state*/
			input:checked + label::after {
				opacity: 1;
			}
		}

		.block {
			display:block;
		}

		.text-description {
			${(props) => props.theme.typography.style9.getStyle()};
			margin-bottom:${(props) => props.theme.spacing.space1}rem;

		}
		
		// Epi forms
		.text-red-600 {
			${(props) => props.theme.typography.style9.getStyle()};
			color: ${(props) => props.theme.palette.text.error};
			margin-top:${(props) => props.theme.spacing.space1}rem;
			${mediaQueries.phone} {
				color: ${(props) => props.theme.palette.text.error};
			}

		}

		.warning-symbol {
			
		}

		.warning-symbol::before {
			content:"\u2022\u00a0"
		}

		.border-red-400 {
			padding: ${(props) => props.theme.spacing.space3}rem;
			border: 2px solid ${(props) => props.theme.colors.accentRed};
			border-left: 8px solid ${(props) => props.theme.colors.accentRed};
			background-color: ${(props) => props.theme.colors.white};
			display: inline-block;
			box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.03);
		}

		.border-green-400 {
			padding: ${(props) => props.theme.spacing.space3}rem;
			border: 2px solid ${(props) => props.theme.colors.green1};
			border-left: 8px solid ${(props) => props.theme.colors.green1};
			background-color: ${(props) => props.theme.colors.white};
			display: block;
			box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.03);
		}

		.border-green-400 > div {
			${styleForP}
		}

		// Epi forms error ul list
		ul {
			list-style-position: outside;
			margin: 0;
			padding: 0;
			padding-left: ${(props) => props.theme.spacing.space2 + 0.25}rem;
		}

		li {
			${styleForP}
			margin-bottom: 0;
		}


		li > span {
			color: ${(props) => props.theme.palette.text.error};
			${mediaQueries.phone} {
				color: ${(props) => props.theme.palette.text.error};
			}

		}
}


`;
