import styled from 'styled-components';

export const SidebarRelatedPanelStyle = styled.div`
	p {
		margin: 0rem;
		${props => props.theme.typography.style5.getStyle()};		
		margin-bottom: ${props => props.theme.spacing.space2}rem;
	}

	ul {
		list-style-type: none;
		padding: 0px;
		margin-bottom: 0;
		> :last-child {
			margin-bottom: 0;
		}
		margin-top: 0;
	}

	li {
		display: table;
		${props => props.theme.typography.style9.getStyle()};		
		padding-top: 4px;
		padding-bottom: 3px;
		padding-left: ${props => props.theme.spacing.space1}rem;
		padding-right: ${props => props.theme.spacing.space1}rem;

		margin-bottom: ${props => props.theme.spacing.space2}rem;
		background-color: rgba(203, 203, 203, 0.32);
	}
`;
