/**
 * Button
 */

import Icon from 'components/Boilerplate/Icon';
import { IconNames } from 'components/Boilerplate/Icon/Icon';
import React from 'react';
import { StandardButton, LinkButton } from './Button.styles';
import { ButtonVariant, ButtonColors } from 'pages/sharedModelTypes';

export interface ButtonProps {
	type?: 'submit' | 'button' | 'reset';
	variant?: ButtonVariant;
	color?: ButtonColors;
	fill?: boolean;
	iconName?: IconNames;
	iconColor?: string;
	iconPosition?: number;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: any;
	tabIndex?: number;
	children?: any;
	disabled?: boolean;
	to?: string;
	external?: boolean;
	showLinkIcon?: boolean;
}

export type PlayIconProps = {
	hover: boolean;
};

//	size?: 'large' | 'medium' | 'small';

/** A button / link component. */
const Button = React.forwardRef<any, ButtonProps>(
	(
		{
			variant = ButtonVariant.Primary,
			color,
			to,
			fill,
			type = 'button',
			onClick,
			tabIndex,
			children,
			iconName,
			iconColor,
			iconPosition = 0,
			className,
			disabled,
			showLinkIcon,
			external,
			...propsToTransfer
		},
		ref
	) => {
		let content;
		if (iconName) {
			if (iconPosition === 0) {
				content = (
					<>
						<Icon color={iconColor} size={2} icon={iconName} />
						{children && <span>{children}</span>}
					</>
				);
			} else {
				content = (
					<>
						{children && <span>{children}</span>}
						<Icon color={iconColor} size={2} icon={iconName} />
					</>
				);
			}
		} else {
			content = <span>{children}</span>;
		}

		if (to) {
			return (
				<LinkButton
					ref={ref}
					tabIndex={tabIndex}
					variant={variant}
					$fill={fill}
					to={to}
					$color={color}
					showLinkIcon={showLinkIcon}
					external={external}
					{...propsToTransfer}
				>
					{content}
				</LinkButton>
			);
		}
		return (
			<StandardButton
				ref={ref}
				className={className}
				onClick={onClick}
				variant={variant}
				$fill={fill}
				$color={color}
				tabIndex={tabIndex}
				disabled={disabled}
				{...propsToTransfer}
			>
				{content}
			</StandardButton>
		);
	}
);

export default Button;
