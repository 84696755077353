interface Props {
	id: string;
	label: string;
	required?: boolean;
	pattern?: string;
	type?: string;
	validationMessage?: string;
	patternMessage?: string;
	single?: boolean;
	multiple?: boolean;
}
interface FieldValidation {
	(fieldRef: any, fieldProps: Props): [
		ValidateField,
		ShowFieldError,
		HideFieldError
	];
}
interface ValidateField {
	(value: string[] | string | number | boolean): Field;
}
interface ShowFieldError {
	(field: Field): void;
}
interface HideFieldError {
	(): void;
}
interface Field {
	label: string;
	id: string;
	message?: string;
}

/**
 * Custom hook for validating a field
 */
export const useFieldValidation: FieldValidation = (fieldRef, fieldProps) => {
	const {
		required,
		pattern,
		label,
		id,
		type,
		validationMessage,
		patternMessage,
		single,
	} = fieldProps;

	const validateField: ValidateField = (value) => {
		let field: Field = { id, label };

		const fieldIsNotValid = value !== '' && !fieldRef.current.checkValidity();
		const requiredFieldIsNotFilledIn =
			(required && value === '') ||
			(required && value === 'fieldset' && !fieldsetHasOneCheckedInput());
		const fieldDoesNotMatchPattern =
			pattern && !value.toString().match(pattern) && value !== '';
		const singleInputIsNotChecked = single && required && !value;

		if (type === 'url' && fieldIsNotValid) {
			field.message = validationMessage || 'Tillhandahåll en giltig url.';
		} else if (type === 'email' && fieldIsNotValid) {
			field.message =
				patternMessage ||
				validationMessage ||
				'Tillhandahåll en giltig e-postadress.';
		} else if (requiredFieldIsNotFilledIn || singleInputIsNotChecked) {
			field.message =
				validationMessage ||
				'Du måste fylla i det här fältet för att gå vidare.';
		} else if (pattern && fieldDoesNotMatchPattern) {
			field.message =
				patternMessage || 'Vänligen matcha det förväntade formatet.';
		}

		return field;
	};

	/**
	 * Checks if any input (radio/checkbox) within the fieldset group has been checked
	 */
	const fieldsetHasOneCheckedInput = () => {
		const allInputs = fieldRef.current.querySelectorAll('input');
		let hasOneValidInput = false;

		allInputs.forEach((field: any) => {
			if (field.checked) {
				hasOneValidInput = true;
			}
		});

		return hasOneValidInput;
	};

	/**
	 * Displays a field error with message
	 */
	const showFieldError: ShowFieldError = (field) => {
		let label: any = document.querySelector(`#form${id}__desc`);
		fieldRef.current.classList.add('invalid');

		if (label) {
			label.textContent = field.message;
			label.removeAttribute('hidden');
		}
	};

	/**
	 * Hides field error and clears message
	 */
	const hideFieldError: HideFieldError = () => {
		let label = document.querySelector(`#form${id}__desc`);
		fieldRef.current.classList.remove('invalid');

		if (label) {
			label.textContent = '';
			label.setAttribute('hidden', 'true');
		}
	};

	return [validateField, showFieldError, hideFieldError];
};
