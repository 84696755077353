/**
 * BannerPuffTextYoutube
 */
import React from 'react';
import {
	BannerPuffTextYoutubeStyle,
} from './BannerPuffTextYoutube.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import BannerPanel from 'components/BannerPanel';
import YouTube from 'components/YouTube';
import { YouTubeFragmentModel } from 'types/fragments';
import { ColumnContainer } from '../BannerPuffTextImage/BannerPuffTextImage.styles';

export interface BannerPuffTextYoutubeProps {
	textToRight?: boolean;
	themeName?: PuffTheme;
	decal?: string;
	/**
	 * Heading level
	 */
	headingLevel?: number;
	heading?: string;
	text?: string | null;
	link?: LinkType | null;
	youtube?: YouTubeFragmentModel;
}

const BannerPuffTextYoutube: React.FC<BannerPuffTextYoutubeProps> = ({
	textToRight = false,
	themeName,
	decal,
	heading,
	headingLevel,
	text,
	link,
	youtube,
}) => {
	return (
		<BannerPuffTextYoutubeStyle themeName={themeName}>
			<ColumnContainer textToRight={textToRight}>
				<BannerPanel
					headingLevel={headingLevel}
					alignbottom={true}
					themeName={themeName}
					decal={decal}
					heading={heading}
					text={text}
					link={link}
				></BannerPanel>

				{youtube && (
					<YouTube
						title={youtube.title}
						videoId={youtube.videoID}
						allowfullscreen={youtube.allowFullScreen}
					/>
				)}
			</ColumnContainer>
		</BannerPuffTextYoutubeStyle>
	);
};

export default BannerPuffTextYoutube;
