/**
 * JobListPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { px2rem } from 'theme/utils';

export const Div = styled.div`
	display: flex;
	justify-content: center;
`;

export const SeeMore = styled.p`
	display: flex;
	align-items: center;
	color: #003366;
	font-family: Akkurat Pro;
	font-size: 1.125rem;
	cursor: pointer;
`;

export const AdsDisplayed = styled.p`
	font-size: 1.125rem;
`;

export const StyledTable = styled.table`
	caption-side: top;
	border: none;
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 2rem;
	td,
	th {
		border: none;
	}
	${mediaQueries.phone} {
		width: 90vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	thead > tr {
		width: 100%;
		height: 4.5rem;

		display: grid;
		grid-template-columns: 2.5fr repeat(3, 1fr);
		align-items: center;
		justify-items: flex-start;

		font-family: Akkurat Pro;
		background-color: #003366;
		color: white;
		padding: 5px 2rem;

		${mediaQueries.tablet} {
			grid-template-columns: minmax(14rem, 1fr) repeat(3, 1fr);
			th:first-of-type {
				text-align: left;
			}
		}

		${mediaQueries.phone} {
			height: 3rem;
			grid-template-columns: 1fr;
			padding: 0px;
			th:first-of-type {
				display: flex;
				width: 100%;
				padding: 1rem;
			}
			th {
				display: none;
			}
		}
	}

	tbody tr {
		width: 100%;
		background-color: white;
		display: grid;
		grid-template-columns: 2.5fr repeat(3, 1fr);
		grid-template-rows: auto;
		align-items: flex-start;
		justify-items: flex-start;
		padding: 0 2rem;
		margin: 10px 0;

		td {
			font-family: Akkurat Pro;
			padding: 1.5rem 2rem 1.5rem 0rem;
			a {
				text-decoration: none;
				color: #003366;
				font-family: 'Tiempos Text';
				line-height: ${px2rem(26)};
				font-size: 1.125rem;
				overflow-wrap: normal;
			}
		}

		${mediaQueries.tablet} {
			grid-template-columns: minmax(14rem, 1fr) repeat(3, 1fr);
		}

		${mediaQueries.phone} {
			height: auto;
			grid-template-columns: 1fr;
			margin: 10px 0px;
			padding: 0;
			td {
				font-size: 1.125rem;
				padding: .4rem 1rem;
			}
			td:first-of-type {
				padding-top: 1.25rem;
			}
			td:last-of-type {
				padding-bottom: 1.25rem;
			}
			a {
				margin-right: 0px;
			}

			td:last-of-type div {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				span:first-of-type {
					font-weight: bold;
					margin-bottom: 5px;
				}
			}
		}
	}
`;
