import styled from 'styled-components';
import { accessibilityFocus } from 'theme/styles';
import { px2rem } from 'theme/utils';

export const AutocompleteContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const SuggestionsList = styled.ul`
	position: absolute;
	width: 100%;
	max-height: 300px;
	overflow-y: hidden;
	background-color: #fff;
	list-style-type: none;
	border-radius: 8px;
	padding: 0;
	margin: 0;
	z-index: 1000;
	margin-top: 4px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
	padding-top: ${px2rem(10)};
	padding-bottom: ${px2rem(10)};
	li {
		width: 100%;
		max-width: none;
	}
`;

export const SuggestionItem = styled.p<{
	isfocused: boolean;
}>`
	cursor: pointer;
	max-width: none;
	width: 100%;
	margin: 0;
	padding: ${px2rem(2)} ${px2rem(12)} ${px2rem(2)} ${px2rem(12)};
	display: block;
	span {
		${(props) => props.theme.typography.style9.getStyle()};
		color: ${(props) => props.theme.palette.text.primary};
	}

	&:hover {
		background-color: #f0f0f0;
	}
	${(props) => props.isfocused && accessibilityFocus()};
`;

export const RegularText = styled.span`
	font-weight: 400 !important;
`;

export const HighlightedText = styled.span`
	font-weight: 700 !important;
	color: ${(props) => props.theme.colors.primary} !important;
`;
