/**
 * Highcharts
 */
import React, { useEffect, useRef, useState } from 'react';

import HighchartsModule from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Options } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import dataModule from 'highcharts/modules/data';
import accessibility from 'highcharts/modules/accessibility';

import {
	HighchartAndTable,
	HighchartsLegendsActions,
	HighchartsLegendsStyle,
	HighchartsLegendsSymbols,
	HighchartsStyle,
} from './Highcharts.styles';

import { EpiEvent } from 'types/epi';
import Button from 'components/Button';
import Tab, { TabProps } from 'components/Tab/Tab';
import { ButtonVariant } from 'pages/sharedModelTypes';

import Checkbox from 'components/Checkbox';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import DropdownMenu, {
	DropdownMenuItem,
} from 'components/Banners/DropdownMenu/DropdownMenu';
import { LinkType } from 'pages/sharedModelTypes';

export interface HighchartsProps {
	data?: Options;
	showLegends?: boolean;
	showAsChartLabel?: string;
	showAsTableLabel?: string;
	downloadLabel?: string;
	exportAsLabel?: string;
	legendHeading?: string;
	legendSelectAllLabel?: string;
	legendSelectNoneLabel?: string;
	categoryLabel?: string;
	useShortDate: boolean;
	statisticsClassificationsLink?: LinkType;
	classificationList: string[];
	epi?: EpiEvent; // deprecated
	id?: string;
}

export type ChartVisibleProps = {
	showChartMode: boolean;
};

enum MimeTypes {
	pdf = 'application/pdf',
	png = 'image/png',
	jpg = 'image/jpeg',
	svg = 'image/svg+xml',
	csv = 'excel',
}

export const Highcharts: React.FC<HighchartsProps> = ({
	data,
	showAsChartLabel = 'Visa som graf',
	showAsTableLabel = 'Visa som tabell',
	downloadLabel = 'Ladda ner',
	exportAsLabel = 'Spara som',
	legendHeading = 'Filtrering',
	legendSelectAllLabel = 'Tänd alla',
	legendSelectNoneLabel = 'Släck alla',
	categoryLabel = null,
	showLegends = true,
	useShortDate,
	statisticsClassificationsLink,
	classificationList,
	epi,
	id = 'hc',
}) => {
	const chartId = `${id}-chart`;
	const tableId = `${id}-table`;

	const [selectedTabPage, setSelectedTabPage] = useState<string>(chartId);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
	const [showFilterLegends, setShowFilterLegends] = useState<boolean>(true);
	const [legends, setLegends] = useState<HighchartsLegendItem[]>([]);
	const [classificationLegends, setClassificationLegends] = useState<
		HighchartsLegendItem[]
	>(
		classificationList?.map((item, index) => {
			return {
				id: item,
				name: item,
				checked: true,
				symbolType: 'circle' as SymbolType,
				symbolColor: 'red',
			} as HighchartsLegendItem;
		})
	);
	const tabPops = {
		label: 'Utforska',
		defaultTabId: selectedTabPage,
		autoSelect: false,
		items: [
			{
				heading: showAsChartLabel,
				id: chartId,
				iconName: null,
			},
			{
				heading: showAsTableLabel,
				id: tableId,
				iconName: null,
			},
		],
	} as TabProps;

	const stateRef = useRef<Highcharts.Options>();
	stateRef.current = chartOptions;

	const chartRef = React.createRef();

	useEffect(() => {
		if (typeof HighchartsModule === 'object') {
			// Initialize the modules
			exporting(HighchartsModule);
			exportData(HighchartsModule);
			dataModule(HighchartsModule);
			accessibility(HighchartsModule);

			HighchartsModule.setOptions({
				exporting: {
					filename: window.location.pathname.replace('/', ''),
				},
				lang: {
					loading: 'Laddar...',
					resetZoom: 'Återställ zoom',
					numericSymbols: [
						' Tusen',
						' Miljoner',
						' Miljarder',
						' Biljoner',
						' Biljarder',
						' Triljoner',
					],
					decimalPoint: ',',
					months: [
						'Januari',
						'Februari',
						'Mars',
						'April',
						'Maj',
						'Juni',
						'Juli',
						'Augusti',
						'September',
						'Oktober',
						'November',
						'December',
					],
					weekdays: [
						'Söndag',
						'Måndag',
						'Tisdag',
						'Onsdag',
						'Torsdag',
						'Fredag',
						'Lördag',
					],
					shortMonths: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'Maj',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Okt',
						'Nov',
						'Dec',
					],
				},
			});
		}
	}, []);

	useEffect(() => {
		if (selectedTabPage === tableId) {
			// Controlling the location of the Table when using showTable
			// https://www.highcharts.com/forum/viewtopic.php?t=41596

			const newOptions = { ...chartOptions };
			const showTable = true;

			if (!newOptions.exporting) {
				newOptions.exporting = {};
			}

			newOptions.exporting.showTable = showTable;
			newOptions.exporting.tableCaption = newOptions.title?.text;

			if (newOptions.exporting.csv != null) {
				if (useShortDate) {
					newOptions.exporting.csv.dateFormat = '%Y-%m-%d';
				} else {
					newOptions.exporting.csv.dateFormat = '%Y-%m-%d %H:%M';
				}
			}

			setChartOptions(newOptions);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabPage]);

	useEffect(() => {
		const legendItemClick = (event: any) => {
			event.preventDefault();
			const serie = event.target
				.userOptions as HighchartsModule.SeriesOptionsType;
			// toggle state
			if (serie && serie.id) {
				setVisibilityOnSerie(stateRef.current, serie.id, !serie.visible);
			}
		};

		const enhanceOptions = (data: Highcharts.Options) => {
			// Set defaults
			if (data.chart) {
				data.chart.backgroundColor = 'transparent';
				data.chart.marginTop = 30;
			} else {
				data.chart = {
					backgroundColor: 'transparent',
					marginTop: 30,
				};
			}

			// undefined  = visar   set(true)
			// true		  = visar   set(true)
			// false      = hide    set(false)

			if (!showLegends || (data.legend && data.legend.enabled === false)) {
				setShowFilterLegends(false);
			} else {
				setShowFilterLegends(true);
			}

			// Hide build in legends
			if (showLegends) {
				if (data.legend) {
					data.legend.enabled = false;
				} else {
					data.legend = {
						enabled: false,
					};
				}
			}

			// Customize "Category" name
			if (data.exporting === undefined) {
				data.exporting = {};
			}

			if (data.exporting.chartOptions) {
				if (data.exporting.chartOptions.legend) {
					data.exporting.chartOptions.legend = {
						...data.exporting.chartOptions.legend,
						enabled: true,
					};
				} else {
					data.exporting.chartOptions = {
						...data.exporting.chartOptions,
						legend: {
							enabled: true,
						},
					};
				}
			} else {
				data.exporting.chartOptions = {
					legend: {
						enabled: true,
					},
				};
			}

			if (data.exporting.csv === undefined) {
				data.exporting.csv = {};
			}

			data.exporting.csv.columnHeaderFormatter = (item: any, key: any) => {
				if (!item || item instanceof HighchartsModule.Axis) {
					return categoryLabel;
				} else {
					return item.name;
				}
			};

			// Add event
			if (data.plotOptions) {
				if (data.plotOptions.series) {
					if (data.plotOptions.series.events) {
						data.plotOptions.series.events.legendItemClick = legendItemClick;
					} else {
						data.plotOptions.series.events = {
							legendItemClick: legendItemClick,
						};
					}
				} else {
					data.plotOptions.series = {
						events: {
							legendItemClick: legendItemClick,
						},
					};
				}
			} else {
				data.plotOptions = {
					series: {
						events: {
							legendItemClick: legendItemClick,
						},
					},
				};
			}
		};

		if (data) {
			enhanceOptions(data);
			setChartOptions(data);
		}
	}, [data, showLegends]);

	useEffect(() => {
		if (chartOptions) {
			const legends = chartOptions.series?.map((item, index) => {
				item.id = index.toString();
				if (item.visible === undefined) {
					item.visible = true;
				}

				let a = (chartRef.current as any).chart;
				const symbolType = a.series[index].symbol;
				const symbolColor = a.series[index].color;

				return {
					id: item.id,
					name: item.name,
					checked: item.visible,
					symbolType: symbolType as SymbolType,
					symbolColor: symbolColor,
				} as HighchartsLegendItem;
			});
			setLegends(legends ? legends : []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartOptions]);

	const onTabPageSelected = (panelId: string) => {
		setSelectedTabPage(panelId);
	};

	const onLegendsCheckAll = () => {
		setAllLegenSelect(true);
	};

	const onLegendsUnCheckAll = () => {
		setAllLegenSelect(false);
	};

	const setAllLegenSelect = (show: boolean) => {
		if (chartOptions && chartOptions.series) {
			chartOptions.series.forEach((chartSerieitem) => {
				chartSerieitem.visible = show;
			});

			setChartOptions({
				...chartOptions,
			});
		}
		if (classificationLegends) {
			const groups = classificationLegends.map((item) => {
				return {
					id: item.id,
					name: item.name,
					checked: show,
					symbolType: 'circle' as SymbolType,
					symbolColor: 'red',
				} as HighchartsLegendItem;
			});
			setClassificationLegends(groups);
		}
	};

	const legendCheckedChanged = (
		item: HighchartsLegendItem,
		checked: boolean
	) => {
		setVisibilityOnSerie(chartOptions, item.id, checked);
	};

	const classificationLegendCheckedChanged = (
		item: HighchartsLegendItem,
		classification: string,
		checked: boolean
	) => {
		setVisibilityOnClassificationSeries(chartOptions, classification, checked);
	};

	const setVisibilityOnSerie = (
		options: Highcharts.Options | undefined,
		id: string,
		enabled: boolean | undefined
	) => {
		if (options && options.series) {
			const pos = options.series.findIndex((chartSerieitem) => {
				return chartSerieitem.id === id;
			});
			if (pos !== -1) {
				if (options && options.series) {
					options.series[pos].visible = enabled ? enabled : false;
					setChartOptions({
						...options,
					});
				}
			} else {
				console.error('Missing serie:' + id);
			}
		} else {
			console.error('chartOptions not set');
		}
	};

	const setVisibilityOnClassificationSeries = (
		options: Highcharts.Options | undefined,
		classification: string,
		enabled: boolean | undefined
	) => {
		if (classificationLegends) {
			const groups = classificationLegends.map((item) => {
				return {
					id: item.id,
					name: item.name,
					checked: item.name == classification ? enabled : item.checked,
					symbolType: item.symbolType,
					symbolColor: item.symbolColor,
				} as HighchartsLegendItem;
			});
			setClassificationLegends(groups);
		}

		if (options && options.series) {
			options.series.forEach((chartSerieitem) => {
				if (chartSerieitem.custom?.classification == classification) {
					chartSerieitem.visible = enabled;
					setChartOptions({
						...options,
					});
				}
			});
		} else {
			console.error('chartOptions not set');
		}
	};

	// const onExportSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
	// 	const exportFormat = event.target.value as MimeTypes;

	// 	if (exportFormat === MimeTypes.xls) {
	// 		(chartRef.current as any).chart.downloadXLS();
	// 	} else {
	// 		const mimeType = {
	// 			type: exportFormat,
	// 			filename: undefined,
	// 		} as any;
	// 		(chartRef.current as any).chart.exportChart(mimeType); // This works (save a png file)
	// 	}

	// 	setSelectedDownload("");
	// };

	const onExportSelect = (value: string) => {
		if (value === MimeTypes.csv) {
			(chartRef.current as any).chart.downloadCSV();
		} else {
			const mimeType = {
				type: value,
				filename: undefined,
			} as any;
			(chartRef.current as any).chart.exportChart(mimeType); // This works (save a png file)
		}
	};

	const chartElement = (
		<HighchartAndTable showChartMode={selectedTabPage === chartId}>
			{chartOptions && (
				<HighchartsReact
					id={chartId}
					ref={chartRef as any}
					highcharts={HighchartsModule}
					options={chartOptions}
				/>
			)}
			{/* todo, the id should be placed on the inserted div by highchart */}
			<div id={chartId}></div>
		</HighchartAndTable>
	);

	const withLegends = (
		<Grid inner={true}>
			<Cell span={7} tablet={8} phone={4}>
				{chartElement}
			</Cell>

			<Cell span={1} tablet={0} phone={0}>
				{' '}
			</Cell>

			<Cell span={4} tablet={4} phone={4}>
				{showFilterLegends && (
					<HighchartsLegends
						items={legends}
						classificationItems={classificationLegends}
						heading={legendHeading}
						selectAllLabel={legendSelectAllLabel}
						selectNoneLabel={legendSelectNoneLabel}
						onCheckAll={onLegendsCheckAll}
						onUncheckAll={onLegendsUnCheckAll}
						onCheckedChanged={legendCheckedChanged}
						onClassificationsCheckedChanged={classificationLegendCheckedChanged}
						statisticsClassificationsLink={statisticsClassificationsLink}
					></HighchartsLegends>
				)}
			</Cell>
		</Grid>
	);

	let renderOutput = chartElement;
	// Only render legends in chart tap page.
	if (showFilterLegends && selectedTabPage === chartId) {
		renderOutput = withLegends;
	}

	const exportItems: DropdownMenuItem[] = [
		{ heading: `${exportAsLabel} PDF...`, value: MimeTypes.pdf },
		{ heading: `${exportAsLabel} PNG...`, value: MimeTypes.png },
		{ heading: `${exportAsLabel} JPG...`, value: MimeTypes.jpg },
		{ heading: `${exportAsLabel} SVG...`, value: MimeTypes.svg },
		{ heading: `${exportAsLabel} CSV...`, value: MimeTypes.csv },
	];

	return (
		<HighchartsStyle id={id}>
			<Tab onSelect={onTabPageSelected} {...tabPops}>
				<DropdownMenu
					heading={downloadLabel}
					items={exportItems}
					onSelect={onExportSelect}
				></DropdownMenu>
			</Tab>
			{renderOutput}
		</HighchartsStyle>
	);
};

export default Highcharts;

/**
 * Legends
 */

export type SymbolType =
	| 'circle'
	| 'diamond'
	| 'square'
	| 'triangle'
	| 'triangle-down';

export interface HighchartsLegendItem {
	id: string;
	name: string;
	checked: boolean;
	symbolType: SymbolType;
	symbolColor: string;
}

interface HighchartsLegendsProps {
	heading?: string;
	selectAllLabel?: string;
	selectNoneLabel?: string;
	onCheckAll?: () => void;
	onUncheckAll?: () => void;
	onCheckedChanged?: (item: HighchartsLegendItem, checked: boolean) => void;
	onClassificationsCheckedChanged?: (
		item: HighchartsLegendItem,
		classification: string,
		checked: boolean
	) => void;

	items: HighchartsLegendItem[];
	classificationItems?: HighchartsLegendItem[];
	statisticsClassificationsLink?: LinkType;
}

const HighchartsLegends: React.FC<HighchartsLegendsProps> = ({
	heading = 'Filter',
	selectAllLabel = 'Select all',
	selectNoneLabel = 'Select none',
	onCheckAll,
	onUncheckAll,
	onCheckedChanged,
	onClassificationsCheckedChanged,
	items,
	classificationItems,
	statisticsClassificationsLink,
}) => {
	const toggleSeries = (e: any) => {};

	const itemCheckedChanged = (item: HighchartsLegendItem, checked: boolean) => {
		if (onCheckedChanged) {
			onCheckedChanged(item, checked);
		}
	};

	const classificationCheckedChanged = (
		item: HighchartsLegendItem,
		checked: boolean
	) => {
		if (onClassificationsCheckedChanged) {
			onClassificationsCheckedChanged(item, item.name, checked);
		}
	};

	// "circle" ◝
	// "diamond" ♦
	// "square" ■
	// "triangle" ▲
	// "triangle-down" ▼

	return (
		<HighchartsLegendsStyle>
			<label>{heading}</label>
			<HighchartsLegendsActions>
				<Button onClick={onCheckAll} variant={ButtonVariant.Tertiary}>
					{selectAllLabel}
				</Button>
				<Button onClick={onUncheckAll} variant={ButtonVariant.Tertiary}>
					{selectNoneLabel}
				</Button>
			</HighchartsLegendsActions>
			{/* Rendering the classification categories (stationstyp, Gaturum, Urban bakgrund, Regional Bakgrund, Förortsmiljö)  */}
			<ul>
				{classificationItems?.map((item, index) => {
					let symbolChar: string;
					switch (item.symbolType) {
						default:
						case 'circle':
							symbolChar = '●';
							break;
						case 'diamond':
							symbolChar = '♦';
							break;
						case 'square':
							symbolChar = '■';
							break;
						case 'triangle':
							symbolChar = '▲';
							break;
						case 'triangle-down':
							symbolChar = '▼';
							break;
					}

					return (
						<li key={index} onClick={() => toggleSeries(item)}>
							<Checkbox
								onCheckedChanged={(checked) =>
									classificationCheckedChanged(item, checked)
								}
								checked={item.checked}
							>
								{item.name}
							</Checkbox>
						</li>
					);
				})}
			</ul>
			<ul>
				<li>
					{statisticsClassificationsLink &&
						statisticsClassificationsLink.url &&
						statisticsClassificationsLink.heading && (
							<a href={statisticsClassificationsLink.url}>
								{statisticsClassificationsLink.heading}
							</a>
						)}
					<p></p>
				</li>
			</ul>
			<ul>
				{items.map((item, index) => {
					let symbolChar: string;
					switch (item.symbolType) {
						default:
						case 'circle':
							symbolChar = '●';
							break;
						case 'diamond':
							symbolChar = '♦';
							break;
						case 'square':
							symbolChar = '■';
							break;
						case 'triangle':
							symbolChar = '▲';
							break;
						case 'triangle-down':
							symbolChar = '▼';
							break;
					}

					return (
						<li key={index} onClick={() => toggleSeries(item)}>
							<Checkbox
								onCheckedChanged={(checked) =>
									itemCheckedChanged(item, checked)
								}
								checked={item.checked}
							>
								<HighchartsLegendsSymbols {...item}>
									{symbolChar}
								</HighchartsLegendsSymbols>
								{item.name}
							</Checkbox>
						</li>
					);
				})}
			</ul>
		</HighchartsLegendsStyle>
	);
};
