import Link from 'components/Boilerplate/Link';
import React from 'react';
import { RegulationHistory } from './NVseRegulationPage.model';
import { RegulationHistoryDescriptionStyle } from './RegulationHistoryDescription.styles';

/**
 * RegulationHistoryDescription
 */
export const RegulationHistoryDescription: React.FC<RegulationHistory> = ({
	optionalTitle,
	regulationId,
	regulationType,
	description,
	relatedRegulationLinksLabel,
	relatedRegulationLinks,
}) => {
	const title = optionalTitle
		? optionalTitle
		: `${regulationId} - ${regulationType}`;

	return (
		<RegulationHistoryDescriptionStyle>
			<span>{title}</span>
			{description && <p>{description}</p>}
			{relatedRegulationLinks.length > 0 && relatedRegulationLinksLabel && (
				<span>{relatedRegulationLinksLabel}</span>
			)}

			{relatedRegulationLinks.length > 0 && (
				<ul>
					{relatedRegulationLinks.map((item, index) => {
						return (
							<li key={index}>
								<Link to={item.url} external={item.isFile}>
									{item.text}
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</RegulationHistoryDescriptionStyle>
	);
};
