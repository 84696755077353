/**
 * DashboardBlock
 */
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Options, wrapElementInGrid } from 'components/Boilerplate/EpiFragments/EpiFragments';
import { PuffTheme } from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { FragmentModelTypes } from 'types/fragments';
import {
	DashboardBlockStyle,
	DashboardLineDeviderStyle,
	DashboardLineStyle,
} from './DashboardBlock.styles';


export type DashboardBlockProps = {
	theme: string;
	items: FragmentModelTypes[];
	options: Options;
	disableCustomHeadingLogic: boolean;
}

/** This is the banner component */
const DashboardBlock: React.FC<DashboardBlockProps> = ({
	theme,
	items,
	options,
	disableCustomHeadingLogic
}) => {
	const puffTheme = theme as PuffTheme;
	const themeContext = useContext(ThemeContext);

	const [preparedItems, setPreparedItems] = useState<FragmentModelTypes[][]>();

	useEffect(() => {
		// Add theme property to each item
		const newItems = items.map((item) => {
			return { ...item, theme: theme };
		});

		let chunkList: FragmentModelTypes[][] = [];

		var i,
			j,
			temparray,
			chunk = 4;
		for (i = 0, j = newItems.length; i < j; i += chunk) {
			temparray = newItems.slice(i, i + chunk);
			chunkList.push(temparray as FragmentModelTypes[]);
		}

		setPreparedItems(chunkList);
	}, [items, theme]);

	const element = <DashboardBlockStyle themeName={puffTheme}>
		{preparedItems?.map((item, index) => {
			return (
				<div key={index}>
					<DashboardLine
						key={index}
						options={{ ...options, insideGrid: true, themeContext: themeContext }}
						items={item}
						theme={theme}
						disableCustomHeadingLogic={disableCustomHeadingLogic}

					></DashboardLine>
					{index < preparedItems.length - 1 && (
						<DashboardLineDeviderStyle
							themeName={puffTheme}
						></DashboardLineDeviderStyle>
					)}
				</div>
			);
		})}
	</DashboardBlockStyle>

	if (options?.insideGrid) {
		return element;
	}
	return wrapElementInGrid(element);
};

export default DashboardBlock;

type DashboardLineProps = {
	theme: string;
	items: FragmentModelTypes[];
	options: Options;
	disableCustomHeadingLogic: boolean;
};

/** This is the DashboardLine component
 * 4 Widget blocks
 */
const DashboardLine: React.FC<DashboardLineProps> = ({
	theme,
	items,
	options,
	disableCustomHeadingLogic
}) => {
	const puffTheme = theme as PuffTheme;
	return (
		<DashboardLineStyle themeName={puffTheme}>
			<EpiFragments
				insideGrid={options.insideGrid}
				insideCell={options.insideCell}
				insideInnerGrid={options.insideInnerGrid}
				headingLevel={options.headingLevel}
				fragments={items}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</DashboardLineStyle>
	);
};
