import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSessionStorage, setSessionStorage } from 'store/modules/model';
import { SessionStorageModel } from 'types/epi';

export function useSessionStorage(modelType: string, id: string) {
	const [result, setResult] = useState<SessionStorageModel | null>();

	const sessionStorageModel = useSelector(selectSessionStorage);

	useEffect(() => {
		if (sessionStorageModel) {
			const index = sessionStorageModel.findIndex(
				(item) => item.modelType === modelType && item.id === id
			);
			if (index !== -1) {
				const result = sessionStorageModel[index];
				setResult(result);
			}
		} else {
			setResult(null);
		}
	}, [sessionStorageModel, modelType, id]);

	return result;
}


export function usePageSessionStorage<Type>(modelType: string, id: string, initialData: any) {
	const [result, setResult] = useState<Type>(initialData);

	const sessionStorageModel = useSelector(selectSessionStorage);
	const dispatch = useDispatch();

	useEffect(() => {

		const pageSessionStorage = getSessionStorage(sessionStorageModel, modelType, id);
		if (pageSessionStorage) {
			// Update
			setResult(pageSessionStorage.data);
			return;
		}

		const payLoad: SessionStorageModel = {
			modelType, id, data: initialData
		};

		// No page session, Create one 
		dispatch(setSessionStorage(payLoad));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionStorageModel, modelType, id]);

	return result;
}

export function getSessionStorage(sessionStorageModels: SessionStorageModel[], modelType: string, id: string): SessionStorageModel | null {

	if (sessionStorageModels) {
		const index = sessionStorageModels.findIndex(
			(item) => item.modelType === modelType && item.id === id
		);
		if (index !== -1) {
			return sessionStorageModels[index];
		}
	}
	return null
}

export function createPageSessionStorage(modelType: string, id: string, data: any): SessionStorageModel {
	return { modelType, id, data }
}