/**
 * Meta
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocalization, selectMetaData } from 'store/modules/model';

import { Helmet } from 'react-helmet';
import { FavIcon } from 'types/epi';

interface Props {
	/** Title for the page */
	title?: string;

	/** favIcons for the Naturvårdsverktet page or Microsite */
	favIcons?: FavIcon[];

	/** Meta description */
	description?: string;

	/** Meta keywords */
	keywords?: string;

	/** Twitter card
	 *
	 * _The card type, which will be one of “summary”, “summary_large_image”, “app”, or “player”._
	 */
	twitterCard?: string;

	/** Twitter card title
	 *
	 * _A concise title for the related content._
	 */
	twitterTitle?: string;

	/** Twitter card description
	 *
	 * _A description that concisely summarizes the content as appropriate for presentation within a Tweet. You should not re-use the title as the description or use this field to describe the general services provided by the website._
	 */
	twitterDescription?: string;

	/** Twitter card image
	 *
	 * _A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 2:1 with minimum dimensions of 300x157 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported._
	 *
	 * Read more: [Formats for open graph images](https://whatabout.dev/formats-for-open-graph-images)
	 */
	twitterImage?: string;

	/** Open graph site name
	 *
	 * _A one to two sentence description of your object._
	 */
	ogSiteName?: string;

	/** Open graph type
	 *
	 * _The [type](https://ogp.me/#types) of your object, e.g., "video.movie". Depending on the type you specify, other properties may also be required._
	 */
	ogType?: string;

	/** Open graph title
	 *
	 * _The title of your object as it should appear within the graph, e.g., "The Rock"._
	 */
	ogTitle?: string;

	/** Open graph url
	 *
	 * _The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "http://www.imdb.com/title/tt0117500/"._
	 */
	ogUrl?: string;

	/** Open graph description
	 *
	 * _A one to two sentence description of your object._
	 */
	ogDescription?: string;

	canonicalUrl?: string;
}

/**
 * Component that handles meta data for the <head>.
 *
 * - https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
 * - https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started
 */
const Meta: React.FC<Props> = ({ keywords }) => {
	const metaData = useSelector(selectMetaData);
	const localizationData = useSelector(selectLocalization);

	const mappedMetaData = {
		title: metaData?.title || undefined,
		favIcons: metaData?.favIcons || null,
		description: metaData?.description || undefined,
		googleSiteVerificationTag: metaData?.googleSiteVerificationTag || undefined,
		ogSiteName: metaData?.openGraph?.siteName || undefined,
		ogType: metaData?.openGraph?.type || 'website',
		ogTitle: metaData?.openGraph?.title || undefined,
		ogUrl: metaData?.openGraph?.url || undefined,
		ogDescription: metaData?.openGraph?.description || undefined,
		twitterCard: metaData?.twitter?.card || 'summary',
		twitterTitle: metaData?.twitter?.title || undefined,
		twitterDescription: metaData?.twitter?.description || undefined,
		twitterImage: metaData?.twitter?.image || undefined,
		excludeFromIndex: metaData?.excludeFromIndex || undefined,
		canonicalUrl: metaData?.canonicalUrl || undefined,
	};
	const {
		title,
		favIcons,
		description,
		googleSiteVerificationTag,
		ogSiteName,
		ogType,
		ogTitle,
		ogUrl,
		ogDescription = description,
		twitterCard,
		twitterTitle,
		twitterImage,
		twitterDescription = description,
		excludeFromIndex,
		canonicalUrl,
	} = mappedMetaData;

	const renderFavIcon = (favIcons: FavIcon[]) => {
		return favIcons.map((icon: FavIcon, index: number) => {
			return (
				<link
					key={icon.src}
					rel={icon.rel}
					href={icon.src}
					type={icon.type}
					sizes={icon.sizes}
				/>
			);
		});
	};

	// prettier-ignore
	return (
		<Helmet>
			{localizationData && <html lang={localizationData?.culture} />}

			{excludeFromIndex && excludeFromIndex === true && <meta name="robots" content="noindex,nofollow" />}

			{title && <title>{title}</title>}
			{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
			{description && <meta name="description" content={description} />}
			{keywords && <meta name="keywords" content={keywords} />}
			{googleSiteVerificationTag && <meta name="google-site-verification" content={googleSiteVerificationTag} />}
			{ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
			{ogType && <meta property="og:type" content={ogType} />}
			{(ogTitle || title) && <meta property="og:title" content={ogTitle || title} />}
			{ogUrl && <meta property="og:url" content={ogUrl} />}
			{(ogDescription) && <meta property="og:description" content={ogDescription} />}
			{twitterCard && <meta name="twitter:card" content={twitterCard} />}
			{twitterCard && twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
			{twitterCard && (twitterDescription) && <meta name="twitter:description" content={twitterDescription} />}
			{twitterCard && twitterImage && <meta name="twitter:image" content={twitterImage} />}
			

			<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
			<link rel="stylesheet" href="https://use.typekit.net/oyr5sbc.css" />
			
			<meta name="viewport" content="width=device-width, initial-scale=1" />

			{metaData?.sdg && metaData.sdg.includeSDGMetaTags && metaData.sdg.sdgMetaDataTags &&
				Object.keys(metaData.sdg.sdgMetaDataTags).map((item, index) => {
					if (metaData && metaData.sdg) {
						const value = metaData.sdg.sdgMetaDataTags[item]
						return <meta key={index} name={item} content={value} />
					}
					return null;
				})
			}	

			{favIcons && renderFavIcon(favIcons)}
		</Helmet>
	);
};

export default Meta;
