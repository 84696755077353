import { Heading, Tags } from 'components/Typography/Typography';
import { HeadingFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetHeadingFragmentElement = (
	data: HeadingFragmentModel,
	options: Options,
	disableCustomHeadingLogic?: boolean
) => {
	let headingLevel: number = options.headingLevel + 1;
	const colorHeading = options.themeContext.isMicrositeActive
		? options.themeContext.palette.text.primary
		: undefined;

	const getHeadingLevel = (data: HeadingFragmentModel) => {
		if (data.isPage || disableCustomHeadingLogic) {
			return (headingLevel = data.level);
		} else {
			return (headingLevel = data.level + data.blockLevel);
		}
	};
	return (
		<Heading level={getHeadingLevel(data)} color={colorHeading}>
			<span dangerouslySetInnerHTML={{ __html: data.raw }} />
		</Heading>
	);
};
