import { Grid } from 'components/Boilerplate/Grid';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { Heading } from 'components/Typography/Typography';
import { ImageFragmentModel } from 'types/fragments';
import { LinkListItem } from './NVseStartPage.model';
import {
	ActionsContainer,
	HeroContainer,
	MicroSubtitle,
	Subtitle,
	TopImage,
} from './PageTopOverlappBanner.styles';
import { useImageTitle } from 'hooks/useImageTitle';
import EditableField from 'components/EditableField/EditableField';

type PageTopOverlappBannerProps = {
	image: ImageFragmentModel;
	image_htmlAttributes?: any;

	headingLevel?: number;
	heading: string | null;
	heading_htmlAttributes?: any;

	preamble: string | null;
	preamble_htmlAttributes?: any;

	linkList?: LinkListItem[];

	/* ask if is Microsite */
	isMicrositeActive?: boolean;
};

const PageTopOverlappBanner = ({
	image,
	headingLevel,
	heading,
	preamble,
	linkList,
	image_htmlAttributes = {},
	heading_htmlAttributes = {},
	preamble_htmlAttributes = {},
	isMicrositeActive = false,
}: PageTopOverlappBannerProps) => {
	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	return (
		<>
			<EditableField opeProperty={image_htmlAttributes}>
				{image && (
					<>
						<TopImage
							alt={image.alt}
							src={image.src}
							srcSet={image.srcSet}
							title={title}
						></TopImage>
					</>
				)}
			</EditableField>

			<Grid paddingTop={false} paddingBottom={false}>
				<HeroContainer>
					<EditableField opeProperty={heading_htmlAttributes}>
						{heading && (
							<Heading
								level={headingLevel}
								htmlAttributes={heading_htmlAttributes}
							>
								{heading}
							</Heading>
						)}
					</EditableField>
					{isMicrositeActive ? (
						<MicroSubtitle {...preamble_htmlAttributes}>
							{preamble}
						</MicroSubtitle>
					) : (
						<EditableField opeProperty={preamble_htmlAttributes}>
							<Subtitle {...preamble_htmlAttributes}>{preamble}</Subtitle>
						</EditableField>
					)}

					{linkList && linkList.length > 0 && (
						<ActionsContainer>
							{linkList.map((item, index) => {
								return (
									<Button
										key={index}
										variant={ButtonVariant.Tertiary}
										to={item.url}
										external={item.isFile}
									>
										{item.text}
									</Button>
								);
							})}
						</ActionsContainer>
					)}
				</HeroContainer>
			</Grid>
		</>
	);
};

export default PageTopOverlappBanner;
