import styled from 'styled-components';
import Link from 'components/Boilerplate/Link';

export const SectionHeaderStyle = styled.div`
	display: grid;
	place-items: center;
`;

export const SectionLinkStyle = styled(Link)`
	margin-top: ${(props) => props.theme.spacing.space2}rem;
`;
