/**
 * BannerPuffTextYoutube - styles
 */

import { BannerPanelStyle } from 'components/BannerPanel/BannerPanel.styles';
import { getThemeBackground } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { BannerPuffTextYoutubeProps } from './BannerPuffTextYoutube';

export const BannerPuffTextYoutubeStyle = styled.div<BannerPuffTextYoutubeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};

	${mediaQueries.tabletOrLarger} {
		min-height: 25rem;
	}

	${BannerPanelStyle as any} {
		padding: ${props => props.theme.spacing.space5}rem ${props => props.theme.spacing.space6}rem;
		${mediaQueries.phoneOrTablet} {
			padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem;
		}
	}
`;
