import React, { useEffect, useRef } from 'react';
import {
	FilterModalContainer,
	FilterModalContent,
	FilterModalFooter,
	FilterModalHeader,
} from './FilterModal.styles';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { H4 } from 'components/Typography';
import Icon from 'components/Boilerplate/Icon';
import { Transition } from 'react-transition-group';

interface Props {
	filteredCount: number;
	clearAllText: string;
	filterLabel: string;
	filterExist: boolean;
	showFilterModal: boolean;
	onClose: () => void;
	onMobileSubmit: () => void;
	onClear: () => void;
}

const FilterModal: React.FC<Props> = ({
	children,
	filteredCount,
	clearAllText,
	filterLabel,
	filterExist,
	showFilterModal,
	onClose,
	onClear,
	onMobileSubmit,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (showFilterModal) {
			// Focus the modal when it's shown
			if (modalRef.current) {
				modalRef.current.focus();
			}
		}
	}, [showFilterModal]);

	// Focus management
	useEffect(() => {
		const handleFocus = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		window.addEventListener('keydown', handleFocus);

		return () => {
			window.removeEventListener('keydown', handleFocus);
		};
	}, [onClose]);

	useEffect(() => {
		if (showFilterModal) {
			// Disable scrolling when the modal is open
			document.body.style.overflow = 'hidden';
			document.documentElement.style.overflow = 'hidden';
		} else {
			// Re-enable scrolling when the modal is closed
			document.body.style.overflow = 'auto';
			document.documentElement.style.overflow = 'auto';
		}
	}, [showFilterModal]);

	return (
		<Transition in={showFilterModal} timeout={300} unmountOnExit>
			{(state) => (
				<FilterModalContainer
					state={state}
					ref={modalRef}
					tabIndex={-1}
					role="dialog"
					aria-label={filterLabel}
					aria-modal="true"
				>
					<FilterModalHeader>
						<H4>{filterLabel}</H4>
						<span
							onClick={onClose}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									onClose();
								}
							}}
							tabIndex={0}
							aria-label="close modal"
						>
							<Icon color="white" icon="cross" size={3} aria-hidden={true} />
						</span>
					</FilterModalHeader>
					<FilterModalContent>{children}</FilterModalContent>
					<FilterModalFooter filterFlag={filterExist}>
						<Button
							onClick={onMobileSubmit}
							variant={ButtonVariant.Primary}
							aria-label="Visa results button"
							disabled={filteredCount === 0}
						>
							Visa ({filteredCount})
						</Button>
						<Button
							variant={ButtonVariant.Tertiary}
							onClick={onClear}
							aria-label={clearAllText}
							disabled={!filterExist}
							className={'mobile-clearall-btn'}
						>
							{clearAllText}
						</Button>
					</FilterModalFooter>
				</FilterModalContainer>
			)}
		</Transition>
	);
};

export default FilterModal;
