import { ThemeProps } from 'components/Banner/Banner';
import { getThemeSettings } from 'pages/sharedModelTypes';
import Link from 'components/Boilerplate/Link';
import styled from 'styled-components';

export const FragmentPuffListStyle = styled.div<ThemeProps>`
	${(p) => p && getThemeSettings(p.themeName, p.theme)};
`;

export const MicrositeLinkStyle = styled(Link)`
	${(props) => props.theme.typography.style6.getStyle()};
`;
