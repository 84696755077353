import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { SearchListingResultModel } from 'types/epi';
import { getSessionStorage } from 'hooks/useSessionStorage';
import { NVseStandardListingPageModel } from './NVseStandardListingPage.model';
import { selectSessionStorage } from 'store/modules/model';
import { Grid } from 'components/Boilerplate/Grid';
import { LinkType } from 'pages/sharedModelTypes';

import {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import Space from 'components/Boilerplate/Space';
import ListingPageGrantsHeader from 'components/ListingPageGrantsHeader';
import PuffListItem from 'components/PuffListItem';
import SearchByListngPage from 'components/SearchByListngPage';
import { ListingPageShowMoreButton } from 'components/Panels/ListPageShowMoreButton';
import { FacetModel } from 'components/SearchByListngPage/SearchByListngPage.model';
import Typography, { H4 } from 'components/Typography';

import {
	TotalCountDisplay,
	ClearAllLinkContainer,
} from './NVseStandardListingPage.styles';
import SearchByListngPageMobile from 'components/SearchByListngPageMobile';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

/**
 * # Listningssida för statistik
 * Modeltype:<code>NVseStandardListingPage</code>
 *
 *
 * Landningssida för webbplatsens standard Listing Page
 */
const NVseStandardListingPage: React.FC<NVseStandardListingPageModel> = ({
	id,
	modelType,
	heading,
	preamble,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
	themeTop,
	filterLabel,
	searchActionText,
	loadMoreSearchResults,
	searchTopic,
	searchPlaceholder,
	searchResultLabel,
	searchResultLabelNoMatchesHint,
	searchResultLabelNoMatches,
	searchModel,
	displayedResultsText,
	url,
	clearAllFiltersText,
	showFiltersText,
	numberOfSearchResults,
	apiEndpointSearch,
	apiEndpointAutoComplete,
	resetSearchInput,
	publicationLink,
}) => {
	// will need localization to culture: sv
	const themeContext = useContext(ThemeContext);
	// Create a copy of the searchModel with updated page property
	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	//page flag is used execute a new search when user clicks on see more button
	const [pageChanged, setPageChanged] = useState<boolean>(false);
	const [showClearAll, setShowClearAll] = useState<boolean>(false);
	const [filterEnabled, setFilterEnabled] = useState<boolean>(false);
	const [
		listingResultModel,
		setListingResultModel,
	] = useState<SearchListingResultModel>({
		searchModel,
		numberOfSearchResults,
	});
	const sessionStorageModel = useSelector(selectSessionStorage);
	/**checking if there is any filter exist in search model and this will be used to show/hide no result feedback and clear all link */
	const checkFilterEnabled = (facetModels: FacetModel[]) => {
		for (const model of facetModels) {
			for (const option of model.facetOptions) {
				if (option.selected) {
					return true;
				}
			}
		}
		return false;
	};
	useEffect(() => {
		// If we do not have any saved state, we execute the search.
		const pageSessionStorage = getSessionStorage(
			sessionStorageModel,
			modelType,
			id
		);
		setPageChanged(false);
		setShowClearAll(false);
		if (pageSessionStorage?.data) {
			checkFilterEnabled(
				pageSessionStorage?.data?.searchResponse?.searchModel?.facetModels
			)
				? setFilterEnabled(true)
				: setFilterEnabled(false);
			if (pageSessionStorage?.data?.searchResponse?.searchModel.page === 1) {
				setListingResultModel((prevState) => ({
					...pageSessionStorage?.data?.searchResponse,
					searchModel: {
						...pageSessionStorage?.data?.searchResponse?.searchModel,
					},
				}));
			} else {
				setListingResultModel((prevState) => ({
					...pageSessionStorage?.data?.searchResponse,

					searchModel: {
						...pageSessionStorage?.data?.searchResponse?.searchModel,
						results: [
							...prevState.searchModel.results,
							...pageSessionStorage?.data?.searchResponse?.searchModel?.results,
						],
					},
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionStorageModel]);

	const handleShowMoreClick = () => {
		setListingResultModel((prevState) => ({
			...prevState,
			searchModel: {
				...prevState.searchModel,
				page: prevState.searchModel.page + 1,
			},
		}));
		setPageChanged(true);
	};

	// #region Space calculation

	let lastBlockType = BlockType.Element;

	lastBlockType = BlockType.Element;

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			{themeContext && (
				<ListingPageGrantsHeader
					preamble={preamble}
					heading={heading}
					headingLevel={1}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
					themeTop={themeTop}
					themeContext={themeContext}
					publicationLink={publicationLink}
				></ListingPageGrantsHeader>
			)}

			<Grid>
				{!isNarrowDisplay ? (
					<SearchByListngPage
						filterLabel={filterLabel}
						searchActionText={searchActionText}
						searchTopic={searchTopic}
						searchResultLabel={searchResultLabel}
						searchResultLabelNoMatchesHint={searchResultLabelNoMatchesHint}
						searchResultLabelNoMatches={searchResultLabelNoMatches}
						searchModel={listingResultModel.searchModel}
						pageChanged={pageChanged}
						url={url}
						rootPageId={id}
						clearAllFiltersText={clearAllFiltersText}
						modelType={modelType}
						searchApi={apiEndpointSearch}
						autoCompleteApi={apiEndpointAutoComplete}
						showClearAll={showClearAll}
						filterEnabled={filterEnabled}
						searchPlaceholder={searchPlaceholder}
					/>
				) : (
					<SearchByListngPageMobile
						filterLabel={filterLabel}
						searchActionText={searchActionText}
						searchTopic={searchTopic}
						searchResultLabel={searchResultLabel}
						searchResultLabelNoMatchesHint={searchResultLabelNoMatchesHint}
						searchResultLabelNoMatches={searchResultLabelNoMatches}
						searchModel={listingResultModel.searchModel}
						pageChanged={pageChanged}
						allFilterText={showFiltersText}
						url={url}
						rootPageId={id}
						clearAllFiltersText={clearAllFiltersText}
						modelType={modelType}
						searchApi={apiEndpointSearch}
						autoCompleteApi={apiEndpointAutoComplete}
						showClearAll={showClearAll}
						filterEnabled={filterEnabled}
						searchPlaceholder={searchPlaceholder}
					/>
				)}
			</Grid>

			<Space bottom={spaceAfterLast}>
				<div>
					<Grid paddingTop={false} paddingBottom={false}>
						{listingResultModel.searchModel && (
							<>
								{listingResultModel.searchModel.page &&
									listingResultModel.searchModel.size &&
									listingResultModel.searchModel.numberOfHits > 0 && (
										<TotalCountDisplay>
											<Typography
												as="h4"
												text={displayedResultsText
													?.replace(
														'{0}',
														listingResultModel?.searchModel?.results.length.toString()
													)
													.replace(
														'{1}',
														listingResultModel.searchModel.numberOfHits
															? listingResultModel.searchModel?.numberOfHits?.toString()
															: listingResultModel.searchModel?.totalHits?.toString()
													)}
												role="status"
												aria-live="polite"
											/>
										</TotalCountDisplay>
									)}
								{listingResultModel.searchModel.results?.length === 0 &&
									filterEnabled && (
										<ClearAllLinkContainer>
											<H4>{searchResultLabelNoMatches}</H4>
											<span
												role="button"
												tabIndex={0}
												onClick={(e) => {
													setShowClearAll(true);
												}}
											>
												{resetSearchInput}
											</span>
										</ClearAllLinkContainer>
									)}
								{listingResultModel.searchModel.results?.length > 0 && (
									<Space
										top={themeContext.spacing.space3}
										stack={themeContext.spacing.space1}
									>
										{listingResultModel.searchModel.results.map(
											(item, index) => {
												const linkItem: LinkType = {
													url: item.url,
													heading: item.heading,
												};
												return (
													<PuffListItem
														key={index}
														decal={item.decal}
														link={linkItem}
														preamble={item.excerpt}
														image={item.image}
													></PuffListItem>
												);
											}
										)}
									</Space>
								)}
							</>
						)}
					</Grid>
					{listingResultModel.searchModel &&
						listingResultModel.searchModel.page <
							listingResultModel.searchModel.totalPages && (
							<Grid paddingTop={true} paddingBottom={false}>
								<ListingPageShowMoreButton
									header={loadMoreSearchResults}
									onClick={handleShowMoreClick}
								/>
							</Grid>
						)}
				</div>
			</Space>
		</>
	);
};

export default NVseStandardListingPage;
