import React from 'react';
import {
	GalleryListStyle,
	GalleryListItemStyle,
	GalleryContainer,
} from './Gallery.styles';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { FragmentModelTypes } from 'types/fragments';

export interface GalleryProps {
	puffItems: FragmentModelTypes[];
	translate: number;
	transition: number;
	isTablet: boolean;
	headingLevel?: number;
	disableCustomHeadingLogic: boolean;
}

const Gallery = ({
	puffItems,
	translate,
	transition,
	isTablet,
	headingLevel,
	disableCustomHeadingLogic
}: GalleryProps) => {
	const listLength = puffItems.length;
	let numberOfColumns = 0;
	let numberOfItemsInColumns = 3;

	numberOfColumns =
		listLength % 3 === 0
			? Math.floor(listLength / 3)
			: Math.floor(listLength / 3) + 1;

	if (isTablet) {
		numberOfColumns =
			listLength % 2 === 0
				? Math.floor(listLength / 2)
				: Math.floor(listLength / 2) + 1;

		numberOfItemsInColumns = 2;
	}

	return (
		<GalleryContainer>
			<GalleryListStyle
				columns={numberOfColumns}
				myTranslate={translate}
				transition={transition}
			>
				<GalleryListItems
					headingLevel={headingLevel}
					puffItems={puffItems}
					columns={numberOfColumns}
					numberOfItemsInColumns={numberOfItemsInColumns}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				></GalleryListItems>
			</GalleryListStyle>
		</GalleryContainer>
	);
};

export default Gallery;

type GalleryListItemsProps = {
	puffItems: FragmentModelTypes[];
	columns: number;
	numberOfItemsInColumns: number;
	headingLevel?: number;
	disableCustomHeadingLogic: boolean;
};

export const GalleryListItems: React.FC<GalleryListItemsProps> = ({
	puffItems,
	columns,
	headingLevel,
	numberOfItemsInColumns,
	disableCustomHeadingLogic
}) => {
	let array1 = [];
	let galleryItems = [];
	const clonedPuffItems = [...puffItems];

	for (let index = 0; index < columns; index++) {
		let tempFragmentArray = clonedPuffItems.splice(0, numberOfItemsInColumns);
		array1.push(tempFragmentArray);
	}

	for (let j = 0; j < columns; j++) {
		var galleryListItem = (
			<GalleryListItem
				headingLevel={headingLevel}
				width={100 / columns}
				puffItems={array1[j]}
				key={j}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			></GalleryListItem>
		);
		galleryItems.push(galleryListItem);
	}
	return <>{galleryItems}</>;
};

type GalleryListItemProps = {
	puffItems: FragmentModelTypes[];
	width: number;
	headingLevel?: number;
	disableCustomHeadingLogic: boolean;
};

export const GalleryListItem: React.FC<GalleryListItemProps> = ({
	puffItems,
	width,
	headingLevel,
	disableCustomHeadingLogic
}) => {
	let tempJSXArray = puffItems.map((puffItem, index) => (
		<EpiFragments
			headingLevel={headingLevel}
			fragments={[puffItem]} key={index}
			disableCustomHeadingLogic={disableCustomHeadingLogic} />
	));

	return (
		<>
			<GalleryListItemStyle width={width} key={0}>
				{tempJSXArray}
			</GalleryListItemStyle>
		</>
	);
};
