/**
 * ExpanderPrototype - styles
 */
import {
	PuffTheme,
	getThemeBackground,
	getThemeHeadingColor,
	getThemeTextColor,
} from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { ExpanderPrototypeStyleProps } from './ExpanderPrototype';

export const IconElement = styled.div`
	align-self: center;
`;

export const ExpanderPrototypeHeader = styled.div<ExpanderPrototypeStyleProps>`
	display: flex;
	flex-direction: column;
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	color: ${(p) => getThemeHeadingColor(p.theme, p.themeName)};
	${(p) => p.isEnabled && `cursor: pointer;`}
	&:focus {
		outline-offset: 0px;
		outline: 2px solid;
		outline-color: currentcolor;
	}
	scroll-margin-top: 150px;
`;

export const ExpanderPrototypeHeaderContent = styled.div<ExpanderPrototypeStyleProps>`
	display: flex;
	justify-content: space-between;

	padding: ${(props) => props.theme.spacing.space4}rem;

	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
		align-self: center;
		flex: 0 0 auto;
		margin-left: 1rem;
	}
`;

export const ExpanderPrototypeContent = styled.div<ExpanderPrototypeStyleProps>`
	display: ${(p) => (p.isOpen ? 'inherit' : 'none')};
	padding-top: ${(props) => props.theme.spacing.space4}rem;
	padding-bottom: ${(props) => props.theme.spacing.space4}rem;
	${(p) =>
		p.themeName &&
		`padding-left: ${p.theme.spacing.space4}rem;padding-right: ${
			p.theme.spacing.space4
		}rem;background-color: ${getThemeBackground(p.theme, p.themeName)};`}
	${(p) => !p.themeName && `padding-bottom: 0;`}

	h1,h2,h3,h4,h5,h6 {
		color: ${(p) => getThemeHeadingColor(p.theme, p.themeName)};
	}

	${(p) =>
		(p.themeName === PuffTheme.GreenDark ||
			p.themeName === PuffTheme.BlueDark ||
			p.themeName === PuffTheme.BrownDark) &&
		`
		p, li {
			color: ${getThemeTextColor(p.theme, p.themeName)}; 
		}
	`}

	a {
		color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	}
`;
export const HeaderDevider = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.primary};
	opacity: 0.24;
`;
