import { Heading } from 'components/Typography/Typography';
import React, { useContext } from 'react';
import {
	FooterSectionHeader,
	FooterSectionItem,
	FooterSectionItemList,
	FooterSectionLink,
	SocialLogoStyle,
} from './FooterSection.styles';
import { LinkSection } from './SiteFooter';
import facebookImg from './Facebook.png';
import twitterImg from './Twitter-white.png';
import linkedInImg from './LinkedIn.png';
import SimpleExpander from './SimpleExpander';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { getThemeTextColor, PuffTheme } from 'pages/sharedModelTypes';
import { ThemeContext } from 'styled-components';

type FooterSectionProp = {
	section: LinkSection;
	id?: string;
	ariaLabel: string;
	themeName?: PuffTheme;
};

export const FooterSection = ({
	section,
	id,
	ariaLabel,
	themeName,
}: FooterSectionProp) => {
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	let linkHeading;
	if (section.heading.url && !isPhone) {
		linkHeading = (
			<Heading
				level={2}
				styleLevel={5}
				zeroBottom={true}
				color={getThemeTextColor(themeContext, themeName)}
			>
				<FooterSectionHeader showLinkIcon={false} to={section.heading.url}>
					{section.heading.heading}
				</FooterSectionHeader>
			</Heading>
		);
	} else {
		// Show expander with simple text header
		linkHeading = (
			<Heading
				zeroBottom={true}
				level={2}
				styleLevel={5}
				color={getThemeTextColor(themeContext, themeName)}
			>
				{section.heading.heading}
			</Heading>
		);
	}

	return (
		<SimpleExpander
			ariaLabel={ariaLabel}
			id={id}
			isEnabled={isPhone}
			isOpen={!isPhone}
			heading={linkHeading}
			themeName={themeName}
		>
			<FooterSectionItemList>
				{section.links.map((item, index) => {
					if (item.type) {
						let socialLogo = { src: '', alt: '' };
						switch (item.type.toLowerCase()) {
							case 'facebook':
								socialLogo.src = facebookImg;
								socialLogo.alt = 'facebook image';
								break;
							case 'twitter':
								socialLogo.src = twitterImg;
								socialLogo.alt = 'twitter image';
								break;
							case 'linkedin':
								socialLogo.src = linkedInImg;
								socialLogo.alt = 'linkedin image';
								break;
						}
						const customStyle =
							socialLogo.src === twitterImg
								? { height: '24px', marginRight: '0.6rem' }
								: {};
						return (
							<FooterSectionItem key={index}>
								{socialLogo && (
									<SocialLogoStyle
										src={socialLogo.src}
										alt={socialLogo.alt}
										style={customStyle}
									></SocialLogoStyle>
								)}
								<FooterSectionLink key={index} to={item.link?.url}>
									{item.link?.heading}
								</FooterSectionLink>
							</FooterSectionItem>
						);
					} else {
						return (
							<FooterSectionItem key={index}>
								<FooterSectionLink to={item.link?.url}>
									{item.link?.heading}
								</FooterSectionLink>
							</FooterSectionItem>
						);
					}
				})}
			</FooterSectionItemList>
		</SimpleExpander>
	);
};

export default FooterSection;
