/**
 * HeroBanner - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { ImageFragmentModel } from 'types/fragments';

export const HeroBannerStyle = styled.div<ImageFragmentModel>`
	background-image: url(${(props) => props.src});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const HeroBannerTextBlockStyle = styled.div`
	display: inline-block;
	background-color: ${props => props.theme.colors.white};

	margin-top: ${props => props.theme.spacing.space7}rem;
	margin-bottom: ${props => props.theme.spacing.space8}rem;
	padding: ${props => props.theme.spacing.space3}rem ${props => props.theme.spacing.space5}rem;
	${mediaQueries.phone} {
		margin-top: ${props => props.theme.spacing.space3}rem;
		margin-bottom: ${props => props.theme.spacing.space7}rem;
		padding: ${props => props.theme.spacing.space3}rem ${props => props.theme.spacing.space3}rem;
	}
`;

