import { useSelector } from "react-redux";
import { selectLocalization } from "store/modules/model";
import { translate } from "utils/helper-utils";

export const useImageTitle = (photographer?: string, agency?: string, illustrator?: string) => {
    
    const localization = useSelector(selectLocalization);
	const photographerLabel = translate(
		'/frontend/image/photographer',
		'Photographer',
		localization
	);

	const agencyLabel = translate(
		'/frontend/image/agency',
		'Agency',
		localization
	);

	const illustratorLabel = translate(
		'/frontend/image/illustrator',
		'Illustrator',
		localization
	);

    let parts: string[] = [];
    let labels: string[] = [];
    const separator = ":";

    if (photographer) {
        parts = [photographer ?? "", agency ?? ""];
        labels = [photographerLabel, agencyLabel];
    }

    if (illustrator) {
        parts = [illustrator ?? "", agency ?? ""];
        labels = [illustratorLabel, agencyLabel];
    }

    if (parts.length != labels.length) {
		return "";
	}

	const sb: string[] = [];

	for (let i: number = 0; i < parts.length; i++) {
		if (parts[i] && labels[i]) {
            sb.push(`${labels[i]}${separator} ${parts[i]}`);
        }
	}

	return sb.join("\n");
};