/**
 * BannerTextLinks - styles
 * https://styled-components.com/docs/advanced#referring-to-other-components
 */

import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { BannerTextLinksProps } from './BannerTextLinks';

export const BannerTextLinksStyle = styled.div<BannerTextLinksProps>`
	padding: ${props => props.theme.spacing.space6}rem 0rem;
	${mediaQueries.tablet} {
		padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem;
	}
	${mediaQueries.phone} {
		padding: ${props => props.theme.spacing.space4}rem 0;
	}

	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
`;

export const BannerTextLinksListStyle = styled.ul`
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	align-self: center;

	> li:last-child {
		margin-bottom: ${0}rem;
	}

	${mediaQueries.tablet} {
		align-self: auto;
		text-align: center;
	}

	${mediaQueries.phone} {
		align-self: auto;
		text-align: center;
	}
`;

export const BannerTextLinksListItemStyle = styled.li<ThemeProps>`
	a {
		color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	}
	margin-bottom: ${props => props.theme.spacing.space2}rem;
`;
