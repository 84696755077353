/**
 * PuffCard - styles
 */

import styled from 'styled-components';
import { getLinkArrowStyle } from 'theme/styles';
import Link from 'components/Boilerplate/Link';
import { CommonDecalStyle } from 'components/Panels/Panels.styles';
import { Heading } from 'components/Typography/Typography';
import { mediaQueries } from 'theme';

export const PuffCardStyle = styled(Link)`
	background-color: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
	flex-shrink: 1;
	flex-grow: 1;

	${mediaQueries.tabletOrLarger} {
		flex-basis: calc(33.33% - 16px);
		min-width: 300px;
		flex-shrink: 0;
		flex-grow: 0;
	}

	&:after {
		content: none;
	}

	&:hover {
		text-decoration: none;
	}
`;

// TODO: Do we need object-fit: contain;
export const PuffCardImageStyle = styled.div`
	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
`;

export const PuffColumnReverse = styled.div`
	display: flex;
	flex-direction: column-reverse;
	flex-grow: 1;
`;

export const PuffColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const PuffCardDecalStyle = styled(CommonDecalStyle)`
	margin-top: ${(props) => props.theme.spacing.space2}rem;
	text-align: center;
	padding: 0 ${(props) => props.theme.spacing.space2}rem;
`;

export const PuffCardTextContainerStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: ${(props) => props.theme.spacing.space2}rem;
	text-align: center;
	align-self: center;
	justify-content: center;
`;

export const PuffCardHeaderStyle = styled(Heading)``;

export const PuffCardPreambleStyle = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space2}rem;
	max-width: 65ch;
`;

export const PuffCardMetadataStyle = styled.div``;

export const PuffCardLinkStyle = styled.div`
	${(props) => props.theme.typography.style6.getStyle()};
	color: ${(props) => props.theme.colors.secondaryBlue};

	border-top: 1px solid ${(props) => props.theme.colors.grey3};
	padding: ${(props) => props.theme.spacing.space2}rem
		${(props) => props.theme.spacing.space2}rem;
	margin: 0 ${(props) => props.theme.spacing.space2}rem;

	text-align: center;

	${getLinkArrowStyle()};

	${PuffCardStyle}:hover &,${PuffCardStyle}:focus & {
		text-decoration: underline;
		&:after {
			opacity: 1;
		}
	}
`;
