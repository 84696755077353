/**
 * DateRangeDropdown
 */

import {
	FieldsetContainer,
	LabelWrapper,
} from 'components/Panels/Panels.styles';
import { NumberRange } from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

export type DateRangeDropdownProps = {
	label: string;
	fromLabel: string;
	toLabel: string;
	selectedRange: NumberRange;
	numberRange: NumberRange;
	onYearRangeChanged?: (range: NumberRange) => void;
	flexRow?: boolean;
};

export const DateRangeDropdown: React.FC<DateRangeDropdownProps> = ({
	label,
	fromLabel,
	toLabel,
	numberRange,
	selectedRange,
	onYearRangeChanged,
	flexRow = false,
}) => {
	const themeContext = useContext(ThemeContext);

	const [state, setState] = useState<NumberRange>(selectedRange);
	const [yearRanges, setYearRanges] = useState<number[]>([]);

	// Calculate date range
	useEffect(() => {
		setState(selectedRange);
	}, [selectedRange]);

	// Calculate date range
	useEffect(() => {
		const range = [];

		let end =
			numberRange.to && numberRange.to > 0
				? numberRange.to
				: new Date().getFullYear();
		let start = numberRange.from ? numberRange.from : end;

		for (let i = start; i <= end; i++) {
			range.push(i);
		}

		setYearRanges(range);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberRange]);

	const onStartYearRangeSelected = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const newRangeState = { ...state };

		if (event.target.value) {
			newRangeState.from = parseInt(event.target.value);
			if (newRangeState.to && newRangeState.from > newRangeState.to) {
				newRangeState.to = newRangeState.from;
			}
		} else {
			newRangeState.from = null;
		}

		setState(newRangeState);
		if (onYearRangeChanged) {
			onYearRangeChanged(newRangeState);
		}
	};

	const onEndYearRangeSelected = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const newRangeState = { ...state };
		if (event.target.value) {
			newRangeState.to = parseInt(event.target.value);
			if (newRangeState.from && newRangeState.to < newRangeState.from) {
				newRangeState.from = newRangeState.to;
			}
		} else {
			newRangeState.to = null;
		}

		setState(newRangeState);
		if (onYearRangeChanged) {
			onYearRangeChanged(newRangeState);
		}
	};

	return (
		<FieldsetContainer flexRow={flexRow}>
			<legend aria-label={label}>{label}</legend>

			<select
				onChange={onStartYearRangeSelected}
				value={state.from ? state.from : ''}
				aria-label={fromLabel}
			>
				<option value="">{fromLabel}</option>

				{yearRanges.map((item, index) => {
					return (
						<option key={index} value={item}>
							{item}
						</option>
					);
				})}
			</select>
			<strong style={{ marginRight: `${themeContext.spacing.space2}rem` }}>
				–
			</strong>
			<select
				onChange={onEndYearRangeSelected}
				value={state.to ? state.to : ''}
				aria-label={toLabel}
			>
				<option value="">{toLabel}</option>

				{yearRanges.reverse().map((item, index) => {
					return (
						<option key={index} value={item}>
							{item}
						</option>
					);
				})}
			</select>
		</FieldsetContainer>
	);
};
