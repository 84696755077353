/**
 * SidebarPuff
 */

import Typography, { Heading } from 'components/Typography/Typography';
import React from 'react';
import { ImageFragmentModel } from 'types/fragments';
import { ImagePuffContainer, ImagePuffContent } from './SidebarPuff.styles';
import Image from 'components/Boilerplate/Image';
import { useImageTitle } from 'hooks/useImageTitle';

type SidebarPuffProps = {
	image?: ImageFragmentModel | null;
	headingLevel?: number;
	heading?: string | null;
	text?: string | null;
};

export const SidebarPuff: React.FC<SidebarPuffProps> = ({
	image,
	headingLevel,
	heading,
	text,
}) => {

	const title = useImageTitle(image?.photographer, image?.agency, image?.illustrator);

	return (
		<ImagePuffContainer>
			{image && (
				<Image 
					alt={image.alt}
					src={image.src}
					srcSet={image.srcSet}
					title={title}>
				</Image>
			)}

			<ImagePuffContent>
				{heading && (
					<Heading level={headingLevel} styleLevel={4}>
						{heading}
					</Heading>
				)}
				{text && <Typography as="p">{text}</Typography>}
			</ImagePuffContent>
		</ImagePuffContainer>
	);
};
