/* PLOP_INJECT_IMPORT */
import NVseStandardListingPage from './NVseStandardListingPage';
import FjallStartPage from './microsite/Fjall/FjallStartPage';
import FjallArticlePage from './microsite/Fjall/FjallArticlePage';
import Microsite404Page from './microsite/Microsite404Page';
import MicrositeListingPage from './microsite/MicrositeListingPage';
import MicrositeSearchPage from './microsite/MicrositeSearchPage';
import MicrositeArticlePage from './microsite/MicrositeArticlePage';
import MicrositeStartPage from './microsite/MicrositeStartPage';
import MicrositeQuizPage from './microsite/MicrositeQuizPage';
import NVseJobListPage from './NVseJobListPage';
import NVseJobDetailPage from './NVseJobDetailPage';
import NVseGrantsListingPage from './NVseGrantsListingPage';
import NVseStatisticsPage from './NVseStatisticsPage';
import NVseStartPage from './NVseStartPage';
import NVseGrantPage from './NVseGrantPage';
import NVseGrantStartPage from './NVseGrantStartPage';
import NVseGuidanceStartPage from './NVseGuidanceStartPage';
import NVseNavigationPage from './NVseNavigationPage';
import NVseArticlePage from './NVseArticlePage';
import StandardPage from './StandardPage';
import { ContentTypes } from 'types/pages';
import NVseGuidanceCategoryPage from './NVseGuidanceCategoryPage';
import NVseGuidanceChapterPage from './NVseGuidanceChapterPage';
import NVseSearchPage from './NVseSearchPage';
import NVseThemePage from './NVseThemePage';
import NVseStatisticsCategoryPage from './NVseStatisticsCategoryPage';
import NVsePublicationPage from './NVsePublicationPage';
import NVsePublicationsCategoryPage from './NVsePublicationsCategoryPage';
import NVseSubjectAreaListingPage from './NVseSubjectAreaListingPage';
import NVseSubjectAreaPage from './NVseSubjectAreaPage';
import ErrorHandler404Page from './ErrorHandler404Page';
import NVseEventPage from './NVseEventPage';
import NVseEventsListingPage from './NVseEventsListingPage/NVseEventsListingPage';
import NVseRegulationPage from './NVseRegulationPage';
import NVseRegulationsListingPage from './NVseRegulationsListingPage';
// do not remove these line
import NVseGuidanceListingPage from './NVseGuidanceListingPage';
import NVseStatisticsListingPage from './NVseStatisticsListingPage';
import NVsePublicationsListingPage from './NVsePublicationsListingPage';

const Pages: any = {
	/* PLOP_INJECT_PAGE */
	FjallStartPage,
	FjallArticlePage,
	Microsite404Page,
	MicrositeListingPage,
	MicrositeSearchPage,
	MicrositeArticlePage,
	MicrositeStartPage,
	MicrositeQuizPage,
	NVseGuidanceListingPage: NVseStandardListingPage,
	NVseStatisticsListingPage: NVseStandardListingPage,
	NVsePublicationsListingPage: NVseStandardListingPage,
	NVseStartPage,
	ErrorHandler404Page,
	NVseJobListPage,
	NVseJobDetailPage,
	NVseArticlePage,
	NVseGrantsListingPage,
	NVseGrantPage,
	NVseGrantStartPage,
	NVseGuidanceChapterPage,
	NVseGuidanceCategoryPage,
	NVseGuidanceStartPage,
	NVseNavigationPage,
	NVseSearchPage,
	StandardPage,
	NVseStatisticsPage,
	NVseThemePage,
	NVsePublicationPage,
	NVseStatisticsCategoryPage,
	NVsePublicationsCategoryPage,
	NVseSubjectAreaListingPage,
	NVseSubjectAreaPage,
	NVseEventsListingPage,
	NVseEventPage,
	NVseRegulationPage,
	NVseRegulationsListingPage,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType!];

		if (page) {
			return page;
		}
	}

	return ErrorHandler404Page;
};
