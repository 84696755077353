/**
 * Microsite404Page
 */

import EpiFragments from 'components/Boilerplate/EpiFragments';
import { BlockType } from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Microsite404PageModel } from './Microsite404PageModel';

/** Main description for this component. */
const Microsite404Page: React.FC<Microsite404PageModel> = ({
	heading,
	preamble,
	textAsModel,
	epi,
	_properties,
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={themeContext.spacing.space3}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			{textAsModel != null && textAsModel.fragments.length > 0 && (
				<EpiFragments
					previousBlockType={BlockType.Element}
					headingLevel={2}
					epi={epi}
					fragments={textAsModel.fragments}
					htmlAttributes={_properties?.mainBody}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}
		</Space>
	);
};

export default Microsite404Page;
