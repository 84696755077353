import React, { useEffect, useRef } from 'react';
import {
	Answer,
	AnswerIcon,
	AnswerText,
	IconWrapper,
	Question,
	Alternatives,
	Answers,
} from './QuizResult.style';
import Icon from '../../../Boilerplate/Icon';
import { FragmentListModel } from 'types/fragments';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { AnswerModel } from 'hooks/useQuizForm';
import Typography, { Heading } from 'components/Typography/Typography';

export interface AnswerResultType {
	resultTitle: string;
	yourAnswerText: string;
	correctAnswerText: string;
	minimumPassing: number;
	failedText: FragmentListModel | null;
	passedText: FragmentListModel | null;
	answers: AnswerModel[];
}

const AnswerResult: React.FC<AnswerResultType> = ({
	resultTitle,
	yourAnswerText,
	correctAnswerText,
	minimumPassing,
	failedText,
	passedText,
	answers,
}) => {
	const headingRef = useRef<HTMLHeadingElement>(null);

	const correctAnswersTotal = () => {
		return answers.filter((answer) => answer.answer === answer.correctAnswer)
			.length;
	};

	const userPassed = () => {
		var total = correctAnswersTotal();
		return total >= minimumPassing ? true : false;
	};

	useEffect(() => {
		if (headingRef.current) headingRef.current?.focus();
	}, []);

	return (
		<div>
			<Typography as="h2" ref={headingRef} tabIndex={-1}>
				{resultTitle} - {`${correctAnswersTotal()} / ${answers.length}`}
			</Typography>
			<div style={{ marginLeft: '-16px' }}>
				<EpiFragments
					fragments={
						userPassed() ? passedText?.fragments : failedText?.fragments
					}
				/>
			</div>
			{answers.map((answer, index) => (
				<div key={index}>
					<Answers>
						<Heading level={3}>
							<Question>
								{answer.question}
								{answer.answer === answer.correctAnswer ? (
									<IconWrapper>
										<Icon color="green" icon="check" size={2} />
									</IconWrapper>
								) : (
									<IconWrapper>
										<Icon color="red" icon="cross" size={2} />
									</IconWrapper>
								)}
							</Question>
						</Heading>
						<div style={{ marginLeft: '-16px' }}>
							<EpiFragments fragments={answer.questionDescription?.fragments} />
						</div>
						<Alternatives>
							{answer.answerOptions.map((answerOption, i) => (
								<li>{answerOption}</li>
							))}
						</Alternatives>
						<hr />
						<Typography as="div">{yourAnswerText}</Typography>
						<Typography as="div">
							<Answer>
								<AnswerIcon>
									{answer.answer === answer.correctAnswer ? (
										<Icon color="green" icon="check" size={2} />
									) : (
										<Icon color="red" icon="cross" size={2} />
									)}
								</AnswerIcon>

								<AnswerText>{answer.answer}</AnswerText>
							</Answer>
						</Typography>
						<Typography as="div">{correctAnswerText}</Typography>
						<Answer>
							<AnswerIcon>
								<Icon color="green" icon="check" size={2} />
							</AnswerIcon>
							<AnswerText>{answer.correctAnswer}</AnswerText>
						</Answer>
						<div style={{ marginLeft: '-16px' }}>
							<EpiFragments
								fragments={answer.correctAnswerExplination?.fragments}
							/>
						</div>
					</Answers>
				</div>
			))}
		</div>
	);
};

export default AnswerResult;
