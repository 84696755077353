import React from 'react';
import { PuffWithImageModel } from 'types/blocks';
import { PuffWithImage } from 'components/Boilerplate/Puff';
import { Options } from '../EpiFragments';

export const GetPuffWithLargeImageElement = (
	puff: PuffWithImageModel,
	options?: Options
) => {
	return <PuffWithImage {...puff} />;
};
