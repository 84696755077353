import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Img } from './Image.styles';
import { LinkType } from 'pages/sharedModelTypes';

interface LinkableImageProps {
	src?: string;
	srcSet?: string;
	alt?: string;
	/** Pass optional css class names for the html */
	className?: string;
	title?: string;
	link?: LinkType;
	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

const LinkableImage: React.FC<LinkableImageProps> = ({
	src,
	srcSet,
	alt,
	className,
	title,
	htmlAttributes,
	link,
}) => {
	if (link) {
		if (link.isFile) {
			return (
				<a
					href={link.url}
					target="_self"
					rel={link.rel ?? undefined}
					title={link.heading}
				>
					<Img
						src={src}
						srcSet={srcSet}
						alt={alt}
						className={className}
						title={title}
						{...htmlAttributes}
					/>
				</a>
			);
		}
		return (
			<div>
				<RouterLink
					to={link.url}
					target={link.target ?? '_self'}
					rel={link.rel ?? undefined}
					title={link.heading}
				>
					<Img
						src={src}
						srcSet={srcSet}
						alt={alt}
						className={className}
						title={title}
						{...htmlAttributes}
					/>
				</RouterLink>
			</div>
		);
	}

	return (
		<Img
			src={src}
			srcSet={srcSet}
			alt={alt}
			className={className}
			title={title}
			{...htmlAttributes}
		/>
	);
};

export default LinkableImage;
