/**
 * MicrositeFooter
 */

import React, { useContext } from 'react';
import { CustomerService, LogoModel, ModelFooterSection } from 'types/epi';
import { LinkType } from 'pages/sharedModelTypes';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import {
	MicrositeContainer,
	MicrositeFooterLinkContainer,
	MicrositeFooterLogoLink,
	MicrositeFooterSectionContainer,
	MicrositeFooterTextContainer,
} from './MicrositeFooter.styles';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import { ThemeContext } from 'styled-components';
import {
	FooterSectionItem,
	FooterSectionItemList,
	FooterSectionLink,
	FooterSectionText,
	FooterSectionTextEmail,
	SocialLogoStyle,
} from 'components/SiteFooter/FooterSection.styles';
import { Heading } from 'components/Typography/Typography';

interface Props {
	footerGroups: ModelFooterSection[];
	customerService: CustomerService;
	micrositeLogo?: LogoModel;
}

const MicrositeFooter: React.FC<Props> = ({
	customerService,
	micrositeLogo,
}) => {
	const themeContext = useContext(ThemeContext);
	const localization = useSelector(selectLocalization);
	return (
		<footer>
			<MicrositeContainer>
				<nav aria-label="footer navigation">
					<Grid paddingTop={false} paddingBottom={false}>
						<MicrositeFooterLogoLink
							showLinkIcon={false}
							data-testid="logoLink"
							to={micrositeLogo && micrositeLogo.url ? micrositeLogo.url : '/'}
							height={micrositeLogo?.height}
						>
							<img
								data-testid="logoImage"
								src={micrositeLogo?.src}
								alt={micrositeLogo?.alt}
							/>
						</MicrositeFooterLogoLink>
						<MicrositeFooterSectionContainer>
							<Grid inner={true}>
								<Cell span={4} tablet={4} phone={4}>
									<Heading
										level={2}
										styleLevel={2}
										zeroBottom={true}
										color={themeContext?.colors.white}
									>
										{customerService?.footerLinkList.heading}
									</Heading>

									<FooterSectionItemList>
										{customerService.footerLinkList.links.map((item, index) => (
											<FooterSectionItem key={index}>
												<MicrositeFooterLinkContainer>
													<FooterSectionLink to={item?.url}>
														{item?.heading}
													</FooterSectionLink>
												</MicrositeFooterLinkContainer>
											</FooterSectionItem>
										))}
									</FooterSectionItemList>
								</Cell>
								<Cell span={4} tablet={4} phone={4}>
									<Heading
										level={2}
										styleLevel={2}
										zeroBottom={true}
										color={themeContext?.colors.white}
									>
										{customerService?.heading}
									</Heading>

									<FooterSectionItemList>
										{customerService?.contactInformation &&
											customerService?.contactInformation.map((item, index) => (
												<FooterSectionItem key={index}>
													<MicrositeFooterTextContainer>
														{item?.key === 'Email' ? (
															<FooterSectionTextEmail
																href={`mailto:${item?.value}`}
																target="_blank"
															>
																{item?.value}
															</FooterSectionTextEmail>
														) : (
															<FooterSectionText>
																{item?.value}
															</FooterSectionText>
														)}
													</MicrositeFooterTextContainer>
												</FooterSectionItem>
											))}
									</FooterSectionItemList>
								</Cell>
								{customerService?.socialLinks && (
									<Cell span={4} tablet={4} phone={4}>
										<Heading
											level={2}
											styleLevel={2}
											zeroBottom={true}
											color={themeContext?.colors.white}
										>
											{customerService?.socialLinks.heading}
										</Heading>
										<FooterSectionItemList>
											{customerService?.socialLinks &&
												customerService?.socialLinks.links.map(
													(item, index) => {
														return (
															<FooterSectionItem key={index}>
																{item.icon && (
																	<SocialLogoStyle
																		width={item.icon?.width}
																		height={item.icon?.height}
																		src={item.icon?.src}
																		alt={item.icon?.alt}
																	></SocialLogoStyle>
																)}
																<FooterSectionLink
																	key={index}
																	to={item.link?.url}
																>
																	{item.link?.heading}
																</FooterSectionLink>
															</FooterSectionItem>
														);
													}
												)}
										</FooterSectionItemList>
									</Cell>
								)}
							</Grid>
						</MicrositeFooterSectionContainer>
					</Grid>
				</nav>
			</MicrositeContainer>
		</footer>
	);
};

export default MicrositeFooter;
