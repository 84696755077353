/**
 * MicrositeHeader - styles
 */

import Link from 'components/Boilerplate/Link';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const MicrositeHeaderLogoLink = styled(Link)`
	flex: 0 0 auto;
	margin-right: ${(props) => props.theme.spacing.space6}rem;
	padding-top: ${(props) => props.theme.spacing.space2}rem;
	padding-bottom: ${(props) => props.theme.spacing.space2}rem;
	img {
		height: ${(props) => props.height || 'auto'};
	}

	${mediaQueries.phone} {
		margin-right: ${(props) => props.theme.spacing.space1}rem;
	}
`;
