/**
 * SubjectAreaIconPuff - styles
 */

import Image from 'components/Boilerplate/Image';
import Link from 'components/Boilerplate/Link';
import styled from 'styled-components';
import { gridMaxWidth } from 'theme/grid';
import { px } from 'theme/utils';

export const SubjectAreaIconPuffMobileContainer = styled.div`
	display: flex;
	flex-direction: row;
	min-height: unset;
	border-bottom: 1px solid ${props => props.theme.colors.grey3};
	align-items: flex-start;
	padding: 0 0 ${props => props.theme.spacing.space2}rem 0;
	width: 100%;
`;

export const SubjectAreaIconPuffMobileImage = styled(Image)`
	margin-top: ${props => props.theme.spacing.space1}rem 0 ${props => props.theme.spacing.space2}rem 0;
	height: unset;
	width: 64px;
`;

export const SubjectAreaIconPuffMobileContent = styled.div`
	flex: 2;
	padding: 0 0 0 ${props => props.theme.spacing.space2}rem;
	align-self: center;
`;

// TODO: H2??
export const SubjectAreaIconPuffMobileTitleLink = styled(Link)`
		${props => props.theme.typography.style4.getStyle()};		
	color: ${props => props.theme.palette.text.primary};			
	h2 {
		display: inline;
	}
`;

export const SubjectAreaIconPuffContainer = styled.div``;

export const SubjectAreaIconPuffLink = styled(Link)`
	height: 204px;
	display: flex;
	flex-direction: column;
	width: ${props => (px(gridMaxWidth - 16 * 2) - props.theme.spacing.space2 * 3) / 4}rem;

	align-items: center;
	background-color: ${props => props.theme.colors.white};
	padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem
	${props => props.theme.spacing.space1}rem ${props => props.theme.spacing.space2}rem;
	border: 3px solid transparent;
	color: ${props => props.theme.palette.text.primary};			

	&:hover {
		border: 3px solid ${props => props.theme.colors.secondaryBlue};
	}

	h2 {
		flex: 1 1 auto;
		margin-bottom: 0;
		text-align: center;
		display: flex;
		align-items: center;
	}
`;

export const SubjectAreaIconPuffImage = styled(Image)`
	margin: 0px;
	width: 64px;
	flex: 0;
`;
