import PuffCard from 'components/PuffCard';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { GuidancePuffModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetGuidancePuffModelElement = (
	guidancePuffModel: GuidancePuffModel,
	options: Options
) => {
	switch (options?.view) {
		default:
		case ViewType.Card:
			return (
				<PuffCard
					image={guidancePuffModel.image}
					decal={guidancePuffModel.decal}
					heading={guidancePuffModel.heading}
					headingLevel={options.headingLevel}
					preamble={guidancePuffModel.areaNames.join(' ')}
					link={guidancePuffModel.link}
				></PuffCard>
			);
		case ViewType.List:
			const newLink: LinkType = {
				url: guidancePuffModel.link.url,
				heading: guidancePuffModel.heading,
				isFile: guidancePuffModel.link.isFile,
			};

			return (
				<PuffListItem
					decal={guidancePuffModel.decal}
					headingLevel={options.headingLevel}
					link={newLink}
					image={guidancePuffModel.image}
					compact={false}
				>
					{guidancePuffModel.areaNames.length > 0 && (
						<span>{guidancePuffModel.areaNames.join(' ')}</span>
					)}
				</PuffListItem>
			);

		case ViewType.CompactList:
			return (
				<PuffListItem
					decal={guidancePuffModel.decal}
					headingLevel={options.headingLevel}
					link={guidancePuffModel.link}
					image={guidancePuffModel.image}
					compact={true}
				>
					{guidancePuffModel.areaNames.length > 0 && (
						<span>{guidancePuffModel.areaNames.join(' ')}</span>
					)}
				</PuffListItem>
			);
	}
};
