import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBorderColor } from 'pages/sharedModelTypes';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from 'theme/styles';
import { SimpleExpanderStyleProps } from './SimpleExpander';

export const focusOutlineEffect = () => {
	return css`
		&:focus {
			${accessibilityFocus()};
		}
	`;
};

export const SimpleExpanderHeader = styled.div<SimpleExpanderStyleProps>`
	display: flex;
	flex-direction: column;
	${(p) => p.isEnabled && `cursor: pointer;`}

	${mediaQueries.phone} {
		${(p) => p.isEnabled && focusOutlineEffect()};
	}
`;

const cheveron = () => {
	return css`
		margin: 0px ${props => props.theme.spacing.space2}rem;
		flex: 0 1 auto;
	`;
};

export const SimpleExpanderHeaderContent = styled.div<SimpleExpanderStyleProps>`
	display: flex;
	flex: 1 1 auto;

	> *:first-child {
		flex: 1 1 auto;
	}

	${({ isEnabled }) => isEnabled && cheveron()};

	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
		align-self: center;
	}
`;

export const SimpleExpanderDevider = styled.div<ThemeProps>`
	${mediaQueries.phone} {
		margin-top: 8px;
		border-bottom: 1px solid;
		opacity: 0.18;
		border-bottom-color: ${(p) => `${getThemeBorderColor(p.theme, p.themeName)}`};
	}
`;

export const SimpleExpanderContent = styled.div<SimpleExpanderStyleProps>`
	display: ${(p) => (p.isOpen ? 'inherit' : 'none')};
`;
