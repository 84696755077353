/**
 * PuffContainer - styles
 */

import styled from 'styled-components';

export interface ContactBlockStyles {
	jobTitle: string | null;
}

export const ContactBlockContainer = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${props => props.theme.colors.white};
	img {
		width: 102px;
		object-fit: cover;
	}
`;

export const ContactBlockContentWrapper = styled.div<ContactBlockStyles>`
	flex-direction: column;
	padding: ${props => props.theme.spacing.space4}rem;
	align-items: flex-start;
	display: flex;
	justify-content: center;
`;

export const ContactBlockHeader = styled.div`
		${props => props.theme.typography.style8.getStyle()};		
	color: ${props => props.theme.palette.text.primary};			
	margin-bottom: ${props => props.theme.spacing.space1}rem;
`;

export const ContactBlockSubTitle = styled.div`
		${props => props.theme.typography.style9.getStyle()};		
`;
