import Link from 'components/Boilerplate/Link';
import Button from 'components/Button';
import { ScreenReaderText } from 'components/Typography';
import { useState } from 'react';
import {
	MessageContainer,
	TopBorder,
	HeaderContainer,
	MessageHeading,
	BackgroundWrapper,
	Text,
	TextWrapper,
	LinkWrapper,
} from './GlobalMessage.styles';

interface Props {
	index: number;
	heading: string | undefined;
	link: string | undefined;
	text: string | undefined;
	showMoreText: string | undefined;
	showLessText: string | undefined;
	linkText: string | undefined;
}

const GlobalMessage = ({
	index,
	heading,
	link,
	text,
	showMoreText,
	showLessText,
	linkText,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const openMessageHandler = () => {
		setIsOpen((prevState) => !prevState);
	};

	const notFirst = index > 0;

	return (
		<MessageContainer role="status">
			{notFirst && <TopBorder />}
			<HeaderContainer>
				<MessageHeading>{heading}</MessageHeading>
				<Button
					aria-expanded={isOpen}
					aria-controls={`message-button-${index}`}
					iconName={isOpen ? 'arrowup' : 'arrowdown'}
					onClick={openMessageHandler}
				>
					{isOpen ? (
						<>
							{showLessText}
							<ScreenReaderText>om {heading}</ScreenReaderText>
						</>
					) : (
						<>
							{showMoreText}
							<ScreenReaderText>om {heading}</ScreenReaderText>
						</>
					)}
				</Button>
			</HeaderContainer>
			<BackgroundWrapper>
				<TextWrapper>
					<Text hidden={!isOpen}>{text}</Text>
					{link && (
						<LinkWrapper hidden={!isOpen}>
							<Link href={link}>
								{linkText}
								<ScreenReaderText>om {heading}</ScreenReaderText>
							</Link>
						</LinkWrapper>
					)}
				</TextWrapper>
			</BackgroundWrapper>
		</MessageContainer>
	);
};

export default GlobalMessage;
