/**
 * ExpanderPrototype
 */

import React, { useEffect, useState } from 'react';
import {
	ExpanderPrototypeHeader,
	ExpanderPrototypeHeaderContent,
	ExpanderPrototypeContent,
	HeaderDevider,
} from './ExpanderPrototype.styles';
import Icon from 'components/Boilerplate/Icon';
import { PuffTheme } from 'pages/sharedModelTypes';

export type ExpanderPrototypeContainerProps = {
	isOpen: boolean;
};

export type ExpanderPrototypeStyleProps = {
	isEnabled?: boolean;
	isOpen?: boolean;
	themeName?: PuffTheme;
};

export interface ExpanderPrototypeProps {
	heading: string | JSX.Element | null;
	isOpen?: boolean;
	isEnabled?: boolean;
	themeHeader?: PuffTheme;
	id?: string;
	themeContent?: PuffTheme | undefined;
}

/** A ExpanderPrototype component. */
const ExpanderPrototype: React.FC<ExpanderPrototypeProps> = ({
	heading,
	isOpen = true,
	isEnabled = true,
	children,
	id = 'expander',
	themeHeader,
	themeContent,
}) => {
	const [isOpenState, setIsOpenState] = useState(isOpen);
	const [isEnabledState, setIsEnabledState] = useState(isEnabled);

	const elementId = `${id}-content`;

	useEffect(() => {
		setIsOpenState(isOpen);
	}, [isOpen]);

	useEffect(() => {
		setIsEnabledState(isEnabled);
	}, [isEnabled]);

	const headerClicked = () => {
		setIsOpenState(!isOpenState);
	};

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		switch (event.key) {
			case 'Enter':
			case ' ':
				event.preventDefault();
				setIsOpenState(!isOpenState);
				break;
			default:
				break;
		}
	};

	// Todo.. wrap header content in a heading element <h2,h3, etc..>

	return (
		<>
			<ExpanderPrototypeHeader
				aria-expanded={isOpenState}
				aria-controls={elementId}
				tabIndex={0}
				role="button"
				aria-disabled={!isEnabled}
				onKeyDown={handleContainerKeyPress}
				onClick={isEnabledState ? headerClicked : undefined}
				themeName={themeHeader}
				isOpen={isOpenState}
				isEnabled={isEnabledState}
			>
				<ExpanderPrototypeHeaderContent isOpen={isOpenState}>
					{heading && heading}
					{children && isEnabledState && <Icon icon="chevron" size={1} />}
				</ExpanderPrototypeHeaderContent>

				{isOpenState && themeHeader === themeContent && (
					<HeaderDevider></HeaderDevider>
				)}
			</ExpanderPrototypeHeader>

			<ExpanderPrototypeContent
				role="region"
				id={elementId}
				isOpen={isOpenState}
				themeName={themeContent}
			>
				{children}
			</ExpanderPrototypeContent>
		</>
	);
};

export default ExpanderPrototype;
