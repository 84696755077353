import { PillButton, PillWrapper } from './Pills.styles';
import Icon from 'components/Boilerplate/Icon';

/**
 * Pills
 */
export type PillItem = {
	label: string;
	TypeFilter: string;
	keyPill: string;
};

export type PillsProps = {
	items: PillItem[];
	onRemove?: (item: string) => void;
};

export const Pills: React.FC<PillsProps> = ({ items, onRemove }) => {
	const ariaLabel = 'Ta bort sökord';

	const onItemClicked = (item: string) => {
		if (onRemove) {
			onRemove(item);
		}
	};

	return (
		<PillWrapper>
			{items.map((p) => {
				return (
					<PillButton
						onClick={() => onItemClicked(p.label)}
						key={p.keyPill}
						aria-label={p.label ?? ariaLabel}
					>
						<Icon size={2} icon="cross" />
						<span>{p.label}</span>
					</PillButton>
				);
			})}
		</PillWrapper>
	);
};

export default Pills;
