import axios from 'axios';
import { ContentLinkResponse, Model } from 'types/epi';

export const getEpiPage = async (apiUrl: string, route: string) => {
	const appendUrl = '.json';

	if (route === '') {
		route = `index${appendUrl}`;
	} else if (route.indexOf('?') > -1) {
		let index = route.indexOf('?');
		let query = route.substring(index);
		route = route.substring(0, index);

		if (route.endsWith('/')) {
			route += `index${appendUrl}`;
		} else {
			route += appendUrl;
		}

		route += query;
	} else if (/\.(jpe?g|png|gif|bmp)$/i.test(route)) {
		route += '';
	} else {
		route += appendUrl;
	}

	const url = `${apiUrl}${route}`;
	const { data } = await axios.get<Model>(url);
	return data;
};

export const getEpiPageContent = async (apiUrl: string, route: string) => {
	let appendUrl = '.json';

	if (route === '') {
		route = `index${appendUrl}`;
	} else if (route.indexOf('?') > -1) {
		let index = route.indexOf('?');
		let query = route.substring(index);
		route = route.substring(0, index);

		if (route.endsWith('/')) {
			route += `index${appendUrl}`;
		} else {
			route += appendUrl;
		}

		route += query;
	} else {
		route += appendUrl;
	}

	const url = `${apiUrl}${route}`;
	const { data } = await axios.get<Model>(url);
	return data.content;
};

export const getPagegontentWithContentLink = async (contentLink: string) => {
	const { data } = await axios.get<ContentLinkResponse>(
		`/api/naturvardsverket/pageonediting/${contentLink}`
	);
	return data;
};

// function sleep(ms: number) {
// 	return new Promise(resolve => setTimeout(resolve, ms));
// }

export const fetchSuggestions = async (
	apiUrl: string,
	route: string,
	query: string
): Promise<string[]> => {
	try {
		const url = `${apiUrl}${route}?${query}`;
		const { data } = await axios.get<any>(url);
		return data?.suggestions;
	} catch (error) {
		console.error('Error fetching suggestions', error);
		return [];
	}
};
export const getEpiSearchResult = async (
	apiUrl: string,
	route: string,
	query: string
) => {
	// TODO: <any> => <T>

	const url = `${apiUrl}${route}?${query}`;

	const { data } = await axios.get<any>(url);
	//	await sleep(2000);

	return data;
};

export enum ApiPath {
	NVseSearchPage = 'api/naturvardsverket/search/publications',
	NVseSearchPageDemo = '/api/search/publications',
	NVseRegulations = 'api/naturvardsverket/search/regulations',
	NVseRegulationsDemo = '/api/search/regulations',
	NVseStatisticsListingPage = 'api/naturvardsverket/search/statistics',
	NVseStatisticsListingPageDemo = '/api/search/statistics',
}
