/**
 * LogoBanner
 */
import { Grid } from 'components/Boilerplate/Grid';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import React from 'react';
import { ImageFragmentModel, LogoBannerModel } from 'types/fragments';
import {
	LogoBannerFooter,
	LogoBannerImage,
	LogoBannerImageContainer,
	LogoBannerLink,
	LogoBannerStyle,
} from './LogoBanner.styles';

export type LogoBannerProps = {
	logoItems: ImageFragmentModel[];
	link?: LinkType;
	themeName?: PuffTheme;
};

const LogoBanner: React.FC<LogoBannerProps> = ({
	themeName,
	logoItems = [],
	link,
}) => {
	return (
		<LogoBannerStyle themeName={themeName}>
			<Grid paddingTop={false} paddingBottom={false}>
				<LogoBannerImageContainer>
					{logoItems.map((item, index) => {
						return (
							(item.url && (
								<a href={item.url}>
									<LogoBannerImage
										key={index}
										src={item.src}
										srcSet={item.srcSet}
										alt={item.alt}
									></LogoBannerImage>
								</a>
							)) || (
								<LogoBannerImage
									key={index}
									src={item.src}
									srcSet={item.srcSet}
									alt={item.alt}
								></LogoBannerImage>
							)
						);
					})}
				</LogoBannerImageContainer>
				{link && (
					<LogoBannerFooter>
						<LogoBannerLink themeName={themeName} to={link?.url}>
							{link?.heading}{' '}
						</LogoBannerLink>
					</LogoBannerFooter>
				)}
			</Grid>
		</LogoBannerStyle>
	);
};

export default LogoBanner;
