import ResponsiveIframe from 'components/Boilerplate/ResponsiveIframe';
import React from 'react';
import { VideoFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetVideoElement = (
	videoData: VideoFragmentModel,
	options?: Options
) => {
	return (
		<ResponsiveIframe aria-label="Embedded video" src={videoData.embedUrl} />
	);
};
