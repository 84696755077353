/**
 * SubjectAreaIconPuff
 */
import { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { EpiEvent } from 'types/epi';
import { ImageFragmentModel } from 'types/fragments';
import {
	SubjectAreaIconPuffMobileContainer,
	SubjectAreaIconPuffMobileContent,
	SubjectAreaIconPuffMobileImage,
	SubjectAreaIconPuffMobileTitleLink,
	SubjectAreaIconPuffContainer,
	SubjectAreaIconPuffImage,
	SubjectAreaIconPuffLink,
} from './SubjectAreaIconPuff.styles';

export interface StandardPuffProps {
	link: LinkType;
	image: ImageFragmentModel;
	headingLevel?: number;
	epi?: EpiEvent; // deprecated
}

const SubjectAreaIconPuff: React.FC<StandardPuffProps> = ({
	image,
	link,
	headingLevel,
	epi,
}) => {
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	return (
		<>
			{isPhone ? (
				<>
					<SubjectAreaIconPuffMobileContainer>
						{image && (
							<SubjectAreaIconPuffMobileImage
								alt={image.alt}
								src={image.src}
							></SubjectAreaIconPuffMobileImage>
						)}
						<SubjectAreaIconPuffMobileContent>
							{link && (
								<SubjectAreaIconPuffMobileTitleLink to={link.url}>
									{link.heading && (
										<Heading level={headingLevel} styleLevel={4}>
											{link.heading}
										</Heading>
									)}
								</SubjectAreaIconPuffMobileTitleLink>
							)}
						</SubjectAreaIconPuffMobileContent>
					</SubjectAreaIconPuffMobileContainer>
				</>
			) : (
				<>
					{link && (
						<SubjectAreaIconPuffContainer>
							<SubjectAreaIconPuffLink
								to={link.url}
								showLinkIcon={false}
								external={link.isFile}
							>
								{image && (
									<SubjectAreaIconPuffImage
										alt={image.alt}
										src={image.src}
									></SubjectAreaIconPuffImage>
								)}
								<Heading level={headingLevel} styleLevel={4}>
									{link.heading}
								</Heading>
							</SubjectAreaIconPuffLink>
						</SubjectAreaIconPuffContainer>
					)}
				</>
			)}
		</>
	);
};

export default SubjectAreaIconPuff;
