import { ItemProps } from './MenuDropdown';
import styled, { css } from 'styled-components';

export const Dropdown = styled.div<any>`
	position: absolute;
	margin-top: 0.25rem;
	margin-left: 0.125rem;
	border-radius: 12px;
	background: ${(props) => props.theme.colors.white};
	box-shadow: -1px 1px 4px #20202040;
	padding: 8px;
	z-index: 2147483647;
`;

export const MainContainer = styled.div`
	position: relative;

	button: {
		background: ${(props) => props.theme.colors.blue2};
	}
`;

const getActiveStyle = (isActive?: boolean) => {
	if (isActive) {
		return '1px solid';
	} else {
		return 'none';
	}
};

export const DropdownItem = styled.button<ItemProps>`
	padding: 16px 10px;
	border-radius: 12px;
	background: transparent;
	border: ${({ isActive }) => getActiveStyle(isActive)};
	width: 100%;
	font-weight: 600;

	&:hover,
	&:focus,
	&:focus-within {
		cursor: pointer;
		background: ${(props) =>
			props.theme.isMicrositeActive
				? props.theme.colors.primary
				: props.theme.colors.secondaryBlue};
		color: #fff;
	}
`;
