import styled from 'styled-components';

interface CustomProps {
	shorterInput?: boolean;
}

export const SearchInputFormContainer = styled.form<CustomProps>`
	justify-content: space-between;

	align-items: flex-end;
	fieldset {
		padding: 0;
		margin: 0;
		border: none;
		flex: ${(props) => (props.shorterInput ? '0 1 633px' : '1 1 auto')};
		display: flex;
		flex-direction: column;
	}
`;

export const SearchInputFormInputContainer = styled.div<CustomProps>`
	justify-content: ${(props) =>
		props.shorterInput ? 'flex-start' : 'space-between'};
	display: flex;
	align-items: flex-end;

	button {
		margin-right: 0;
	}
`;
