/**
 * MenuDropdown
 */

import Button from 'components/Button';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useEffect, useRef, useState } from 'react';
import { Link as LinkInterface } from 'types/global';
import { Dropdown, DropdownItem, MainContainer } from './MenuDropdown.styles';

interface Props {
	/**Default header to use for menu, if any. */
	mobileHeader?: string;
	/** Menu Item List containing links to other pages or sections */
	links?: Array<LinkInterface | (LinkInterface & { code: string })>;
	/** bool var to pass the correct button variant */
	isMicrosite?: boolean;
}

/** Dropdown Menu for PageHeader */
const MenuDropdown: React.FC<Props> = ({
	links = [],
	mobileHeader,
	isMicrosite = false,
}) => {
	const [dropdownExpanded, setDropdownExpanded] = useState(false);
	const [menuTitle, setMenuTitle] = useState<string>();
	let containerRef = useRef<any>();

	useOutsideClick(containerRef, () => {
		setDropdownExpanded(false);
	});

	useEffect(() => {
		setMenuTitle(mobileHeader);
	}, [mobileHeader]);

	const onLangChange = (newUrl?: string) => {
		if (newUrl) document.location.href = newUrl;
	};

	return (
		<MainContainer ref={containerRef} role="menu">
			<Button
				type="button"
				variant={isMicrosite ? ButtonVariant.Microsite : ButtonVariant.Tertiary}
				onClick={() => setDropdownExpanded(!dropdownExpanded)}
			>
				{menuTitle}
			</Button>
			{dropdownExpanded && (
				<Dropdown>
					{links?.map((link, index) => (
						<DropdownItem
							isActive={link.active}
							key={index}
							onClick={() => onLangChange(link?.url)}
						>
							{link.text}
						</DropdownItem>
					))}
				</Dropdown>
			)}
		</MainContainer>
	);
};

export default MenuDropdown;

export type ItemProps = {
	isActive?: boolean;
};
