/**
 * Button
 */

import React from 'react';
import clsx from 'clsx';
import Link from 'components/Boilerplate/Link';

interface Props {
	/** Sets the size of the button */
	size?: 'large' | 'medium' | 'small';

	/** Sets the variant of the button */
	variant?: 'primary' | 'secondary';

	/** Sets the width of the button */
	displayWidth?: 'dynamic' | 'fixed' | 'full';

	/** Pass optional classnames for the html */
	className?: string;

	/** If component should render as a link or a button */
	type?: 'link' | 'button';

	/** On click handler */
	onClick?: (e: any) => void;

	/** Should the button be disabled */
	disabled?: boolean;

	[htmlAttributes: string]: any;
}

/** A reusable button component. */
const Button: React.FC<Props> = ({
	children,
	className = '',
	size = 'medium',
	variant = 'primary',
	disabled = false,
	type = 'button',
	displayWidth = 'dynamic',
	onClick,
	...htmlAttributes
}) => {
	const Tag = type === 'link' ? Link : 'button';
	return (
		<Tag
			disabled={disabled}
			className={clsx(
				className,
				'inline-block',
				'outline-none',
				'rounded-full',
				'font-alt',
				variant === 'primary' && 'font-bold',
				disabled && ['bg-gray-300', 'text-white'],
				variant === 'primary' &&
				!disabled && ['bg-blue', 'text-white', 'hover:bg-blue-dark'],
				variant === 'secondary' &&
				!disabled && [
					'bg-white',
					'border-2',
					'border-gray-300',
					'text-gray-600',
					'hover:bg-white-hover',
				],
				size === 'small' && ['h-10', 'px-4', 'text-base', 'leading-5'],
				size === 'medium' && ['h-14', 'px-6', 'text-lg'],
				size === 'large' && ['h-16', 'px-8', 'text-lg'],
				displayWidth === 'full' && 'w-full',
				displayWidth === 'fixed' && 'w-full md:w-button'
			)}
			onClick={onClick}
			{...htmlAttributes}
		>
			{children}
		</Tag>
	);
};

export default Button;
