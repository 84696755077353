/**
 * NVseEventPage
 */

import React, { useContext } from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import { NVseEventPageModel } from './NVseEventPage.model';
import Space from 'components/Boilerplate/Space';
import { AsideBoxStyle } from './NVseEventPage.styles';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
	Options,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import EventInfoBox from 'components/EventInfoBox';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { ColumnPageWithSidebar } from 'components/Panels/ColumnPageWithSidebar';
import { TopImage } from 'pages/NVseArticlePage/NVseArticlePage.styles';
import { ThemeContext } from 'styled-components';
import { useImageTitle } from 'hooks/useImageTitle';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Evenemangssida
 * Modeltype:<code>NVseEventPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2460057660/NVseEventPage)
 *
 * En sida för att beskriva ett specifikt evenemang, inkl datum för evenemanget
 */
const NVseEventPage: React.FC<NVseEventPageModel> = ({
	eventStartDate,
	duration,
	durationLabel,
	eventType,
	eventPlace,
	otherInfo,
	infoBoxTitle,
	infoBoxDateHeading,
	infoBoxLocationHeading,
	eventTimeLabel,
	otherInfoHeading,
	registrationLink,
	iCalLink,
	textAsModel,
	heading,
	preamble,
	bottomItems,
	mainImage,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	const title = useImageTitle(
		mainImage?.photographer,
		mainImage?.agency,
		mainImage?.illustrator
	);

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
	}
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	const options = {
		epi: epi,
		headingLevel: 2,
		insideGrid: true,
		insideCell: true,
		insideInnerGrid: true,
	} as Options;

	return (
		<>
			<EditableField opeProperty={_properties?.mainImage}>
				{mainImage && (
					<TopImage
						alt={mainImage.alt}
						src={mainImage.src}
						srcSet={mainImage.srcSet}
						title={title}
					/>
				)}
			</EditableField>

			<Space top={themeContext.spacing.getElement()} bottom={spaceAfterLast}>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						columns={8}
						headingLevel={1}
						heading={heading}
						heading_htmlAttributes={_properties?.heading}
					></GrantsHeader>
				</Grid>

				<Grid paddingTop={true} paddingBottom={false}>
					<ColumnPageWithSidebar
						options={options}
						textAsModel={textAsModel}
						link={null}
						bottomItems={bottomItems}
						preamble={preamble}
						preamble_htmlAttributes={_properties?.preamble}
						mainBody_htmlAttributes={_properties?.mainBody}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					>
						<AsideBoxStyle>
							<EventInfoBox
								eventStartDate={eventStartDate}
								duration={duration}
								durationLabel={durationLabel}
								eventTimeLabel={eventTimeLabel}
								eventType={eventType}
								eventPlace={eventPlace}
								otherInfo={otherInfo}
								infoBoxTitle={infoBoxTitle}
								infoBoxDateHeading={infoBoxDateHeading}
								infoBoxLocationHeading={infoBoxLocationHeading}
								otherInfoHeading={otherInfoHeading}
								registrationLink={registrationLink}
								iCalLink={iCalLink}
							></EventInfoBox>
						</AsideBoxStyle>
					</ColumnPageWithSidebar>
				</Grid>
			</Space>
		</>
	);
};

export default NVseEventPage;
