/**
 * InfoBox - styles
 */

import styled from 'styled-components';

export const EventInfoBoxContainer = styled.div`
	width: 100%;
	${props => props.theme.typography.style6.getStyle()};		
`;

export const LabelStyle = styled.label`
	font-weight: bold;
`;

export const SpanStyle = styled.span`
	display: block;
`;

export const SectionStyle = styled.div`
	padding-bottom: ${props => props.theme.spacing.space2}rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${props => props.theme.spacing.space2}rem;

	> *:first-child {
		padding-bottom: ${props => props.theme.spacing.space2}rem;
	}
`;

export const ButtonWrapper = styled.div``;
