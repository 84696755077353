import PuffCard from 'components/PuffCard';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { ImageFragmentModel, PublicationPuffModel } from 'types/fragments';
import {
	convertDateWithMonthWrittenOut,
	convertDateWithShortMonthWrittenOut,
} from 'utils/helper-utils';
import { Options } from '../EpiFragments';

export const GetPublicationPuffModelElement = (
	publicationPuffModel: PublicationPuffModel,
	options: Options
) => {
	const image = {
		modelType: 'Image',
		src: publicationPuffModel.image?.src,
		srcSet: publicationPuffModel.image?.srcSet,
		alt: publicationPuffModel.image?.alt,
		caption: publicationPuffModel.image?.caption,
		photographer: publicationPuffModel.image?.photographer,
		agency: publicationPuffModel.image?.agency,
		illustrator: publicationPuffModel.image?.illustrator,
	} as ImageFragmentModel;

	switch (options?.view) {
		default:
		case ViewType.Card:
			return (
				<PuffCard
					image={image}
					decal={publicationPuffModel.decal}
					heading={publicationPuffModel.heading}
					headingLevel={options.headingLevel}
					preamble={convertDateWithShortMonthWrittenOut(
						publicationPuffModel.publicationDate
					)}
					link={publicationPuffModel.link}
				></PuffCard>
			);
		case ViewType.List:
		case ViewType.CompactList:
			const newLink: LinkType = {
				url: publicationPuffModel.link.url,
				heading: publicationPuffModel.heading,
				isFile: publicationPuffModel.link.isFile,
			};

			return (
				<PuffListItem
					decal={publicationPuffModel.decal}
					headingLevel={options.headingLevel}
					link={newLink}
					image={image}
					compact={options.view === ViewType.CompactList}
				>
					{publicationPuffModel.publicationDate && (
						<span>
							{convertDateWithMonthWrittenOut(
								publicationPuffModel.publicationDate
							)}
						</span>
					)}
				</PuffListItem>
			);
	}
};
