/**
 * AnswerResult - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Question = styled.span`
	display: flex;
	margin-bottom: 5px;
	align-items: center;
`;

export const QuestionAndAnswer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 5px;
`;

export const ShowCorrectAnswer = styled.span`
	color: ${(props) => props.theme.colors.primary};
	cursor: pointer;
`;
export const Answers = styled.div`
	margin-bottom: 1rem;
	padding: 1rem;
	border: 2px solid ${(props) => props.theme.colors.primary};

	${mediaQueries.phone} {
		padding: 0;
		border: none;
	}
`;

export const Alternatives = styled.ol`
	margin-bottom: 0;
`;

export const CorrectAnswer = styled.div`
	margin-top: 0.5rem;
	font-size: 0.8rem;
	font-style: italic;
	text-decoration: underline;
`;

export const IconWrapper = styled.span`
	padding-left: 4px;
	display: flex;
	align-items: center;
`;
export const Answer = styled.div`
	display: flex;
	text-align: left;
	margin: 5px 0 1rem 0;
	cursor: pointer;
	align-items: center;
	line-height: 1.5;
	border: 2px solid ${(props) => props.theme.colors.primary};
`;
export const AnswerText = styled.span`
	flex-grow: 1;
	padding: 0.75rem;
	font-family: 'GT Walsheim';
	border-left: inherit;
`;
export const AnswerIcon = styled.div`
	margin: 1em;
	min-width: 1.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1rem;
`;
