import PuffCard from 'components/PuffCard';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { ModelLocalization } from 'types/epi';
import { RegulationPuffModel } from 'types/fragments';
import {
	convertDateWithShortMonthWrittenOut,
	translate,
} from 'utils/helper-utils';
import { Options } from '../EpiFragments';

export const GetRegulationPuffModelElement = (
	regulationPuffModel: RegulationPuffModel,
	options: Options,
	localization: ModelLocalization | undefined
) => {
	const validFromString = translate(
		'/frontend/puff/validfrom',
		'Valid from',
		localization
	);

	const buildMetaDataString = (): string | null => {
		let metadata = '';

		if (regulationPuffModel.validFromDate) {
			const dateString = convertDateWithShortMonthWrittenOut(
				regulationPuffModel.validFromDate
			);
			if (dateString) {
				if (metadata) {
					metadata += ', ';
				}
				metadata += `${validFromString} ${dateString}`;
			}
		}

		if (regulationPuffModel.regulationId) {
			const nfsString = `${regulationPuffModel.regulationId}`;
			if (nfsString) {
				if (metadata) {
					metadata += ', ';
				}
				metadata += nfsString;
			}
		}

		if (regulationPuffModel.tag) {
			if (metadata) {
				metadata += `, ${regulationPuffModel.tag}`;
			} else {
				metadata += regulationPuffModel.tag;
			}
		}

		return metadata;
	};

	const metadata = buildMetaDataString();

	switch (options?.view) {
		default:
		case ViewType.Card:
			return (
				<PuffCard
					decal={regulationPuffModel.decal}
					heading={regulationPuffModel.heading}
					headingLevel={options.headingLevel}
					preamble={regulationPuffModel.regulationId}
					link={regulationPuffModel.link}
				></PuffCard>
			);
		case ViewType.List:
			const linkItem: LinkType | undefined = regulationPuffModel.link?.url
				? {
						url: regulationPuffModel.link?.url,
						heading: regulationPuffModel.heading,
						isFile: regulationPuffModel.link.isFile,
				  }
				: undefined;

			return (
				<PuffListItem
					decal={regulationPuffModel.decal}
					headingLevel={options.headingLevel}
					link={linkItem}
					compact={false}
				>
					{metadata && <span>{metadata}</span>}
				</PuffListItem>
			);

		case ViewType.CompactList: {
			const linkItem: LinkType | undefined = regulationPuffModel.link?.url
				? {
						url: regulationPuffModel.link?.url,
						heading: regulationPuffModel.heading,
						isFile: regulationPuffModel.link.isFile,
				  }
				: undefined;

			return (
				<PuffListItem
					decal={regulationPuffModel.decal}
					headingLevel={options.headingLevel}
					link={linkItem}
					compact={true}
				>
					{metadata && <span>{metadata}</span>}
				</PuffListItem>
			);
		}
	}
};
