/**
 * SimplePuff
 */

import React, { useContext } from 'react';
import { SimplePuffStyle, TopPuffContent, TopPuffHeader } from './SimplePuff.styles';
import Typography from 'components/Typography';
import { Heading } from 'components/Typography/Typography';
import { ThemeContext } from 'styled-components';

type SimplePuffProps = {
	title: string;
	content: string;
	headingLevel: number;
};

/** A SimplePuff */
export const SimplePuff = ({
	title,
	content,
	headingLevel,
}: SimplePuffProps) => {

	const themeContext = useContext(ThemeContext);

	return (
		<SimplePuffStyle>
			<TopPuffHeader>
				<Heading color={themeContext.colors.primary} level={headingLevel} styleLevel={4} zeroBottom={true}>
					{title}
				</Heading>
			</TopPuffHeader>
			<TopPuffContent>
				<Typography as="p">{content}</Typography>
			</TopPuffContent>
		</SimplePuffStyle>
	);
};

export default SimplePuff;
