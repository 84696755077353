import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateEpi } from 'store/modules/epi';
import { fetchPageWithContentLink } from 'store/modules/model';
import { ContentSavedEvent } from 'types/epi';

export const useEpiContextAndSavedEvent = () => {
	const dispatch = useDispatch();

	const handleContentSaved = () => {
		if (window.epi.isEditable) {
			window.epi.subscribe('contentSaved', (event: ContentSavedEvent) => {
				dispatch(fetchPageWithContentLink(event.contentLink));
			});
		}
	};

	const setContextAndEvent = () => {
		dispatch(
			updateEpi({
				isEditable: window.epi.isEditable,
				inEditMode: window.epi.inEditMode,
				inPreviewMode: window.epi.inEditMode && !window.epi.isEditable,
				ready: window.epi.ready,
			})
		);

		handleContentSaved();
	};

	const handleSetContextAndEvent = () => {
		//epi object won't be available outside of Epi
		if (!window.epi) {
			return;
		}

		if (window.epi.ready === true) {
			// `epiReady` already fired.
			setContextAndEvent();

			// The subscribe method won't be available outside of Epi.
		} else if (window.epi.subscribe) {
			// Subscribe if the `epiReady` event hasn't happened yet.
			window.epi.subscribe('epiReady', () => setContextAndEvent());
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('load', handleSetContextAndEvent);
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('load', handleSetContextAndEvent);
			}
		};
	}, []);
};
