/**
 * PageTopBanner - styles
 */

import { ThemeProps } from 'components/Banner/Banner';
import Link from 'components/Boilerplate/Link';
import {
	getThemeBackground,
	getThemeTextColor,
	PuffTheme,
} from 'pages/sharedModelTypes';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { gridMaxWidth } from 'theme/grid';

export const Div = styled.div``;

export const TopBannerWithImageContainer = styled.div<ThemeProps>`
	display: grid;
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};

	${mediaQueries.desktop} {
		grid-template-columns: 50% 50%;
	}
`;

export const MicroTopBannerWithImageContainer = styled.div<ThemeProps>`
	display: grid;
	background-color: transparent;

	${mediaQueries.desktop} {
		grid-template-columns: 50% 50%;
	}

	${mediaQueries.tabletOrLarger} {
		background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	}
`;

export const TopBannerWithTextContainer = styled.div`
	display: flex;
	justify-self: center;

	${mediaQueries.desktop} {
		justify-self: flex-end;
		align-items: flex-end;
		width: ${(gridMaxWidth - 32) / 2}px;
	}
`;

export const MicroTopBannerWithTextContainer = styled.div<ThemeProps>`
	display: flex;
	justify-self: center;
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	width: 100vw;
	${mediaQueries.desktop} {
		background-color: transparent;
		justify-self: flex-end;
		align-items: flex-end;
		width: ${(gridMaxWidth - 32) / 2}px;
	}
`;

export type ImageProp = {
	_height: number;
	_width: number;
	zoom: boolean;
};

const getImageDiementions = (prop: ImageProp) => {
	return css`
		height: ${prop._height}px;
		width: ${prop._width}px;
		${prop.zoom && `object-fit: cover`};
	`;
};

export const ImageContainerStyle = styled.div<ImageProp>`
	img {
		${mediaQueries.desktop} {
			${(p) => getImageDiementions(p)};
		}
		max-width: unset;
		vertical-align: unset;
	}
`;

export const MicroImageContainerStyle = styled.div<ImageProp>`
	display: grid;
	place-items: center;
	height: 50%;
	background-color: ${(props) => props.theme.colors.tertiary};
	img {
		${mediaQueries.phone} {
			width: 90%;
		}
		${mediaQueries.desktop} {
			${(p) => getImageDiementions(p)};
		}
		max-width: unset;
		vertical-align: unset;
	}
`;

export const TextContainerContentStyle = styled.div<ThemeProps>`
	display: flex;
	flex-direction: column;
	overflow-wrap: anywhere;
	padding-right: ${(props) => props.theme.spacing.space5}rem;

	color: ${(props) => props.theme.colors.white};
	align-self: center;

	${mediaQueries.phoneOrTablet} {
		text-align: center;
		padding: ${(props) => props.theme.spacing.space4}rem;
	}
`;

export const TopBannerNoImageContainer = styled.div<ThemeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	padding-top: ${(props) => props.theme.spacing.space5}rem;
	padding-bottom: ${(props) => props.theme.spacing.space5}rem;
`;

export type TextContainerWrapperProps = {
	themeName?: PuffTheme | null;
	paddingHorizontal?: boolean;
	center?: boolean;
};

export const TextContainerHeader = styled.div<TextContainerWrapperProps>`
	${(props) => props.theme.typography.style5.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const TextContainerSubHeader = styled.div<TextContainerWrapperProps>`
	${(props) => props.theme.typography.style6.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const TopBannerLink = styled(Link)<TextContainerWrapperProps>`
	${(props) => props.theme.typography.style7.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
`;
