/**
 * NVseSubjectAreaListingPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const Div = styled.div``;

export const ListingPageIndexPanelWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing.space5}rem;
	margin-bottom: ${px2rem(150)};
	${mediaQueries.phone} {
		margin-top: ${(props) => props.theme.spacing.space3}rem;
		margin-bottom: ${px2rem(253)};
	}
`;
