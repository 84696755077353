/**
 * NVseJobDetailPage - styles
 */

import { Cell, Grid } from 'components/Boilerplate/Grid';
import { mediaQueries } from 'theme';
import styled from 'styled-components';
import { styleForPreamble } from 'components/Typography/Typography.styles';

export const Div = styled.div``;

export const DetailGrid = styled(Grid)`
	margin-block: 3rem;
`;

export const ContentBodyTop = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-block-end: 1rem;
`;

export const ContentBodyCell = styled(Cell)`
	grid-column-start: 6;
	${mediaQueries.phoneOrTablet} {
		grid-column-start: 1;
	}

	> * {
		&:first-child > div > div {
			p:first-of-type > strong,
			p:first-of-type > em > strong,
			p:first-of-type > em {
				${styleForPreamble};
				font-style: normal;
				em {
					font-style: normal;
				}
			}
		}
	}
`;
