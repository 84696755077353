/**
 * PageTopBanner
 */
import { Grid } from 'components/Boilerplate/Grid';
import {
	getThemeHeadingColor,
	PuffTheme,
	LinkType,
} from 'pages/sharedModelTypes';
import React, { useContext, useRef } from 'react';
import {
	ImageContainerStyle,
	TextContainerContentStyle,
	TextContainerHeader,
	TextContainerSubHeader,
	TopBannerNoImageContainer,
	TopBannerWithImageContainer,
	TopBannerWithTextContainer,
	TopBannerLink,
	ImageProp,
	MicroTopBannerWithImageContainer,
	MicroTopBannerWithTextContainer,
	MicroImageContainerStyle,
} from './PageTopBanner.styles';
import Image from 'components/Boilerplate/Image';
import { Heading } from 'components/Typography/Typography';
import { ImageFragmentModel } from 'types/fragments';
import { useSize } from 'hooks/useSize';
import { ThemeContext } from 'styled-components';
import { rem2px } from 'theme/utils';
import { useImageTitle } from 'hooks/useImageTitle';
import EditableField from 'components/EditableField/EditableField';

type PageTopBannerProps = {
	mainImage?: ImageFragmentModel;
	themeTop: PuffTheme | null;
	decal: string | null;
	headingLevel?: number;
	heading: string | null;
	subheading?: string | null;
	link?: LinkType | null;
	mainImage_htmlAttributes?: any;
	heading_htmlAttributes?: any;
};

/** Top image banner */
const PageTopBanner: React.FC<PageTopBannerProps> = ({
	mainImage,
	decal,
	headingLevel,
	heading,
	subheading,
	themeTop,
	link,
	heading_htmlAttributes = {},
	mainImage_htmlAttributes = {},
}) => {
	const themeContext = useContext(ThemeContext);
	const bannerElementRef = useRef<HTMLDivElement>(null);
	const textElementRef = useRef<HTMLDivElement>(null);
	const textSize = useSize(textElementRef);
	const banner = useSize(bannerElementRef);

	const title = useImageTitle(
		mainImage?.photographer,
		mainImage?.agency,
		mainImage?.illustrator
	);

	const textBannerContainer = (
		<TextContainerContentStyle themeName={themeTop} ref={textElementRef}>
			{decal && (
				<TextContainerHeader themeName={themeTop}>{decal}</TextContainerHeader>
			)}
			<EditableField opeProperty={heading_htmlAttributes}>
				{heading && (
					<Heading
						level={headingLevel}
						styleLevel={1}
						color={getThemeHeadingColor(themeContext, themeTop)}
					>
						{heading}
					</Heading>
				)}
			</EditableField>

			{subheading && (
				<TextContainerSubHeader themeName={themeTop}>
					{subheading}{' '}
				</TextContainerSubHeader>
			)}
			{link && (
				<TopBannerLink
					themeName={themeTop}
					to={link.url}
					external={link.isFile}
				>
					{link.heading}
				</TopBannerLink>
			)}
		</TextContainerContentStyle>
	);

	if (mainImage) {
		const bw = (banner as any)?.width;
		const th = (textSize as any)?.height;
		const p_max = rem2px(themeContext.spacing.space7) * 4;
		const p_min = rem2px(themeContext.spacing.space2) * 2;

		const bh_max = th + p_max;
		const bh_min = th + p_min;

		const wantedImageHeight = Math.floor((bw / 2) * (9 / 16));
		let imageHeight = 0;
		let imageWidth = Math.floor(bw / 2);

		let zoom: boolean = false;

		if (wantedImageHeight > bh_max) {
			// ZOOM
			imageHeight = bh_max;
			zoom = true;
		} else if (wantedImageHeight < bh_min) {
			// ZOOM
			imageHeight = bh_min;
			zoom = true;
		} else {
			imageHeight = wantedImageHeight;
		}

		const imageProps: ImageProp = {
			_height: imageHeight,
			_width: imageWidth,
			zoom: zoom,
		};

		return (
			<>
				{themeContext.isMicrositeActive ? (
					<>
						<MicroTopBannerWithImageContainer
							ref={bannerElementRef}
							themeName={themeTop}
						>
							<MicroTopBannerWithTextContainer themeName={themeTop}>
								{textBannerContainer}
							</MicroTopBannerWithTextContainer>
							<MicroImageContainerStyle {...imageProps}>
								<Image
									alt={mainImage.alt}
									src={mainImage.src}
									srcSet={mainImage.srcSet}
									title={title}
									{...mainImage_htmlAttributes}
								></Image>
							</MicroImageContainerStyle>
						</MicroTopBannerWithImageContainer>
					</>
				) : (
					<TopBannerWithImageContainer
						ref={bannerElementRef}
						themeName={themeTop}
					>
						<TopBannerWithTextContainer>
							{textBannerContainer}
						</TopBannerWithTextContainer>
						<ImageContainerStyle {...imageProps}>
							<Image
								alt={mainImage.alt}
								src={mainImage.src}
								srcSet={mainImage.srcSet}
								title={title}
								{...mainImage_htmlAttributes}
							></Image>
						</ImageContainerStyle>
					</TopBannerWithImageContainer>
				)}
			</>
		);
	}

	return (
		<TopBannerNoImageContainer themeName={themeTop}>
			<Grid paddingTop={false} paddingBottom={false}>
				{textBannerContainer}
				<EditableField opeProperty={mainImage_htmlAttributes} />
			</Grid>
		</TopBannerNoImageContainer>
	);
};

export default PageTopBanner;
