/**
 * ListHeader - styles
 */

import styled, { css } from 'styled-components';
import Link from 'components/Boilerplate/Link';
import { mediaQueries } from 'theme';
import { Heading } from 'components/Typography/Typography';

export const ListHeaderStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

type AddRightMargin = {
	addRightMargin: boolean;
};

const getightMargin = () => {
	return css`
		margin-right: ${(props) => props.theme.spacing.space2}rem;
	`;
};

export const ListHeadingStyle = styled(Heading)<AddRightMargin>`
	${({ addRightMargin }) => addRightMargin && getightMargin()}
`;

export const ListHeaderLinkStyle = styled(Link)`
	margin-top: ${(props) => props.theme.spacing.space1}rem;
`;

export const ListHeaderMetadataStyle = styled.div`
	flex-grow: 1;
	display: flex;
	align-self: stretch;

	@media (max-width: 768px) {
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 1rem;
	}
`;

export const ListHeaderControllsContainerStyle = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: baseline;
`;

export const ListHeaderSwitchesStyle = styled.div`
	${mediaQueries.phone} {
		display: none;
	}

	*:not(:last-child) {
		margin-right: ${(props) => props.theme.spacing.space2}rem;
	}

	input[type='radio'] {
		opacity: 0;
		position: fixed;
		width: 0;
	}

	input[type='radio']:checked + label {
		border: 2px solid ${(props) => props.theme.colors.secondaryBlue};
		svg {
			fill: ${(props) => props.theme.colors.secondaryBlue};
		}
	}

	label {
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		height: 42px;
		width: 42px;
	}
`;
