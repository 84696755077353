import { EpiFormsProps } from 'components/EpiForms/EpiForms';
import { LinkExtendedType, LinkType, PuffTheme } from 'pages/sharedModelTypes';
import { PuffWithImageModel } from './blocks';
import { ViewType } from './enums';

export enum PageLayoutType {
	Standard = 'standard',
	Micro = 'micro',
}

export type FragmentTypes =
	| 'Html'
	| 'FormContainer'
	| 'HeadingFragment'
	| 'LogoBannerModel'
	| 'NumericBannerBlockModel'
	| 'RawFragment'
	| 'ListFragment'
	| 'Image'
	| 'Video'
	| 'BannerModel'
	| 'HeroBannerModel'
	| 'DashboardBlockModel'
	| 'CollapsibleBlockModel'
	| 'WidgetNumericBlockModel'
	| 'PuffWithLargeImage'
	| 'ContentBlockModel'
	| 'ExpanderTabModel'
	| 'YouTubeModel'
	| 'FaqModel'
	| 'MediaBanner'
	| 'LegalBoxModel'
	| 'PuffContainer'
	| 'CollapsibleContainerModel'
	| 'GuidancePuffModel'
	| 'StatisticsPuffModel'
	| 'StatisticsBlockModel'
	| 'StandardPuffModel'
	| 'SubjectAreaIconPuffModel'
	| 'GrantPuffModel'
	| 'EventPuffItemModel'
	| 'PublicationPuffModel'
	| 'RegulationPuffModel'
	| 'TableFragment'
	| 'ContactModel'
	| 'HighlightModel';

export interface FragmentModel {
	modelType: FragmentTypes;
}

export interface HeadingFragmentModel extends FragmentModel {
	level: number;
	id?: string;
	raw: string;
	isPage?: boolean;
	disableCustomHeadingLogic?: boolean;
	blockLevel: number;
}

export interface RawFragmentModel extends FragmentModel {
	raw: string;
}

export interface ListFragmentModel extends FragmentModel {
	raw: string;
}

export interface ImageFragmentModel extends FragmentModel {
	src: string;
	srcSet: string;
	alt: string;
	caption: string;
	url: string;
	photographer: string;
	agency: string;
	illustrator: string;
	link?: LinkType;
	height?: string;
	width?: string;
}

export interface CollapsibleBlockModel extends FragmentModel {
	heading: string;
	text: string;
	textAsModel: FragmentListModel;
	colorTheme?: string;
	showAsClosed: boolean;
}

export interface VideoFragmentModel extends FragmentModel {
	embedUrl: string;
}

export interface TableFragmentModel extends FragmentModel {
	caption?: string;
	raw: string;
}

export type ImagePuffModel = {
	heading: string | null;
	text: string | null;
	image: ImageFragmentModel;
};

export interface ExpanderTabFragmentModel extends FragmentModel {
	heading: string;
	text: string;
	sidebar: ImagePuffModel;
	textAsModel: FragmentListModel;
	link: LinkType | null;
	bottomItems: FragmentModelTypes[];
}

export interface FragmentListModel extends FragmentModel {
	fragments: FragmentModelTypes[];
}

export interface YouTubeFragmentModel extends FragmentModel {
	videoID: string;
	title: string;
	allowFullScreen: boolean;
}

export type FaqFragmentItemModel = {
	question: string;
	answer: string;
};

export interface LegalBoxModel extends FragmentModel {
	name: string;
	link: LinkType;
	sfsNumber: string;
	text: string;
	textAsModel: FragmentListModel;
	showAsClosed: boolean;
	showIcon?: boolean;
}

/**
 * BlockBaseModel
 * Used for onpage navigation, sidebar navigation
 */
export interface BlockBaseModel extends FragmentModel {
	onPageMenuHeading: string; // Null om onPageMenuVisibility = false
	onPageMenuVisibility: boolean;
}

export interface PuffContainerModel extends BlockBaseModel {
	heading: string;
	link: LinkType;
	puffItems: FragmentModelTypes[];
	renderAsCarousel: boolean;
	forwardButtonText: string;
	backwardButtonText: string;
	viewType?: ViewType;
}

export interface ContentBlockModel extends BlockBaseModel {
	heading: string;
	text: string;
	textAsModel: FragmentListModel;
	sidebar: ImagePuffModel;
	link: LinkType | null;
	bottomItems: FragmentModelTypes[];
}

export interface BannerFragmentModel extends BlockBaseModel {
	heading: string;
	link: LinkType;
	preamble: string;
	theme: string;
	linkList: LinkExtendedType[];
}

export interface HeroBannerModel extends BlockBaseModel {
	textToRight: boolean;
	image: ImageFragmentModel;
	heading: string;
	text: string;
	link: LinkType;
}

export interface LogoBannerModel extends BlockBaseModel {
	logoItems: ImageFragmentModel[];
	link?: LinkType;
	theme?: PuffTheme;
}
export interface NumericBannerBlockModel extends BlockBaseModel {
	theme?: PuffTheme;
	textToRight?: boolean;
	heading?: string | null;
	preamble?: string | null;
	text?: string | null;
	subtext?: string | null;
	link?: LinkType | null;
	trend?: number | null;
}
export interface FaqFragmentModel extends BlockBaseModel {
	heading: string;
	items: FaqFragmentItemModel[];
}

export interface CollapsibleContainerModel extends BlockBaseModel {
	heading: string;
	items: CollapsibleBlockModel[];
}
export interface MediaBannerFragmentModel extends BlockBaseModel {
	textToRight: boolean;
	title: string;
	heading: string;
	text: string | null;
	mediaContent: YouTubeFragmentModel | ImageFragmentModel;
	link: LinkType;
	theme: PuffTheme;
}
export interface DashboardBlockModel extends BlockBaseModel {
	theme: string;
	items: FragmentModelTypes[];
}
export interface StatisticsBlockModel extends BlockBaseModel {
	heading: string;
	link: LinkType;
	highChartsOptions: string;
	useShortDate: boolean;
	classificationList: string[];
}

export interface GrantPuffModel extends FragmentModel {
	targetGroupIds: number[];
	statusId: number;
	statusText: string | null;
	statusAlert: boolean;
	applicationEndDate: string | null;
	isPrioritized: boolean;
	decal: string | null;
	heading: string;
	link: LinkType;
	image: ImageFragmentModel | null;
}

export interface PublicationPuffModel extends FragmentModel {
	decal: string | null;
	heading: string;
	link: LinkType;
	image: ImageFragmentModel | null;
	publicationDate: string;
}

export interface RegulationPuffModel extends FragmentModel {
	decal: string | null;
	regulationId: string | null;
	heading: string;
	link: LinkType | null;
	validFromDate: string | null;
	tag: string | null;
}

export interface GuidancePuffModel extends FragmentModel {
	areaIds: number[];
	decal: string | null;
	heading: string;
	areaNames: string[];
	isSupervisory: boolean;
	link: LinkType;
	image: ImageFragmentModel;
}

export interface EventPuffItemModel extends FragmentModel {
	heading: string;
	link: LinkType;
	eventStartDate: string | null;
	eventType: string | null;
	image: ImageFragmentModel;
}

export interface WidgetNumericBlockModel extends FragmentModel {
	heading?: string | null;
	text?: string | null;
	subtext?: string | null;
	trend?: number | null;
	link?: LinkType | null;
	theme?: PuffTheme;
}

export interface ContactBlockModel extends FragmentModel {
	image: ImageFragmentModel;
	fullName: string;
	jobTitle: string | null;
}

export interface HighlightModel extends FragmentModel {
	text: string;
	textAsModel: FragmentListModel;
}

export interface StatisticsPuffModel extends FragmentModel {
	areaIds: number[];
	decal: string | null;
	heading: string;
	areaNames: string[];
	isSupervisory: boolean;
	link: LinkType;
	image: ImageFragmentModel;
}

export interface StandardPuffModel extends FragmentModel {
	heading: string;
	preamble: string;
	url: string;
	image: ImageFragmentModel;
	publicationDate?: string;
}

export interface SubjectAreaIconPuffModel extends FragmentModel {
	link: LinkType;
	image: ImageFragmentModel;
}

/**
 * Used for onpage navigation
 */
export type BlockBaseFragmentModelTypes =
	| PuffContainerModel
	| ContentBlockModel
	| CollapsibleContainerModel
	| BannerFragmentModel
	| LogoBannerModel
	| NumericBannerBlockModel
	| FaqFragmentModel
	| MediaBannerFragmentModel
	| DashboardBlockModel
	| StatisticsBlockModel
	| HeroBannerModel;

export type FragmentModelTypes =
	| EpiFormsProps
	| HeadingFragmentModel
	| RawFragmentModel
	| ListFragmentModel
	| ImageFragmentModel
	| VideoFragmentModel
	| PuffWithImageModel
	| BannerFragmentModel
	| TableFragmentModel
	| YouTubeFragmentModel
	| MediaBannerFragmentModel
	| LegalBoxModel
	| HeroBannerModel
	| DashboardBlockModel
	| CollapsibleBlockModel
	| WidgetNumericBlockModel
	| StandardPuffModel
	| SubjectAreaIconPuffModel
	| StatisticsPuffModel
	| StatisticsBlockModel
	| GrantPuffModel
	| EventPuffItemModel
	| ContentBlockModel
	| PublicationPuffModel
	| GuidancePuffModel
	| ContactBlockModel
	| HighlightModel;
