import DashboardBlock from 'components/Banners/DashboardBlock';
import { DashboardBlockModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetDashboardBlockModelElement = (
	model: DashboardBlockModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {

	return <DashboardBlock options={options} theme={model.theme} items={model.items} disableCustomHeadingLogic={disableCustomHeadingLogic}></DashboardBlock>;
};
