/**
 * HighlightBlock - styles
 */

import styled from 'styled-components';

export const HighlightBlockContainer = styled.div`
	border: 1px solid ${props => props.theme.colors.grey3};
	padding: ${props => props.theme.spacing.space3}rem;
`;
