/**
 * NVseArticlePage - styles
 */

import styled from 'styled-components';

export const Div = styled.div``;

export const TopImage = styled.img`
	width: 100%;
	object-fit: cover;
	max-height: 640px;
`;

export const ContentBlockModelWrapper = styled.div`
	max-width: 1132px;
	margin: 0 auto;
	padding: 0 1rem;
`;
