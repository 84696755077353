/**
 * MenuMobile - styles
 */

import { getCommonStyle } from 'components/Button/Button.styles';
import { isRedirectProps } from 'components/MegaMenu/MegaMenu';
import styled, { css } from 'styled-components';
import { accessibilityFocus } from 'theme/styles';
import { ItemProps } from './MenuMobile';

const getLevelStyle = (level: number) => {
	switch (level) {
		default:
		case 0:
			return css``;
		case 1:
			return css`
				border-left: 10px solid
					${(props) =>
						props.theme.isMicrositeActive
							? props.theme.colors.secondary
							: '#0039A2'};
				background-color: ${(props) =>
					props.theme.isMicrositeActive
						? props.theme.colors.tertiary
						: '#f6f6f6'};
				height: 100%;
			`;
		case 2:
			return css`
				border-left: 10px solid
					${(props) =>
						props.theme.isMicrositeActive
							? props.theme.colors.secondary
							: '#0039A2'}4D;
				background-color: ${(props) =>
					props.theme.isMicrositeActive
						? props.theme.colors.tertiary
						: '#eeeeee'};
			`;
		case 3:
			return css`
				border-left: 10px solid
					${(props) =>
						props.theme.isMicrositeActive
							? props.theme.colors.secondary
							: '#0039A2'}4D;
			`;
	}
};

const getLevelFontStyle = (level: number) => {
	switch (level) {
		default:
		case 0:
			return css`
				${(props) => props.theme.typography.style4.getStyle()};
			`;
		case 1:
			return css`
				${(props) => props.theme.typography.style6.getStyle()};
			`;
		case 2:
			return css`
				${(props) => props.theme.typography.style6.getStyle()};
			`;
		case 3:
			return css`
				${(props) => props.theme.typography.style4.getStyle()};
			`;
	}
};

export const MenuContainer = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	margin: 0px;

	position: fixed;
	top: 0vh;
	left: 0vw;
	width: 100vw;
	height: 100%;
	overflow-y: auto;
	z-index: 2;

	ul {
		list-style-type: none;
		margin: 0px;
		padding: 0px;
	}
`;

export const MenuHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: normal;
	border-bottom: 1px solid #f0f0f0;

	> img {
		margin: 8px ${(props) => props.theme.spacing.space2}rem;
		height: 73px;
	}
`;

type HeightProp = {
	height: string | undefined;
};

export const MicrositeMenuHeader = styled.div<HeightProp>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #cbcbcb;
	padding: 1rem;

	> img {
		height: ${(props) => props.height || 'auto'};
	}
`;

export const MenuListItemContainer = styled.li<ItemProps>`
	display: flex;
	flex-direction: column;

	${({ level }) => {
		return getLevelStyle(level);
	}};

	button {
		color: ${(props) => props.theme.palette.text.primary};
	}
`;

export const MenuItemHeader = styled.button<ItemProps>`
	display: flex;
	flex: 1;

	border: none;
	cursor: pointer;
	background-color: transparent;
	text-align: start;
	padding: 0px;

	${({ level }) => getLevelFontStyle(level)};
	font-weight: 400;

	&:focus {
		${accessibilityFocus('inset')};
		outline-offset: -2px;
	}

	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #eaeaea;

	${({ level }) => level === 0 && 'border-bottom: 1px solid #F0F0F0;'};
	${({ level }) => level === 1 && 'border-bottom: 1px solid #EAEAEA;'};
	${({ level }) => level === 2 && 'border-bottom: 1px solid #D3D3D3;'};

	> *:nth-child(1) {
		flex: 1;
		padding: ${(props) => props.theme.spacing.space2}rem;
	}

	> *:nth-child(2) {
		width: ${(props) => props.theme.spacing.space6}rem;
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: normal;

		${({ level }) => level === 0 && 'border-left: 1px solid #F0F0F0;'};
		${({ level }) => level === 1 && 'border-left: 1px solid #EAEAEA;'};
		${({ level }) => level === 2 && 'border-left: 1px solid #D3D3D3;'};

		background-color: ${({ isOpen, theme }) =>
			isOpen ? theme.colors.secondaryBlue : 'transparent'};
		${({ isOpen, theme }) => isOpen && `color ${theme.palette.text.secondary}`};

		svg {
			transform: ${({ isOpen }) =>
				isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
		}
	}
`;

export const MicrositeMenuItemHeader = styled.button<ItemProps>`
	display: flex;
	flex: 1;

	border: none;
	cursor: pointer;
	background-color: transparent;
	text-align: start;
	padding: 0px;

	${({ level }) => getLevelFontStyle(level)};
	font-weight: 400;

	&:focus {
		${accessibilityFocus('inset')};
		outline-offset: -2px;
	}

	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #cbcbcb;

	${({ level }) => level === 0 && 'border-bottom: 1px solid #CBCBCB;'};
	${({ level }) => level === 1 && 'border-bottom: 1px solid #CBCBCB;'};
	${({ level }) => level === 2 && 'border-bottom: 1px solid #CBCBCB;'};

	> *:nth-child(1) {
		flex: 1;
		padding: ${(props) => props.theme.spacing.space2}rem;
	}

	> *:nth-child(2) {
		width: ${(props) => props.theme.spacing.space6}rem;
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: normal;

		${({ level }) => level === 0 && 'border-left: 1px solid #CBCBCB;'};
		${({ level }) => level === 1 && 'border-left: 1px solid #CBCBCB;'};
		${({ level }) => level === 2 && 'border-left: 1px solid #CBCBCB;'};

		background-color: ${({ isOpen, theme }) =>
			isOpen ? theme.colors.secondary : 'transparent'};
		${({ isOpen, theme }) => isOpen && `color ${theme.palette.text.primary}`};

		svg {
			transform: ${({ isOpen }) =>
				isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
		}
	}
`;

export const MenuLinkItem = styled.div<isRedirectProps>`
	display: flex;
	align-items: center;

	border-bottom: 1px solid
		${(props) => (props.theme.isMicrositeActive ? '#CBCBCB' : '#eaeaea')};

	a {
		display: inline-block;
		width: 100%;
		padding: ${(props) => props.theme.spacing.space2}rem;

		${({ isRedirect, theme }) =>
			!isRedirect &&
			`
		color: ${theme.palette.text.primary};
		`}
	}
`;

export const MenuLinkItemStyle1 = styled.div<ItemProps>`
	border-bottom: 1px solid #eaeaea;
	display: flex;
	a {
		flex: 1 1 auto;
		padding: ${(props) => props.theme.spacing.space2}rem;
		${({ level }) => getLevelFontStyle(level)};
		color: ${(props) => props.theme.palette.text.primary};
		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}
	}
`;

export const CloseButton = styled.button`
	background-color: transparent;
	border: none;

	display: flex;
	align-self: center;
	align-items: unset;

	${(props) => props.theme.typography.style12.getStyle()};
	text-transform: uppercase;

	cursor: pointer;
	padding: ${(props) =>
		props.theme.isMicrositeActive ? '0' : props.theme.spacing.space2}rem;

	span {
		display: inline-flex;
		align-items: flex-end;
	}

	svg {
		fill: ${(props) => props.theme.palette.text.primary};
		margin-left: ${(props) => props.theme.spacing.space1}rem;
	}
`;

export const MicrositeCloseButton = styled.button`
	background-color: transparent;
	${getCommonStyle()};
	border-color: ${(props) => props.theme.palette.text.primary};
	padding: 0 ${(props) => props.theme.spacing.space2}rem;

	height: 45px;
	text-transform: none;
	span {
		color: ${(props) => props.theme.palette.text.primary};
	}
`;
