/**
 * SingleSelectCheckbox
 */

import React, { useEffect, useState } from 'react';
import {
	SingleFilterCheckbox,
	StyledCheckbox,
} from './SingleSelectCheckbox.styles';
import { HideCheckbox } from './MultiSelectCheckbox.styles';
import { Icon } from 'components/Checkbox/Checkbox.styles';
import { log } from 'console';

export type SingleSelectCheckboxProps = {
	checked?: boolean;
	children?: any;
	name?: string;
	value?: any;
	id?: string;
	hits?: number;
	onCheckedChanged?: (checked: boolean) => void;
	focusStyle?: string;
};

/** Single Filter Checkbox */
const SingleSelectCheckbox = React.forwardRef<any, SingleSelectCheckboxProps>(
	(
		{
			checked = false,
			children,
			onCheckedChanged,
			value,
			id,
			hits,
			focusStyle,
			...props
		},
		ref
	) => {
		const [checkedState, setCheckedState] = useState(checked);

		const handleCheckChange = (event: any) => {
			if (onCheckedChanged) {
				onCheckedChanged(event);
			}
		};

		useEffect(() => {
			setCheckedState(checked);
		}, [checked]);

		return (
			<SingleFilterCheckbox
				checked={checkedState}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						handleCheckChange(event);
					}
				}}
				className={focusStyle}
			>
				{children}
				<HideCheckbox
					ref={ref}
					checked={checkedState}
					value={value}
					{...props}
					onChange={handleCheckChange}
					data-filter-typeof={id}
				/>
				<StyledCheckbox checked={checkedState} className="checkbox">
					<Icon viewBox="3 3 18 18">
						<polyline points="18 6 11 16 5 12" />
					</Icon>
				</StyledCheckbox>
			</SingleFilterCheckbox>
		);
	}
);

export default SingleSelectCheckbox;
