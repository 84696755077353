/**
 * NVseEventPage - styles
 */

import styled from 'styled-components';

export const TopImage = styled.img`
	width: 100%;
	object-fit: cover;
	max-height: 640px;
`;

export const ColumnContainer = styled.div`
	display: flex;
`;

export const AsideBoxStyle = styled.aside`
	background-color: ${(props) => props.theme.colors.white};
	padding: ${(props) => props.theme.spacing.space4}rem
		${(props) => props.theme.spacing.space4}rem;
`;
