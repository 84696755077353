import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import MegaMenu from 'components/MegaMenu';
import PageTopBanner from 'components/PageTopBanner';
import {
	getBlockBaseMenuItems,
	getBlockBaseSections,
	OnPageNavigationContent,
} from 'components/Panels/OnPageNavigationContent';
import {
	PageOverviewBanner,
	PageOverviewContent,
} from 'pages/NVseThemePage/NVseThemePage.styles';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { ThemeContext } from 'styled-components';
import { NavigationItem } from 'types/epi';
import { FragmentModelTypes } from 'types/fragments';
import { translate } from 'utils/helper-utils';
import { NVseGuidanceChapterPageModel } from './NVseGuidanceChapterPage.model';

/**
 * # Undersida till vägledningssida
 * Modeltype:<code>NVseGuidanceChapterPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1649770511/NVseGuidanceChapterPage)
 *
 * Visas som en expander på den sida som den ligger under
 */
const NVseGuidanceChapterPage: React.FC<NVseGuidanceChapterPageModel> = ({
	mainImage,
	decal,
	heading,
	lastReviewed,
	showReviewDate,
	themeTop,
	parentHeading,
	preamble,
	textAsModel,
	bottomItems,
	epi,
	chapterList,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [sections, setSections] = useState<string[]>([]);
	const [menuIndex, setMenuIndex] = useState<NavigationItem[]>([]);
	const localization = useSelector(selectLocalization);

	const themeContext = useContext(ThemeContext);

	const findOnPageLabel = translate(
		'/frontend/pages/findonpage',
		'Find on page',
		localization
	);

	const ref = React.createRef<any>();

	useEffect(() => {
		const expanderModels = getBlockBaseSections([
			...chapterList.items,
			...bottomItems,
		]);
		const sectionStrings = expanderModels.map((item) => item.onPageMenuHeading);

		if (sectionStrings.length > 0) {
			const menuIndexRoot = getBlockBaseMenuItems(sectionStrings);
			setMenuIndex(menuIndexRoot);
			setSections(sectionStrings);
		} else {
			setMenuIndex([]);
			setSections([]);
		}
	}, [bottomItems, chapterList]);

	const onCloseMenuEvent = (userClosed: boolean) => {
		setIsMenuOpen(false);
		if (userClosed && ref.current) {
			ref.current.focus();
		}
	};

	const onOpenMenu = () => {
		setIsMenuOpen(true);
	};

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_chapterList: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (textAsModel && textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_chapterList = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (chapterList && chapterList.items.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			<PageTopBanner
				mainImage={mainImage}
				themeTop={themeTop}
				decal={decal}
				subheading=""
				headingLevel={1}
				heading={parentHeading}
				heading_htmlAttributes={null}
				mainImage_htmlAttributes={_properties?.mainImage}
			></PageTopBanner>

			{sections.length > 1 && (
				<PageOverviewBanner>
					<Grid paddingTop={false} paddingBottom={false}>
						<PageOverviewContent>
							<nav>
								<Button
									ref={ref}
									onClick={onOpenMenu}
									aria-expanded={isMenuOpen}
									aria-haspopup="dialog"
									variant={ButtonVariant.Tertiary}
								>
									{findOnPageLabel}
								</Button>
							</nav>
						</PageOverviewContent>
					</Grid>
				</PageOverviewBanner>
			)}

			<Space top={themeContext.spacing.getElement()} bottom={spaceAfterLast}>
				<OnPageNavigationContent
					headingLevel={2}
					heading={heading}
					heading_htmlAttributes={_properties?.heading}
					lastReviewed={lastReviewed}
					showReviewDate={showReviewDate}
					preamble={preamble}
					preamble_htmlAttributes={_properties?.preamble}
					textAsModel={textAsModel}
					textAsModel_htmlAttributes={_properties?.mainBody}
					sections={sections}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				></OnPageNavigationContent>

				{chapterList && (
					<EpiFragments
						previousBlockType={blockTypeBefore_chapterList}
						headingLevel={2}
						epi={epi}
						fragments={chapterList.items}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				)}

				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			</Space>

			{isMenuOpen && (
				<MegaMenu
					items={menuIndex}
					heading={heading}
					onClose={onCloseMenuEvent}
				/>
			)}
		</>
	);
};

export default NVseGuidanceChapterPage;
