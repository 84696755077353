import React, { useContext } from 'react';
import {
	PuffCardDecalStyle,
	PuffCardHeaderStyle,
	PuffCardImageStyle,
	PuffCardLinkStyle,
	PuffCardMetadataStyle,
	PuffCardPreambleStyle,
	PuffCardStyle,
	PuffCardTextContainerStyle,
	PuffColumn,
	PuffColumnReverse,
} from './PuffCard.styles';
import { LinkType } from 'pages/sharedModelTypes';
import { ImageFragmentModel } from 'types/fragments';
import Icon from 'components/Boilerplate/Icon';
import styled, { ThemeContext } from 'styled-components';
import PuffCardMobile from 'components/PuffCardMobile';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber, mediaQueries } from 'theme/media-queries';
import { useImageTitle } from 'hooks/useImageTitle';

export interface PuffCardProps {
	/**
	 * decal
	 */
	decal?: string | null;
	/**
	 * Heading
	 */
	heading?: string | undefined;
	/**
	 * Heading level
	 */
	headingLevel?: number | undefined;
	/**
	 * Preamble
	 */
	preamble?: string | null;
	/**
	 * Link
	 */
	link?: LinkType | null;
	/**
	 * Image
	 */
	image?: ImageFragmentModel | null;
	/**
	 * Compact view
	 * (no bottom link on 'mobile')
	 */
	compact?: boolean;
	/**
	 * optional for microsite
	 */
	publicationDate?: string;
}

/**
 * # PuffCard
 *
 * Puff List item
 */
const PuffCard: React.FC<PuffCardProps> = ({
	decal,
	headingLevel,
	heading,
	link,
	preamble,
	image,
	compact,
	children,
}) => {
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	if (isPhone) {
		return (
			<PuffCardMobile
				image={image}
				decal={decal}
				headingLevel={headingLevel}
				heading={heading}
				preamble={preamble}
				compact={compact}
				link={link}
			>
				{children}
			</PuffCardMobile>
		);
	}

	if (!link) return null;

	return (
		<PuffCardStyle to={link.url} aria-label={link?.heading}>
			<PuffColumnReverse>
				<PuffColumn>
					{decal && <PuffCardDecalStyle>{decal}</PuffCardDecalStyle>}

					<PuffCardTextContainerStyle>
						{heading && (
							<PuffCardHeaderStyle
								color={themeContext.palette.text.primary}
								zeroBottom={true}
								styleLevel={4}
								level={headingLevel}
							>
								{heading}
							</PuffCardHeaderStyle>
						)}
						{preamble && (
							<PuffCardPreambleStyle>{preamble}</PuffCardPreambleStyle>
						)}
					</PuffCardTextContainerStyle>
				</PuffColumn>

				{image && (
					<PuffCardImageStyle>
						<img
							src={image.src}
							srcSet={image.srcSet}
							alt={image.alt}
							title={title}
						/>
					</PuffCardImageStyle>
				)}
			</PuffColumnReverse>

			{children && <PuffCardMetadataStyle>{children}</PuffCardMetadataStyle>}

			{link && link.heading && (
				<PuffCardLinkStyle>{link.heading}</PuffCardLinkStyle>
			)}
		</PuffCardStyle>
	);
};

export default PuffCard;

/**
 * TODO: Move to seperate file
 */
export interface CardClockStatusProps {
	/**
	 * Color
	 */
	color: string;
	/**
	 * Text
	 */
	text?: string | null;
}

/**
 * TODO: Move to seperate file
 */
export const CardClockStatus: React.FC<CardClockStatusProps> = ({
	color,
	text,
}) => {
	return (
		<CardClockStatusStyle color={color}>
			<Icon icon="clock" color={color}></Icon>
			<span>{text}</span>
		</CardClockStatusStyle>
	);
};

type ColorProp = {
	color: string;
};

const CardClockStatusStyle = styled.div<ColorProp>`
	${(props) => props.theme.typography.style11.getStyle()};
	letter-spacing: -0.3px;
	text-align: center;
	color: ${({ color }) => color};
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;

	padding: 0 ${(props) => props.theme.spacing.space2}rem;

	${mediaQueries.phone} {
		text-align: inherit;
		padding: 0;
	}

	svg {
		vertical-align: middle;
		margin-right: ${(props) => props.theme.spacing.space1}rem;
		margin-bottom: 1px;
	}
`;
