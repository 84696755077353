/**
 * WidgetNumericBlock - styles
 */

import { ThemeProps } from 'components/Banner/Banner';
import {
	getThemeHeadingColor,
	getThemeTextColor,
} from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Rotate } from 'types/enums';

interface ArrowProps {
	rotate: Rotate | undefined;
}

export const WidgetNumericBlockStyle = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	flex: 1 1 0px;

	${mediaQueries.phone} {
		a {
			font-size: ${px2rem(16)};
		}
	}
`;

export const WidgetNumericBlockHeader = styled.div<ThemeProps>`
		${props => props.theme.typography.style9.getStyle()};		
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	padding: 0 ${props => props.theme.spacing.space2}rem;
	letter-spacing: 1.07px;

	${mediaQueries.phone} {
		letter-spacing: 1.07px;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			span {
				font-size: ${px2rem(14)};
			}
		}
	}

	text-transform: uppercase;
	margin-bottom: ${props => props.theme.spacing.space1}rem;
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
`;
export const WidgetNumericBlockText = styled.div<ThemeProps>`
		${props => props.theme.typography.style2.getStyle()};		
	color: ${(p) => getThemeHeadingColor(p.theme, p.themeName)};
	margin-bottom: ${props => props.theme.spacing.space1}rem;
	padding: 0 ${props => props.theme.spacing.space2}rem;
`;

export const WidgetNumericBlockSubText = styled.div<ThemeProps>`
		${props => props.theme.typography.style5.getStyle()};		
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	padding: 0 ${props => props.theme.spacing.space2}rem;
	text-transform: none;
	letter-spacing: 1.2px;
	${mediaQueries.phone} {
		text-transform: none;
		letter-spacing: 1.2px;
	}
`;

export const CharRotator = styled.span<ArrowProps>`
	font-family: 'Akkurat Pro';
	font-size: 16px;
	vertical-align: text-top;
	margin-left: 5px;

	transform: rotate(${(p) => p.rotate + 'deg'});
	display: inline-block;

	${mediaQueries.phone} {
		vertical-align: middle;
	}
`;
