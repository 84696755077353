/**
 * NVseEventsListingPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Div = styled.div``;

export const CalendarEventListGroup = styled.ol`
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	margin-top: ${props => props.theme.spacing.space4}rem;
	> * li {
		list-style-type: none;
	}


	> :first-child {
		margin-top: 0;
	}
`;
export const CalendarEventListMonthGroup = styled.li`
	margin-top: ${props => props.theme.spacing.space6}rem;
`;

export const CalendarEventListHeading = styled.div`
	background-color: ${props => props.theme.colors.blue2};
	padding: ${props => props.theme.spacing.space4}rem;

	${mediaQueries.phone} {
		padding: ${props => props.theme.spacing.space2}rem;
	}
`;

export const CalendarEventListContainer = styled.ol`
	padding: 0;
	margin: 0;
	margin-top: ${props => props.theme.spacing.space2}rem;
	> :last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		border: none;
	}
`;
