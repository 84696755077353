import Link from 'components/Boilerplate/Link';
import { P } from 'components/Typography';
import styled from 'styled-components';
import { accessibilityFocus } from 'theme/styles';
import { px2rem } from 'theme/utils';

export const FooterSectionHeader = styled(Link)`
	display: inline-block;
	letter-spacing: 1.2px;
	color: inherit;
	text-transform: uppercase;
	&:focus {
		${accessibilityFocus()};
	}
`;

export const FooterSectionItemList = styled.ul`
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	> *:first-child {
		margin-top: ${(props) =>
			props.theme.isMicrositeActive ? '0rem' : props.theme.spacing.space1}rem;
	}
`;

export const FooterSectionItem = styled.li`
	display: flex;
	align-items: center;
`;

export const FooterSectionLink = styled(Link)`
	line-height: ${(props) =>
		props.theme.isMicrositeActive ? px2rem(27) : px2rem(32)};
	color: inherit;
	&:focus {
		${accessibilityFocus()};
	}
`;

export const FooterSectionText = styled(P)`
	${(props) => props.theme.typography.style8.getStyle()};
	color: inherit;
	padding: 0px;
	margin: 0px;

	&:focus {
		${accessibilityFocus()};
	}
`;

export const FooterSectionTextEmail = styled.a`
	${(props) => props.theme.typography.style8.getStyle()};
	color: white;
	padding: 0px;
	margin: 0px;
	cursor: pointer;
	text-decoration: none;
	&:focus {
		${accessibilityFocus()};
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const SocialLogoStyle = styled.img`
	margin-right: ${(props) => props.theme.spacing.space1}rem;
	max-width: 28px;
	max-height: 28px;
	margin-top: 7px;
	margin-bottom: 9px;
`;
