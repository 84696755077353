/**
 * NVseThemePage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Div = styled.div``;


export const PageOverviewBanner = styled.div`
	position: sticky;
	top: 0;
	background-color: ${props => props.theme.colors.white};
	padding: ${props => props.theme.spacing.space2}rem ${props => props.theme.spacing.space3}rem;
	z-index: 1;
	
	${mediaQueries.phone} {
		padding: ${props => props.theme.spacing.space2}rem 0;
	}
`;

export const PageOverviewContent = styled.div`
	display: flex;
    justify-content: space-between;
`;
