/**
 * NVseJobListPage - styles
 */

import styled from 'styled-components';

import { styleForSelect } from 'components/Typography';
import { mediaQueries } from '../../theme/media-queries';

export const SortContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	select {
		${styleForSelect}
		margin-right: -1.5rem;
	}
	${mediaQueries.phone} {
		padding-top: 1rem;
		justify-content: flex-start;
		margin-right: 0px;
	}
`;
