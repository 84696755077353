import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React from 'react';
import { RegulationHistory } from './NVseRegulationPage.model';
import { RegulationHistoryDescription } from './RegulationHistoryDescription';
import { RegulationHistoryTableStyle } from './RegulationHistoryTable.styles';

/**
 * RegulationHistoryTable Props
 */
export type RegulationHistoryTableProps = {
	titleLabel: string | null;
	validFromDateLabel: string | null;
	downloadPdfLinkLabel: string | null;
	items: RegulationHistory[];
};

/**
 * RegulationHistoryTable
 */
export const RegulationHistoryTable: React.FC<RegulationHistoryTableProps> = ({
	titleLabel,
	validFromDateLabel,
	downloadPdfLinkLabel,
	items,
}) => {
	return (
		<RegulationHistoryTableStyle>
			<thead>
				<tr>
					<th align="left">{titleLabel}</th>
					<th align="left">{validFromDateLabel}</th>
					<th align="left">{downloadPdfLinkLabel}</th>
				</tr>
			</thead>

			<tbody>
				{items.map((item, index) => {
					return (
						<tr key={index}>
							<td>
								<RegulationHistoryDescription
									{...item}
								></RegulationHistoryDescription>
							</td>
							<td>{new Date(item.validFromDate).toLocaleDateString()}</td>
							<td>
								{item.downloadPdfLink && (
									<Button
										variant={ButtonVariant.Link}
										to={item.downloadPdfLink.url}
										showLinkIcon={false}
										external={item.downloadPdfLink.isFile}
									>
										{item.downloadPdfLink.heading}
									</Button>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</RegulationHistoryTableStyle>
	);
};
