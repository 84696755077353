import { NVseRegulationPageModel } from './NVseRegulationPage.model';
import Space from 'components/Boilerplate/Space';
import EpiFragments, {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
	Options,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import React, { useContext } from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import { SidebarContainer } from './NVseRegulationPage.styles';
import { Heading } from 'components/Typography/Typography';
import Link from 'components/Boilerplate/Link';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { RegulationHistoryNarrow } from './RegulationHistoryNarrow';
import { RegulationHistoryTable } from './RegulationHistoryTable';
import { SidebarRelatedPanel } from 'components/Panels/SidebarRelatedPanel';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { ColumnPageWithSidebar } from 'components/Panels/ColumnPageWithSidebar';
import { ThemeContext } from 'styled-components';
import EditableField from 'components/EditableField/EditableField';

/**
 * # NFS Behållare
 * Modeltype:<code>NVseRegulationPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2499477589/NVseRegulationPage)
 *
 * En behållare för en ny Grundföreskrift, förordning eller allmänna råd
 */
const NVseRegulationPage: React.FC<NVseRegulationPageModel> = ({
	heading,
	decal,
	preamble,
	textAsModel,
	tag,
	history,
	linkListHeadingLabel,
	linkList,
	downloadPdfLinkLabel,
	validFromDateLabel,
	titleLabel,
	bottomItems,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_history: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_history = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (history.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceBefore_history = getSpaceToAddBefore(
		blockTypeBefore_history,
		BlockType.Element,
		themeContext
	);

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	const options = {
		headingLevel: 2,
		insideGrid: true,
		insideCell: true,
		insideInnerGrid: true,
	} as Options;

	const related = [];

	if (tag) {
		related.push({ label: '', items: [tag] });
	}

	const relatedElement =
		related.length > 0 &&
		related.map((item, index) => {
			return (
				<SidebarRelatedPanel
					key={index}
					label={item.label}
					items={item.items}
				></SidebarRelatedPanel>
			);
		});

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					sectionHeading={decal}
					columns={10}
					headingLevel={1}
					heading={heading}
					heading_htmlAttributes={_properties?.heading}
					preamble={preamble}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>

				{relatedElement && relatedElement}
			</Grid>

			<EditableField opeProperty={_properties?.mainBody}>
				{linkList.length > 0 ? (
					<Grid paddingTop={true} paddingBottom={false}>
						<ColumnPageWithSidebar
							options={options}
							textAsModel={textAsModel}
							textAsModel_htmlAttributes={_properties?.mainBody}
							link={null}
							bottomItems={[]}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						>
							<SidebarContainer>
								{linkListHeadingLabel && (
									<Heading level={2} styleLevel={4}>
										{linkListHeadingLabel}
									</Heading>
								)}

								<ul>
									{linkList.map((item, index) => {
										return (
											<li key={index}>
												<Link to={item.url} external={item.isFile}>
													{item.text}
												</Link>
											</li>
										);
									})}
								</ul>
							</SidebarContainer>
						</ColumnPageWithSidebar>
					</Grid>
				) : (
					textAsModel &&
					textAsModel.fragments.length > 0 && (
						<EpiFragments
							previousBlockType={BlockType.Element}
							headingLevel={2}
							fragments={textAsModel.fragments}
							htmlAttributes={_properties?.mainBody}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)
				)}
			</EditableField>

			{history.length > 0 && (
				<Space top={spaceBefore_history}>
					<Grid paddingTop={true} paddingBottom={false}>
						{isPhone ? (
							<RegulationHistoryNarrow
								items={history}
								titleLabel={titleLabel}
								validFromDateLabel={validFromDateLabel}
								downloadPdfLinkLabel={downloadPdfLinkLabel}
							></RegulationHistoryNarrow>
						) : (
							<RegulationHistoryTable
								items={history}
								titleLabel={titleLabel}
								validFromDateLabel={validFromDateLabel}
								downloadPdfLinkLabel={downloadPdfLinkLabel}
							></RegulationHistoryTable>
						)}
					</Grid>
				</Space>
			)}

			<EpiFragments
				previousBlockType={blockTypeBefore_bottomItems}
				headingLevel={2}
				fragments={bottomItems}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default NVseRegulationPage;
