/**
 * useCookie
 */

import { useState } from 'react';

interface ISetCookieActionOptions {
	secure?: any;
	sameSite?: any;
	days?: number;
	path: string;
}

const setCookie = (
	name: string,
	value: string,
	options: ISetCookieActionOptions
) => {
	if (typeof document !== 'undefined') {
		const expires = options.days
			? '; expires=' + new Date(Date.now() + options.days * 864e5).toUTCString()
			: '';
		const sameSite = options.sameSite
			? '; SameSite=' + options.sameSite
			: '; SameSite=Lax';
		const secure = options.secure ? '; Secure' : '';

		document.cookie =
			name +
			'=' +
			encodeURIComponent(value) +
			expires +
			'; path=' +
			options.path +
			sameSite +
			secure;
	}
};

const getCookie = (name: string): string => {
	if (typeof document === 'undefined') {
		return '';
	}

	return document.cookie.split('; ').reduce((r, v) => {
		const parts = v.split('=');
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, '');
};

export const useCookie = (key: string, initialValue: string): [string, any] => {
	const [item, setItem] = useState(() => {
		return getCookie(key) || initialValue;
	});

	const updateItem = (
		value: string,
		options: ISetCookieActionOptions = { path: '/' }
	) => {
		setItem(value);
		setCookie(key, value, options);
	};

	return [item, updateItem];
};
