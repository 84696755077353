import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Subtitle = styled.p`
	${(props) => props.theme.typography.style6.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space3}rem;
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const MicroSubtitle = styled.p`
	${(props) => props.theme.typography.style7.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	margin-top: ${(props) => props.theme.spacing.space3}rem;
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const TopImage = styled.img`
	width: 100%;
	max-height: 508px;
	object-fit: cover;

	~ :nth-child(2) {
		position: relative;
		margin-top: -${(props) => props.theme.spacing.space7}rem;
		${mediaQueries.phone} {
			margin-top: -${(props) => props.theme.spacing.space4}rem;
		}
	}
`;

export const HeroContainer = styled.div`
	background-color: ${(props) => props.theme.colors.white};

	padding: ${(props) => props.theme.spacing.space4}rem
		${(props) => (props.theme.isMicrositeActive ? '11' : '3')}rem;

	${mediaQueries.phone} {
		padding: ${(props) => props.theme.spacing.space4}rem
			${(props) => props.theme.spacing.space2}rem;
	}
	text-align: center;
`;

export const ActionsContainer = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.spacing.space2}rem;
	justify-content: center;
`;
