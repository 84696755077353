/**
 * Pagination - styles
 */

import styled from 'styled-components';

export const PaginationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	color: ${props => props.theme.palette.text.primary};			

	button:first-child {
		&::before {
			content: '';
			display: inline-block;
			width: 7px;
			height: 7px;
			border-right: 2px solid;
			border-top: 2px solid;
			transform: rotate(225deg);
			margin-top: 0px;
			margin-bottom: 0px;
			margin-left: 5px;
			margin-right: 1px;
			vertical-align: middle;
			color: ${props => props.theme.palette.text.primary};			
		}
	}

	button:last-child {
		&::after {
			content: '';
			display: inline-block;
			width: 7px;
			height: 7px;
			border-right: 2px solid;
			border-top: 2px solid;
			transform: rotate(45deg);
			margin-top: 0px;
			margin-bottom: 0px;
			margin-left: 5px;
			margin-right: 1px;
			vertical-align: middle;
			color: ${props => props.theme.palette.text.primary};			
		}
	}
`;
