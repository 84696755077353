const baseFontSize: number = 16;

export const px = (sizeInPx: number) => sizeInPx / baseFontSize;
export const px2rem = (sizeInPx: number) => `${px(sizeInPx)}rem`;
export const rem2px = (sizeInRem: number) => sizeInRem * baseFontSize;

export const hashCode = (str: string) => {
	var hash = 0,
		i,
		chr;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

export const generateAnchorId = (str: string) => {
	return 'E' + hashCode('prefix' + str);
};
