import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EpiEvent } from 'types/epi';
import { RootState } from 'store';

let initialState: EpiEvent = {
	isEditable: false,
	inEditMode: false,
	inPreviewMode: false,
	ready: false,
};

const slice = createSlice({
	name: 'epi',
	initialState,
	reducers: {
		updateEpi: (state, action: PayloadAction<EpiEvent>) => {
			state.isEditable = action.payload.isEditable;
			state.inEditMode = action.payload.inEditMode;
			state.inPreviewMode = action.payload.inPreviewMode;
			state.ready = action.payload.ready;
		},
	},
});

export const selectEpi = (state: RootState) => state.epi;
export const { updateEpi } = slice.actions;

export default slice.reducer;
