import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import React from 'react';
import { ViewType } from 'types/enums';
import { PuffContainerModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetPuffContainerElement = (
	puffContainer: PuffContainerModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	return (
		<FragmentPuffList
			renderAsCarousel={puffContainer.renderAsCarousel}
			nextLabel={puffContainer.forwardButtonText}
			previousLabel={puffContainer.backwardButtonText}
			heading={puffContainer.heading}
			link={puffContainer.link}
			options={{
				...options,
				view: puffContainer.viewType ?? ViewType.Card,
			}}
			items={puffContainer.puffItems}
			disableCustomHeadingLogic={disableCustomHeadingLogic}
		></FragmentPuffList>
	);
};
