/**
 * BannerPuffTextImage - styles
 */

import { BannerPanelStyle } from 'components/BannerPanel/BannerPanel.styles';
import { getThemeBackground } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { BannerPuffTextImageProps } from './BannerPuffTextImage';

export const BannerPuffTextImageStyle = styled.div<BannerPuffTextImageProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};

	${mediaQueries.tabletOrLarger} {
		min-height: 25rem;
	}

	${BannerPanelStyle as any} {
		padding: ${props => props.theme.spacing.space5}rem ${props => props.theme.spacing.space6}rem;
		${mediaQueries.phoneOrTablet} {
			padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem;
		}
	}
`;

export const BannerPuffTextImageImage = styled.img`
	width: 100%;
	height:100%;
	object-fit: cover;
`;

export const ColumnContainer = styled.div<BannerPuffTextImageProps>`
	display: grid;
	grid-template-columns:50% 50%;

	> :last-child {
		${(p) => p.textToRight && 'grid-row: 1'};
	}

	${mediaQueries.phone} {
		grid-template-columns: 100%;
		> :last-child {
			grid-row: 1;
		}
	}
	${mediaQueries.tabletOrLarger} {
		min-height: 25rem;
	}	
`;

