import React from 'react';
import { IconProps } from './Icon';

export const IconClock = ({
	width,
	height,
	fill,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<title>Clock</title>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z M13,6 L11,6 L11,12.4142136 L14.1020719,15.5162855 L15.5162855,14.1020719 L13,11.585 L13,6 Z" />
			</g>
		</svg>
	);
};

export const IconListview = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<title>Listview</title>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<g transform="translate(4.000000, 7.000000)">
					<path d="M17,8 L17,11 L5,11 L5,8 L17,8 Z M4,8 L4,11 L0,11 L0,8 L4,8 Z M17,4 L17,7 L5,7 L5,4 L17,4 Z M4,4 L4,7 L0,7 L0,4 L4,4 Z M4,0 L4,3 L0,3 L0,0 L4,0 Z M17,0 L17,3 L5,3 L5,0 L17,0 Z" />
				</g>
			</g>
		</svg>
	);
};

export const IconChevron = ({
	width,
	height,
	fill,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="currentColor"
			{...extraProps}
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<path d="M7 6.7279L8.58391 5L16.5585 12.31L8.58391 19.62L7 17.8921L13.0883 12.31L7 6.7279Z" />
			</g>
		</svg>
	);
};

export const IconLink = ({ width, height, fill, ...extraProps }: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			fill={fill}
			viewBox="0 0 24 24"
			{...extraProps}
		>
			<title>Link</title>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<polygon
					transform="translate(13.000000, 12.000000) scale(-1, 1) rotate(-270.000000) translate(-13.000000, -12.000000) "
					points="17 8.58578644 18.4142136 10 13 15.4142136 7.58578644 10 9 8.58578644 13 12.585"
				/>
			</g>
		</svg>
	);
};

export const IconArrowDown = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<title>Down arrow</title>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<polygon
					transform="translate(12.308232, 12.159084) rotate(-270.000000) translate(-12.308232, -12.159084) "
					points="12.4573793 19.3181682 19.6164633 12.1590841 12.4573793 5 10.8096536 6.64062344 15.1349335 10.9588011 5 10.9588011 5 13.359367 15.1349335 13.359367 10.8096536 17.684647"
				/>
			</g>
		</svg>
	);
};

export const IconArrowUp = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<polygon
					points="12.457379341125488,19.31816864013672 19.616461753845215,12.15908432006836 12.457379341125488,5 10.809653282165527,6.640623092651367 15.134932518005371,10.95880126953125 4.999999046325684,10.95880126953125 4.999999046325684,13.359367370605469 15.134932518005371,13.359367370605469 10.809653282165527,17.684646606445312 "
					transform="rotate(-90 12.308231353759767,12.15908432006836) "
				/>
			</g>
		</svg>
	);
};

export const IconCross = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<g transform="translate(12.071068, 12.000000) rotate(-270.000000) translate(-12.071068, -12.000000) translate(7.071068, 7.000000)">
					<polygon
						transform="translate(4.949747, 5.050253) rotate(45.000000) translate(-4.949747, -5.050253) "
						points="10.9497475 4.05025253 10.9497475 6.05025253 -1.05025253 6.05025253 -1.05025253 4.05025253"
					/>
					<polygon
						transform="translate(4.949747, 5.050253) scale(-1, 1) rotate(45.000000) translate(-4.949747, -5.050253) "
						points="10.9497475 4.05025253 10.9497475 6.05025253 -1.05025253 6.05025253 -1.05025253 4.05025253"
					/>
				</g>
			</g>
		</svg>
	);
};

export const IconPlus = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" {...extraProps}>
			<title>Plus</title>
			<g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
				<g transform="translate(12.071068, 12.000000) rotate(-315.000000) translate(-12.071068, -12.000000) translate(7.071068, 7.000000)">
					<polygon
						transform="translate(4.949747, 5.050253) rotate(45.000000) translate(-4.949747, -5.050253) "
						points="10.9497475 4.05025253 10.9497475 6.05025253 -1.05025253 6.05025253 -1.05025253 4.05025253"
					/>
					<polygon
						transform="translate(4.949747, 5.050253) scale(-1, 1) rotate(45.000000) translate(-4.949747, -5.050253) "
						points="10.9497475 4.05025253 10.9497475 6.05025253 -1.05025253 6.05025253 -1.05025253 4.05025253"
					/>
				</g>
			</g>
		</svg>
	);
};

export const IconSearch = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="currentColor"
			{...extraProps}
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<path d="M11.5,3 C15.6421356,3 19,6.35786438 19,10.5 C19,12.3882064 18.3022281,14.1134394 17.1504741,15.4319094 L20.7353039,19.3222625 L19.2646961,20.6777375 L15.6460056,16.7508231 C14.4583701,17.5401288 13.0328894,18 11.5,18 C7.35786438,18 4,14.6421356 4,10.5 C4,6.35786438 7.35786438,3 11.5,3 Z M11.5,5 C14.5375661,5 17,7.46243388 17,10.5 C17,13.5375661 14.5375661,16 11.5,16 C8.46243388,16 6,13.5375661 6,10.5 C6,7.46243388 8.46243388,5 11.5,5 Z" />
			</g>
		</svg>
	);
};

export const IconMenu = ({ width, height, fill, ...extraProps }: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			fill={fill}
			viewBox="0 0 24 24"
			{...extraProps}
		>
			<g stroke="none" fill="currentColor">
				<path d="M19,16 L19,18 L5,18 L5,16 L19,16 Z M19,11 L19,13 L5,13 L5,11 L19,11 Z M19,6 L19,8 L5,8 L5,6 L19,6 Z"></path>
			</g>
		</svg>
	);
};

export const IconArrow = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" {...extraProps}>
			<title>Arrow</title>
			<g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
				<path
					d="M9.51555027,-2.98983061e-13 L15.999,7.999 L16,8 L16,8 C15.9787734,8.02618776 13.9042323,10.585592 9.77622454,15.6784007 L9.51555027,16 L8,14.6970696 L12.674,8.999 L-1.02282627e-11,9 L-1.02282627e-11,7 L12.675,6.999 L8,1.30293044 L9.51555027,-2.98983061e-13 Z"
					transform="translate(8.000000, 8.000000) scale(-1, -1) rotate(-180.000000) translate(-8.000000, -8.000000) "
				/>
			</g>
		</svg>
	);
};

export const IconCardView = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			{...extraProps}
		>
			<title>Cardview</title>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<g transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) translate(5.500000, 5.000000)">
					<path d="M6,10 L6,14 L-1.02318154e-12,14 L-1.02318154e-12,10 L6,10 Z M13,10 L13,14 L7,14 L7,10 L13,10 Z M6,5 L6,9 L-1.25055521e-12,9 L-1.25055521e-12,5 L6,5 Z M13,5 L13,9 L7,9 L7,5 L13,5 Z M6,0 L6,4 L-7.95807864e-13,4 L-7.95807864e-13,0 L6,0 Z M13,0 L13,4 L7,4 L7,0 L13,0 Z" />
				</g>
			</g>
		</svg>
	);
};

export const IconWarning = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" {...extraProps}>
			<title>Warning</title>
			<g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
				<path d="M23.8042476,21 L12,2.11320377 L0.195752358,21 L23.8042476,21 Z M12,5.885 L20.196,19 L3.803,19 L12,5.885 Z M13.008,15.404 L11.424,15.404 L11.424,17 L13.008,17 L13.008,15.404 Z M12.996,10 L11.424,10 L11.424,11.156 L11.76,14.456 L12.66,14.456 L12.996,11.156 L12.996,10 Z"></path>
			</g>
		</svg>
	);
};

export const IconInfo = ({ width, height, fill, stroke }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		>
			<title>Info</title>
			<circle cx="12" cy="12" r="10"></circle>
			<line x1="12" y1="16" x2="12" y2="12"></line>
			<line x1="12" y1="8" x2="12.01" y2="8"></line>
		</svg>
	);
};

export const IconPlay = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			data-prefix="fas"
			data-icon="play"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 448 512"
			{...extraProps}
		>
			<path
				fill="currentColor"
				d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
			></path>
		</svg>
	);
};

export const IconCopy = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			{...extraProps}
		>
			<rect fill="none" height="24" width="24" x="0" />
			<path d="M11.88,9.14c1.28,0.06,1.61,1.15,1.63,1.66h1.79c-0.08-1.98-1.49-3.19-3.45-3.19C9.64,7.61,8,9,8,12.14 c0,1.94,0.93,4.24,3.84,4.24c2.22,0,3.41-1.65,3.44-2.95h-1.79c-0.03,0.59-0.45,1.38-1.63,1.44C10.55,14.83,10,13.81,10,12.14 C10,9.25,11.28,9.16,11.88,9.14z M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8 s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z" />
		</svg>
	);
};

export const IconCheck = ({
	width,
	height,
	fill,
	stroke,
	...extraProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			{...extraProps}
		>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
		</svg>
	);
};
