import { Cell, Grid } from 'components/Boilerplate/Grid';
import FaqPuff from 'components/FaqPuff';
import React from 'react';
import { FaqFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetFaqModelElement = (
	faqData: FaqFragmentModel,
	options?: Options
) => {
	const content = (
		<Grid key={options?.index} inner={true}>
			{faqData.items.map((item, index) => {
				return (
					<Cell key={index} span={6}>
						<FaqPuff
							key={index}
							question={item.question}
							answerRaw={item.answer}
						></FaqPuff>
					</Cell>
				);
			})}
		</Grid>
	);

	if (options?.insideGrid) {
		return <>{content}</>;
	}

	return (
		<Grid key={options?.index} paddingTop={false} paddingBottom={false}>
			{content}
		</Grid>
	);
};
