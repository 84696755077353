import React, { useContext, useEffect, useState } from 'react';
import { CarouselContainer } from './Carousel.styles';
import Pagination from '../Pagination';
import Gallery from 'components/Gallery';
import { FragmentModelTypes } from 'types/fragments';
import Space from 'components/Boilerplate/Space';
import { breakpointsNumber } from 'theme/media-queries';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';

export interface Props {
	forwardButtonText: string;
	backwardButtonText: string;
	puffItems: FragmentModelTypes[];
	inactivateForwardButton?: boolean;
	inactivateBackwardButton?: boolean;
	headingLevel?: number;
	disableCustomHeadingLogic: boolean;
}

const Carousel = ({
	puffItems,
	headingLevel,
	disableCustomHeadingLogic
}: Props) => {
	const [cssState, setcssState] = useState({
		translate: 0,
		transition: 0.4,
	});

	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.desktop - 1,
	});

	const { translate, transition } = cssState;
	const [currentIndex, setCurrentIndex] = useState<number>(3);
	const [slideWidth, setSlideWidth] = useState<number>(0);
	const [numberOfPuffsToSlide, setNumberOfPuffsToSlide] = useState<number>(3);

	useEffect(() => {
		getSlideWidth(puffItems.length);
	}, [isPhone, isTablet, puffItems.length]);

	const onPreviousSlide = () => {
		setcssState({
			...cssState,
			translate: translate - slideWidth,
		});

		setCurrentIndex((prev) => prev - numberOfPuffsToSlide);
	};

	const onNextSlide = () => {
		setcssState({
			...cssState,
			translate: translate + slideWidth,
		});

		setCurrentIndex((prev) => prev + numberOfPuffsToSlide);
	};

	const getSlideWidth = (listLength: number) => {
		let numberOfColumns = 0;

		if (isTablet) {
			numberOfColumns =
				listLength % 2 === 0
					? Math.floor(listLength / 2)
					: Math.floor(listLength / 2) + 1;
			setNumberOfPuffsToSlide(2);
			setCurrentIndex(2);
		} else {
			numberOfColumns =
				listLength % 3 === 0
					? Math.floor(listLength / 3)
					: Math.floor(listLength / 3) + 1;
		}

		setSlideWidth(100 / numberOfColumns);
	};

	return (
		<CarouselContainer>
			<Gallery
				puffItems={puffItems}
				translate={translate}
				transition={transition}
				isTablet={isTablet}
				headingLevel={headingLevel}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
			{puffItems.length > 3 &&
				<Space top={themeContext.spacing.space3}>
					<Pagination
						onPreviousSlide={onPreviousSlide}
						onNextSlide={onNextSlide}
						inactivateForwardButton={puffItems.length <= currentIndex}
						inactivateBackwardButton={currentIndex === 3 || currentIndex === 2}
					/>
				</Space>}
		</CarouselContainer>
	);
};

export default Carousel;
