/**
 * HighlightBlock
 */
import React from 'react';
import { HighlightBlockContainer } from './HighlightBlock.styles';
import { FragmentListModel } from 'types/fragments';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Options } from 'components/Boilerplate/EpiFragments/EpiFragments';

export interface HighlightBlockProps {
	textAsModel: FragmentListModel;
	options: Options;
	disableCustomHeadingLogic: boolean;
}

const HighlightBlock: React.FC<HighlightBlockProps> = ({
	textAsModel,
	options,
	disableCustomHeadingLogic
}) => {

	return <HighlightBlockContainer>
		<EpiFragments
			insideGrid={options?.insideGrid}
			insideCell={options?.insideCell}
			insideInnerGrid={options?.insideInnerGrid}
			headingLevel={options?.headingLevel}
			fragments={textAsModel.fragments}
			disableCustomHeadingLogic={disableCustomHeadingLogic}
		/>
	</HighlightBlockContainer>


	// if (options?.insideGrid) {
	// 	if (options?.insideCell) {
	// 		return (
	// 			<HighlightBlockContainer>
	// 				<EpiFragments
	// 					insideCell={options?.insideCell}
	// 					insideGrid={options?.insideGrid}
	// 					epi={options?.epi}
	// 					fragments={textAsModel.fragments}
	// 				/>
	// 			</HighlightBlockContainer>
	// 		);
	// 	} else {
	// 		return (
	// 			<Cell span={8}>
	// 				<HighlightBlockContainer>
	// 					<EpiFragments
	// 						insideCell={options?.insideCell}
	// 						insideGrid={options?.insideGrid}
	// 						epi={options?.epi}
	// 						fragments={textAsModel.fragments}
	// 					/>
	// 				</HighlightBlockContainer>
	// 			</Cell>
	// 		);
	// 	}
	// }
	// return (
	// 	<Grid paddingTop={false} paddingBottom={false}>
	// 		<Grid inner={true}>
	// 			<Cell span={8}>
	// 				<HighlightBlockContainer>
	// 					<EpiFragments
	// 						insideCell={true}
	// 						insideGrid={true}
	// 						epi={options?.epi}
	// 						fragments={textAsModel.fragments}
	// 					/>
	// 				</HighlightBlockContainer>
	// 			</Cell>
	// 		</Grid>
	// 	</Grid>
	//);
};

export default HighlightBlock;
