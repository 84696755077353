import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PagesMapper } from 'pages';
import { LoaderStatic } from 'components/Boilerplate/Loader';
import {
	selectModel,
	selectContent,
	fetchPage,
	ListingModelsType,
} from 'store/modules/model';
import { updateRenderingState, selectReact } from 'store/modules/react';
import { RenderingStates } from 'types/epi';
import { selectEpi } from 'store/modules/epi';
import { ScreenReaderText } from 'components/Typography/Typography.styles';
export interface ParamTypes {
	siteRoute: string;
}

/** Loads data and renders the correct page based on the route. */
const RouteHandler: React.FC = () => {
	let { siteRoute } = useParams<ParamTypes>();
	siteRoute = siteRoute || '/';
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<any>(null);
	const { action } = useHistory();
	const dispatch = useDispatch();
	const { error, loading } = useSelector(selectModel);
	const { inEditMode } = useSelector(selectEpi);
	const pageContent = useSelector(selectContent);
	const { renderingState, apiUrl } = useSelector(selectReact);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const Page = PagesMapper(pageContent);

	// Fix siteRoute so we don't request '//'.
	if (
		apiUrl &&
		siteRoute &&
		apiUrl.charAt(apiUrl.length - 1) === '/' &&
		siteRoute.charAt(0) === '/'
	) {
		siteRoute = siteRoute.substr(1);
	}

	// Load the page
	useEffect(() => {
		// First hydrate rendering we wont be doing any request, becase we already have the data from the SSR.
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		dispatch(fetchPage(apiUrl, siteRoute));
		// eslint-disable-next-line
	}, [apiUrl, siteRoute, dispatch]);

	// Set renderingState to clientside after the first (hydration) render.
	useEffect(() => {
		dispatch(updateRenderingState(RenderingStates.ClientSide));
		// eslint-disable-next-line
	}, []);

	// If we have been waiting for the response more than 400ms we display the loader
	useEffect(() => {
		let loaderTimeout: any;

		if (loading) {
			loaderTimeout = setTimeout(() => {
				// Tell sighted users
				setLoaderVisible(true);
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = 'Sidan laddar';
				}
				setTimeout(() => {
					if (ariaLiveRef.current) {
						ariaLiveRef.current.innerHTML = '';
					}
				}, 500);
			}, 400);
		} else {
			setLoaderVisible(false);
			if (action === 'PUSH') {
				if (
					pageContent?.modelType &&
					!ListingModelsType.includes(pageContent.modelType)
				) {
					window.scrollTo(0, 0);
				}
			}
		}

		return () => {
			if (loaderTimeout) {
				clearTimeout(loaderTimeout);
			}
		};
		// eslint-disable-next-line
	}, [loading]);

	// Page is loaded
	useEffect(() => {
		if (pageContent && !inEditMode) {
			if (ariaLiveRef.current) {
				ariaLiveRef.current.innerHTML = 'Sidan har laddats';
			}
			setTimeout(() => {
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = '';
				}
			}, 500);
		}
	}, [pageContent]);

	const epi = useSelector(selectEpi);

	return (
		<>
			{loading && loaderVisible && <LoaderStatic />}

			<main
				role="main"
				id="main"
				ref={pageRef}
				tabIndex={-1}
				style={{ outline: 'none' }}
			>
				{pageContent && <Page {...pageContent} epi={epi} />}
			</main>

			<ScreenReaderText
				aria-live="assertive"
				ref={ariaLiveRef}
			></ScreenReaderText>
		</>
	);
};
export { RouteHandler };
