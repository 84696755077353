/**
 * Radio
 * Inspiration from
 * https://medium.com/better-programming/lets-make-a-react-radio-that-will-pass-a11y-part-2-932ac911a3e2
 * https://medium.com/@elfi_y/react-callback-refs-a-4bd2da317269
 */

import React, {
	useState,
	useEffect,
	KeyboardEvent,
	SyntheticEvent,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { ThemeContext } from 'styled-components';
import { Wrap, Container, RadioOption, Icon } from './Radio.styles';

export type Option = {
	id: string | number;
	value: string;
};

export type RadioProps = {
	id: string;
	label: string;
	options: Option[];
	selectedId?: string | number;
	onSelectedChanged?: (id: string | number | undefined) => void;
};

/** A Radio */
const Radio = ({
	id,
	label,
	options,
	selectedId,
	onSelectedChanged,
}: RadioProps) => {

	const themeContext = useContext(ThemeContext);

	const [focusId, setFocusId] = useState<string | number | undefined>(
		undefined
	);
	const [selectedIdState, setSelectedStateId] = useState(selectedId);
	const [optionsState, setOptionsState] = useState(options);
	const [labelState, setLabelState] = useState(label);

	const [hasFocus, setHasFocus] = useState(false);

	useEffect(() => {
		setLabelState(label);
		setOptionsState(options);
		setSelectedStateId(selectedId);
	}, [options, label, id, selectedId]);

	const handleInitialContainerFocus = () => {
		setHasFocus(true);

		if (!focusId) {
			const focusedOption = optionsState.find(
				(option, index) => option.id === selectedIdState
			);
			if (focusedOption) {
				setFocusId(focusedOption.id);
			} else {
				setFocusId(optionsState[0].id);
			}
		}
	};

	const changeSelected = (id: string | number | undefined) => {
		setSelectedStateId(id);
		if (onSelectedChanged) {
			onSelectedChanged(id);
		}
	};

	const hasDoneInitialDrawRef = useRef(false);
	const lastListSectionRef = useCallback(
		(lastListSectionElement) => {
			if (hasFocus && hasDoneInitialDrawRef.current && lastListSectionElement) {
				lastListSectionElement.focus();
			}
			hasDoneInitialDrawRef.current = true;
		},
		[hasDoneInitialDrawRef, hasFocus]
	);

	const handleOptionClick = (option: Option) => {
		return (event: SyntheticEvent) => {
			setFocusId(option.id);
			changeSelected(option.id);
		};
	};

	const handleOnBlur = (event: any) => {
		setHasFocus(false);
	};

	const handleContainerKeyPress = (event: KeyboardEvent) => {
		// TODO: KeyboardEvent.key, Browser not supported QQ and Baidu
		var nextOption: string | number;

		switch (event.key) {
			case 'ArrowLeft':
			case 'ArrowUp':
				event.preventDefault();
				const previousOptionIndex =
					optionsState.findIndex((option) => option.id === selectedIdState) - 1;
				nextOption =
					previousOptionIndex >= 0
						? optionsState[previousOptionIndex].id
						: optionsState[optionsState.length - 1].id;

				changeSelected(nextOption);
				setFocusId(nextOption);
				break;
			case 'ArrowDown':
			case 'ArrowRight':
				event.preventDefault();
				const currentSelectedOptionIndex = optionsState.findIndex(
					(option) => option.id === selectedIdState
				);
				const nextOptionIndex =
					currentSelectedOptionIndex === -1
						? 1
						: currentSelectedOptionIndex + 1;
				nextOption =
					nextOptionIndex < optionsState.length
						? optionsState[nextOptionIndex].id
						: (nextOption = optionsState[0].id);

				changeSelected(nextOption);
				setFocusId(nextOption);
				break;
			case ' ':
				event.preventDefault();
				const focusedOption = optionsState.find(
					(option, index) => option.id === focusId
				);
				if (focusedOption) {
					changeSelected(focusedOption.id);
				}
				break;
			default:
				break;
		}
	};

	return (
		<Wrap>
			{/* <p id={id}>{labelState}</p> */}
			<Container
				role={optionsState.length > 0 ? 'radiogroup' : undefined}
				aria-label={labelState}
				//aria-labelledby={id}
				// aria-activedescendant={focusElementId}
				onFocus={handleInitialContainerFocus}
				onKeyDown={handleContainerKeyPress}
				onBlur={handleOnBlur}
			>
				{optionsState.map((option, index) => {
					const focusSelect =
						selectedIdState === option.id || (index === 0 && !selectedIdState);
					const selected = selectedIdState === option.id;
					const elementId = `${id}-option-${index}`;
					var opt = (
						<RadioOption
							key={index}
							id={elementId}
							ref={focusSelect ? lastListSectionRef : undefined}
							role="radio"
							tabIndex={focusSelect ? 0 : -1}
							aria-checked={selected}
							onClick={handleOptionClick(option)}
						>
							{selected ? (
								<Icon viewBox="0 0 24 24">
									<g>
										<circle
											cx="12"
											cy="12"
											r="10"
											stroke={themeContext.colors.primary}
											strokeWidth="2"
											fill="transparent"
										/>
										<circle cx="12" cy="12" r="6" fill={themeContext.colors.primary} />
									</g>
								</Icon>
							) : (
								<Icon viewBox="0 0 24 24">
									<circle
										cx="12"
										cy="12"
										r="10"
										stroke={themeContext.colors.primary}
										strokeWidth="2"
										fill="transparent"
									/>
								</Icon>
							)}

							<span>{option.value}</span>
						</RadioOption>
					);

					return opt;
				})}
			</Container>
		</Wrap>
	);
};

export default Radio;
