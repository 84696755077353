/***
 * SearchInputForm
 *
 */

import Space from 'components/Boilerplate/Space';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { translate } from 'utils/helper-utils';
import {
	SearchInputFormContainer,
	SearchInputFormInputContainer,
} from './SearchInputForm.styles';

type SearchInputFormProps = {
	query: string;
	label?: string | null | undefined;
	required?: boolean;
	onSubmit?: (query: string) => void;
	onEsc?: () => void;
	micrositeActive?: boolean;
	isNarrowDisplay?: boolean;
	shorterInput?: boolean;
};

export const SearchInputForm: React.FC<SearchInputFormProps> = ({
	query,
	label,
	required,
	onSubmit,
	onEsc,
	shorterInput = false,
	micrositeActive = false,
	isNarrowDisplay,
	children,
}) => {
	const [userQuery, setUserQuery] = useState<string>(query);

	const localization = useSelector(selectLocalization);

	const searchActionText = translate(
		'/frontend/components/searchBar/actions/search',
		'Search',
		localization
	);

	const searchLabel = label
		? label
		: translate(
				'/frontend/components/searchBar/inputlabel',
				'Search on Naturvårdsverket',
				localization
		  );

	useEffect(() => {
		setUserQuery(query);
	}, [query]);

	const handleSubmit = (event: any) => {
		event.preventDefault();

		// Remove leading and trailing whitespace of userQuery
		const trimmedValue = userQuery.trimStart();

		// If the input value is valid, make the request
		if (onSubmit) {
			onSubmit(trimmedValue);
		}
	};

	const escapeListener = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				e.preventDefault();
				if (userQuery) {
					setUserQuery('');
				} else {
					if (onEsc) {
						onEsc();
					}
				}
			}
		},
		[onEsc, userQuery]
	);

	useEffect(() => {
		document.addEventListener('keyup', escapeListener);
		return () => {
			document.removeEventListener('keyup', escapeListener);
		};
	}, [escapeListener]);

	return (
		<SearchInputFormContainer
			shorterInput={shorterInput}
			role="search"
			onSubmit={handleSubmit}
		>
			<SearchInputFormInputContainer shorterInput={shorterInput}>
				<fieldset aria-label={searchLabel}>
					<label htmlFor="userInputQuery">{searchLabel}</label>
					<input
						required={required}
						autoFocus
						id="userInputQuery"
						aria-label={searchActionText}
						type="text"
						value={userQuery}
						maxLength={200}
						onChange={(evt) => setUserQuery(evt.target.value)}
					></input>
				</fieldset>

				{micrositeActive ? (
					<Button
						aria-label={searchActionText}
						type="submit"
						iconName={'search'}
						iconPosition={1}
						variant={ButtonVariant.Microsite}
					>
						{searchActionText}
					</Button>
				) : (
					<Button aria-label={searchActionText} type="submit">
						{searchActionText}
					</Button>
				)}
			</SearchInputFormInputContainer>
			{children && children}
		</SearchInputFormContainer>
	);
};
