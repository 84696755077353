// Autocomplete.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { fetchSuggestions } from 'api/epiApi';
import { ApiPath } from 'api/epiApi';
import { buildQueryString } from 'utils/helper-utils';
import { queryParameters } from 'components/SearchByListngPage/SearchByListngPage';
import {
	AutocompleteContainer,
	SuggestionsList,
	SuggestionItem,
	RegularText,
	HighlightedText,
} from './Autocomplete.styles';

interface Props {
	query: string;
	currentState: queryParameters;
	autoCompleteApi: string;
	onSubmit: (suggestion: string) => void;
}
const Autocomplete: React.FC<Props> = ({
	query,
	currentState,
	autoCompleteApi,
	onSubmit,
}) => {
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [listIndex, setListIndex] = useState<number>(-1);

	/**checking if the query lenght is >=  and  getting suggestions from backend*/
	useEffect(() => {
		if (query && query.length >= 3) {
			let query = buildQueryParam(currentState);
			const fetchAndSetSuggestions = async () => {
				const suggestions = await fetchSuggestions('/', autoCompleteApi, query);
				setSuggestions(suggestions);
				suggestions && suggestions.length > 0
					? setShowSuggestions(true)
					: setShowSuggestions(false);

				setListIndex(-1);
			};
			fetchAndSetSuggestions();
		} else {
			setShowSuggestions(false);
		}
	}, [query]);

	/**focus helper */
	useEffect(() => {
		if (showSuggestions) {
			if (containerRef.current) {
				containerRef.current.focus();
			}
		}
	}, [showSuggestions]);

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target as Node)
		) {
			setShowSuggestions(false);
		}
	}, []);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setShowSuggestions(false);
			} else if (event.key === 'ArrowDown') {
				setListIndex((prevIndex) =>
					prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
				);
			} else if (event.key === 'ArrowUp') {
				setListIndex((prevIndex) =>
					prevIndex > 0 ? prevIndex - 1 : prevIndex
				);
			} else if (event.key === 'Enter') {
				if (listIndex >= 0 && listIndex < suggestions.length) {
					onSubmit(suggestions[listIndex]);
				}
			}
		},
		[listIndex, suggestions?.length]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleClickOutside, handleKeyDown]);

	/**we are styling the matched and unmatched suggestion text fragment */
	const getStyledSuggestion = (suggestion: string) => {
		const regex = new RegExp(`(${query})`, 'gi'); // regex to match the query case-insensitively
		const parts = suggestion.split(regex); // Split the suggestion by the query
		// Check if there is at least one match
		const hasMatch = parts.some(
			(part) => part.toLowerCase() === query.toLowerCase()
		);

		if (!hasMatch) {
			return <RegularText>{suggestion}</RegularText>;
		}
		return (
			<>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<RegularText key={index}>{part}</RegularText>
					) : (
						<HighlightedText key={index}>{part}</HighlightedText>
					)
				)}
			</>
		);
	};

	/**building final query param for autocomplete api call */
	const buildQueryParam = (params: queryParameters) => {
		const queryParams = {} as queryParameters;
		const searchURL = {} as queryParameters;

		queryParams.p = params.p;
		searchURL.p = params.p && params.p > 1 ? params.p : null;
		queryParams.s = params.s ? params.s : 12;
		queryParams.id = params.id;
		queryParams.lang = params.lang;
		if (params.sortBy !== null) {
			queryParams.sortBy = params.sortBy;
			searchURL.sortBy = params.sortBy;
		}
		if (query) {
			searchURL.q = query;
			queryParams.q = query;
		}

		/* special treatment for facets */
		const facetsArray = Object.entries(params.facets);

		const encodesFilters = facetsArray
			.map(([key, values]) => {
				if (values.length > 0) {
					const formattedValues = values.map((value) =>
						encodeURIComponent(value)
					);
					return `${key}:${formattedValues.join(':')}`;
				}
				return null;
			})
			.filter((filter) => filter !== null) // Remove null values
			.join(';');

		const filtersURL = encodesFilters !== '' ? `facets=${encodesFilters}` : '';

		/* build URL */
		const queryFormated = buildQueryString(searchURL);

		let queryURL = '';
		if (filtersURL.length > 0) {
			queryURL += filtersURL;
		}
		if (queryFormated.length > 0) {
			queryURL += (queryURL.length > 0 ? '&' : '') + queryFormated;
		}

		/* fetch results */
		const queryParamsURL =
			filtersURL.length > 0
				? filtersURL + '&' + buildQueryString(queryParams)
				: buildQueryString(queryParams);

		return queryParamsURL;
	};

	return (
		<>
			{showSuggestions && (
				<AutocompleteContainer ref={containerRef}>
					<SuggestionsList tabIndex={0} aria-label="autocomplete suggestions">
						{suggestions.map((suggestion, index) => (
							<li>
								<SuggestionItem
									key={index}
									tabIndex={0}
									isfocused={index === listIndex}
									onClick={() => onSubmit(suggestion)}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											onSubmit(suggestion);
										}
									}}
								>
									{getStyledSuggestion(suggestion)}
								</SuggestionItem>
							</li>
						))}
					</SuggestionsList>
				</AutocompleteContainer>
			)}
		</>
	);
};

export default Autocomplete;
