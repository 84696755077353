/**
 * StandardPage
 */

import React from 'react';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { FragmentModelTypes } from 'types/fragments';
import { EpiEvent } from 'types/epi';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Typography from 'components/Typography';

type TextFragments = {
	modelType: string;
	fragments: FragmentModelTypes[];
};

interface Props {
	/** Description of this property */
	heading: string;
	preamble: string;
	text: TextFragments;
	epi: EpiEvent;
	disableCustomHeadingLogic: boolean;
}

/** Main description for this component. */
const StandardPage: React.FC<Props> = ({ heading, preamble, text, epi, disableCustomHeadingLogic }) => {
	return (
		<Grid padding={false} inner={false} paddingLeft={false}>
			<Cell>
				<Typography as="h1" prop="heading">
					{heading}
				</Typography>
			</Cell>

			<Cell>
				<Typography as="preamble" prop="preamble">
					{preamble}
				</Typography>

				<EpiFragments
					epi={epi}
					headingLevel={2}
					insideCell={true}
					insideInnerGrid={true}
					insideGrid={true}
					fragments={text?.fragments}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>					
			</Cell>
		</Grid>
	);
};

export default StandardPage;
