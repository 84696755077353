import { Cell, Grid } from 'components/Boilerplate/Grid';
import { PuffTheme } from 'pages/sharedModelTypes';
import React from 'react';
import FooterSection from './FooterSection';
import { FooterLinksContainer } from './LinksFooter.styles';
import { LinkSection } from './SiteFooter';

type LinksFooterProps = {
	sections: LinkSection[];
	itemsAria: string;
};

export const LinksFooter: React.FC<LinksFooterProps> = ({
	sections,
	itemsAria,
}) => {
	const themeName: PuffTheme = PuffTheme.BlueDark;

	return (
		<FooterLinksContainer themeName={themeName}>
			<Grid paddingTop={false} paddingBottom={false}>
				<nav aria-label={itemsAria}>
					<Grid inner={true}>
						{sections.map((section, index) => {
							return (
								<Cell key={index} span={3} tablet={4} phone={4}>
									<FooterSection
										themeName={themeName}
										ariaLabel={section.heading.heading}
										id={`linksfooter-${index}`}
										section={section}
									></FooterSection>
								</Cell>
							);
						})}
					</Grid>
				</nav>
			</Grid>
		</FooterLinksContainer>
	);
};

export default LinksFooter;
