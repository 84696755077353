import React from 'react';
import { LinkType } from 'pages/sharedModelTypes';
import {
	ListHeaderControllsContainerStyle,
	ListHeaderLinkStyle,
	ListHeaderMetadataStyle,
	ListHeaderStyle,
	ListHeaderSwitchesStyle,
	ListHeadingStyle,
} from './ListHeader.styles';
import { ViewType } from 'types/enums';
import Icon from 'components/Boilerplate/Icon';

export interface ListHeaderProps {
	/**
	 * Heading level
	 */
	heading?: string | undefined | null;
	/**
	 * Heading level
	 */
	headingLevel?: number | undefined;
	/**
	 * Heading level
	 */
	sectionStyle?: boolean;
	/**
	 * Link
	 */
	link?: LinkType | null;

	/**
	 * Color
	 */
	color?: string;

	/**
	 * show Card/List options
	 */
	showOptions?: boolean;

	onViewChange?: (value: ViewType) => void;
	defaultValue?: ViewType;
}

/**
 * # ListHeader
 *
 * ListHeader
 */
const ListHeader: React.FC<ListHeaderProps> = ({
	headingLevel,
	sectionStyle,
	heading,
	link,
	color,
	onViewChange,
	defaultValue,
	showOptions,
	children,
}) => {
	const getStyleLevel = (): number => {
		return headingLevel ? headingLevel : 2;
	};
	return (
		<ListHeaderStyle>
			{heading && (
				<ListHeadingStyle
					addRightMargin={link ? true : false}
					color={color}
					zeroBottom={true}
					level={headingLevel}
					styleLevel={getStyleLevel()}
				>
					{heading}
				</ListHeadingStyle>
			)}

			{link && (
				<ListHeaderLinkStyle to={link.url} external={link.isFile}>
					{link.heading}
				</ListHeaderLinkStyle>
			)}
			{children ||
				(showOptions && (
					<ListHeaderControllsContainerStyle>
						{children && (
							<ListHeaderMetadataStyle>{children}</ListHeaderMetadataStyle>
						)}
						{showOptions && onViewChange && (
							<ListHeaderSwitchesStyle>
								<input
									aria-label="List"
									type="radio"
									id="radioList"
									name="radioViewType"
									checked={defaultValue === ViewType.List}
									onChange={(e) => onViewChange(e.target.value as ViewType)}
									value={ViewType.List}
								/>

								<label htmlFor="radioList">
									<Icon size={2} icon="listview"></Icon>
								</label>

								<input
									aria-label="Card"
									type="radio"
									id="radioCard"
									name="radioViewType"
									onChange={(e) => onViewChange(e.target.value as ViewType)}
									checked={defaultValue === ViewType.Card}
									value={ViewType.Card}
								/>

								<label htmlFor="radioCard">
									<Icon size={2} icon="cardview"></Icon>
								</label>
							</ListHeaderSwitchesStyle>
						)}
					</ListHeaderControllsContainerStyle>
				))}
		</ListHeaderStyle>
	);
};

export default ListHeader;
