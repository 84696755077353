import styled from 'styled-components';
import { px2rem } from 'theme/utils';

export const AccordionContainer = styled.div`
	width: 100%;
`;

export const AccordionItem = styled.div`
	.accordion-border {
		display: block;
		margin: 0 ${(props) => props.theme.spacing.space2}rem;
		border-bottom: 1px solid ${(props) => props.theme.colors.grey1};
	}
`;

export const AccordianPills = styled.div`
	margin-bottom: 28px;
	padding: 0 ${(props) => props.theme.spacing.space2}rem;

	span {
		${(props) => props.theme.typography.style13.getStyle()};
		font-size: 14px;
		line-height: ${px2rem(24)};
		color: #6b6b6b;
	}
	span::after {
		content: ',';
		padding: 0 2px;
	}
	span:last-child::after {
		content: '';
	}
`;

export const AccordionHeader = styled.div<{
	isOpen: boolean;
	activePills: boolean;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${(props) => (props.isOpen ? '22px 16px 7px 16px' : '22px 16px')};
	padding-bottom: ${(props) => !props.isOpen && props.activePills && '5px'};
	cursor: pointer;
	h5 {
		font-weight: 700;
		margin-bottom: 0;
		text-transform: none;
	}
`;

export const AccordionContent = styled.div`
	label {
		padding-left: ${(props) => props.theme.spacing.space2}rem;
		padding-right: ${(props) => props.theme.spacing.space2}rem;
	}
	.sort-svg {
		margin-right: 0;
	}
`;

export const AccordionIcon = styled.span<{ isOpen: boolean }>`
	transform: ${(props) => (props.isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
	transition: transform 0.3s ease;
`;
