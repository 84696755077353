export enum ViewType {
	Card = 'Card',
	List = 'List',
	CompactCard = 'CompactCard',
	CompactList = 'CompactList',
}

export enum Rotate {
	Up = 0,
	Right = 90,
	Down = 180,
	Left = 270,
}
