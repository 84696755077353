import { styleForLabel } from 'components/Typography';
import styled from 'styled-components';
import { accessibilityFocus } from 'theme/styles';
import { MultiSelectCheckboxProps } from './MultiSelectCheckbox';
import { Icon } from 'components/Checkbox/Checkbox.styles';
import { mediaQueries } from 'theme';

export const FilterCheckboxGroup = styled.label<MultiSelectCheckboxProps>`
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	display: flex;
	width: 100%;
	${styleForLabel}
	padding: 12px;
	margin: 0;
	max-height: 67px;

	span {
		color: ${({ disabled, theme }) =>
			disabled ? '#BFBFBF' : theme.colors.black};
		text-decoration: ${({ checked }) => checked && 'underline'};
	}

	&:hover {
		${mediaQueries.desktop} {
			transition: background-color 100ms ease;
			background-color: ${(props) =>
				!props.disabled && props.theme.colors.blue2}a0;
			.name {
				text-decoration: underline;
			}
			.checkbox {
				outline: ${({ disabled, checked, theme }) =>
					!disabled && !checked && `1px solid ${theme.colors.primary}`};
			}
		}
	}
	&:focus-within {
		background-color: ${(props) =>
			!props.disabled && props.theme.colors.blue2}a0;
		outline: 1px solid ${(props) => props.theme.colors.primary};
		outline-offset: -1px;
		.name {
			text-decoration: underline;
		}
		.checkbox {
			outline: ${({ disabled, checked, theme }) =>
				!disabled && !checked && `1px solid ${theme.colors.primary}`};
		}
		${mediaQueries.phone} {
			background-color: ${(props) =>
				!props.disabled && props.checked
					? `${props.theme.colors.blue2}a0`
					: 'white'};
			outline: ${(props) =>
				!props.disabled && props.checked
					? `1px solid ${props.theme.colors.primary}`
					: 'unset'};
			.checkbox {
				outline: ${({ disabled, checked, theme }) =>
					!disabled && checked ? `1px solid ${theme.colors.primary}` : 'unset'};
			}
		}
	}
	&.first:focus-within {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&.last:focus-within {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	background-color: ${({ checked, disabled, theme }) =>
		checked && !disabled ? `${theme.colors.blue2}a0` : 'white'};
`;

export const ChildrenWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	.txt_wrapper {
		display: flex;
		flex-direction: column;
	}

	.help {
		font-size: 12px;
		text-decoration: none;
		color: #6b6b6b;
	}

	.hits {
		text-decoration: none;
	}
`;

export const HideCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const StyledCheckbox = styled.div<MultiSelectCheckboxProps>`
	flex: 0 0 auto;
	width: 20px;
	height: 20px;
	border: ${(props) =>
		props.disabled
			? '1px solid #BFBFBF'
			: props.checked
			? `2px solid ${props.theme.colors.primary}`
			: `1px solid ${props.theme.colors.primary}`};

	border-radius: 0px;
	margin-left: 4px;

	background-color: ${(props) => props.theme.colors.white};

	${Icon} {
		line-height: 1rem;
		visibility: ${(props) =>
			props.checked && !props.disabled ? 'visible' : 'hidden'};
	}
`;
export { Icon };
