/**
 * DashboardBlock
 */
import Link from 'components/Boilerplate/Link';
import { Heading } from 'components/Typography/Typography';
import { getThemeTextColor, PuffTheme } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { WidgetNumericBlockModel } from 'types/fragments';
import { getRotationDegrees } from 'utils/helper-utils';
import {
	WidgetNumericBlockHeader,
	WidgetNumericBlockStyle,
	WidgetNumericBlockSubText,
	WidgetNumericBlockText,
	CharRotator,
} from './WidgetNumericBlock.styles';

/** This is the banner component */
const WidgetNumericBlock: React.FC<WidgetNumericBlockModel> = ({
	heading,
	text,
	subtext,
	link,
	theme,
	trend,
}) => {
	const themeContext = useContext(ThemeContext);

	const triangleChar = '\u25B2';
	const puffTheme = theme as PuffTheme;
	return (
		<WidgetNumericBlockStyle>
			<WidgetNumericBlockHeader themeName={puffTheme}>
				{heading !== null && heading !== undefined ? (
					<Heading
						level={2}
						color={getThemeTextColor(themeContext, theme)}
						styleLevel={5}
						zeroBottom={true}
					>
						<span>{heading}</span>
					</Heading>
				) : (
					<span>{'\u00A0'}</span>
				)}
			</WidgetNumericBlockHeader>

			<WidgetNumericBlockText themeName={puffTheme}>
				<span>
					{text ? text : '\u00A0'}

					{text && trend !== null && trend !== undefined && trend !== 0 ? (
						<CharRotator rotate={getRotationDegrees(trend)}>
							{triangleChar}
						</CharRotator>
					) : null}
				</span>
			</WidgetNumericBlockText>
			<WidgetNumericBlockSubText themeName={puffTheme}>
				<span>{subtext ? subtext : '\u00A0'}</span>
			</WidgetNumericBlockSubText>
			{link && (
				<Link to={link.url} puffTheme={puffTheme} external={link.isFile}>
					{link.heading}
				</Link>
			)}
		</WidgetNumericBlockStyle>
	);
};

export default WidgetNumericBlock;
