import styled from 'styled-components';

export const RegulationHistoryTableStyle = styled.table`
	border-collapse: collapse;
	background-color: ${(props) => props.theme.colors.blue4};
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};

	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
		> li:last-child {
			margin-bottom: 0rem;
		}
	}

	li {
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}
	th {
		text-transform: uppercase;
	}

	td,
	th {
		border-right: solid 1px ${(props) => props.theme.colors.black}40;
		border-left: solid 1px ${(props) => props.theme.colors.black}40;
		padding: ${(props) => props.theme.spacing.space2}rem;
	}

	td:nth-child(1n + 2),
	th:nth-child(1n + 2) {
		white-space: nowrap;
	}

	tr {
		border: none;
	}

	td {
		vertical-align: top;
	}

	tbody > tr:nth-child(odd) {
		background-color: ${(props) => props.theme.colors.blue2};
	}
`;
