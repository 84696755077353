/**
 * Image
 */

import React from 'react';
import { Figure, FigCaption } from './Image.styles';
import { LinkType } from 'pages/sharedModelTypes';
import LinkableImage from './LinkableImage';

export interface ImageProps {
	/** The image src */
	src?: string;

	srcSet?: string;

	/** The image alt */
	alt?: string;

	/** Pass optional classnames for the html */
	className?: string;

	/** The image figure caption */
	figCaption?: string;

	title?: string;

	/**
	 * Link object
	 */
	link?: LinkType;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

/** The image component with or without the figure caption. */
const Image: React.FC<ImageProps> = ({
	src,
	srcSet,
	alt,
	className,
	figCaption,
	title,
	link,
	...htmlAttributes
}) => {
	if (!src) {
		return null;
	}

	return (
		<>
			{!figCaption && (
				<LinkableImage
					src={src}
					srcSet={srcSet}
					alt={alt}
					className={className}
					title={title}
					htmlAttributes={{ ...htmlAttributes }}
					link={link}
				/>
			)}
			{figCaption && (
				<Figure>
					<LinkableImage
						src={src}
						srcSet={srcSet}
						alt={alt}
						className={className}
						title={title}
						htmlAttributes={{ ...htmlAttributes }}
						link={link}
					/>
					<FigCaption>{figCaption}</FigCaption>
				</Figure>
			)}
		</>
	);
};

export default Image;
