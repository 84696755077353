/**
 * MicrositeFooter - styles
 */
import Link from 'components/Boilerplate/Link';
import { mediaQueries } from 'theme';
import { P } from 'components/Typography';
import styled from 'styled-components';
import { px2rem } from 'theme/utils';
export const Div = styled.div``;

export const MicrositeFooterLogoLink = styled(Link)`
	flex: 0 0 auto;
	img {
		height: ${(props) => props.height || 'auto'};
	}
`;

export const MicrositeContainer = styled(Div)`
	background-color: ${(p) => p.theme.colors.primary};
	color: ${(p) => p.theme.colors.white};
	padding-bottom: ${px2rem(80)};
	padding-top: ${px2rem(80)};
	${mediaQueries.phone} {
		padding-top: ${px2rem(50)};
		padding-bottom: ${px2rem(50)};
	}
`;

export const MicrositeFooterSectionContainer = styled(Div)`
	padding-top: ${px2rem(40)};
`;

export const MicrositeFooterLinkContainer = styled(Div)`
	padding-top: ${px2rem(8)};
`;

export const MicrositeFooterTextContainer = styled(Div)`
	padding-top: ${px2rem(12)};
`;
