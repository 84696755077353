import SubjectAreaIconPuff from 'components/SubjectAreaIconPuffModel';
import React from 'react';
import { SubjectAreaIconPuffModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetSubjectAreaIconPuffElement = (
	subjectAreaIcondPuffModel: SubjectAreaIconPuffModel,
	options: Options
) => {
	return (
		<SubjectAreaIconPuff
			{...subjectAreaIcondPuffModel}
			headingLevel={options.headingLevel}
		></SubjectAreaIconPuff>
	);
};
