/**
 * Runs through and checks a field's conditions/dependencies
 */
export const checkDependency = (
	name: string,
	dependencies: any[],
	values: any
) => {
	const dependency = dependencies.filter(
		(field) => field.fieldName === name
	)[0];

	const conditions = checkFieldConditions(dependency, values);
	let conditionsFulFilled = null;

	if (dependency.type === 'any') {
		conditionsFulFilled = checkIfAnyConditionIsTrue(conditions);
	} else if (dependency.type === 'all') {
		conditionsFulFilled = checkIfAllConditionsAreTrue(conditions);
	}

	return conditionsFulFilled
		? performAction(dependency.action)
		: performAction(dependency.action === 'show' ? 'hide' : 'show');
};

/**
 * Loops through all conditions and checks if they're fulfilled.
 */
const checkFieldConditions = (dependency: any, values: any) => {
	let conditions: boolean[] = [];

	dependency.conditions.forEach((field: any) => {
		const currentFieldValue = values[field.fieldName];
		const expectedValue = field.fieldValue;

		switch (field.operator) {
			case 'equals':
				conditions.push(currentFieldValue === expectedValue);
				break;
			case 'notEquals':
				conditions.push(currentFieldValue !== expectedValue);
				break;
			case 'contains':
				!currentFieldValue
					? conditions.push(false)
					: conditions.push(currentFieldValue.includes(expectedValue));
				break;
			case 'notContains':
				!currentFieldValue
					? conditions.push(true)
					: conditions.push(!currentFieldValue.includes(expectedValue));
				break;
			case 'notApplicable':
				conditions.push(false);
				break;
			default:
				console.log(`The operator '${field.operator}' is not yet implemented.`);
				break;
		}
	});

	return conditions;
};

const checkIfAnyConditionIsTrue = (conditions: boolean[]) => {
	return conditions.includes(true);
};

const checkIfAllConditionsAreTrue = (conditions: boolean[]) => {
	return conditions.includes(false) ? false : true;
};

/**
 * Performs the given action and returns true/false based on if field should be rendered
 */
const performAction = (action: string) => {
	switch (action) {
		case 'show':
			return true;
		case 'hide':
			return false;
		default:
			console.log(`The action ${action} is not yet implemented.`);
			break;
	}
};
