/**
 * NVseSearchPage - styles
 */

import styled from 'styled-components';

export const Div = styled.div``;

export const SearchInput = styled.div`
	justify-content: space-between;
	display: flex;

	input {
		${(props) => props.theme.typography.style8.getStyle()};
		flex: 1;
		vertical-align: top;
		border-radius: 8px;
		border: 2px solid #afafaf;
		background-color: ${(props) => props.theme.colors.white};
		padding-left: ${(props) => props.theme.spacing.space2}rem;
		padding-right: ${(props) => props.theme.spacing.space2}rem;
		padding-top: 7px;
		padding-bottom: 9px;
		margin-right: ${(props) => props.theme.spacing.space2}rem;
	}
`;

export const SearchRelevanceContainer = styled.div`
	flex-grow: 1;
	justify-content: flex-end;
	display: flex;

	@media (max-width: 768px) {
		justify-content: flex-start;
		margin-top: 1rem;
	}
`;

export const SearchFilterContainer = styled.div`
	flex-grow: 1;
	justify-content: flex-end;
	display: flex;

	@media (max-width: 768px) {
		justify-content: flex-start;
	}
`;
