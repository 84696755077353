/**
 * BannerPuffTextImage
 */
import React from 'react';
import {
	BannerPuffTextImageImage,
	BannerPuffTextImageStyle,
	ColumnContainer,
} from './BannerPuffTextImage.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import BannerPanel from 'components/BannerPanel';
import { ImageFragmentModel } from 'types/fragments';
import { useImageTitle } from 'hooks/useImageTitle';

export interface BannerPuffTextImageProps {
	textToRight?: boolean;
	themeName?: PuffTheme;
	decal?: string;

	/**
	 * Heading text
	 */
	headingLevel?: number;
	heading?: string;
	text?: string | null;
	link?: LinkType | null;
	image?: ImageFragmentModel;
}

// Todo: textToRight (switch column order )

// Todo: Adapt so we also can show Video
// Todo: Remove to BannerPuffTextMedia

const BannerPuffTextImage: React.FC<BannerPuffTextImageProps> = ({
	textToRight = false,
	themeName,
	decal,
	headingLevel,
	heading,
	text,
	link,
	image,
}) => {

	const title = useImageTitle(image?.photographer, image?.agency, image?.illustrator);

	return (
		<BannerPuffTextImageStyle themeName={themeName}>
			<ColumnContainer textToRight={textToRight}>
				<BannerPanel
					headingLevel={headingLevel}
					alignbottom={true}
					themeName={themeName}
					decal={decal}
					heading={heading}
					text={text}
					link={link}
				></BannerPanel>
				{image && (
					<BannerPuffTextImageImage
						src={image.src}
						alt={image.alt}
						srcSet={image.srcSet}
						title={title}
					></BannerPuffTextImageImage>
				)}
			</ColumnContainer>
		</BannerPuffTextImageStyle>
	);
};

export default BannerPuffTextImage;
