/**
 * ContactBlock
 */
import Image from 'components/Boilerplate/Image';
import { useImageTitle } from 'hooks/useImageTitle';
import React from 'react';
import { ImageFragmentModel } from 'types/fragments';
import {
	ContactBlockContainer,
	ContactBlockContentWrapper,
	ContactBlockHeader,
	ContactBlockSubTitle,
} from './ContactBlock.styles';

export interface ContactBlockProps {
	image: ImageFragmentModel;
	fullName: string;
	jobTitle: string | null;
}

const ContactBlock: React.FC<ContactBlockProps> = ({
	image,
	fullName,
	jobTitle,
}) => {

	const title = useImageTitle(image?.photographer, image?.agency, image?.illustrator);

	return (
		<ContactBlockContainer>
			{image && <Image
						alt={image.alt}
						src={image.src}
						srcSet={image.srcSet}
						title={title}>
				</Image>}

			<ContactBlockContentWrapper jobTitle={jobTitle}>
				{fullName && <ContactBlockHeader>{fullName}</ContactBlockHeader>}
				{jobTitle && <ContactBlockSubTitle>{jobTitle}</ContactBlockSubTitle>}
			</ContactBlockContentWrapper>
		</ContactBlockContainer>
	);
};

export default ContactBlock;
