/**
 * Tab - styles
 */

import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from 'theme/styles';
import { ThemeType } from 'theme/theme';
import { SelectedItem } from './Tab';

const TopPanelStyle = () => {
	return css`
		border-bottom: 1px solid #d7d7d7;
		display: flex;
		flex-wrap: wrap;

		> :not(:last-child) {
			margin-right: ${props => props.theme.spacing.space4}rem;
		}
	`;
};

export const TabListStyle = styled.div`
	${TopPanelStyle};
	color: ${props => props.theme.palette.text.primary};			
	margin-bottom: ${props => props.theme.spacing.space5}rem;

	${mediaQueries.phone} {
		margin-bottom: ${props => props.theme.spacing.space2}rem;
	}
`;

export const TabListActionsStyle = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
`;

const TabItemStyle = (themeContext: ThemeType, selected: boolean) => {

	return css`
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		${props => props.theme.typography.style6.getStyle()};		
		text-transform: uppercase;
		letter-spacing: 1.2px;
		border-bottom: 5px solid transparent;
		${selected && `border-bottom: 5px solid ${themeContext.colors.primary}`};

		font-weight: ${selected ? 'bold' : 'normal'};

		outline-color:transparent;
		svg {
			vertical-align: text-bottom;
			margin-right: ${themeContext.spacing.space1 / 2}rem;
		}
		&:focus {
			${accessibilityFocus()}
			outline-color:${themeContext.colors.primary};

		}
	`;
};

export const TabListItemContainerStyle = styled.div<SelectedItem>`
	${(props) => TabItemStyle(props.theme, props.selected)};
`;

export const TabPanelContainerStyle = styled.div``;

export const TabPanelStyle = styled.div`
	width: 100%;
`;
