import { styleForSelect } from 'components/Typography';
import styled from 'styled-components';

export const DropdownMenuContainer = styled.div`
	margin-bottom: ${props => props.theme.spacing.space1}rem;

	> button {
		${styleForSelect};
		padding-top: 7px;
		padding-bottom: 7px;


		svg {
			margin-left: ${props => props.theme.spacing.space1}rem;
			transform: rotate(90deg);
			display: inline-block;
			vertical-align: middle;
		}
	}
`;

export const DropdownMenuStyle = styled.ul`
	position: absolute;
	z-index: 1;
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	border: 1px solid ${props => props.theme.colors.grey4};
	box-shadow: 0px 5px 5px #00000020;

	li {
		border-bottom: 1px solid ${props => props.theme.colors.grey4};
	}

	li > button:hover {
		background-color: ${props => props.theme.colors.grey4};
	}

	li > button {
		border: none;
		width: 100%;
		background-color: ${props => props.theme.colors.white};
		color: ${props => props.theme.palette.text.primary};			
		padding: 12px 12px;
	}

	li > button:focus {
		background-color: ${props => props.theme.colors.primary};
		color: ${props => props.theme.palette.text.secondary};
	}

	> :last-child {
		border: none;
	}
`;
