import EpiFragments, {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Button from 'components/Button';
import { Heading } from 'components/Typography/Typography';
import {
	getThemeTextColor,
	LinkType,
	PuffTheme,
} from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';
import { breakpointsNumber } from 'theme/media-queries';
import { FragmentListModel } from 'types/fragments';
import { FooterContactContainer } from './ContactFooter.styles';
import FooterSection from './FooterSection';
import SimpleExpander from './SimpleExpander';
import { LinkSection } from './SiteFooter';

export type CustomerServiceFooter = {
	heading: string | null;
	text: string | null;
	textAsModel: FragmentListModel;
	link: LinkType | null;
};
type ContactFooterProps = {
	contact: CustomerServiceFooter | undefined;
	linkSections: LinkSection[];
	itemsAria: string;
	disableCustomHeadingLogic: boolean;
};

/** Main description for this component. */
const ContactFooter: React.FC<ContactFooterProps> = ({
	contact,
	linkSections,
	itemsAria,
	disableCustomHeadingLogic
}) => {
	const themeName: PuffTheme = PuffTheme.Black;
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	if (!contact) {
		return null;
	}

	let contactHeading = (
		<Heading
			zeroBottom={true}
			level={2}
			styleLevel={5}
			color={getThemeTextColor(themeContext, themeName)}
		>
			{contact.heading}
		</Heading>
	);

	let lastBlockType = BlockType.Unknown;

	if (contact.textAsModel && contact.textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(contact.textAsModel.fragments, themeContext, disableCustomHeadingLogic);
	}

	const spaceBefore_button = getSpaceToAddBefore(
		lastBlockType,
		BlockType.Element,
		themeContext
	);
	const spaceAfterLast = getSpaceToAddBefore(lastBlockType, BlockType.Element, themeContext);

	return (
		<FooterContactContainer themeName={themeName}>
			<Grid>
				<nav aria-label={itemsAria}>
					<Grid inner={true}>
						<Cell span={6} tablet={8} phone={4}>
							<SimpleExpander
								themeName={themeName}
								ariaLabel="Kontakt vägar"
								id={'contactFooter'}
								isEnabled={isPhone}
								isOpen={!isPhone}
								heading={contactHeading}
							>
								<Space top={themeContext.spacing.space1} bottom={spaceAfterLast}>
									{contact.textAsModel &&
										contact.textAsModel.fragments.length > 0 && (
											<EpiFragments
												previousBlockType={BlockType.Unknown}
												insideGrid={true}
												insideInnerGrid={true}
												insideCell={true}
												headingLevel={2}
												fragments={contact.textAsModel.fragments}
												disableCustomHeadingLogic={disableCustomHeadingLogic}
											/>
										)}

									{contact.link !== null && (
										<Space top={spaceBefore_button}>
											<Button to={contact.link.url}>
												{contact.link.heading}
											</Button>
										</Space>
									)}
								</Space>
							</SimpleExpander>
						</Cell>

						{linkSections.map((item, index) => {
							return (
								<Cell key={index} span={3} tablet={4} phone={4}>
									<FooterSection
										themeName={themeName}
										ariaLabel={item.heading.heading}
										id={`contactfooter-${index}`}
										section={item}
									></FooterSection>
								</Cell>
							);
						})}
					</Grid>
				</nav>
			</Grid>
		</FooterContactContainer>
	);
};

export default ContactFooter;
