import EpiFragments, {
	BlockType,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import React from 'react';
import { FragmentListModel } from 'types/fragments';
import { GrantsHeader } from './GrantsHeader';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';
import { Category } from 'pages/microsite/MicrositeArticlePage/MicrositeArticlePage.model';

export type PageSectionContentProps = {
	heading?: string | null;
	headingLevel?: number;
	heading_htmlAttributes?: any;
	lastReviewed?: string | null;
	showReviewDate?: boolean | null;
	preamble?: string | null;
	preamble_htmlAttributes?: any;
	textAsModel?: FragmentListModel | null;
	textAsModel_htmlAttributes?: any;
	previousBlockType?: BlockType;
	insideCell?: boolean;
	insideInnerGrid?: boolean;
	insideGrid?: boolean;
	disableCustomHeadingLogic: boolean;
	publicationDate?: string;
	categories?: Category[];
};
/**
 * PageSectionContent (Helper)
 * @param param0
 * @returns
 */
export const PageSectionContent: React.FC<PageSectionContentProps> = ({
	previousBlockType,
	headingLevel,
	heading,
	heading_htmlAttributes = {},
	lastReviewed,
	showReviewDate,
	preamble,
	preamble_htmlAttributes = {},
	textAsModel,
	textAsModel_htmlAttributes = {},
	insideCell,
	insideInnerGrid,
	insideGrid,
	disableCustomHeadingLogic,
	publicationDate,
	categories,
}) => {
	const { isEditable } = useSelector(selectEpi);

	const renderHeader =
		heading || preamble || lastReviewed !== null ? true : false;
	const isValidHeadear = heading || preamble ? true : false;

	const blockTypeBefore_textAsModel = isValidHeadear
		? BlockType.Element
		: previousBlockType;

	return (
		<>
			{(renderHeader || isEditable) && (
				<GrantsHeader
					columns={insideCell || insideInnerGrid ? 0 : 8}
					headingLevel={headingLevel}
					heading={heading}
					lastReviewed={lastReviewed}
					showReviewDate={showReviewDate}
					cameFromSectionContent
					preamble={preamble}
					heading_htmlAttributes={heading_htmlAttributes}
					preamble_htmlAttributes={preamble_htmlAttributes}
					publicationDate={publicationDate}
					categories={categories}
				></GrantsHeader>
			)}

			{textAsModel && (
				<EpiFragments
					previousBlockType={blockTypeBefore_textAsModel}
					insideGrid={insideGrid}
					insideCell={insideCell}
					insideInnerGrid={insideInnerGrid}
					headingLevel={headingLevel}
					fragments={textAsModel.fragments}
					htmlAttributes={textAsModel_htmlAttributes}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}
		</>
	);
};
