/**
 * MicrositeArticlePage
 */

import { MicrositeArticlePageModel } from './MicrositeArticlePage.model';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import MegaMenu from 'components/MegaMenu';
import Space from 'components/Boilerplate/Space';
import PageTopBanner from 'components/PageTopBanner';
import React, { useContext, useEffect, useState } from 'react';
import { NavigationItem } from 'types/epi';
import { FragmentModelTypes } from 'types/fragments';
import {
	BlockType,
	getLastItemBlockType,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import {
	getBlockBaseMenuItems,
	getBlockBaseSections,
	OnPageNavigationContent,
} from 'components/Panels/OnPageNavigationContent';
import { ThemeContext } from 'styled-components';

/** Main description for this component. */
const MicrositeArticlePage: React.FC<MicrositeArticlePageModel> = ({
	mainImage,
	themeTop,
	decal,
	heading,
	lastReviewed,
	preamble,
	textAsModel,
	metaData,
	subHeading,
	bottomItems,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
	categories,
	publicationDate,
}) => {
	const themeContext = useContext(ThemeContext);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [sections, setSections] = useState<string[]>([]);
	const [menuIndex, setMenuIndex] = useState<NavigationItem[]>([]);

	const ref = React.createRef<any>();

	useEffect(() => {
		const expanderModels = getBlockBaseSections(bottomItems);
		const sectionStrings = expanderModels.map((item) => item.onPageMenuHeading);

		if (sectionStrings.length > 0) {
			const menuIndexRoot = getBlockBaseMenuItems(sectionStrings);
			setMenuIndex(menuIndexRoot);
			setSections(sectionStrings);
		} else {
			setMenuIndex([]);
			setSections([]);
		}
	}, [bottomItems]);

	const onCloseMenuEvent = (userClosed: boolean) => {
		setIsMenuOpen(false);
		if (userClosed && ref.current) {
			ref.current.focus();
		}
	};

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = BlockType.Element;

	if (textAsModel && textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	lastBlockType = BlockType.SectionBanner;

	var DisplayNavigationHeader = false;

	if (
		(bottomItems && bottomItems.length > 0) ||
		(textAsModel && textAsModel?.fragments.length > 0)
	) {
		//we should display the navigationHeader if either TextAsModel or bottomItems has a value
		DisplayNavigationHeader = true;
	}

	return (
		<>
			<PageTopBanner
				mainImage={mainImage}
				themeTop={themeTop}
				decal={decal}
				headingLevel={1}
				heading={heading}
				subheading={subHeading}
				heading_htmlAttributes={_properties?.heading}
				mainImage_htmlAttributes={_properties?.mainImage}
			></PageTopBanner>

			{DisplayNavigationHeader && (
				<Space
					top={themeContext.spacing.getElement()}
					bottom={themeContext.spacing.getElement()}
				>
					<OnPageNavigationContent
						headingLevel={2}
						heading={null}
						heading_htmlAttributes={_properties?.heading}
						lastReviewed={lastReviewed}
						preamble={preamble}
						preamble_htmlAttributes={_properties?.preamble}
						textAsModel={textAsModel}
						textAsModel_htmlAttributes={_properties?.mainImage}
						sections={sections}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
						publicationDate={publicationDate}
						categories={categories}
					></OnPageNavigationContent>

					<EpiFragments
						previousBlockType={blockTypeBefore_bottomItems}
						epi={epi}
						headingLevel={2}
						fragments={bottomItems as FragmentModelTypes[]}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				</Space>
			)}

			{isMenuOpen && (
				<MegaMenu
					items={menuIndex}
					heading={heading}
					onClose={onCloseMenuEvent}
				/>
			)}
		</>
	);
};

export default MicrositeArticlePage;
