/**
 * FormStep
 */

import React from 'react';
import { NextButton, PreviousButton } from './FormButtons';

type Step = {
	title: string;
	description?: string;
	index: number;
};

interface FormStepProps {
	/** Current step data */
	step: Step;

	/** Current step number  */
	currentStep: number;

	/** Number of steps in Form */
	nrOfSteps: number;

	/** Form id */
	formId: string;

	/** Callback function when PreviousButton is clicked */
	onPrevious: any;
}

const FormStep: React.FC<FormStepProps> = ({
	step,
	formId,
	nrOfSteps,
	currentStep,
	children,
	onPrevious,
}) => {
	const { title, description, index } = step;

	const stepId = `form-${formId}-step-${index}`;
	const isFirstStep = index === 0;
	const isLastStep = index + 2 === nrOfSteps;

	const previousIndex = isFirstStep ? undefined : index - 1;
	const previousId = isFirstStep
		? undefined
		: `form-${formId}-step-${previousIndex}`;

	const nextIndex = isLastStep ? undefined : index + 1;
	const nextId = isLastStep ? undefined : `form-${formId}-step-${nextIndex}`;

	return (
		<section
			id={stepId}
			key={stepId}
			aria-hidden={index !== currentStep}
			hidden={index !== currentStep}
		>
			{title && (
				<header>
					<h1 tabIndex={-1}>{title}</h1>
					{description && <p>{description}</p>}
				</header>
			)}
			{children}

			<div>
				{!isFirstStep && (
					<PreviousButton
						isExpanded={currentStep === previousIndex}
						previousIndex={previousIndex}
						previousId={previousId}
						id={`${formId}-step-${index}-prev-button`}
						onPrevious={onPrevious}
					/>
				)}
				{!isLastStep && (
					<NextButton
						isExpanded={currentStep === nextIndex}
						nextId={nextId}
						id={`${formId}-step-${index}-next-button`}
					/>
				)}
			</div>
		</section>
	);
};

export default FormStep;
