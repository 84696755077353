import { FragmentListModel } from 'types/fragments';
import { useEffect, useState } from 'react';

interface QuizFormState {
	selectedQuiz: number;
	answers: AnswerModel[];
}

export interface AnswerModel {
	questionIndex: number;
	answer: string;
	question: string;
	questionDescription: FragmentListModel | null;
	correctAnswer: string;
	answerOptions: string[];
	correctAnswerExplination: FragmentListModel | null;
}

const initialState: QuizFormState = {
	selectedQuiz: 0,
	answers: new Array<AnswerModel>(),
};

export const useQuizForm = () => {
	const [quizFormState, setQuizFormState] = useState<QuizFormState>(
		initialState
	);

	useEffect(() => {
		const quizFormData = JSON.parse(
			sessionStorage.getItem('quizForm') || JSON.stringify(quizFormState)
		) as QuizFormState;
		setQuizFormState(quizFormData);
	}, []);

	const setAnswer = (newAnswer: AnswerModel) => {
		setQuizFormState((prevState) => {
			const existingAnswerIndex = prevState.answers.findIndex(
				(answer) => answer.questionIndex === newAnswer.questionIndex
			);

			if (existingAnswerIndex !== -1) {
				const updatedAnswers = [...prevState.answers];
				updatedAnswers[existingAnswerIndex] = newAnswer;
				const updatedState = { ...prevState, answers: updatedAnswers };
				updateSessionStorage(updatedState);
				return updatedState;
			} else {
				const updatedState = {
					...prevState,
					answers: [...prevState.answers, newAnswer],
				};
				updateSessionStorage(updatedState);
				return updatedState;
			}
		});
	};

	const setSelectedQuiz = (selectedQuiz: number) => {
		setQuizFormState((prevState) => {
			const updatedState = { ...prevState, selectedQuiz };
			updateSessionStorage(updatedState);
			return updatedState;
		});
	};

	const updateSessionStorage = (quizState: QuizFormState) => {
		sessionStorage.setItem('quizForm', JSON.stringify(quizState));
	};

	const selectedAnswer = quizFormState.answers.filter(
		(answer) => answer.questionIndex === quizFormState.selectedQuiz
	)[0];

	return {
		setAnswer,
		setSelectedQuiz,
		quizFormState,
		selectedAnswer,
	};
};
