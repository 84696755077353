/**
 * Link
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { styleForLink, styleForLinkDark } from 'theme/styles';
import { selectEpi } from 'store/modules/epi';
import { getIsDarkTheme } from 'pages/sharedModelTypes';
import { useSelector } from 'react-redux';

export interface Props {
	/** The url the link should navigate to */
	to?: string;

	/** The url the link should navigate to */
	href?: string;

	/** Pass optional classnames for the html */
	className?: string;

	external?: boolean;

	target?: string;

	/** pass html attr for exmaple aria label */
	[htmlAttributes: string]: any;
}

export const hideIcon = () => {
	return '&::after {content: none;}';
};

const LinkBasicStyle = styled.a<linkProps>`
	${({ $isDarkTheme }) => ($isDarkTheme ? styleForLinkDark : styleForLink)};
	${({ $showLinkIcon }) => !$showLinkIcon && hideIcon};
`;

const RouterLinkBasicStyle = styled(RouterLink)<linkProps>`
	${({ $isDarkTheme }) => ($isDarkTheme ? styleForLinkDark : styleForLink)};
	${({ $showLinkIcon }) => !$showLinkIcon && hideIcon};
`;

type linkProps = {
	$showLinkIcon: boolean;
	className?: string;
	$isDarkTheme?: boolean;
};

const Link = React.forwardRef<any, Props>(
	(
		{
			to,
			href,
			children,
			showLinkIcon = true,
			className,
			external,
			target = '_self',
			tabIndex,
			puffTheme,
			...htmlAttributes
		},
		ref
	) => {
		const url = to || href || '#';

		const { inEditMode } = useSelector(selectEpi);

		const isDarkTheme = getIsDarkTheme(puffTheme);

		if (
			(url && url.charAt(0) !== '/') ||
			url.includes('#') ||
			inEditMode ||
			external
		) {
			return (
				<LinkBasicStyle
					tabIndex={tabIndex}
					ref={ref}
					$showLinkIcon={showLinkIcon}
					className={className}
					target={target}
					href={url}
					$isDarkTheme={isDarkTheme}
					{...htmlAttributes}
				>
					{children}
				</LinkBasicStyle>
			);
		} else {
			return (
				<RouterLinkBasicStyle
					tabIndex={tabIndex}
					ref={ref}
					$showLinkIcon={showLinkIcon}
					className={className}
					target={target}
					to={url}
					$isDarkTheme={isDarkTheme}
					{...htmlAttributes}
				>
					{children}
				</RouterLinkBasicStyle>
			);
		}
	}
);

export default Link;
