/**
 * BannerTextLinks
 */
import React from 'react';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import BannerPanel from 'components/BannerPanel';
import Link from 'components/Boilerplate/Link';
import { Grid } from 'components/Boilerplate/Grid';
import {
	BannerTextLinksListItemStyle,
	BannerTextLinksListStyle,
	BannerTextLinksStyle,
} from './BannerTextLinks.styles';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	ColumnContainerDevider,
	ColumnContainerWithDevider,
} from 'components/Panels/Panels.styles';

export interface BannerTextLinksProps {
	themeName?: PuffTheme;
	/**
	 * Heading level
	 */
	headingLevel?: number;
	/**
	 * Heading text
	 */
	heading?: string;

	preamble?: string;
	link?: LinkType;
	textToRight?: boolean;

	/**
	 * Links
	 */
	links?: LinkType[];
}

/** This is the banner component */
const BannerTextLinks: React.FC<BannerTextLinksProps> = ({
	themeName,
	headingLevel,
	heading,
	preamble,
	link,
	links = [],
	textToRight = false,
}) => {
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.desktop - 1,
	});
	return (
		<BannerTextLinksStyle themeName={themeName}>
			<Grid paddingTop={false} paddingBottom={false}>
				<ColumnContainerWithDevider textToRight={textToRight}>
					<BannerPanel
						headingLevel={headingLevel}
						center={isPhone || isTablet ? true : false}
						themeName={themeName}
						heading={heading}
						text={preamble}
						link={link}
					></BannerPanel>

					<ColumnContainerDevider
						themeName={themeName}
					></ColumnContainerDevider>

					{links.length > 0 && (
						<BannerTextLinksListStyle>
							{links?.map((item, index) => {
								return (
									<BannerTextLinksListItemStyle
										key={index}
										themeName={themeName}
									>
										<Link
											to={item.url}
											target={item.target}
											external={item.isFile}
										>
											{item.heading}
										</Link>
									</BannerTextLinksListItemStyle>
								);
							})}
						</BannerTextLinksListStyle>
					)}
				</ColumnContainerWithDevider>
			</Grid>
		</BannerTextLinksStyle>
	);
};

export default BannerTextLinks;
