import PuffCard from 'components/PuffCard';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { EventPuffItemModel } from 'types/fragments';
import {
	convertDateWithMonthWrittenOut,
	getTimeFromDate,
} from 'utils/helper-utils';
import { Options } from '../EpiFragments';

export const GetEventPuffItemModelElement = (
	eventPuffItemModel: EventPuffItemModel,
	options: Options
) => {
	switch (options?.view) {
		default:
		case ViewType.Card:
			const formattedSubtitle = `${
				eventPuffItemModel.eventStartDate !== null
					? convertDateWithMonthWrittenOut(eventPuffItemModel.eventStartDate)
					: ''
			} ${
				eventPuffItemModel.eventStartDate !== null
					? 'kl. ' + getTimeFromDate(eventPuffItemModel.eventStartDate)
					: ''
			}`;

			return (
				<PuffCard
					image={eventPuffItemModel.image}
					decal={eventPuffItemModel.eventType}
					heading={eventPuffItemModel.heading}
					headingLevel={options.headingLevel}
					preamble={formattedSubtitle}
					link={eventPuffItemModel.link}
				></PuffCard>
			);
		case ViewType.List:
			const newLink: LinkType = {
				url: eventPuffItemModel.link.url,
				heading: eventPuffItemModel.heading,
				isFile: eventPuffItemModel.link.isFile,
			};

			return (
				<PuffListItem
					decal={eventPuffItemModel.eventType}
					headingLevel={options.headingLevel}
					link={newLink}
					image={eventPuffItemModel.image}
					compact={false}
				></PuffListItem>
			);
		case ViewType.CompactList:
			return (
				<PuffListItem
					decal={eventPuffItemModel.eventType}
					headingLevel={options.headingLevel}
					link={eventPuffItemModel.link}
					image={eventPuffItemModel.image}
					compact={true}
				></PuffListItem>
			);
	}
};
