import { Cell, Grid } from 'components/Boilerplate/Grid';
import SimplePuff from 'components/SimplePuff';
import React from 'react';
import {} from './TargetGroups.styles';

type TargetGroupsProps = {
	targetGroupsHeading: string;
	targetGroupsText: string;

	applyScopeHeading: string;
	applyScopeText: string;

	applyPeriodHeading: string;
	applyPeriodText: string;

	headingLevel: number;
};

export const TargetGroups: React.FC<TargetGroupsProps> = ({
	targetGroupsHeading,
	targetGroupsText,
	applyScopeHeading,
	applyScopeText,
	applyPeriodHeading,
	applyPeriodText,
	headingLevel,
}) => {
	return (
		<Grid inner={true}>
			{targetGroupsText && (
				<Cell span={4}>
					<SimplePuff
						title={targetGroupsHeading}
						content={targetGroupsText}
						headingLevel={headingLevel}
					></SimplePuff>
				</Cell>
			)}

			{applyScopeText && (
				<Cell span={4}>
					<SimplePuff
						title={applyScopeHeading}
						content={applyScopeText}
						headingLevel={headingLevel}
					></SimplePuff>
				</Cell>
			)}

			{applyPeriodText && (
				<Cell span={4}>
					<SimplePuff
						title={applyPeriodHeading}
						content={applyPeriodText}
						headingLevel={headingLevel}
					></SimplePuff>
				</Cell>
			)}
		</Grid>
	);
};

export default TargetGroups;
