/**
 * SidebarRelatedPanel
 */

import React from 'react';
import { SidebarRelatedPanelStyle } from './SidebarRelatedPanel.styles';

export type SidebarRelatedPanelProps = {
	label: string;
	items: string[];
};

export const SidebarRelatedPanel: React.FC<SidebarRelatedPanelProps> = ({
	label,
	items,
}) => {
	return (
		<>
			{items.length > 0 && (
				<SidebarRelatedPanelStyle>
					<section>
						{label && <p>{label}</p>}
						<ul>
							{items.map((item, index) => {
								return <li key={index}>{item}</li>;
							})}
						</ul>
					</section>
				</SidebarRelatedPanelStyle>
			)}
		</>
	);
};
