import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React from 'react';
import {
	convertDateWithMonthWrittenOut,
	getTimeFromDate,
} from 'utils/helper-utils';
import {
	EventInfoBoxContainer,
	LabelStyle,
	ButtonContainer,
	ButtonWrapper,
	SpanStyle,
	SectionStyle,
} from './EventInfoBox.styles';

export interface Props {
	eventStartDate: string;
	eventType: string;
	eventPlace: string;
	otherInfo: string;
	infoBoxTitle: string;
	infoBoxDateHeading: string;
	infoBoxLocationHeading: string;
	otherInfoHeading: string;
	registrationLink: LinkType;
	iCalLink: LinkType;
	duration: string;
	durationLabel: string;
	eventTimeLabel: string;
}

const EventInfoBox = ({
	eventStartDate,
	duration,
	durationLabel,
	eventTimeLabel,
	eventType,
	eventPlace,
	otherInfo,
	infoBoxTitle,
	infoBoxDateHeading,
	infoBoxLocationHeading,
	otherInfoHeading,
	registrationLink,
	iCalLink,
}: Props) => {
	return (
		<EventInfoBoxContainer>
			{infoBoxTitle && (
				<Heading level={2} styleLevel={4} zeroBottom={false}>
					{infoBoxTitle}
				</Heading>
			)}

			{eventStartDate && (
				<SectionStyle>
					<LabelStyle>{infoBoxDateHeading}</LabelStyle>
					<SpanStyle>
						{convertDateWithMonthWrittenOut(eventStartDate)}
					</SpanStyle>
					<SpanStyle>
						{eventTimeLabel} {getTimeFromDate(eventStartDate)}
					</SpanStyle>
				</SectionStyle>
			)}

			{duration && (
				<SectionStyle>
					<LabelStyle>{durationLabel}</LabelStyle>
					<SpanStyle>{duration}</SpanStyle>
				</SectionStyle>
			)}

			{eventPlace && (
				<SectionStyle>
					<LabelStyle>{infoBoxLocationHeading}</LabelStyle>
					<SpanStyle>{eventPlace}</SpanStyle>
				</SectionStyle>
			)}

			{otherInfo && (
				<SectionStyle>
					<LabelStyle>{otherInfoHeading}</LabelStyle>
					<SpanStyle>{otherInfo}</SpanStyle>
				</SectionStyle>
			)}

			<ButtonContainer>
				{registrationLink && (
					<ButtonWrapper>
						<Button to={registrationLink.url} showLinkIcon={false}>
							{registrationLink.heading}
						</Button>
					</ButtonWrapper>
				)}

				{iCalLink && (
					<ButtonWrapper>
						<Button
							to={iCalLink.url}
							variant={ButtonVariant.Tertiary}
							showLinkIcon={false}
							external={iCalLink.isFile}
						>
							{iCalLink.heading}
						</Button>
					</ButtonWrapper>
				)}
			</ButtonContainer>
		</EventInfoBoxContainer>
	);
};

export default EventInfoBox;
