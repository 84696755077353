/**
 * MicrositeSearchPage
 */

import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MicrositeSearchPageModel } from './MicrositeSearchPage.model';
import {
	convertDateWithMonthWrittenOut,
	formatText,
	translate,
} from '../../../utils/helper-utils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectLocalization } from 'store/modules/model';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import ListHeader from 'components/ListHeader';
import { ViewType } from 'types/enums';

import { ThemeContext } from 'styled-components';
import Pagination from 'components/Pagination';

enum SearchRelevansTypes {
	rel = 'rel',
	date = 'date',
}

enum SearchFilterTypes {
	All = 'All',
	NVsePage = 'NVsePage',
	NVseDocument = 'NVseDocument',
}

type searchParams = {
	q: string;
	p: number;
	s: SearchRelevansTypes;
	f: SearchFilterTypes;
};

/**
 * # Söksida
 * Modeltype:<code>NVseSearchPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2012446725/MicrositeSearchPage)
 *
 * Webbplatsens söksida
 */
const MicrositeSearchPage: React.FC<MicrositeSearchPageModel> = ({
	preamble,
	heading,
	searchModel,
	displayedSearchHitsText,
	displayedSearchNoHitsText,
	url,
	_properties = {},
}) => {
	const history = useHistory();
	const location = useLocation();
	const localization = useSelector(selectLocalization);
	const themeContext = useContext(ThemeContext);

	const getInitialParameters = (): searchParams => {
		return {
			q: searchModel ? searchModel.query : '',
			p: 1,
			s: SearchRelevansTypes.rel,
			f: SearchFilterTypes.All,
		};
	};

	const [selectedViewType, setSelectedViewType] = useState<ViewType>(
		ViewType.List
	);

	const [searchState, setSearchState] = useState<searchParams>(
		getInitialParameters()
	);

	const lastUpdatedText = translate(
		'/frontend/puff/updated',
		'Updated',
		localization
	);

	useEffect(() => {
		let initialParameters = getSearchStateFromParameters(
			location.search.substring(1)
		);
		if (initialParameters) {
			setSearchState(initialParameters);
			return;
		}
		setSearchState(getInitialParameters());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	function getSearchStateFromParameters(url: string): null {
		// Convert query parameters to object
		try {
			return JSON.parse(
				'{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
				function (key, value) {
					return key === '' ? value : decodeURIComponent(value);
				}
			);
		} catch (e) {
			return null;
		}
	}

	const executeSearch = (params: searchParams) => {
		//if (!query) return;

		// var params = {
		// 	q: query,
		// 	p: 1,
		// 	s: 'rel',
		// } as searchParams;

		var queryString = Object.keys(params)
			.map((key: string) => {
				return (
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent((params as any)[key])
				);
			})
			.join('&');

		history.push({ pathname: url, search: '?' + queryString });
		history.go(0);
	};

	const handleSubmit = (query: string) => {
		const newSearchState: searchParams = {
			...searchState,
			p: 1,
			q: query,
		} as searchParams;
		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const changePage = (page: number) => {
		const newSearchState = { ...searchState, p: page } as searchParams;
		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const onPaginationPreviousClick = () => {
		if (!searchModel) return;

		let newPageNr = searchModel.page ? searchModel.page : 1;
		if (newPageNr > 0) {
			changePage(newPageNr - 1);
		}
	};

	const onPaginationNextClick = () => {
		if (!searchModel) return;

		let newPageNr = searchModel.page ? searchModel.page : 1;
		if (newPageNr < searchModel.totalPages) {
			changePage(newPageNr + 1);
		}
	};
	//#endregion

	return (
		<>
			<Space
				top={themeContext.spacing.getPageTopPadding()}
				bottom={themeContext.spacing.getPageTopPadding()}
			>
				<Grid paddingTop={false} paddingBottom={false}>
					<Space bottom={themeContext.spacing.getSection()}>
						<GrantsHeader
							columns={8}
							headingLevel={1}
							heading={heading}
							heading_htmlAttributes={_properties?.heading}
						/>
					</Space>
				</Grid>

				<Grid paddingTop={false} paddingBottom={false}>
					<Space top={themeContext.spacing.space4}>
						<SearchInputForm
							label={preamble}
							shorterInput
							micrositeActive
							required
							query={searchState.q}
							onSubmit={handleSubmit}
						></SearchInputForm>
					</Space>
					{searchModel && (
						<>
							<Space top={themeContext.spacing.getElement()}>
								<ListHeader
									headingLevel={2}
									heading={
										searchModel.results.length > 0
											? formatText(
													displayedSearchHitsText,
													searchModel.size,
													searchModel.numberOfHits
											  )
											: displayedSearchNoHitsText
									}
									defaultValue={selectedViewType}
								></ListHeader>
							</Space>

							{searchModel.results.length > 0 && (
								<Space
									top={themeContext.spacing.space3}
									stack={themeContext.spacing.space2}
								>
									{searchModel.results.map((item, index) => {
										const linkItem: LinkType = {
											url: item.url,
											heading: item.heading,
										};
										return (
											<PuffListItem
												key={index}
												link={linkItem}
												headingLevel={2}
												preamble={item.excerpt}
												image={item.image}
											></PuffListItem>
										);
									})}
								</Space>
							)}
							{searchModel.totalPages > 1 && (
								<Space top={themeContext.spacing.getSection()}>
									<Pagination
										onPreviousSlide={onPaginationPreviousClick}
										inactivateBackwardButton={searchModel.page <= 1}
										onNextSlide={onPaginationNextClick}
										inactivateForwardButton={
											searchModel.page >= searchModel.totalPages
										}
									/>
								</Space>
							)}
						</>
					)}
				</Grid>
			</Space>
		</>
	);
};

export default MicrositeSearchPage;
