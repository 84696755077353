import { css } from 'styled-components';
import { px, px2rem } from './utils';

export type Palette = {
	text: TextPalette;
};

export type TextPalette = {
	primary: string;
	secondary: string;
	disabled: string;
	error: string;
	hint: string;
};

export type Spacing = {
	getSection: Function;
	getElement: Function;
	getTextAndElement: Function;
	getText: Function;
	getPageTopPadding: Function;
	space1: number;
	space2: number;
	space3: number;
	space4: number;
	space5: number;
	space6: number;
	space7: number;
	space8: number;
};

export type TypographyType = {
	getStyle: Function;
	fontFamily?: string;
	fontWeight?: number;
	fontSize?: string;
	lineHeight?: string;
	letterSpacing?: string;
	fontStyle?: string;
	textTransform?: string;
	color?: string;
};

export type Colors = {
	primary?: string;
	secondary?: string;
	tertiary?: string;
	quaternary?: string;
	black?: string;
	white?: string;
	secondaryBlue?: string;
	secondaryGreen?: string;
	secondaryBrown?: string;
	blue1?: string;
	blue2?: string;
	blue3?: string;
	blue4?: string;
	green1?: string;
	green2?: string;
	green3?: string;
	brown1?: string;
	brown2?: string;
	red1?: string;
	accentRed?: string;
	accentYellow?: string;
	accentBlue?: string;
	accentGreen?: string;
	grey1?: string;
	grey2?: string;
	grey3?: string;
	grey4?: string;
	grey5?: string;
	accessibility?: string;

	/** common mircosite colors here  */
	commonBgColor?: string;
	commonAccentHue?: string;
	bgBreadcrumbs?: string;
};

export type ThemeType = {
	name: string;
	isMicrositeActive?: boolean;
	colors: Colors;
	palette: Palette;
	typography: {
		/** H1 */
		style1: TypographyType;
		/** H2 */
		style2: TypographyType;
		/** H3 */
		style3: TypographyType;
		/** H4 */
		style4: TypographyType;
		/** H5 */
		style5: TypographyType;

		/** Link */
		style6: TypographyType;

		/** Preamble */
		style7: TypographyType;

		/** Paragraph */
		style8: TypographyType;

		/** Byline */
		style9: TypographyType;

		/** Extra */
		style10: TypographyType;
		style11: TypographyType;
		style12: TypographyType;
		style13: TypographyType;
	};
	spacing: Spacing;
};

const getCssStyle = (o: TypographyType): any => {
	return css`
		font-family: ${o.fontFamily};
		font-weight: ${o.fontWeight};
		font-size: ${o.fontSize};
		line-height: ${o.lineHeight};
		letter-spacing: ${o.letterSpacing};
		text-transform: ${o.textTransform};
		${o.color && `color:${o.color};`};
	`;
};

/** Naturvårdsverket values */

const colors = {
	black: '#000000',
	white: '#ffffff',
	primary: '#003366',
	secondaryBlue: '#0039A2',
	secondaryGreen: '#005643',
	secondaryBrown: '#9B6C17',
	blue1: '#ACCFDB',
	blue2: '#DBF0F7',
	blue3: '#559CB4',
	blue4: '#C4DCE5',

	green1: '#98C49F',
	green2: '#BBD9C0',
	green3: '#2A855D',
	brown1: '#F4DBA1',
	brown2: '#F5E8CB',
	red1: '#9E2800', // Used for error texts
	accentRed: '#DE3600',
	accentYellow: '#F5FF21',
	accentBlue: '#9CF7FF',
	accentGreen: '#02FEAF',
	grey1: '#7B8794',
	grey2: '#595959',
	grey3: '#CBD2D9',
	grey4: '#E4E7EB',
	grey5: '#F5F5F5',
	accessibility: '#fd0',
} as Colors;

const palette: Palette = {
	text: {
		primary: '#2A2A2D',
		secondary: '#ffffff',
		disabled: '#ff0000',
		error: colors.red1,
		hint: '#ff0000',
	} as TextPalette,
};

/** Naturvårdsverket Themes */

export const ThemeDesktop: ThemeType = {
	name: 'Theme Desktop',
	colors: colors,
	palette: palette,
	isMicrositeActive: false,
	typography: {
		style1: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(60),
			lineHeight: px2rem(80),
			letterSpacing: '0',
		},
		style2: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(40),
			lineHeight: px2rem(48),
			letterSpacing: '0',
		},
		style3: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(32),
			lineHeight: px2rem(38),
			letterSpacing: '0',
		},
		style4: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Text', // Tiempos Text Regular
			fontWeight: 400,
			fontSize: px2rem(24),
			lineHeight: px2rem(32),
			letterSpacing: '0',
		},
		style5: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Akkurat Pro Regular
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(25),
			letterSpacing: px2rem(1.2),
			textTransform: 'uppercase',
		},
		style6: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Link..
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: px2rem(0),
		},
		style7: {
			// Preamble
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro',
			fontWeight: 400,
			fontSize: px2rem(24),
			lineHeight: px2rem(36),
			letterSpacing: px2rem(0),
		},
		style8: {
			// Paragraph <p>
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Text',
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: '0',
		},
		style9: {
			// Byline (SimplePuff,GrantPuff,StandardPuff)

			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro',
			fontWeight: 400,
			fontSize: px2rem(16),
			lineHeight: px2rem(24),
			letterSpacing: '0',
			color: colors.grey2,
		},
		style10: {
			// EXTRA
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Tiempos Text',
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: '0',
		},
		style11: {
			// EXTRA, Button text, Grant Puff
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontWeight: 700,
			fontSize: px2rem(16),
			lineHeight: px2rem(19),
			letterSpacing: '0',
		},
		style12: {
			// EXTRA, Menu desktop, second level menuitems, legends
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontWeight: 700,
			fontSize: px2rem(18),
			lineHeight: px2rem(19),
			letterSpacing: '0',
		},
		style13: {
			// EXTRA, figcaptions (image captions / bildtexter in RTE/TinyMCE)
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontSize: px2rem(14),
			lineHeight: '1.4',
			letterSpacing: '0',
		},
	},
	spacing: {
		getSection() {
			return this.space5;
		},
		getElement() {
			return this.space4;
		},
		getTextAndElement() {
			return this.space3;
		},
		getText() {
			return this.space1;
		},
		getPageTopPadding() {
			// Page top padding
			// TODO: On mobile it should be this.space4
			return this.space6;
		},
		space1: px(8), // Between texts
		space2: px(16),
		space3: px(24), // Between texts and elements that should be close
		space4: px(32),
		space5: px(48), // Between Elements
		space6: px(64),
		space7: px(96), // Between Blocks
		space8: px(144), // Between last block and footer
	},
};

export const ThemeMobile: ThemeType = {
	name: 'Theme Mobile',
	colors: colors,
	palette: palette,
	isMicrositeActive: false,
	typography: {
		style1: {
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(40),
			lineHeight: px2rem(40),
			letterSpacing: '0',
		},
		style2: {
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(32),
			lineHeight: px2rem(40),
			letterSpacing: '0',
		},
		style3: {
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Fine', // Tiempos Fine Light
			fontWeight: 300,
			fontSize: px2rem(28),
			lineHeight: px2rem(40),
			letterSpacing: '0',
		},
		style4: {
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Text', // Tiempos Text Regular
			fontWeight: 400,
			fontSize: px2rem(18), // Orginal 22
			lineHeight: px2rem(28),
			letterSpacing: '0',
		},
		style5: {
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Akkurat Pro Regular
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(25),
			letterSpacing: px2rem(1.2),
			textTransform: 'uppercase',
		},
		style6: {
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro', // Link..
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: px2rem(0),
		},
		style7: {
			// Preamble
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro',
			fontWeight: 400,
			fontSize: px2rem(20),
			lineHeight: px2rem(32),
			letterSpacing: px2rem(0),
		},
		style8: {
			// Paragraph <p>
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Text',
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: '0',
		},
		style9: {
			// Byline (SimplePuff,GrantPuff,StandardPuff)

			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro',
			fontWeight: 400,
			fontSize: px2rem(16),
			lineHeight: px2rem(24),
			letterSpacing: '0',
			color: colors.grey2,
		},
		style10: {
			// EXTRA
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Tiempos Text',
			fontWeight: 400,
			fontSize: px2rem(18),
			lineHeight: px2rem(28),
			letterSpacing: '0',
		},
		style11: {
			// EXTRA, Button text, Grant Puff
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontWeight: 700,
			fontSize: px2rem(16),
			lineHeight: px2rem(19),
			letterSpacing: '0',
		},
		style12: {
			// EXTRA, Menu desktop, second level menuitems, legends
			getStyle() {
				return getCssStyle(this);
			},

			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontWeight: 700,
			fontSize: px2rem(18),
			lineHeight: px2rem(19),
			letterSpacing: '0',
		},
		style13: {
			// EXTRA, figcaptions (image captions / bildtexter in RTE/TinyMCE)
			getStyle() {
				return getCssStyle(this);
			},
			fontFamily: 'Akkurat Pro', // Akkurat Pro Bold
			fontSize: px2rem(14),
			lineHeight: '1.4',
			letterSpacing: '0',
		},
	},
	spacing: {
		getSection() {
			return this.space5; // 7
		},
		getElement() {
			return this.space4; // 5
		},
		getTextAndElement() {
			return this.space3;
		},
		getText() {
			return this.space1;
		},
		getPageTopPadding() {
			// Page top padding
			return this.space4; // 6
		},
		space1: px(8), // Between texts
		space2: px(16),
		space3: px(24), // Between texts and elements that should be close
		space4: px(32),
		space5: px(48), // Between Elements
		space6: px(64),
		space7: px(96), // Between Blocks
		space8: px(144), // Between last block and footer
	},
};
