/**
 * SiteFooter
 */

import React, { useEffect, useState } from 'react';
import { CustomerService, ModelFooterSection } from 'types/epi';
import { LinkType } from 'pages/sharedModelTypes';
import LinksFooter from './LinksFooter';
import ContactFooter, { CustomerServiceFooter } from './ContactFooter';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { translate } from 'utils/helper-utils';

type DefinedLinkType = {
	type: string | null;
	link: LinkType | null;
};

export type LinkSection = {
	heading: LinkType;
	links: DefinedLinkType[];
};

interface Props {
	footerGroups: ModelFooterSection[];
	customerService: CustomerService;
	disableCustomHeadingLogic: boolean;
}

const SiteFooter: React.FC<Props> = ({
	footerGroups,
	customerService,
	disableCustomHeadingLogic,
}) => {
	const [contact, setContact] = useState<CustomerServiceFooter>();
	const [footerSections, setFooterSections] = useState<LinkSection[]>([]);
	const [contactSections, setContactSections] = useState<LinkSection[]>([]);

	const localization = useSelector(selectLocalization);
	const ariaLabel_links = translate(
		'/frontend/components/footer/arialabel_links',
		'Links',
		localization
	);

	const ariaLabel_Contact = translate(
		'/frontend/components/footer/arialabel_contact',
		'Contacts',
		localization
	);

	useEffect(() => {
		function generateFooterGroups(): LinkSection[] {
			let footerSections = [] as LinkSection[];
			if (footerGroups && footerGroups.length > 0) {
				footerSections = footerGroups.map((item, index) => {
					return {
						heading: {
							heading: item.head.heading,
							url: item.head.url,
						} as LinkType,
						links: item.children.map((link) => {
							return {
								type: null,
								link: {
									heading: link.heading,
									url: link.url,
								} as LinkType,
							} as DefinedLinkType;
						}),
					} as LinkSection;
				});
			}
			return footerSections;
		}

		setFooterSections(generateFooterGroups());
	}, [footerGroups]);

	useEffect(() => {
		function generateLinkList(): LinkSection[] {
			let sections = [] as LinkSection[];

			if (customerService && customerService.footerLinkList) {
				const newSection = {
					heading: {
						heading: customerService.footerLinkList.heading,
						url: '',
					} as LinkType,
					links: [] as DefinedLinkType[],
				} as LinkSection;

				if (customerService.footerLinkList.links) {
					newSection.links = customerService.footerLinkList.links.map(
						(item) => {
							return {
								type: null,
								link: item,
							} as DefinedLinkType;
						}
					);
				}

				sections.push(newSection);
			}
			if (customerService && customerService.socialLinks) {
				const newSection = {
					heading: {
						heading: customerService.socialLinks.heading,
						url: '',
					} as LinkType,
					links: [] as DefinedLinkType[],
				} as LinkSection;

				if (customerService.socialLinks.links) {
					newSection.links = customerService.socialLinks.links.map((item) => {
						return item;
					});
				}

				sections.push(newSection);
			}

			return sections;
		}

		setContactSections(generateLinkList());

		if (customerService) {
			const customerServiceFixed = {
				heading: customerService.heading,
				textAsModel: customerService.textAsModel,
				text: customerService.text,
				link: customerService.customerServiceLink,
			} as CustomerServiceFooter;
			setContact(customerServiceFixed);
		} else {
			setContact(undefined);
		}
	}, [customerService]);

	return (
		<footer>
			{footerSections.length > 0 && (
				<LinksFooter
					itemsAria={ariaLabel_links}
					sections={footerSections}
				></LinksFooter>
			)}
			{contact && (
				<ContactFooter
					contact={contact}
					itemsAria={ariaLabel_Contact}
					linkSections={contactSections}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				></ContactFooter>
			)}
		</footer>
	);
};

export default SiteFooter;
