import EpiFragments, {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { Select } from 'components/Typography';
import { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { FragmentModelTypes } from 'types/fragments';
import { capitalizeFirstLetter, getLongMonthName } from 'utils/helper-utils';
import EventListItem from './EventListItem';
import { NVseEventsListingPageModel } from './NVseEventsListingPage.model';
import {
	CalendarEventListContainer,
	CalendarEventListGroup,
	CalendarEventListHeading,
	CalendarEventListMonthGroup,
} from './NVseEventsListingPage.styles';

export type CalendarEvent = {
	decal: string;
	heading: string;
	startDate: Date;
	endDate: Date;
	duration: string;
	link: LinkType;
	headingLevel: number;
};

type YearNode = {
	year: number;
	months: MonthNode[];
};

type MonthNode = {
	month: number;
	monthName: string;
	events: CalendarEvent[];
};

/**
 * # Kalendarium 
 * Modeltype:<code>NVseEventsListingPage</code>
 * 
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2469462021/NVseEventsListingPage)
 * 
 * En sida som listar kommande evenemang
 */
const NVseEventsListingPage: React.FC<NVseEventsListingPageModel> = ({
	heading,
	preamble,
	showAllLabel,
	selectMonthLabel,
	events,
	bottomItems,
	_properties = {},
	disableCustomHeadingLogic
}) => {
	const [fixedEvents, setFixedEvents] = useState<CalendarEvent[]>([]);

	const [selectNodes, setSelectNodes] = useState<YearNode[]>([]);
	const [selectedFilter, setSelectedFilter] = useState<string>();
	const [filteredEvents, setFilteredEvents] = useState<YearNode[]>([]);

	const themeContext = useContext(ThemeContext);

	useEffect(() => {
		const fixedEvents = events.map((item) => {
			return {
				decal: item.decal,
				heading: item.heading,
				startDate: new Date(item.startDate),
				endDate: new Date(item.endDate),
				duration: item.duration,
				link: item.link,
			} as CalendarEvent;
		});

		const sorted = fixedEvents.sort((item1, item2) => {
			if (item1.startDate < item2.startDate) {
				return -1;
			}
			if (item1.startDate > item2.startDate) {
				return 1;
			}
			return 0;
		});

		setFixedEvents(sorted);

		const grouped = groupItems(sorted);
		setSelectNodes(grouped);
	}, [events]);

	useEffect(() => {
		if (!selectedFilter) {
			setFilteredEvents(groupItems(fixedEvents));
			return;
		}

		const selectedYear = new Date(selectedFilter).getFullYear();
		const selectedMonth = new Date(selectedFilter).getMonth() + 1;

		const filteredEvents = fixedEvents.filter((item) => {
			if (
				item.startDate.getFullYear() === selectedYear &&
				item.startDate.getMonth() + 1 === selectedMonth
			) {
				return true;
			}
			return false;
		});

		const grouped = groupItems(filteredEvents);

		// Filter
		setFilteredEvents(grouped);
	}, [fixedEvents, selectedFilter]);

	const groupItems = (events: CalendarEvent[]): YearNode[] => {
		// Group by year and month
		let yearNodes: YearNode[] = [];

		events.forEach((item) => {
			const year = item.startDate.getFullYear();
			const month = item.startDate.getMonth() + 1;

			// Assure year
			let yearNode = yearNodes.find((item) => item.year === year);

			if (!yearNode) {
				yearNode = {
					year: year,
					months: [],
				} as YearNode;
				yearNodes.push(yearNode);
			}

			// Assure month
			let monthNode = yearNode.months.find((item) => item.month === month);
			if (!monthNode) {
				monthNode = {
					month: month,
					monthName: capitalizeFirstLetter(getLongMonthName(item.startDate)),
					events: [],
				} as MonthNode;
				yearNode.months.push(monthNode);
			}

			monthNode.events.push(item);
		});
		return yearNodes;
	};

	const onFilterChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		setSelectedFilter(value);
	};

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (fixedEvents.length > 0 || selectNodes.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space top={themeContext.spacing.getPageTopPadding()} bottom={spaceAfterLast}>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					columns={8}
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			<Grid paddingTop={false} paddingBottom={false}>
				{selectNodes.length > 0 && (
					<Space top={themeContext.spacing.getElement()}>
						<form>
							<label>{selectMonthLabel}</label>
							<Select
								name="filter"
								value={selectedFilter}
								aria-label="Filter"
								onChange={onFilterChanged}
							>
								<option value="">{showAllLabel}</option>

								{selectNodes.length > 1 ? (
									<>
										{selectNodes.map((yearItem, index) => {
											return (
												<optgroup key={index} label={yearItem.year.toString()}>
													{yearItem.months.map((item, index) => {
														return (
															<option
																key={index}
																value={`${yearItem.year}-${item.month}`}
															>
																{item.monthName}
															</option>
														);
													})}
												</optgroup>
											);
										})}
									</>
								) : (
									selectNodes[0].months.map((item, index) => {
										return (
											<option
												key={index}
												value={`${selectNodes[0].year}-${item.month}`}
											>
												{item.monthName}
											</option>
										);
									})
								)}
							</Select>
						</form>
					</Space>
				)}
				{filteredEvents.length > 0 && (
					<CalendarEventListGroup>
						{filteredEvents.map((yearItem) => {
							return yearItem.months.map((monthItem, index) => {
								return (
									<CalendarEventListMonthGroup key={index}>
										<CalendarEventListHeading>
											<Heading level={2} styleLevel={4} zeroBottom={true}>
												{monthItem.monthName}
												<br />
												{yearItem.year}
											</Heading>
										</CalendarEventListHeading>

										<CalendarEventListContainer>
											{monthItem.events.map((item, index) => {
												return (
													<li key={index}>
														<EventListItem

															{...item}
															headingLevel={3}
														></EventListItem>
													</li>
												);
											})}
										</CalendarEventListContainer>
									</CalendarEventListMonthGroup>
								);
							});
						})}
					</CalendarEventListGroup>
				)}
			</Grid>

			<EpiFragments
				previousBlockType={blockTypeBefore_bottomItems}
				headingLevel={2}
				fragments={bottomItems as FragmentModelTypes[]}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default NVseEventsListingPage;
