/**
 * HeaderTextActionPanel - styles
 */

import { getThemeTextColor } from 'pages/sharedModelTypes';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { BannerPanelProps } from './BannerPanel';

const getPositionCss = (centerd: boolean) => {
	if (centerd) {
		return css`
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		`;
	} else {
		return css`
			display: inline-block;
			align-items: flex-start;
		`;
	}
};

const setAlignBottomCss = () => {
	return css`
		display: inline-flex;
		flex-direction: column;
		height: 100%;

		> span {
			flex: 1 1 auto;
		}
	`;
};

export const BannerPanelHeaderStyle = styled.div`
	> *:last-child {
		margin-bottom: 0rem;
	}
`;

export const BannerPanelStyle = styled.div<BannerPanelProps>`
	${(p) => getPositionCss(p.center ? true : false)};
	${(p) => p.alignbottom && setAlignBottomCss()};

	> a {
		margin-top: ${props => props.theme.spacing.space4}rem;
		padding-left: ${props => props.theme.spacing.space4}rem;
		padding-right: ${props => props.theme.spacing.space4}rem;
	}

	> *:last-child {
		margin-bottom: ${0}rem;
	}

	p {
		${(p) => p.center && `margin-left: auto;margin-right: auto;`};
		margin-right: auto;
		${props => props.theme.typography.style5.getStyle()};		
		text-transform: none;
		${mediaQueries.phone} {
			text-transform: none;
		}
		color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	}
`;

export const DecalStyle = styled.div<BannerPanelProps>`
		${props => props.theme.typography.style9.getStyle()};		
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};

	letter-spacing: 1.07px;
	text-transform: uppercase;
	margin-bottom: ${props => props.theme.spacing.space1}rem;
`;
