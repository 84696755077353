import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	BreadcrumbStyled,
	BreadcrumbLink,
	BreadcrumbItemWarpper,
} from './Breadcrumbs.styles';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { translate } from 'utils/helper-utils';
import { ListingPageURLs } from 'types/common';

/**
 * Breadcrumbs
 *
 * todo:
 * 	- WAI-ARIA: The landmark region is labelled via aria-label or aria-labelledby.
 */

export type BreadcrumbItemtype = {
	url: string;
	text: string;
	isActive: boolean;
};

type Props = {
	items?: BreadcrumbItemtype[];
};

export type MediaQueryProps = {
	isPhone: boolean;
};

const Breadcrumbs = ({ items }: Props) => {
	const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemtype[]>([]);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	useEffect(() => {
		if (items && items.length > 0) {
			if (isPhone) {
				if (items.length > 1) {
					// show second last -1
					const secondLastItem = { ...items[items.length - 2] };
					setBreadcrumbs([secondLastItem]);
				} else {
					// Show text (no link)
					const firstItem = { ...items[0] };
					setBreadcrumbs([firstItem]);
				}
			} else {
				setBreadcrumbs([...items]);
			}
		}
	}, [items, isPhone]);

	const localization = useSelector(selectLocalization);

	const ariaLabel = translate(
		'/frontend/components/breadcrumbs/arialabel',
		'You are here',
		localization
	);

	return (
		<nav aria-label={ariaLabel}>
			<BreadcrumbStyled>
				{breadcrumbs.map((item, index) => {
					return (
						<BreadcrumbItemWarpper isPhone={isPhone} key={index}>
							<BreadcrumbLink
								showLinkIcon={false}
								$active={item.isActive}
								to={item.url}
								external={ListingPageURLs.includes(item.url)}
							>
								{item.text}
							</BreadcrumbLink>
						</BreadcrumbItemWarpper>
					);
				})}
			</BreadcrumbStyled>
		</nav>
	);
};

export default Breadcrumbs;
