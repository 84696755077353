import PuffCard from 'components/PuffCard';
import { CardClockStatus } from 'components/PuffCard/PuffCard';
import PuffListItem from 'components/PuffListItem';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { GrantPuffModel, ImageFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetGrantPuffModelElement = (
	grantPuffModel: GrantPuffModel,
	options: Options
) => {
	const image = {
		modelType: 'Image',
		src: grantPuffModel.image?.src,
		srcSet: grantPuffModel.image?.srcSet,
		alt: grantPuffModel.image?.alt,
		caption: grantPuffModel.image?.caption,
		photographer: grantPuffModel.image?.photographer,
		agency: grantPuffModel.image?.agency,
		illustrator: grantPuffModel.image?.illustrator,
	} as ImageFragmentModel;

	switch (options?.view) {
		default:
		case ViewType.Card:
			let statusColor = options.themeContext.colors.accentRed;
			switch (grantPuffModel.statusId) {
				case 0: // Kommande
					statusColor = options.themeContext.colors.secondaryBlue;
					break;
				case 1: // Aktuella
					statusColor = options.themeContext.colors.green3;
					break;
				case 2: // Avslutade
					statusColor = options.themeContext.colors.accentRed;
					break;
			}

			if (grantPuffModel.statusAlert) {
				statusColor = options.themeContext.colors.accentRed;
			}

			const metadata = (
				<CardClockStatus
					color={statusColor!}
					text={grantPuffModel.statusText}
				></CardClockStatus>
			);

			return (
				<PuffCard
					image={image}
					decal={grantPuffModel.decal}
					heading={grantPuffModel.heading}
					headingLevel={options.headingLevel}
					link={grantPuffModel.link}
				>
					{metadata}
				</PuffCard>
			);
		case ViewType.List:
			const newLink: LinkType = {
				url: grantPuffModel.link.url,
				heading: grantPuffModel.heading,
			};

			return (
				<PuffListItem
					decal={grantPuffModel.decal}
					headingLevel={options.headingLevel}
					link={newLink}
					image={image}
					compact={false}
				></PuffListItem>
			);

		case ViewType.CompactList:
			return (
				<PuffListItem
					decal={grantPuffModel.decal}
					headingLevel={options.headingLevel}
					link={grantPuffModel.link}
					image={image}
					compact={true}
				></PuffListItem>
			);
	}
};
