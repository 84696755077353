/**
 * PuffListItem - styles
 */

import styled, { css } from 'styled-components';
import { getLinkArrowStyle } from 'theme/styles';
import Link from 'components/Boilerplate/Link';
import { Heading } from 'components/Typography/Typography';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const ComponentWithBorderLeft = styled.div`
	position: relative;
	padding-left: 10px; /* Set padding to make space for border */
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		border-left: 10px solid ${(props) => props.theme.colors.secondary};
	}
`;

export const PuffListItemLinkStyle = styled(Link)`
	background-color: ${(props) => props.theme.colors.white};
	display: flex;
	border: ${(props) =>
		props.theme.isMicrositeActive ? 'none' : '4px solid transparent'};

	${mediaQueries.phone} {
		padding: ${(props) =>
			props.theme.isMicrositeActive ? '0' : props.theme.spacing.space2}rem;
	}

	&:after {
		content: none;
	}

	&:after {
		content: none;
	}

	&:hover {
		text-decoration: none;
	}
`;

export const PuffListItemTextStyle = styled.div`
	flex-grow: 1;
	align-self: center;
	padding: ${(props) =>
			props.theme.isMicrositeActive
				? props.theme.spacing.space4
				: props.theme.spacing.space2}rem
		${(props) => props.theme.spacing.space4}rem;

	color: ${(props) =>
		props.theme.isMicrositeActive && props.theme.palette.text.primary};
	${mediaQueries.phone} {
		padding: ${(props) => (props.theme.isMicrositeActive ? '1' : '0 1')}rem;
	}

	> *:first-child {
		margin-top: 0;
	}
`;

export const PuffTimeStyle = styled.span`
	font-family: ${(props) => props.theme.typography.primaryFontFamily};
	font-size: ${px2rem(16)};
	color: ${(props) => props.theme.colors.primary};
`;

export const PuffListItemImageStyle = styled.div`
	width: 240px;
	flex-shrink: 0;
	padding: ${(props) => props.theme.spacing.space1}rem;
`;

export const PuffListItemLink = styled(Heading)`
	margin-top: ${(props) => props.theme.spacing.space1}rem;
	max-width: 50ch;
	${getLinkArrowStyle()}

	${PuffListItemLinkStyle}:hover &,${PuffListItemLinkStyle}:focus & {
		text-decoration: underline;
		&:after {
			opacity: 1;
		}
	}
`;

export const PuffListItemPreamble = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) =>
		props.theme.isMicrositeActive
			? props.theme.palette.text.primary
			: '#00000'};
	margin-top: ${(props) => props.theme.spacing.space1}rem;
	max-width: 65ch;

	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
`;

export const PuffListItemMetadata = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space2}rem;
`;

export const PuffListItemMetadataCompact = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};

	${mediaQueries.tabletOrLarger} {
		align-self: center;
		flex-shrink: 0;
		padding-right: ${(props) => props.theme.spacing.space4}rem;
	}

	${mediaQueries.phone} {
		margin-top: ${(props) => props.theme.spacing.space1}rem;
		padding: 0 ${(props) => props.theme.spacing.space4}rem;
	}
`;
