import EpiFragments, {
	Options,
	wrapElementInGrid,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Carousel from 'components/Carousel';
import ListHeader from 'components/ListHeader';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';
import { breakpointsNumber } from 'theme/media-queries';
import { ViewType } from 'types/enums';
import { FragmentModelTypes } from 'types/fragments';
import {
	FragmentPuffListStyle,
	MicrositeLinkStyle,
} from './FragmentPuffList.styles';
import { ListItemsStyle } from './Panels.styles';
import SectionHeader from './SectionHeader';
import { ShowMoreButton } from './ShowMoreButton';

/**
 * FragmentPuffList
 */
type FragmentPuffListProps = {
	theme?: PuffTheme;
	heading?: string | null;
	link?: LinkType | null;
	items: FragmentModelTypes[];
	showMoreLabel?: string;
	initialMaxCount?: number;
	renderAsCarousel?: boolean;
	nextLabel?: string;
	previousLabel?: string;
	options: Options;
	disableCustomHeadingLogic: boolean;
};

// TODO: previousBlockType={BlockType.Element}

export const FragmentPuffList: React.FC<FragmentPuffListProps> = ({
	theme,
	heading,
	showMoreLabel,
	initialMaxCount,
	items,
	link,
	renderAsCarousel = false,
	nextLabel,
	previousLabel,
	options,
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);
	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.desktop - 1,
	});
	const [showItemsCount, setShowItemsCount] = useState<number | undefined>(
		initialMaxCount
	);
	const [filterdItems, setFilterdItems] = useState<FragmentModelTypes[]>([]);

	useEffect(() => {
		if (!initialMaxCount) {
			setShowItemsCount(items.length);
		}

		setFilterdItems(items.slice(0, showItemsCount));
	}, [initialMaxCount, items, showItemsCount]);

	let stackItems: boolean;
	let newViewOptions: ViewType = ViewType.Card;

	switch (options?.view) {
		default:
		case ViewType.Card:
			stackItems = false;
			newViewOptions = ViewType.Card;
			break;
		case ViewType.List:
			stackItems = true;
			newViewOptions = ViewType.List;
			break;
		case ViewType.CompactList:
			stackItems = true;
			newViewOptions = ViewType.CompactList;
			break;
	}

	// Special handling for the start page
	const centeredHeading = options.modelType === 'NVseStartPage';
	let contentHeadingLevel = options.headingLevel ? options.headingLevel : 1;

	let headerElement = null;
	if (heading) {
		if (centeredHeading) {
			// TODO: Create a simple centered header, or rename SectionHeader
			headerElement = (
				<Space bottom={themeContext.spacing.space3}>
					<SectionHeader
						heading={heading}
						link={link}
						headingLevel={contentHeadingLevel}
					></SectionHeader>
				</Space>
			);
		} else {
			headerElement = (
				<Space bottom={themeContext.spacing.space3}>
					<ListHeader
						heading={heading}
						link={link}
						sectionStyle
						headingLevel={contentHeadingLevel}
					></ListHeader>
				</Space>
			);
		}
		contentHeadingLevel = contentHeadingLevel + 1;
	}

	let listElement;
	if (renderAsCarousel) {
		listElement = (
			<Carousel
				forwardButtonText={nextLabel ? nextLabel : 'Next'}
				backwardButtonText={previousLabel ? previousLabel : 'Previous'}
				puffItems={filterdItems}
				headingLevel={contentHeadingLevel}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		);
	} else {
		listElement = (
			<>
				<ListItemsStyle cardView={!stackItems}>
					<EpiFragments
						view={newViewOptions}
						insideGrid={true}
						headingLevel={contentHeadingLevel}
						fragments={filterdItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				</ListItemsStyle>

				{showMoreLabel &&
					showItemsCount !== undefined &&
					showItemsCount < items.length && (
						<Space top={themeContext.spacing.space5}>
							<ShowMoreButton
								header={showMoreLabel}
								onClick={() => setShowItemsCount(items.length)}
							></ShowMoreButton>
						</Space>
					)}
			</>
		);
	}

	let element = (
		<>
			{headerElement}
			{listElement}
		</>
	);

	const renderElement = () => {
		if (themeContext.isMicrositeActive) {
			// Microsite elements
			let headerMicrositeElement = (
				<Space
					bottom={
						isNarrowDisplay
							? themeContext.spacing.space1
							: themeContext.spacing.space2
					}
					top={
						isNarrowDisplay
							? themeContext.spacing.space5
							: themeContext.spacing.space7
					}
				>
					<ListHeader
						heading={heading}
						sectionStyle
						headingLevel={contentHeadingLevel}
						color={themeContext.palette.text.primary}
					></ListHeader>
				</Space>
			);

			let listMicrositeElement = (
				<ListItemsStyle
					cardView={options.view === ViewType.Card ? true : false}
				>
					<EpiFragments
						view={options.view}
						insideGrid={true}
						headingLevel={contentHeadingLevel}
						fragments={filterdItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				</ListItemsStyle>
			);

			let micrositeElement = (
				<>
					{headerMicrositeElement}
					{listMicrositeElement}
					{link && (
						<MicrositeLinkStyle to={link.url} external={link.isFile}>
							{link.heading}
						</MicrositeLinkStyle>
					)}
				</>
			);
			return (
				<Grid paddingTop={false} paddingBottom={false}>
					{micrositeElement}
				</Grid>
			);
		} else {
			return (
				<FragmentPuffListStyle themeName={theme}>
					{element}
				</FragmentPuffListStyle>
			);
		}
	};

	if (!options?.insideGrid) {
		element = wrapElementInGrid(element);
	}

	return <>{renderElement()}</>;
};
