import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';

export const useSize = (target: any) => {
	const [size, setSize] = React.useState();

	React.useLayoutEffect(() => {
		if (target && target.current) {
			setSize(target.current.getBoundingClientRect());
		}
	}, [target]);

	// Where the magic happens
	useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
	return size;
};
