import Link from 'components/Boilerplate/Link';
import { Heading } from 'components/Typography/Typography';
import React from 'react';
import { generateAnchorId } from 'theme/utils';
import { PageOverview } from './PageOverviewPuff.styles';

type PageOverviewPuffModel = {
	headingLevel?: number;
	heading: string | null;
	items: string[];
};

export const PageOverviewPuff: React.FC<PageOverviewPuffModel> = ({
	headingLevel,
	heading,
	items,
}) => {
	return (
		<PageOverview>
			<nav aria-labelledby="page-overview-nav">
				{heading && (
					<Heading level={headingLevel} styleLevel={4} id={'page-overview-nav'}>
						{heading}
					</Heading>
				)}

				<ul>
					{items.map((heading, index) => {
						const anchorId = generateAnchorId(heading);
						return (
							<li key={index}>
								<Link to={'#' + anchorId} key={index}>
									{heading}
								</Link>
							</li>
						);
					})}
				</ul>
			</nav>
		</PageOverview>
	);
};
