import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import ListHeader from 'components/ListHeader';
import { ColumnPageWithSidebar } from 'components/Panels/ColumnPageWithSidebar';
import { SidebarPuff } from 'components/Panels/SidebarPuff';
import { ContentBlockModel } from 'types/fragments';
import { Options } from '../EpiFragments';
import { ContentBlockModelWrapper } from 'pages/NVseArticlePage/NVseArticlePage.styles';

// ContentBlockModel
export const GetContentBlockModelElement = (
	expanderData: ContentBlockModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	const colorHeading = options.themeContext.isMicrositeActive
		? options.themeContext.palette.text.primary
		: undefined;

	return (
		<>
			{expanderData.heading && (
				<ContentBlockModelWrapper>
					<Grid paddingTop={false} paddingBottom={false} inner>
						<Cell span={8}>
							<ListHeader
								sectionStyle
								color={colorHeading}
								heading={expanderData.heading}
								headingLevel={options.headingLevel}
							></ListHeader>
						</Cell>
					</Grid>
				</ContentBlockModelWrapper>
			)}

			<Space top={options.themeContext.spacing.space3}>
				<ColumnPageWithSidebar
					textAsModel={expanderData.textAsModel}
					link={expanderData.link}
					bottomItems={expanderData.bottomItems}
					options={{ ...options, headingLevel: options.headingLevel + 1 }}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				>
					{expanderData.sidebar && (
						<SidebarPuff
							image={expanderData.sidebar?.image}
							heading={expanderData.sidebar?.heading}
							headingLevel={options.headingLevel + 1}
							text={expanderData.sidebar?.text}
						></SidebarPuff>
					)}
				</ColumnPageWithSidebar>
			</Space>
		</>
	);
};
