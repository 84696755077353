/**
 * MegaMenu
 */
import Icon from 'components/Boilerplate/Icon';
import Link from 'components/Boilerplate/Link';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { Heading } from 'components/Typography/Typography';
import React, {
	MutableRefObject,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { toggleMenuItem, selectLocalization } from 'store/modules/model';
import { ThemeContext } from 'styled-components';
import { breakpointsNumber } from 'theme/media-queries';
import { NavigationItem } from 'types/epi';
import { translate } from 'utils/helper-utils';
import { useSelector } from 'react-redux';

import {
	BackgroundOverlayStyle,
	MegaMenuAlternativSiblingLinkItem,
	MegaMenuCloseContainer,
	MegaMenuErrorItem,
	MegaMenuHeaderContainer,
	MegaMenuListContainer,
	MegaMenuListsContainer,
	MegaMenuRootItem,
	MegaMenuRootLinkItem,
	MegaMenuSiblingItem,
	MegaMenuSiblingLinkItem,
	MicrositeMegaMenuRootItem,
	MicrositeMegaMenuSiblingLinkItem,
} from './MegaMenu.styles';
import { ListingPageURLs } from 'types/common';

export enum AriaLabel {
	MENU = 'Menu',
	MENY = 'Meny',
	SIDEMENU = 'Side menu',
	SIDOMENY = 'Sidomeny',
	LEVEL = 'level',
	NIVA = 'nivå',
}

type MenuList = {
	heading: string | null;
	parent: NavigationItem | null;
	items: NavigationItem[];
};

export interface MegaMenuProps {
	items: NavigationItem[];
	heading?: string | null;
	onClose?: (focusOrigin: boolean) => void;
}

const MegaMenu: React.FC<MegaMenuProps> = ({ items, heading, onClose }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const isEnglish = location.pathname.startsWith('/en');
	const [menus, setMenus] = useState<MenuList[]>([]);
	const [currentLocation, setCurrentLocation] = useState<string>();
	const [animate, setAnimate] = useState(false);
	const [animate2, setAnimate2] = useState(false);

	const animSpeed = 200;
	const ariaLabel = isEnglish ? AriaLabel.MENU : AriaLabel.MENY;

	const ref = useRef(null);

	const getActiveItem = (
		items: NavigationItem[]
	): NavigationItem | undefined => {
		return items.find((item) => {
			return item.isActive === true;
		});
	};

	useEffect(() => {
		const currentUrl = location.pathname + location.hash;

		setCurrentLocation(currentUrl);

		if (currentLocation !== undefined) {
			if (onClose) {
				closeMenu(false);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		setAnimate(true);
		setAnimate2(true);

		let rootMenuList = {
			items: items,
			heading: heading,
			parent: null,
		} as MenuList;

		let rootMenues = [rootMenuList];
		let currentLevel = items;
		// Add all "first" open menues
		while (true) {
			let activeItem = getActiveItem(currentLevel);
			if (activeItem === undefined || activeItem.children.length === 0) {
				break;
			}
			currentLevel = activeItem.children;

			// Add level to menulist
			let newMenuList = {
				items: activeItem.children,
				heading: activeItem.text,
				parent: activeItem,
			} as MenuList;

			rootMenues = [...rootMenues, newMenuList];
		}

		setMenus(rootMenues);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const onToggleItem = (item: NavigationItem, level: number) => {
		// Only one item can be active in a menu list
		// toggle all active items
		const menuList = menus[level];
		menuList.items.forEach((item) => {
			if (item.isActive) {
				const result = createNodeIndexPath(items, item, []);
				if (result) {
					dispatch(toggleMenuItem(result));
				}
			}
		});

		// Toggle the clicked/activated item
		const result = createNodeIndexPath(items, item, []);
		if (result) {
			dispatch(toggleMenuItem(result));
		}
	};

	/**
	 * A menu list want to be closed
	 * by keyboard (Left arrow) or close button if enabled
	 */
	const onMenuListClose = (level: number, focusOrigin: boolean) => {
		if (level === 0) {
			closeMenu(focusOrigin);
		} else {
			// Fint parent node and toggle it.
			let menuList = menus[level];
			if (menuList.parent) {
				const result = createNodeIndexPath(items, menuList.parent, []);
				if (result) {
					dispatch(toggleMenuItem(result));
				}
			}
		}
	};

	const onMouseClickOutside = (e: any) => {
		let element = ref.current;
		if (element) {
			if (element === e.target && onClose) {
				e.preventDefault();
				closeMenu(false);
			}
		}
	};

	/** Close down menu */
	const closeMenu = (focusOrigin: boolean) => {
		if (onClose) {
			setAnimate2(false);
			setTimeout(() => {
				toggleItemsToFalse(items);
				onClose(focusOrigin);
			}, animSpeed);
		}
	};

	const createNodeIndexPath = (
		items: NavigationItem[],
		find: NavigationItem,
		path: number[]
	): number[] | null => {
		for (let i = 0; i < items.length; i++) {
			let item = items[i];
			if (item === find) {
				return [...path, i];
			}

			if (item.children.length > 0) {
				let result = createNodeIndexPath(item.children, find, [...path, i]);
				if (result) {
					return result;
				}
			}
		}
		return null;
	};

	const toggleItemsToFalse = (children: NavigationItem[]) => {
		children.forEach((item) => {
			if (item.isActive) {
				const result = createNodeIndexPath(items, item, []);
				if (result) {
					dispatch(toggleMenuItem(result));
				}
			}

			if (item.children.length > 0) {
				toggleItemsToFalse(item.children);
			}
		});
	};

	return (
		<Transition in={animate2} timeout={animSpeed}>
			{(state2) => (
				<BackgroundOverlayStyle
					role="dialog"
					aria-label={ariaLabel}
					aria-modal={true}
					ref={ref}
					state={state2}
					onClick={onMouseClickOutside}
				>
					<MegaMenuListsContainer state={state2} tabIndex={0}>
						<TransitionGroup component={null}>
							{menus.map((menuItem, index) => {
								return (
									<Transition key={index} in={animate} timeout={animSpeed}>
										{(state) => (
											<MegaMenuList
												isEnglish={isEnglish}
												state={state}
												showCloseButton={index === 0}
												onClose={onMenuListClose}
												key={index}
												parent={menuItem.parent}
												heading={menuItem.heading}
												items={menuItem.items}
												level={index}
												onSelectedChanged={onToggleItem}
											></MegaMenuList>
										)}
									</Transition>
								);
							})}
						</TransitionGroup>
					</MegaMenuListsContainer>
				</BackgroundOverlayStyle>
			)}
		</Transition>
	);
};

export default MegaMenu;

/**
 * MegaMenuList
 */

export interface MegaMenuListProps {
	heading: string | null;
	parent: NavigationItem | null;
	items: NavigationItem[];
	state: any;
	level: number;
	showCloseButton?: boolean;
	onClose?: (level: number, mouseClick: boolean) => void;
	onSelectedChanged?: (child: NavigationItem, level: number) => void;
	isEnglish?: boolean;
}

const MegaMenuList: React.FC<MegaMenuListProps> = ({
	heading,
	parent,
	items,
	level,
	showCloseButton = true,
	state,
	onClose,
	onSelectedChanged,
	isEnglish,
}) => {
	const themeContext = useContext(ThemeContext);
	const isMicrositeActive = themeContext.isMicrositeActive;

	//	const [listItems, setListItems] = useState<NavigationItem[]>([]);
	const [focusIndex, setFocusIndex] = useState<number>(0);
	const [animate, setAnimate] = useState(state);

	// TODO:!!
	//const itemRefs = useMemo(() => { return [] }, [items]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const itemRefs: React.RefObject<any>[] = [];
	const closeElement = useRef<HTMLButtonElement>(
		null
	) as MutableRefObject<HTMLButtonElement>;

	const localization = useSelector(selectLocalization);
	const closeLabel = translate(
		'/frontend/components/headerMenu/close',
		'Close',
		localization
	);

	const ariaCloseLabel = translate(
		'/frontend/components/headerMenu/closeLabel',
		'Close menu',
		localization
	);

	useEffect(() => {
		setAnimate(state);
	}, [state]);

	useEffect(() => {
		if (itemRefs[focusIndex] && itemRefs[focusIndex].current) {
			itemRefs[focusIndex].current.focus();
		}
	}, [focusIndex, itemRefs]);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const onMenuItemSelected = (child: NavigationItem) => {
		setFocusIndex(items.indexOf(child));
		if (onSelectedChanged) {
			onSelectedChanged(child, level);
		}
	};

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		// TODO: KeyboardEvent.key, Browser not supported QQ and Baidu
		let newFocusIndex = 0;

		if (event.key.length === 1) {
			const match = items.filter((item) => {
				return item.text
					.toLocaleUpperCase()
					.startsWith(event.key.toUpperCase());
			});

			for (let i = 0; i < match.length; i++) {
				let matchIndex = items.indexOf(match[i]);
				if (matchIndex > focusIndex) {
					event.preventDefault();
					setFocusIndex(matchIndex);
					return;
				}

				if (matchIndex > focusIndex) {
					event.preventDefault();
					setFocusIndex(matchIndex);
					return;
				}
			}

			if (match.length > 0) {
				event.preventDefault();
				setFocusIndex(items.indexOf(match[0]));
				return;
			}
		}
		switch (event.key) {
			case 'Escape':
				event.preventDefault();
				event.stopPropagation();
				if (onClose) {
					onClose(level, true);
				}
				break;
			case 'Tab':
				event.preventDefault();
				if (event.shiftKey) {
					if (showCloseButton) {
						if (focusIndex === 0) {
							// On first item
							setFocusIndex(-1);
							closeElement.current.focus();
							break;
						}
					}
					newFocusIndex = focusIndex <= 0 ? items.length - 1 : focusIndex - 1;
				} else {
					if (showCloseButton) {
						if (focusIndex >= items.length - 1) {
							// On last item
							setFocusIndex(-1);
							closeElement.current.focus();
							break;
						}
					}

					newFocusIndex = focusIndex >= items.length - 1 ? 0 : focusIndex + 1;
				}
				setFocusIndex(newFocusIndex);
				itemRefs[newFocusIndex].current.focus();
				break;

			default:
				break;
		}
	};

	const handleContainerKeyUpPress = (
		e: React.KeyboardEvent<HTMLDivElement>
	) => {
		// Block all keyup
		e.stopPropagation();
	};

	const onFocus = (item: NavigationItem) => {
		setFocusIndex(items.indexOf(item));
	};

	const onCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (onClose) {
			onClose(level, true);
		}
	};

	const handleStyleLevels = () => {
		if (isMicrositeActive) {
			return isPhone ? 2 : 3;
		} else {
			return isPhone ? 2 : 4;
		}
	};

	return (
		<MegaMenuListContainer
			state={animate}
			style={{ zIndex: 10 - level }}
			onKeyDown={handleContainerKeyPress}
			onKeyUp={handleContainerKeyUpPress}
		>
			<MegaMenuCloseContainer
				style={{ visibility: showCloseButton ? 'visible' : 'hidden' }}
			>
				{onClose && (
					<Button
						ref={closeElement}
						iconName="cross"
						variant={
							isMicrositeActive
								? ButtonVariant.Microsite
								: ButtonVariant.Tertiary
						}
						onClick={onCloseClick}
						aria-label={ariaCloseLabel}
					>
						{closeLabel}
					</Button>
				)}
			</MegaMenuCloseContainer>
			{heading && (
				<MegaMenuHeaderContainer>
					<Heading
						zeroBottom={true}
						color={themeContext.palette.text.primary}
						styleLevel={handleStyleLevels()}
						level={isMicrositeActive ? 3 : 2}
					>
						{heading}
					</Heading>
				</MegaMenuHeaderContainer>
			)}
			<nav
				aria-label={`${
					isEnglish
						? AriaLabel.SIDEMENU + ' ' + AriaLabel.LEVEL
						: AriaLabel.SIDOMENY + ' ' + AriaLabel.NIVA
				} ${level + 1}${heading !== undefined ? ', ' + heading : ''}`}
			>
				<ul>
					{items.map((child, index) => {
						const ref = React.createRef<any>();
						itemRefs[index] = ref;

						return (
							<MenuListItem
								onFocus={onFocus}
								key={index}
								onSelect={onMenuItemSelected}
								item={child}
								level={level}
								ref={itemRefs[index]}
							></MenuListItem>
						);
					})}
				</ul>
			</nav>
		</MegaMenuListContainer>
	);
};

export type selectedProps = {
	selected?: boolean;
};

export type isRedirectProps = {
	isRedirect: boolean;
	color?: string;
};

/*** ITEM */

/**
 * Menu Item
 * Can be button or link
 * different styles depending of level
 */

export interface MenuListItemProps {
	item: NavigationItem;
	level: number;
	onSelect?: (child: NavigationItem) => void;
	onFocus?: (child: NavigationItem) => void;
}

const MenuListItem = React.forwardRef<any, MenuListItemProps>(
	({ item, level, onSelect, onFocus }, ref) => {
		const themeContext = useContext(ThemeContext);
		const isMicrositeActive = themeContext.isMicrositeActive;

		const getLinkItem = (item: NavigationItem, level: number) => {
			if (item.style === 1) {
				return (
					<MegaMenuAlternativSiblingLinkItem onFocus={onItemFocus}>
						<Link
							tabIndex={-1}
							ref={ref}
							to={item.url}
							external={ListingPageURLs.includes(item.url)}
						>
							{item.text}
						</Link>
					</MegaMenuAlternativSiblingLinkItem>
				);
			}

			if (isMicrositeActive) {
				if (item.style === 2 || item.style === 3) {
					return (
						<MicrositeMegaMenuSiblingLinkItem onFocus={onItemFocus}>
							{item.url && (
								<Link tabIndex={-1} ref={ref} to={item.url}>
									{item.text}
								</Link>
							)}
							{!item.url && (
								<MegaMenuErrorItem>
									Error:missing url:{item.text}
								</MegaMenuErrorItem>
							)}
						</MicrositeMegaMenuSiblingLinkItem>
					);
				}
			} else {
				if (item.style === 2 || item.style === 3) {
					return (
						<MegaMenuSiblingLinkItem
							color={
								item.isRedirect || item.style === 3
									? undefined
									: themeContext.palette.text.primary
							}
							isRedirect={item.isRedirect}
							onFocus={onItemFocus}
						>
							{item.url && (
								<Link
									tabIndex={-1}
									ref={ref}
									to={item.url}
									external={ListingPageURLs.includes(item.url)}
								>
									{item.text}
								</Link>
							)}
							{!item.url && (
								<MegaMenuErrorItem>
									Error:missing url:{item.text}
								</MegaMenuErrorItem>
							)}
						</MegaMenuSiblingLinkItem>
					);
				}
			}

			if (level === 0) {
				return (
					<MegaMenuRootLinkItem onFocus={onItemFocus}>
						{item.url && (
							<Link
								tabIndex={-1}
								ref={ref}
								showLinkIcon={false}
								to={item.url}
								external={ListingPageURLs.includes(item.url)}
							>
								{item.text}
							</Link>
						)}
						{!item.url && (
							<MegaMenuErrorItem>
								Error:missing url:{item.text}
							</MegaMenuErrorItem>
						)}
					</MegaMenuRootLinkItem>
				);
			}

			if (isMicrositeActive) {
				return (
					<MicrositeMegaMenuSiblingLinkItem onFocus={onItemFocus}>
						{item.url && (
							<Link showLinkIcon={false} tabIndex={-1} ref={ref} to={item.url}>
								{item.text}
							</Link>
						)}
						{!item.url && (
							<MegaMenuErrorItem>
								Error:missing url:{item.text}
							</MegaMenuErrorItem>
						)}
					</MicrositeMegaMenuSiblingLinkItem>
				);
			} else {
				return (
					<MegaMenuSiblingLinkItem
						isRedirect={item.isRedirect}
						onFocus={onItemFocus}
						color={
							item.isRedirect ? undefined : themeContext.palette.text.primary
						}
					>
						{item.url && (
							<Link
								showLinkIcon={false}
								tabIndex={-1}
								ref={ref}
								to={item.url}
								aria-label={item.text}
								external={ListingPageURLs.includes(item.url)}
							>
								{item.text}
							</Link>
						)}
						{!item.url && (
							<MegaMenuErrorItem>
								Error:missing url:{item.text}
							</MegaMenuErrorItem>
						)}
					</MegaMenuSiblingLinkItem>
				);
			}
		};

		const getItemWithSubItems = (item: NavigationItem, level: number) => {
			if (level === 0) {
				if (isMicrositeActive) {
					return (
						<MicrositeMegaMenuRootItem
							onFocus={onItemFocus}
							selected={item.isActive}
						>
							<div></div>
							<button
								aria-expanded={item.isActive}
								aria-label={item.text}
								onClick={onSelected}
								ref={ref}
								tabIndex={-1}
							>
								<span>{item.text}</span>
								<Icon size={1} icon="chevron"></Icon>
							</button>
						</MicrositeMegaMenuRootItem>
					);
				} else {
					return (
						<MegaMenuRootItem onFocus={onItemFocus} selected={item.isActive}>
							<button
								aria-expanded={item.isActive}
								aria-label={item.text}
								onClick={onSelected}
								ref={ref}
								tabIndex={-1}
							>
								<span>{item.text}</span>
								<Icon size={1} icon="chevron"></Icon>
							</button>
						</MegaMenuRootItem>
					);
				}
			}

			return (
				<MegaMenuSiblingItem onFocus={onItemFocus} selected={item.isActive}>
					<button
						aria-expanded={item.isActive}
						aria-label={item.text}
						tabIndex={-1}
						onClick={onSelected}
						ref={ref}
					>
						<span>{item.text}</span>
						<Icon size={1} icon="chevron"></Icon>
					</button>
				</MegaMenuSiblingItem>
			);
		};

		const onSelected = () => {
			if (onSelect) {
				onSelect(item);
			}
		};

		const onItemFocus = () => {
			if (onFocus) {
				onFocus(item);
			}
		};

		if (item.children.length > 0) {
			return getItemWithSubItems(item, level);
		}

		return getLinkItem(item, level);
	}
);
