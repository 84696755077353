/**
 * Banner
 */
import React from 'react';
import {
	BannerLinksListItemStyle,
	BannerLinksListStyle,
	BannerLinksStyle,
} from './BannerLinks.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import BannerPanel from 'components/BannerPanel';
import Link from 'components/Boilerplate/Link';
import { Grid } from 'components/Boilerplate/Grid';

export interface BannerLinksProps {
	themeName?: PuffTheme;
	/**
	 * Heading level
	 */
	headingLevel?: number;
	/**
	 * Heading text
	 */
	heading?: string;
	/**
	 * Links
	 */
	links?: LinkType[];

	columns?: number;
}

/** This is the banner component */
const BannerLinks: React.FC<BannerLinksProps> = ({
	themeName,
	heading,
	headingLevel,
	links = [],
	columns = 2,
}) => {
	return (
		<BannerLinksStyle themeName={themeName}>
			<Grid paddingTop={false} paddingBottom={false}>
				{heading && (
					<BannerPanel
						headingLevel={headingLevel}
						themeName={themeName}
						heading={heading}
					></BannerPanel>
				)}
				{links?.length > 0 && (
					<BannerLinksListStyle columns={columns}>
						{links?.map((item, index) => {
							return (
								<BannerLinksListItemStyle key={index} themeName={themeName}>
									<Link to={item.url} external={item.isFile}>
										{item.heading}
									</Link>
								</BannerLinksListItemStyle>
							);
						})}
					</BannerLinksListStyle>
				)}
			</Grid>
		</BannerLinksStyle>
	);
};

export default BannerLinks;
