import EpiFragments from 'components/Boilerplate/EpiFragments';
import { BlockType, getLastItemBlockType, getSpaceToAddBefore } from 'components/Boilerplate/EpiFragments/EpiFragments';
import Space from 'components/Boilerplate/Space';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FragmentModelTypes } from 'types/fragments';
import { NVseStartPageModel } from './NVseStartPage.model';
import PageTopOverlappBanner from './PageTopOverlappBanner';

/**
 * # Startsida 
 * Modeltype:<code>NVseStartPage</code>
 * 
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1735753731/NVseStartPage)
 * 
 * Webbplatsens startsida
 */
const NVseStartPage: React.FC<NVseStartPageModel> = ({
	heading,
	preamble,
	modelType,
	image,
	bottomItems,
	linkList,
	epi,
	_properties = {},
	disableCustomHeadingLogic
}) => {

	const themeContext = useContext(ThemeContext);

	let lastBlockType = BlockType.Element;
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(bottomItems, themeContext, disableCustomHeadingLogic);
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space bottom={spaceAfterLast}>

			<PageTopOverlappBanner
				image={image}
				image_htmlAttributes={_properties?.image}
				heading={heading}
				headingLevel={1}
				heading_htmlAttributes={_properties?.heading}
				preamble={preamble}
				preamble_htmlAttributes={_properties?.preamble}
				linkList={linkList}
			></PageTopOverlappBanner>

			<EpiFragments
				modelType={modelType}
				previousBlockType={BlockType.Element}
				epi={epi}
				headingLevel={2}
				overrideSpacing={themeContext.spacing.space3}
				fragments={bottomItems as FragmentModelTypes[]}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default NVseStartPage;
