/**
 * NVseGrantsListingPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const Div = styled.div``;
export const FilterContainer = styled.div`
	background-color: ${props => props.theme.colors.blue1};
	padding: ${props => props.theme.spacing.space4}rem;
	padding-bottom: 0px;

	> div {
		display: inline-block;
		margin-bottom: ${props => props.theme.spacing.space4}rem;
	}

	> div:not(:last-child) {
		margin-right: ${props => props.theme.spacing.space4}rem;
	}

	> label {
		display: block;
		color: #222425;
		${props => props.theme.typography.style4.getStyle()};		
		margin-bottom: ${props => props.theme.spacing.space3}rem;

		${mediaQueries.phone} {
		font-size: ${px2rem(24)};
		}

	}
`;

export const PastGrantsContainer = styled.div``;
