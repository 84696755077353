/**
 * Image - styles
 */

import styled from 'styled-components';

export const Img = styled.img`
	display: block;
	width: 100%;
`;

export const Figure = styled.figure`
	margin: 0 0 1rem;
	margin-top: 2.5rem;
	margin-block-end: 0;

	${Img} {
		margin-bottom: 0;
	}
`;

export const FigCaption = styled.figcaption`
			${props => props.theme.typography.style13.getStyle()};		

	padding: 10px 0px;
	color: ${props => props.theme.palette.text.primary};			
	width: 100%;
`;
