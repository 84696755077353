/**
 * RadioButton
 */

import React, { useState, useEffect, useRef } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useFormValue } from '../context/Form.context';
import { RadioButtonProps } from '../types';
import { shouldBeChecked } from './Checkbox';



const RadioButton: React.FC<RadioButtonProps> = (props) => {
	const {
		id,
		name,
		defaultValue,
		onFieldChange,
		autoSubmit,
		value,
		single,
		showError,
		disabled,
		className,
		visibleFields,
		index,
	} = props;

	const [checked, setChecked] = useState('');

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		if (single) {
			const validatedField = validateField(checked);

			if (validatedField.message) {
				setValid(false);
				dispatch({ type: 'FIELD_INVALID', field: validatedField });
			} else {
				setValid(true);

				if (invalidFields.includes(validatedField.id)) {
					dispatch({ type: 'FIELD_VALID', field: validatedField });
				}
			}

			if (touched && !valid) {
				showFieldError(validatedField);
			} else if (
				(showError && !valid && visibleFields && visibleFields.includes(id)) ||
				(showError && !valid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}
		}
		// eslint-disable-next-line
	}, [touched, showError, invalidFields, visibleFields, checked]);

	const onChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;
		setChecked(target.value);
		if (!touched) {
			setTouched(true);
		}

		onFieldChange({
			name,
			value: target.value,
			type: 'RadioButton',
			autoSubmit,
		});
	};

	return (
		<input
			type="radio"
			id={index !== undefined && index >= 0 ? `${id}-${index}` : id}
			name={name}
			value={value}
			disabled={disabled}
			className={className}
			defaultChecked={shouldBeChecked(value, defaultValue)}
			ref={fieldRef}
			onChange={onChange}
			aria-describedby={`form${id}__desc`}
		/>

		// aria-invalid={single ? !valid : undefined}

	);
};

export default RadioButton;
