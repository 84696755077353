import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from 'components/Boilerplate/Icon';
import {
	DropdownMenuContainer,
	DropdownMenuStyle,
} from './DropdownMenu.styles';

/**
 * Dropdown menu
 */
export type DropdownMenuItem = {
	heading: string;
	value: string;
};

export type DropdownMenuProps = {
	heading?: string;
	items: DropdownMenuItem[];
	onSelect?: (value: string) => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
	heading,
	items,
	onSelect,
}) => {
	const [openState, setOpenState] = useState(false);
	const [focusIndex, setFocusIndex] = useState(0);

	const itemRefs = useMemo(() => {
		return [] as React.RefObject<any>[];
	}, []);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const toggleMenu = () => {
		if (!openState) {
			setFocusIndex(0);
		}
		setOpenState(!openState);
	};

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setOpenState(false);
			}
		}
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [wrapperRef]);

	useEffect(() => {
		if (focusIndex === -1 && buttonRef.current) {
			buttonRef.current.focus();
			return;
		}

		if (
			focusIndex >= 0 &&
			focusIndex <= itemRefs.length - 1 &&
			itemRefs[focusIndex] &&
			itemRefs[focusIndex].current
		) {
			itemRefs[focusIndex].current.focus();
		}
	}, [focusIndex, itemRefs, openState]);

	const onItemClicked = (value: string) => {
		if (onSelect) {
			onSelect(value);
		}

		setOpenState(false);
		setFocusIndex(-1);
	};

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		// TODO: KeyboardEvent.key, Browser not supported QQ and Baidu
		let newFocusIndex = 0;

		switch (event.key) {
			case 'Space':
			case 'Enter':
				event.preventDefault();

				if (openState && focusIndex >= 0) {
					onItemClicked(items[focusIndex].value);
				}
				toggleMenu();
				break;

			case 'ArrowDown':
				//  moves focus to the next item, optionally wrapping from the last to the first.
				event.preventDefault();
				newFocusIndex = focusIndex >= items.length - 1 ? 0 : focusIndex + 1;
				setFocusIndex(newFocusIndex);
				break;

			case 'ArrowUp':
				// moves focus to the previous item, optionally wrapping from the first to the last.
				event.preventDefault();
				newFocusIndex = focusIndex <= 0 ? items.length - 1 : focusIndex - 1;
				setFocusIndex(newFocusIndex);
				break;

			case 'ArrowRight':
				// Closes the submenu and any parent menus.
				event.preventDefault();
				setOpenState(false);
				setFocusIndex(-1);

				break;

			case 'ArrowLeft':
				// Closes the submenu and any parent menus.
				event.preventDefault();
				setOpenState(false);
				setFocusIndex(-1);
				break;

			case 'Home':
				event.preventDefault();
				setFocusIndex(0);
				break;
			case 'End':
				event.preventDefault();
				setFocusIndex(items.length - 1);
				break;
			case 'Tab':
				setOpenState(false);
				break;
			case 'Escape':
				setOpenState(false);
				setFocusIndex(-1);

				break;

			default:
				break;
		}
	};

	return (
		<DropdownMenuContainer onKeyDown={handleContainerKeyPress} ref={wrapperRef}>
			<button
				ref={buttonRef}
				onClick={toggleMenu}
				aria-haspopup="true"
				aria-expanded={openState}
			>
				{heading}
				<Icon size={1} icon="chevron"></Icon>
			</button>
			{openState && (
				<DropdownMenuStyle role="menu" aria-label="Export meny">
					{items.map((item, index) => {
						const ref = React.createRef<any>();
						itemRefs[index] = ref;

						return (
							<li key={index} role="none">
								<button
									ref={itemRefs[index]}
									tabIndex={index === focusIndex ? 1 : -1}
									role="menuitem"
									onClick={() => onItemClicked(item.value)}
								>
									{item.heading}
								</button>
							</li>
						);
					})}
				</DropdownMenuStyle>
			)}
		</DropdownMenuContainer>
	);
};

export default DropdownMenu;
