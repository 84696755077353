/* 
    DateSelectDropdown

    This component is used to create a dropdown for date range filter
*/

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Span } from 'components/Typography';
import Button from 'components/Button';
import { NumberRange } from 'pages/sharedModelTypes';
import {
	SelectContainer,
	SelectLabel,
	ButtonReset,
	CTAButtons,
	DateSelectDropdownStyle,
	Separator,
} from './DateSelectDropdown.styles';
import {
	SelectableYear,
	TimeInterval,
} from 'components/SearchByListngPage/SearchByListngPage.model';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

interface DateSelectDropdownProps {
	onSubmit: (range: any) => void;
	flexRow?: boolean;
	timeInterval: TimeInterval;
	/**optional property for mobile */
	selectedDates?: NumberRange;
}

export const DateSelectDropdown: React.FC<DateSelectDropdownProps> = ({
	timeInterval,
	selectedDates,
	onSubmit,
}) => {
	const {
		fromLabel,
		toLabel,
		btnResetLabel,
		btnAddLabel,
		selectableYears,
		selectYearLabel,
	} = timeInterval;

	const history = useHistory();

	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [state, setState] = useState<NumberRange>({ from: null, to: null });

	const [yearRanges, setYearRanges] = useState<SelectableYear[]>(
		timeInterval.selectableYears
	);

	useEffect(() => {
		setYearRanges(timeInterval.selectableYears);
	}, []);

	useEffect(() => {
		if (!isNarrowDisplay) udpatedYearRanges();
		else updateYearRangeStateMobile(selectableYears);
	}, [selectableYears]);

	const updateYearRangeStateMobile = (selectableYears: SelectableYear[]) => {
		// Find selected years
		const selectedYears = selectableYears.filter((el) => el.selected === true);

		// If exactly one year is selected, check for the ReleaseDate facet
		if (selectedYears.length === 1) {
			const urlParams = new URLSearchParams(history.location.search);
			const facets = urlParams.get('facets');

			// Extract ReleaseDate value if present
			if (facets) {
				const releaseDateFacet = facets
					.split(';')
					.find((facet) => facet.startsWith('ReleaseDate:'));
				const releaseDateValue = releaseDateFacet
					? releaseDateFacet.split(':')[1]
					: null;

				if (releaseDateValue) {
					const year = parseInt(releaseDateValue.slice(1)); // Extract the year number
					const type = releaseDateValue.charAt(0); // 'T' or 'F'

					setState({
						from: type === 'F' ? year : null,
						to: type === 'T' ? year : null,
					});
				}
			} else {
				setState({
					from: selectedDates && selectedDates.from ? selectedDates.from : null,
					to: selectedDates && selectedDates.to ? selectedDates.to : null,
				});
			}
		}
		// If more than one year is selected, return the default logic for mobile
		else {
			setState({
				from:
					[...selectableYears].reverse().find((el) => el.selected)?.year ??
					null,
				to: selectableYears.find((el) => el.selected)?.year ?? null,
			});
		}
	};

	const udpatedYearRanges = () => {
		const urlParams = new URLSearchParams(history.location.search);
		const facets = urlParams.get('facets');

		// Check if facets contain "ReleaseDate"
		if (facets && facets.includes('ReleaseDate')) {
			const newRangeState = { ...state };
			// Extract the years after "ReleaseDate:"
			const releaseDatePart = facets
				.split(';')
				.find((part) => part.includes('ReleaseDate'));

			// Get the date range or keyword after "ReleaseDate:"
			const dateRange = releaseDatePart?.split(':')[1];

			// Initialize variables to hold the start and end years
			let startYear = '';
			let endYear = '';

			if (dateRange?.includes('-')) {
				// Check if the range is in the format FYYYY-TYYYY
				startYear = dateRange.substring(1, 5);
				endYear = dateRange.substring(7, 11);
				newRangeState.from =
					[...selectableYears]
						.reverse()
						.find(
							(el) => el.selected === true && el.year === parseInt(startYear)
						)?.year ?? null;
				newRangeState.to =
					selectableYears.find(
						(el) => el.selected === true && el.year === parseInt(endYear)
					)?.year ?? null;
			} else {
				if (dateRange?.startsWith('F')) {
					startYear = dateRange.substring(1, 5);
					newRangeState.from =
						[...selectableYears]
							.reverse()
							.find(
								(el) => el.selected === true && el.year === parseInt(startYear)
							)?.year ?? null;
				} else if (dateRange?.startsWith('T')) {
					endYear = dateRange.substring(1, 5);
					newRangeState.to =
						selectableYears.find(
							(el) => el.selected === true && el.year === parseInt(endYear)
						)?.year ?? null;
				}
			}

			setState(newRangeState as NumberRange);
		}
	};

	const onStartYearRangeSelected = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const newRangeState = { ...state };

		if (event.target.value) {
			newRangeState.from = parseInt(event.target.value);
			if (newRangeState.to && newRangeState.from > newRangeState.to) {
				newRangeState.to = newRangeState.from;
			}
		} else {
			newRangeState.from = null;
		}

		setState(newRangeState as NumberRange);
		if (isNarrowDisplay) handleSubmitMobile(newRangeState);
	};

	const onEndYearRangeSelected = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const newRangeState = { ...state };
		if (event.target.value) {
			newRangeState.to = parseInt(event.target.value);
			if (newRangeState.from && newRangeState.to < newRangeState.from) {
				newRangeState.from = newRangeState.to;
			}
		} else {
			newRangeState.to = null;
		}

		setState(newRangeState as NumberRange);
		if (isNarrowDisplay) handleSubmitMobile(newRangeState);
	};

	const handleSubmitAndClose = () => {
		let newState = { ...state };
		/* if (state.from === null) {
			newState.from = selectableYears[0].year;
		}
		if (state.to === null) {
			newState.to = selectableYears[selectableYears.length - 1].year;
		} */

		onSubmit(newState);
	};
	const handleSubmitMobile = (newState: NumberRange) => {
		onSubmit(newState);
	};

	const handleReset = () => {
		setState({ from: null, to: null });
		onSubmit({ from: null, to: null });
	};

	return (
		<>
			<DateSelectDropdownStyle>
				<SelectContainer>
					<SelectLabel>{fromLabel}</SelectLabel>
					<Select
						aria-label={fromLabel}
						onChange={onStartYearRangeSelected}
						style={!isNarrowDisplay ? { maxWidth: '170px' } : {}}
						value={state.from ? state.from : ''}
					>
						<option value="">{selectYearLabel}</option>
						{yearRanges.map((year, index) => {
							return (
								<option value={year.year} key={index.toString() + 'from'}>
									{year.year}
								</option>
							);
						})}
					</Select>
				</SelectContainer>
				<Separator />
				<SelectContainer>
					<SelectLabel>{toLabel}</SelectLabel>
					<Select
						aria-label={toLabel}
						onChange={onEndYearRangeSelected}
						style={!isNarrowDisplay ? { maxWidth: '170px' } : {}}
						value={state.to ? state.to : ''}
					>
						<option value="">{selectYearLabel}</option>
						{yearRanges.map((year, index) => {
							return (
								<option value={year.year} key={index.toString() + 'to'}>
									{year.year}
								</option>
							);
						})}
					</Select>
				</SelectContainer>
			</DateSelectDropdownStyle>

			<CTAButtons>
				<ButtonReset
					id={btnResetLabel}
					onClick={handleReset}
					className={
						state.from === null && state.to === null
							? 'btnResetDisabledStyle'
							: ''
					}
					disabled={state.from === null && state.to === null}
				>
					{btnResetLabel}
				</ButtonReset>
				{!isNarrowDisplay && (
					<Button
						disabled={state.from === null && state.to === null}
						onClick={handleSubmitAndClose}
						className={
							state.from === null && state.to === null
								? 'btnAddStyle btnAddDisabledStyle'
								: 'btnAddStyle'
						}
					>
						{btnAddLabel}
					</Button>
				)}
			</CTAButtons>
		</>
	);
};
