import { ButtonVariant } from 'pages/sharedModelTypes';
import React from 'react';
import {
	ListPageShowMoreButton,
	ListPageShowMoreButtonContainer,
} from './ListPageShowMoreButton.styles';

type ShowMoreButtonProps = {
	header: string | null;
	onClick?: () => void;
};

export const ListingPageShowMoreButton: React.FC<ShowMoreButtonProps> = ({
	header,
	onClick,
}) => {
	return (
		<ListPageShowMoreButtonContainer>
			<ListPageShowMoreButton
				variant={ButtonVariant.Tertiary}
				onClick={onClick}
			>
				{header}
			</ListPageShowMoreButton>
		</ListPageShowMoreButtonContainer>
	);
};
