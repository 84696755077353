/**
 * Button
 */

import React from 'react';
import { ButtonProps } from '../types';

const Button: React.FC<ButtonProps> = ({
	type = 'button',
	onFieldChange,
	resetForm,
	showError,
	visibleFields,
	children,
	...propsToTransfer
}) => {
	if (type === 'reset') {
		return (
			<button type={type} {...propsToTransfer} onClick={resetForm}>
				{children}
			</button>
		);
	} else {
		return (
			<button type={type} {...propsToTransfer}>
				{children}
			</button>
		);
	}
};

export default Button;
