/**
 * Button - styles
 */

import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';
import Link from 'components/Boilerplate/Link';
import { styleForLink } from 'theme/styles';
import { hideIcon } from 'components/Boilerplate/Link/Link';
import { ButtonVariant, ButtonColors } from 'pages/sharedModelTypes';

export const getCommonStyle = () => {
	return css`
		border-radius: 24px;
		cursor: pointer;

		display: inline-flex;
		align-items: center;
		border: 1px solid transparent;
		${(props) => props.theme.typography.style11.getStyle()};

		padding: 0;

		padding-top: ${(props) => (props.theme.isMicrositeActive ? '8px' : '10px')};
		padding-bottom: ${(props) =>
			props.theme.isMicrositeActive ? '8px' : '10px'};

		padding-left: 16px;
		padding-right: 16px;

		svg {
			fill: inherit;
			margin-top: 0px;
			margin-bottom: 0px;

			margin-left: 2px;
			margin-right: 2px;
		}

		span {
			margin-top: ${(props) => (props.theme.isMicrositeActive ? '0' : '6px')};
			margin-bottom: ${(props) =>
				props.theme.isMicrositeActive ? '0' : '1px'};
			margin-left: 4px;
			margin-right: 4px;
		}
	`;
};

const getVariant = (
	variant: ButtonVariant | undefined,
	showLinkIcon: boolean | undefined
) => {
	switch (variant) {
		default:
		case ButtonVariant.Primary:
			return css`
				${getCommonStyle()};
				background-color: ${(props) => props.theme.colors.secondaryBlue};
				color: ${(props) =>
					props.theme.isMicrositeActive
						? props.theme.palette.primary
						: props.theme.colors.white};
				fill: ${(props) => props.theme.colors.white};

				&:hover {
					transition: background-color 100ms ease;
					background-color: ${(props) => props.theme.colors.secondaryBlue}a0;
				}

				&:focus {
					outline-color: ${(props) => props.theme.colors.secondaryBlue};
				}
			`;
		case ButtonVariant.Secondary:
			return css`
				${getCommonStyle()};
				background-color: ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.colors.secondaryBlue};
				fill: ${(props) => props.theme.colors.secondaryBlue};

				&:hover {
					transition: background-color 100ms ease;
					background-color: ${(props) => props.theme.colors.white}a0;
				}

				&:focus {
					outline-color: ${(props) => props.theme.colors.white};
				}
			`;
		case ButtonVariant.Tertiary:
			return css`
				${getCommonStyle()};

				background-color: ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.palette.text.primary};
				fill: ${(props) => props.theme.palette.text.primary};

				border-color: ${(props) => props.theme.colors.grey1};
				&:hover {
					fill: ${(props) => props.theme.palette.text.primary};
					transition: background-color 100ms ease;
					background-color: ${(props) => props.theme.colors.blue2}60;
				}
			`;
		case ButtonVariant.Link:
			if (showLinkIcon === false) {
				return css`
					${styleForLink};
					${hideIcon};
				`;
			}

			return styleForLink;
		case ButtonVariant.Microsite:
			return css`
				${getCommonStyle()};
				background-color: ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.palette.text.primary};
				fill: ${(props) => props.theme.palette.text.primary};

				border-color: ${(props) => props.theme.palette.text.primary};
				&:hover {
					transition: background-color 100ms ease;
					border: none;
					fill: ${(props) => props.theme.palette.text.secondary};
					background-color: ${(props) => props.theme.colors.primary};
					color: ${(props) => props.theme.palette.text.secondary};
				}

				&:focus {
					outline-color: 'currentColor';
				}
				span {
					padding-right: 2px;
				}
			`;
	}
};

const getLinkButtonStyle = (variant: ButtonVariant | undefined) => {
	if (variant === ButtonVariant.Link) {
		return css`
			display: inline-flex;
			span {
				margin-top: 2px;
				line-height: normal;
			}
			align-items: center;
		`;
	}

	return css`
		&:hover {
			text-decoration: none;
		}
	`;
};

export const StandardButton = styled.button<ExtendedButtonProps>`
	${({ variant, showLinkIcon }) => getVariant(variant, showLinkIcon)};

	${({ variant }) =>
		variant === ButtonVariant.Link &&
		`cursor:pointer;
		border:none;
		display: inline-flex;
		span {
			margin-top: 2px;
			line-height: normal;
		}
    	align-items: center;
		padding:0px;
		background-color:transparent;`}

	${({ $fill }) => $fill && `width:100%;max-width:none;text-align: center;`}

	${({ disabled, theme }) =>
		`
    opacity: ${disabled ? '0.35' : '1'};
    
    ${
			disabled &&
			!theme.isMicrositeActive &&
			`
        &:hover {
          color: ${theme.palette.text.primary};
          border: 1px solid transparent;
          cursor: not-allowed;
          box-shadow: none;
          transition: none;
          fill: none;
          border-color: ${theme.colors.grey1};
        }
      `
		}
  `}
`;

export const LinkButton = styled(Link)<ExtendedButtonProps>`
	${({ variant, showLinkIcon }) => getVariant(variant, showLinkIcon)};

	${({ variant }) => getLinkButtonStyle(variant)};

	${({ $fill }) => $fill && `width:100%;max-width:none;text-align: center;`}
`;

interface ExtendedButtonProps extends ButtonProps {
	$color?: ButtonColors; // transient props (we do want these to be rendered in the DOM)
	$fill?: boolean;
	showLinkIcon?: boolean;
}
