import LogoBanner from 'components/Banners/LogoBanner';
import React from 'react';
import { LogoBannerModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetLogoBannerModelElement = (
	modelData: LogoBannerModel,
	options?: Options
) => {

	return <LogoBanner key={options?.index}
		logoItems={modelData.logoItems}
		link={modelData.link}
		themeName={modelData.theme}
	></LogoBanner>;
};
