/**
 * MicrositeListingPage - styles
 */

import { listViewMode } from 'components/Panels/Panels';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';

const getListStyle = () => {
	return css`
		flex-direction: column;
		flex-wrap: nowrap;
	`;
};

export const ListItemsStyle = styled.div<listViewMode>`
	display: flex;
	flex-wrap: wrap;
	${(p) => p.cardView === false && getListStyle()};

	& > * {
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}

	${mediaQueries.phone} {
		> :last-child {
			margin-bottom: ${(props) =>
				props.theme.isMicrositeActive ? '1' : '0'}rem;
		}
	}

	${mediaQueries.tabletOrLarger} {
		margin-bottom: ${(props) =>
			props.theme.isMicrositeActive ? '0' : '-1'}rem;

		margin-right: -${(props) => props.theme.spacing.space2}rem;
		& > * {
			margin-bottom: ${(props) => props.theme.spacing.space2}rem;
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}
	}
`;
