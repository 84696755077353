import React, { useContext } from 'react';
import Space from 'components/Boilerplate/Space';
import { NVseStatisticsCategoryPageModel } from './NVseStatisticsCategoryPage.model';
import { Grid } from 'components/Boilerplate/Grid';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { ViewType } from 'types/enums';
import {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Kategorisida för statistik
 * Modeltype:<code>NVseStatisticsCategoryPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2108555334/NVseStatisticsCategoryPage)
 *
 * Sida för en unik kategori
 */
const NVseStatisticsCategoryPage: React.FC<NVseStatisticsCategoryPageModel> = ({
	heading,
	preamble,
	statisticsItemsHeading,
	allStatisticsItemsText,
	bottomItems,
	statisticsItems,
	textAsModel,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_textAsModel: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (statisticsItems.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_textAsModel = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (textAsModel.fragments.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			{statisticsItems.length > 0 && (
				<Space top={themeContext.spacing.getElement()}>
					<FragmentPuffList
						initialMaxCount={6}
						heading={statisticsItemsHeading}
						showMoreLabel={allStatisticsItemsText}
						items={statisticsItems}
						options={{
							insideCell: false,
							insideInnerGrid: false,
							insideGrid: false,
							headingLevel: 2, // TODO:
							view: ViewType.Card,
							themeContext: themeContext,
						}}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					></FragmentPuffList>
				</Space>
			)}

			<EditableField opeProperty={_properties?.mainBody}>
				{textAsModel && (
					<EpiFragments
						previousBlockType={blockTypeBefore_textAsModel}
						epi={epi}
						headingLevel={2}
						fragments={textAsModel.fragments}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				)}
			</EditableField>

			{bottomItems.length > 0 && (
				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}
		</Space>
	);
};

export default NVseStatisticsCategoryPage;
