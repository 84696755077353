/**
 * EpiFragments
 */

import React, { useContext } from 'react';
import { PuffWithImageModel } from 'types/blocks';
import {
	FragmentModelTypes,
	RawFragmentModel,
	HeadingFragmentModel,
	ImageFragmentModel,
	VideoFragmentModel,
	TableFragmentModel,
	BannerFragmentModel,
	YouTubeFragmentModel,
	FaqFragmentModel,
	MediaBannerFragmentModel,
	LegalBoxModel,
	LogoBannerModel,
	PuffContainerModel,
	CollapsibleContainerModel,
	StandardPuffModel,
	GrantPuffModel,
	EventPuffItemModel,
	GuidancePuffModel,
	HeroBannerModel,
	DashboardBlockModel,
	CollapsibleBlockModel,
	WidgetNumericBlockModel,
	StatisticsPuffModel,
	NumericBannerBlockModel,
	ContactBlockModel,
	PublicationPuffModel,
	HighlightModel,
	StatisticsBlockModel,
	SubjectAreaIconPuffModel,
	RegulationPuffModel,
	BlockBaseFragmentModelTypes,
	ContentBlockModel,
} from 'types/fragments';

import Space from 'components/Boilerplate/Space';
import { EpiEvent, ModelLocalization } from 'types/epi';
import { Cell, Grid } from '../Grid';
import { ThemeType } from 'theme/theme';
import { GetBannerModelElement } from './fragments/BannerModel';
import { GetMediaBannerElement } from './fragments/MediaBanner';
import { GetRawFragmentElement } from './fragments/RawFragment';
import { GetHeadingFragmentElement } from './fragments/HeadingFragment';
import { GetPuffWithLargeImageElement } from './fragments/PuffWithLargeImage';
import { GetImageElement } from './fragments/Image';
import { GetVideoElement } from './fragments/Video';
import { GetYouTubeModelElement } from './fragments/YouTubeModel';
import { GetTableFragmentElement } from './fragments/TableFragment';
import { GetLegalBoxModelElement } from './fragments/LegalBoxModel';
import { GetContentBlockModelElement } from './fragments/ContentBlockModel';
import { GetPuffContainerElement } from './fragments/PuffContainer';
import { GetCollapsibleContainerElement } from './fragments/CollapsibleContainer';
import { GetStandardPuffElement } from './fragments/StandardPuffModel';
import { GetSubjectAreaIconPuffElement } from './fragments/SubjectAreaIconPuffModel';
import { GetGrantPuffModelElement } from './fragments/GrantPuffModel';
import { GetEventPuffItemModelElement } from './fragments/EventPuffItemModel';
import { GetPublicationPuffModelElement } from './fragments/PublicationPuffModel';
import { GetFaqModelElement } from './fragments/FaqModel';
import { GetGuidancePuffModelElement } from './fragments/GuidancePuffModel';
import { GetHeroBannerModelElement } from './fragments/HeroBannerModel';
import { GetDashboardBlockModelElement } from './fragments/DashboardBlockModel';
import { GetCollapsibleBlockModelElement } from './fragments/CollapsibleBlockModel';
import { GetContactModelElement } from './fragments/ContactModel';
import { GetWidgetNumericBlockElement } from './fragments/WidgetNumericBlockModel';
import { GetLogoBannerModelElement } from './fragments/LogoBannerModel';
import { GetHighlightModelElement } from './fragments/HighlightModel';
import BannerTextWidget from 'components/Banners/BannerTextWidget';
import { GetStatisticsBlockModelElement } from './fragments/StatisticsBlockModel';
import { ViewType } from 'types/enums';
import EpiForms from 'components/EpiForms';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';
import { GetRegulationPuffModelElement } from './fragments/RegulationPuffModel';
import { selectLocalization } from 'store/modules/model';
import { useSelector } from 'react-redux';
import { generateAnchorId } from 'theme/utils';
import { ElementContainer } from 'components/Panels/PageOverviewPuff.styles';
import { ThemeContext } from 'styled-components';
import { PuffTheme } from 'pages/sharedModelTypes';

export enum BlockType {
	SectionNew = 'SectionNew', // New Section
	SectionBanner = 'SectionBanner', // New self contained section (0 space between them)
	Element = 'Element', // Element
	Item = 'Item', // ex. item in a list
	_Reserved_Heading = '_Reserved_Heading', // Only for: HeadingFragment
	_Reserved_HtmlContent = '_Reserved_HtmlContent', // Only for: RawFragment
	Unknown = 'Unknown',
}

type BlockInformation = {
	modelType: string;
	blockType: BlockType;
	renderElement: JSX.Element | null;
	hasFragments: boolean;
	fragments: FragmentModelTypes[];
};

const removeBottomMargin = (element: any) => {
	return <Space bottom={0}>{element}</Space>;
};

const wrapFragmentInSpace = (element: any, topSpace: number = 0) => {
	return <Space top={topSpace}>{element}</Space>;
};

export const wrapElementInGrid = (element: any) => {
	return (
		<Grid paddingTop={false} paddingBottom={false}>
			{element}
		</Grid>
	);
};

const wrapElementWithIdElement = (
	model: BlockBaseFragmentModelTypes,
	element: any
) => {
	if (model.onPageMenuVisibility && model.onPageMenuHeading) {
		const anchorId = generateAnchorId(model.onPageMenuHeading);
		return <ElementContainer id={anchorId}>{element}</ElementContainer>;
	}
	return element;
};

const wrapElementInInnerGrid = (element: any) => {
	return <Grid inner={true}>{element}</Grid>;
};

const wrapElementInCell = (element: any) => {
	return <Cell span={8}>{element}</Cell>;
};

export const wrapElement = (element: JSX.Element, options: Options) => {
	if (!options.insideCell) {
		element = wrapElementInCell(element);
	}

	if (!options.insideInnerGrid) {
		element = wrapElementInInnerGrid(element);
	}

	if (!options.insideGrid) {
		element = wrapElementInGrid(element);
	}

	return element;
};

export const getLastItemBlockType = (
	fragments: FragmentModelTypes[],
	themeContext: ThemeType,
	disableCustomHeadingLogic: boolean
): BlockType => {
	if (fragments.length > 0) {
		const options = {
			insideGrid: false,
			insideInnerGrid: false,
			insideCell: false,
			themeContext: themeContext,
		} as Options;
		const lastBlockMetaData = getFragmentMetadata(
			fragments[fragments.length - 1],
			options,
			disableCustomHeadingLogic
		);

		if (lastBlockMetaData.hasFragments) {
			return getLastItemBlockType(
				lastBlockMetaData.fragments,
				themeContext,
				disableCustomHeadingLogic
			);
		}

		return lastBlockMetaData.blockType;
	}

	return BlockType.Unknown;
};

export const getFirstItemBlockType = (
	fragments: FragmentModelTypes[],
	themeContext: ThemeType,
	disableCustomHeadingLogic: boolean
): BlockType => {
	if (fragments.length > 0) {
		const options = {
			insideGrid: false,
			insideInnerGrid: false,
			insideCell: false,
			themeContext: themeContext,
		} as Options;
		const lastBlockMetaData = getFragmentMetadata(
			fragments[0],
			options,
			disableCustomHeadingLogic
		);
		return lastBlockMetaData.blockType;
	}

	return BlockType.Unknown;
};

export const getSpaceToAddBefore = (
	previous: BlockType | null,
	current: BlockType | null,
	themeContext: ThemeType
): number => {
	if (!previous) return 0;

	const spaceBetween_Sections = themeContext.spacing.getSection(); // 5
	const spaceBetween_Elements = themeContext.spacing.getElement(); // 4
	const spaceBetween_TextAndElements = themeContext.spacing.getTextAndElement(); // 3
	const spaceBetween_Texts = themeContext.spacing.getText(); // 1

	// New section
	if (current === BlockType.SectionNew) {
		return spaceBetween_TextAndElements;
	}

	// New self contained section, lika a fullwith banner
	if (current === BlockType.SectionBanner) {
		// No space between two SectionBanners
		if (previous === BlockType.SectionBanner) {
			return 0;
		}

		// Default space between sections
		return spaceBetween_Sections;
	}

	// Element
	if (current === BlockType.Element) {
		if (previous === BlockType.Element) {
			return spaceBetween_Elements;
		}

		if (
			previous === BlockType.SectionNew ||
			previous === BlockType.SectionBanner
		) {
			return spaceBetween_Sections;
		}

		if (previous === BlockType._Reserved_Heading) {
			return spaceBetween_TextAndElements;
		}

		if (previous === BlockType._Reserved_HtmlContent) {
			return spaceBetween_Elements;
		}

		if (previous === BlockType.Item) {
			return spaceBetween_Elements;
		}

		return 0;
	}

	// ArticleHeading Rules
	if (current === BlockType._Reserved_Heading) {
		if (
			previous === BlockType.SectionNew ||
			previous === BlockType.SectionBanner
		) {
			return spaceBetween_Sections;
		}

		return spaceBetween_Elements;
	}

	// Raw (html) content
	if (current === BlockType._Reserved_HtmlContent) {
		if (
			previous === BlockType.SectionNew ||
			previous === BlockType.SectionBanner
		) {
			return spaceBetween_Sections;
		}

		if (previous === BlockType._Reserved_Heading) {
			return spaceBetween_Texts;
		}

		if (previous === BlockType.Element) {
			return spaceBetween_TextAndElements;
		}

		if (previous === BlockType.Item) {
			return spaceBetween_Elements;
		}

		return 0;
	}

	return 0;
};

export const getFragmentMetadata = (
	data: FragmentModelTypes,
	options: Options,
	disableCustomHeadingLogic: boolean,
	localization?: ModelLocalization | undefined
): BlockInformation => {
	const information = {
		modelType: data.modelType,
		blockType: BlockType.Unknown,
		renderElement: null,
		hasFragments: false,
		fragments: [],
	} as BlockInformation;

	/**
	 * Blocks that should get full browser widht
	 * The componeny may add Grid and or a Cell base on given options
	 */
	switch (data.modelType) {
		case 'HeroBannerModel': {
			const model = data as HeroBannerModel;
			information.renderElement = GetHeroBannerModelElement(model, options);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionBanner;
			return information;
		}
		case 'LogoBannerModel': {
			const model = data as LogoBannerModel;
			information.renderElement = GetLogoBannerModelElement(model);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionBanner;
			return information;
		}
		case 'BannerModel': {
			const model = data as BannerFragmentModel;

			information.renderElement = GetBannerModelElement(model, options);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionBanner;

			return information;
		}
		case 'NumericBannerBlockModel': {
			const model = data as NumericBannerBlockModel;
			information.renderElement = (
				<BannerTextWidget
					themeName={model.theme}
					textToRight={model.textToRight}
					headingLevel={options.headingLevel}
					heading={model.heading}
					preamble={model.preamble}
					text={model.text}
					subtext={model.subtext}
					link={model.link}
					trend={model.trend}
				></BannerTextWidget>
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionBanner;
			return information;
		}
		case 'FaqModel': {
			const model = data as FaqFragmentModel;
			information.renderElement = GetFaqModelElement(model);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'ContentBlockModel': {
			const model = data as ContentBlockModel;
			information.renderElement = GetContentBlockModelElement(
				model,
				options,
				disableCustomHeadingLogic
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);

			information.hasFragments =
				model.bottomItems.length > 0 || model.textAsModel.fragments.length > 0;
			// Easy but not correct way to get last item blocktype from textAsModel and bottomItems,
			// We can not be 100% sure that bottomItems is rendered last.
			information.fragments = [
				...model.textAsModel.fragments,
				...model.bottomItems,
			];
			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'CollapsibleContainerModel': {
			const model = data as CollapsibleContainerModel;
			information.renderElement = GetCollapsibleContainerElement(
				model,
				options,
				disableCustomHeadingLogic
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'MediaBanner': {
			const model = data as MediaBannerFragmentModel;
			information.renderElement = GetMediaBannerElement(model, options);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'DashboardBlockModel': {
			const model = data as DashboardBlockModel;
			information.renderElement = GetDashboardBlockModelElement(
				model,
				options,
				disableCustomHeadingLogic
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);

			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'StatisticsBlockModel': {
			const model = data as StatisticsBlockModel;
			information.renderElement = GetStatisticsBlockModelElement(
				model,
				options
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.blockType = BlockType.SectionNew;
			return information;
		}
		case 'PuffContainer': {
			const model = data as PuffContainerModel;
			information.renderElement = GetPuffContainerElement(
				data as PuffContainerModel,
				options,
				disableCustomHeadingLogic
			);
			information.renderElement = wrapElementWithIdElement(
				model,
				information.renderElement
			);
			information.hasFragments = model.puffItems.length > 0;
			information.fragments = model.puffItems;
			information.blockType = BlockType.SectionNew;
			return information;
		}
	}

	/**
	 * Blocks that should exist in a cell (centered page with)
	 * If not then add needed Cell and Grid element
	 */
	switch (data.modelType) {
		case 'HeadingFragment': {
			let element = GetHeadingFragmentElement(
				data as HeadingFragmentModel,
				{
					...options,
					insideCell: true,
					insideInnerGrid: true,
					insideGrid: true,
				},
				disableCustomHeadingLogic
			);

			element = removeBottomMargin(element);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType._Reserved_Heading;
			return information;
		}
		case 'RawFragment': {
			let element = GetRawFragmentElement(data as RawFragmentModel);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType._Reserved_HtmlContent;
			return information;
		}
		case 'ContactModel': {
			let element = GetContactModelElement(data as ContactBlockModel);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'YouTubeModel': {
			let element = GetYouTubeModelElement(data as YouTubeFragmentModel);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'LegalBoxModel': {
			let element = GetLegalBoxModelElement(
				data as LegalBoxModel,
				{
					...options,
					insideCell: true,
					insideInnerGrid: true,
					insideGrid: true,
				},
				disableCustomHeadingLogic
			);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'CollapsibleBlockModel': {
			let element = GetCollapsibleBlockModelElement(
				data as CollapsibleBlockModel,
				{
					...options,
					insideCell: true,
					insideInnerGrid: true,
					insideGrid: true,
				},
				disableCustomHeadingLogic
			);

			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'TableFragment': {
			let element = GetTableFragmentElement(data as TableFragmentModel);
			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'FormContainer': {
			const model = data as EpiFormsProps;
			const opt = {
				...options,
				insideCell: true,
				insideInnerGrid: true,
				insideGrid: true,
			};
			let element = <EpiForms key={options.index} {...model} options={opt} />;
			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'HighlightModel': {
			let element = GetHighlightModelElement(
				data as HighlightModel,
				{
					...options,
					insideCell: true,
					insideInnerGrid: true,
					insideGrid: true,
				},
				disableCustomHeadingLogic
			);
			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
		case 'Image': {
			let element = GetImageElement(data as ImageFragmentModel, localization);
			information.renderElement = wrapElement(element, options);
			information.blockType = BlockType.Element;
			return information;
		}
	}

	/***
	 * Block that should not add any margins and grids around them
	 * These block can for example be placed in a cell or grid or something else
	 */
	switch (data.modelType) {
		case 'GuidancePuffModel': {
			let element = GetGuidancePuffModelElement(data as GuidancePuffModel, {
				...options,
				insideCell: true,
				insideInnerGrid: true,
				insideGrid: true,
			});

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'GrantPuffModel': {
			let element = GetGrantPuffModelElement(data as GrantPuffModel, options);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'EventPuffItemModel': {
			let element = GetEventPuffItemModelElement(
				data as EventPuffItemModel,
				options
			);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'StatisticsPuffModel': {
			let element = GetGuidancePuffModelElement(
				data as StatisticsPuffModel,
				options
			);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'MicrositeArticlePuffModel': {
			let element = GetStandardPuffElement(data as StandardPuffModel, options);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'StandardPuffModel': {
			let element = GetStandardPuffElement(data as StandardPuffModel, options);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'SubjectAreaIconPuffModel': {
			let element = GetSubjectAreaIconPuffElement(
				data as SubjectAreaIconPuffModel,
				options
			);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'WidgetNumericBlockModel': {
			let element = GetWidgetNumericBlockElement(
				data as WidgetNumericBlockModel
			);

			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'Video': {
			// Not currently used
			let element = GetVideoElement(data as VideoFragmentModel);
			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'PublicationPuffModel': {
			let element = GetPublicationPuffModelElement(
				data as PublicationPuffModel,
				options
			);
			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'RegulationPuffModel': {
			let element = GetRegulationPuffModelElement(
				data as RegulationPuffModel,
				options,
				localization
			);
			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
		case 'PuffWithLargeImage': {
			// Not currently used
			let element = GetPuffWithLargeImageElement(data as PuffWithImageModel);
			information.renderElement = element;
			information.blockType = BlockType.Item;
			return information;
		}
	}

	/***
	 * Fallback
	 */
	information.renderElement = (
		<Space bottom={0}>
			<Grid paddingTop={false} paddingBottom={false}>
				<p style={{ color: 'red' }}>
					There is an unsupported fragment type '{data.modelType}', Please
					report this to the system administrator.
				</p>
			</Grid>
		</Space>
	);
	information.blockType = BlockType.Unknown;
	return information;
};

export type Options = {
	index?: number; // todo Move this to Props
	epi?: EpiEvent; // todo Move this to Props
	headingLevel: number; // What level we are on.
	insideGrid: boolean;
	insideInnerGrid: boolean;
	insideCell: boolean;
	view: ViewType; // Prefered view type
	modelType?: string; // Used to know if it's the startpage
	themeContext: ThemeType;
};

interface EpiFragmentsProps {
	fragments?: FragmentModelTypes[];
	previousBlockType?: BlockType | null;
	headingLevel?: number; // What level we are on.
	insideGrid?: boolean;
	insideInnerGrid?: boolean;
	insideCell?: boolean;
	view?: ViewType; // Prefered view type
	modelType?: string; // Used to know if it's the startpage
	index?: number; // todo: check usage
	overrideSpacing?: number;
	epi?: EpiEvent; // todo: check usage
	htmlAttributes?: any;
	disableCustomHeadingLogic?: boolean;
	puffTheme?: PuffTheme;
}

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<EpiFragmentsProps> = ({
	fragments,
	previousBlockType = null,
	insideGrid = false,
	insideInnerGrid = false,
	insideCell = false,
	modelType,
	headingLevel = 1,
	view,
	overrideSpacing,
	htmlAttributes,
	disableCustomHeadingLogic,
}) => {
	const localization = useSelector(selectLocalization);
	const themeContext = useContext<ThemeType>(ThemeContext);

	if (!fragments) {
		return null;
	}

	const options = {
		insideGrid: insideGrid,
		insideInnerGrid: insideInnerGrid,
		insideCell: insideCell,
		headingLevel: headingLevel,
		view: view,
		modelType: modelType,
		index: 0,
		themeContext: themeContext,
	} as Options;

	let content = fragments.map((fragment, index) => {
		const elementInformation = getFragmentMetadata(
			fragment,
			{
				...options,
				index,
			},
			disableCustomHeadingLogic ?? false,
			localization
		);

		let element = elementInformation.renderElement;

		let spaceBefore = getSpaceToAddBefore(
			previousBlockType,
			elementInformation.blockType,
			themeContext
		);

		if (spaceBefore > 0) {
			// If previous block is SectionBanner, then ignore overrideSpacing
			// this is not an optimal solution.
			spaceBefore =
				overrideSpacing !== undefined &&
				previousBlockType !== BlockType.SectionBanner &&
				elementInformation.blockType !== BlockType.SectionBanner
					? overrideSpacing
					: spaceBefore;

			element = wrapFragmentInSpace(element, spaceBefore);
		}

		previousBlockType = elementInformation.blockType;

		return <React.Fragment key={index}>{element}</React.Fragment>;
	});

	// Wrap element in a div with needed attributes when editing the page inside epi-server
	if (htmlAttributes) {
		return <div {...htmlAttributes}>{content}</div>;
	}

	return <>{content}</>;
};

export default EpiFragments;
