import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getFirstItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { Heading } from 'components/Typography/Typography';
import {
	getThemeHeadingColor,
	LinkType,
	PuffTheme,
} from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { generateAnchorId } from 'theme/utils';
import { FragmentListModel, ImageFragmentModel } from 'types/fragments';
import { convertDateToLocalFormat } from 'utils/helper-utils';
import { MetadataGridContainer } from './NVsePublicationPage.styles';
import {
	MetadataContainer,
	MetadataThumbnail,
	MetadataThumbnailContainer,
} from './PublicationMetadataBanner.styles';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { ThemeContext } from 'styled-components';

export type PublicationMetadataBannerProps = {
	thumbnail: ImageFragmentModel | null;
	link: LinkType;
	downloadLinks: LinkType[];
	aboutReportLabel: string;
	description: string | null;
	descriptionAsModel: FragmentListModel;
	pagesLabel: string;
	pages: number;
	releaseDateLabel: string;
	releaseDate: string;
	isbnLabel: string;
	isbn: string;
	authorsLabel: string;
	authors: string;

	heading: string;
	headingLevel?: number;
	disableCustomHeadingLogic: boolean;
};

export const PublicationMetadataBanner: React.FC<PublicationMetadataBannerProps> = ({
	heading,
	headingLevel,
	descriptionAsModel,
	isbn,
	isbnLabel,
	releaseDate,
	releaseDateLabel,
	pagesLabel,
	pages,
	authors,
	authorsLabel,
	thumbnail,
	downloadLinks,
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	const anchorId = generateAnchorId(heading);
	const renderTable =
		!isbn && (!pages || pages === 0) && !releaseDate && !authors === true
			? false
			: true;

	let firstBlockType = BlockType.Element;
	if (descriptionAsModel && descriptionAsModel.fragments.length > 0) {
		firstBlockType = getFirstItemBlockType(
			descriptionAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceBeforeThumbnail = getSpaceToAddBefore(
		BlockType._Reserved_Heading,
		firstBlockType,
		themeContext
	);

	return (
		<MetadataContainer id={anchorId}>
			<Grid paddingTop={false} paddingBottom={false}>
				<Grid inner={true}>
					<Cell span={7}>
						{heading && (
							<Heading
								zeroBottom
								level={headingLevel}
								styleLevel={2}
								color={getThemeHeadingColor(
									themeContext,
									PuffTheme.NeutralWhite
								)}
							>
								{heading}
							</Heading>
						)}
					</Cell>
				</Grid>
				<Grid inner={true}>
					<Cell span={7}>
						{descriptionAsModel && descriptionAsModel.fragments.length > 0 && (
							<EpiFragments
								previousBlockType={BlockType._Reserved_Heading}
								insideCell={true}
								insideGrid={true}
								insideInnerGrid={true}
								headingLevel={headingLevel ? headingLevel + 1 : 2}
								fragments={descriptionAsModel.fragments}
								disableCustomHeadingLogic={disableCustomHeadingLogic}
							/>
						)}

						{renderTable && (
							<Space top={themeContext.spacing.space3}>
								<MetadataGridContainer>
									{isbn && (
										<>
											<div>{isbnLabel}</div>
											<div>{isbn}</div>
										</>
									)}

									{releaseDate && (
										<>
											<div>{releaseDateLabel}</div>
											<div>{convertDateToLocalFormat(releaseDate)}</div>
										</>
									)}

									{pages !== null && pages !== 0 && (
										<>
											<div>{pagesLabel}</div>
											<div>{pages}</div>
										</>
									)}

									{authors && (
										<>
											<div>{authorsLabel}</div>
											<div>{authors}</div>
										</>
									)}
								</MetadataGridContainer>
							</Space>
						)}

						{downloadLinks &&
							downloadLinks.length > 0 &&
							downloadLinks.map((item) => {
								return (
									<Space top={themeContext.spacing.space3}>
										<Button
											showLinkIcon={false}
											iconName="arrowdown"
											iconColor={`${themeContext.colors.secondaryBlue}60`}
											iconPosition={1}
											variant={ButtonVariant.Link}
											to={item.url}
											external={item.isFile}
										>
											{item.heading}
										</Button>
									</Space>
								);
							})}
					</Cell>

					<Cell span={2}> </Cell>
					<Cell span={3} phone={4}>
						{thumbnail && (
							<Space top={spaceBeforeThumbnail}>
								<MetadataThumbnailContainer>
									<MetadataThumbnail {...thumbnail}></MetadataThumbnail>
								</MetadataThumbnailContainer>
							</Space>
						)}
					</Cell>
				</Grid>
			</Grid>
		</MetadataContainer>
	);
};
