/**
 * FjallStartPage
 */

import React, { useContext } from 'react';
import EpiFragments, {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { FjallStartPageModel } from './FjallStartPage.model';
import Space from 'components/Boilerplate/Space';
import PageTopOverlappBanner from 'pages/NVseStartPage/PageTopOverlappBanner';
import { ThemeContext } from 'styled-components';
import { FragmentModelTypes } from 'types/fragments';

/** Main description for this component. */
const FjallStartPage: React.FC<FjallStartPageModel> = ({
	heading,
	preamble,
	modelType,
	image,
	bottomItems,
	linkList,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	let lastBlockType = BlockType.Element;
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);
	return (
		<Space bottom={spaceAfterLast}>
			<PageTopOverlappBanner
				image={image}
				image_htmlAttributes={_properties?.image}
				heading={heading}
				headingLevel={1}
				heading_htmlAttributes={_properties?.heading}
				preamble={preamble}
				preamble_htmlAttributes={_properties?.preamble}
				linkList={linkList}
				isMicrositeActive={themeContext.isMicrositeActive}
			></PageTopOverlappBanner>

			<EpiFragments
				modelType={modelType}
				previousBlockType={BlockType.Element}
				epi={epi}
				headingLevel={2}
				overrideSpacing={themeContext.spacing.space3}
				fragments={bottomItems as FragmentModelTypes[]}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default FjallStartPage;
