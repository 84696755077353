/**
 * NVsePublicationsListingPage - styles
 */

import {
	styleForInput,
	styleForLabel,
	styleForSelect,
} from 'components/Typography';
import styled from 'styled-components';

export const Div = styled.div``;

export const IndexLabel = styled.h2`
	${(props) => props.theme.typography.style2.getStyle()};
`;
export const PublicationHeading = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SearchContainer = styled.div`
	border: 2px solid red;
	display: none;
`;

export const SearchLabel = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};

	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
`;

export const SearchWrapper = styled.div`
	justify-content: space-between;
	display: flex;

	input {
		${(props) => props.theme.typography.style8.getStyle()};
		flex: 1;
		vertical-align: top;
		border-radius: 8px;
		border: 2px solid #afafaf;
		background-color: ${(props) => props.theme.colors.white};
		padding-left: ${(props) => props.theme.spacing.space2}rem;
		padding-right: ${(props) => props.theme.spacing.space2}rem;
		padding-top: 7px;
		padding-bottom: 9px;
		margin-right: ${(props) => props.theme.spacing.space2}rem;
	}
`;

export const LabelStyle = styled.label`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
`;

export const FormStyle = styled.form`
	input {
		${styleForInput}
		margin-right: ${(props) => props.theme.spacing.space1}rem;
	}

	label,
	legend {
		${styleForLabel}
		margin-right: ${(props) => props.theme.spacing.space1}rem;
	}

	select {
		${styleForSelect}
		margin-right: ${(props) => props.theme.spacing.space1}rem;
	}
`;

export const FieldsetInline = styled.div`
	padding: 0px;
	border: none;
	display: inline-flex;
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
`;

export const InputContainerFieldSet = styled.fieldset`
	border: none;
	padding: 0px;
	display: flex;
	display: flex;
	align-items: flex-end;
	> :first-child {
		flex: 1 1 auto;
	}
`;

export const InlineControlls = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${(props) => props.theme.spacing.space2}rem;
`;

export const CheckboxesInline = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	align-items: center;
`;

export const PaginationContainer = styled.div`
	display: flex;
`;

export const CategoriesContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
	margin-right: ${(props) => props.theme.spacing.space1}rem;
`;

export const RadioButtonContainer = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	ul:first-child {
		padding-right: 0.5rem;
	}
	span {
		color: ${(props) => props.theme.palette.text.primary};
	}
`;
