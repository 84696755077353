/**
 * Space
 */

import styled from 'styled-components';

export interface SpaceProps {
	/** Spacing after the component (top-bottom) */
	top?: number;

	/** Spacing after the component (margin-bottom) */
	bottom?: number;

	/** Spacing right of the component (margin-right) */
	right?: number;

	/** Spacing left of the component (margin-left) */
	left?: number;

	/** Spacing between the children */
	stack?: number;
}

/** Space component for handling spacing between other components. */
const Space = styled.div<SpaceProps>`
	${({ top }) =>
		top !== undefined &&
		`
			> *:first-child {
				margin-top: ${top}rem;
			}
		`}

	${({ bottom }) =>
		bottom !== undefined &&
		`
			> *:last-child {
				margin-bottom: ${bottom}rem;
			}
		`}
	
	${({ right }) =>
		right !== undefined &&
		`
			margin-right: ${right}rem;
			> * {
				margin-right: 0;
			}
		`}

	${({ left }) =>
		left !== undefined &&
		`
			margin-left: ${left}rem;
			> * {
				margin-left: 0;
			}
		`}

	${({ stack }) =>
		stack !== undefined &&
		`
			> * + * {
				margin-top: ${stack}rem;
			}
		`}
`;

export default Space;
