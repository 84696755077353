import React from 'react';
import { TableFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';
import { ScrollableTableWrapper } from 'components/Typography';

export const GetTableFragmentElement = (
	tableData: TableFragmentModel,
	options?: Options
) => {
	return (
		<ScrollableTableWrapper
			dangerouslySetInnerHTML={{ __html: tableData.raw }}
		/>
	);
};
