/**
 * BannerTextWidget
 */
import React from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import {
	BannerTextWidgetStyle,
	BannerWidgetContainerStyle,
	BannerWidgetSubTextStyle,
	BannerWidgetTextStyle,
} from './BannerTextWidget.styles';
import { breakpointsNumber } from 'theme/media-queries';
import { useMediaQuery } from 'react-responsive';
import BannerPanel from 'components/BannerPanel';
import {
	ColumnContainerDevider,
	ColumnContainerWithDevider,
} from 'components/Panels/Panels.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';

export interface BannerTextWidgetProps {
	themeName?: PuffTheme;
	textToRight?: boolean;
	/**
	 * Heading text
	 */
	headingLevel?: number;
	heading?: string | null;
	preamble?: string | null;
	text?: string | null;
	subtext?: string | null;
	link?: LinkType | null;
	trend?: number | null;
}

const BannerTextWidget: React.FC<BannerTextWidgetProps> = ({
	themeName,
	headingLevel,
	heading,
	preamble,
	link,
	text,
	subtext,
	trend,
	textToRight = false,
}) => {
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	let widgetText = text;

	if (widgetText) {
		if (trend === 1) {
			widgetText = `${widgetText}\u25b4`;
		}
		if (trend === -1) {
			widgetText = `${widgetText}\u25be`;
		}
	}

	return (
		<BannerTextWidgetStyle themeName={themeName}>
			<Grid paddingTop={false} paddingBottom={false}>
				<ColumnContainerWithDevider textToRight={textToRight}>
					<BannerWidgetContainerStyle>
						{widgetText && (
							<BannerWidgetTextStyle themeName={themeName}>
								<span>{widgetText}</span>
							</BannerWidgetTextStyle>
						)}
						{subtext && (
							<BannerWidgetSubTextStyle themeName={themeName}>
								{subtext}
							</BannerWidgetSubTextStyle>
						)}
					</BannerWidgetContainerStyle>

					<ColumnContainerDevider
						themeName={themeName}
					></ColumnContainerDevider>

					<BannerPanel
						center={isPhone}
						themeName={themeName}
						headingLevel={headingLevel}
						heading={heading}
						text={preamble}
						link={link}
					></BannerPanel>
				</ColumnContainerWithDevider>
			</Grid>
		</BannerTextWidgetStyle>
	);
};

export default BannerTextWidget;
