import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import initStore from 'store';
import { initialState as reactInitialState } from 'store/modules/react';
import Application from './App';
import { Model, RenderingStates } from 'types/epi';
import { unsetNavigationLinksIsActive } from 'store/modules/model';

// Set default rendermethod and a basic model.
// eslint-disable-next-line
let renderMethod = ReactDOM.render;
let model = {
	head: {},
	epi: {},
	react: reactInitialState, // ModelReact
} as Model;

// Replace our basic model if one exitst on the window-object,
// then we also change rendermethod to hydrate.
if (window.__model) {
	renderMethod = ReactDOM.hydrate;
	model = window.__model as Model;
	if (model.react) {
		model.react.renderingState = RenderingStates.Hydrate;
	}

	if (model.navigation && model.navigation.items) {
		unsetNavigationLinksIsActive(model.navigation.items);
	}
}

let react = model.react;
delete model.react;

const preloadedState = {
	react,
	epi: model.epi,
	model: { model, sessionStorage: [] },
};

// Render the application to the DOM
if (react) {
	const element = document.getElementById(react.containerId);
	if (element) {
		renderMethod(
			<Provider store={initStore(preloadedState)}>
				<Application Router={BrowserRouter} />
			</Provider>,
			element
		);
	}
}
