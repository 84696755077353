/**
 * MultiSelectCheckbox
 */

import React, { useEffect, useState } from 'react';
import {
	FilterCheckboxGroup,
	HideCheckbox,
	StyledCheckbox,
} from './MultiSelectCheckbox.styles';
import { Icon } from 'components/Checkbox/Checkbox.styles';

export type MultiSelectCheckboxProps = {
	checked?: boolean;
	disabled?: boolean;
	children?: any;
	required?: boolean;
	name?: string;
	value?: any; // Used for FormData
	id?: string;
	hits?: number;
	onCheckedChanged?: (checked: boolean) => void;
	focusStyle?: string;
};

/** Multi Filter Checkbox */
const MultiSelectCheckbox = React.forwardRef<any, MultiSelectCheckboxProps>(
	(
		{
			checked = false,
			children,
			onCheckedChanged,
			required,
			value,
			disabled,
			id,
			hits,
			focusStyle,
			...props
		},
		ref
	) => {
		const [checkedState, setCheckedState] = useState(checked);

		const handleCheckChange = (event: any) => {
			if (!disabled) {
				setCheckedState((prevValue) => !prevValue);
				if (onCheckedChanged) {
					onCheckedChanged(event);
				}
			}
		};

		useEffect(() => {
			setCheckedState(checked);
		}, [checked]);

		return (
			<FilterCheckboxGroup
				disabled={hits === 0}
				checked={checkedState}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						handleCheckChange(event);
					}
				}}
				className={focusStyle}
			>
				{children}
				<HideCheckbox
					ref={ref}
					checked={checkedState}
					value={value}
					{...props}
					onChange={handleCheckChange}
					data-filter-typeof={id}
					disabled={hits === 0 && !checked}
				/>
				<StyledCheckbox
					checked={checkedState}
					disabled={hits === 0 && !checked}
					className="checkbox"
				>
					<Icon viewBox="3 3 18 18">
						<polyline points="18 6 11 16 5 12" />
					</Icon>
				</StyledCheckbox>
			</FilterCheckboxGroup>
		);
	}
);

export default MultiSelectCheckbox;
