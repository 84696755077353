/**
 * SimpleExpander
 */

import Icon from 'components/Boilerplate/Icon';
import { PuffTheme } from 'pages/sharedModelTypes';
import React, { useEffect, useState } from 'react';
import {
	SimpleExpanderContent,
	SimpleExpanderDevider,
	SimpleExpanderHeader,
	SimpleExpanderHeaderContent,
} from './SimpleExpander.styles';

export type SimpleExpanderContainerProps = {
	isOpen: boolean;
};

export type SimpleExpanderStyleProps = {
	isEnabled?: boolean;
	isOpen?: boolean;
	themeName?: PuffTheme;
};

export interface SimpleExpanderProps {
	heading: string | JSX.Element | null;
	isOpen?: boolean;
	isEnabled?: boolean;
	ariaLabel: string;
	id?: string;
	themeName?: PuffTheme | null;
}

/** A ExpanderPrototype component. */
export const SimpleExpander: React.FC<SimpleExpanderProps> = ({
	heading,
	isOpen = true,
	isEnabled = true,
	children,
	id = 'expander',
	ariaLabel,
	themeName,
}) => {
	const [isOpenState, setIsOpenState] = useState(isOpen);

	const elementId = `${id}-content`;
	useEffect(() => {
		setIsOpenState(isOpen);
	}, [isOpen]);

	const headerClicked = () => {
		setIsOpenState(!isOpenState);
	};

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		switch (event.key) {
			case 'Enter':
			case ' ':
				event.preventDefault();
				setIsOpenState(!isOpenState);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<SimpleExpanderHeader
				aria-expanded={isOpenState}
				aria-controls={elementId}
				tabIndex={isEnabled ? 0 : -1}
				role={isEnabled ? 'button' : undefined}
				aria-disabled={!isEnabled}
				onKeyDown={isEnabled ? handleContainerKeyPress : undefined}
				onClick={isEnabled ? headerClicked : undefined}
				isOpen={isOpenState}
				isEnabled={isEnabled}
			>
				<SimpleExpanderHeaderContent isOpen={isOpenState}>
					{heading !== null && heading}
					{isEnabled && children && (
						<Icon color="currentColor" icon="chevron" size={1} />
					)}
				</SimpleExpanderHeaderContent>
			</SimpleExpanderHeader>

			{!isOpenState && (
				<SimpleExpanderDevider themeName={themeName}></SimpleExpanderDevider>
			)}

			{isOpenState && (
				<>
					<SimpleExpanderContent
						role="region"
						aria-label={ariaLabel}
						id={elementId}
						isOpen={isOpenState}
					>
						{children}
					</SimpleExpanderContent>
				</>
			)}
		</>
	);
};

export default SimpleExpander;
