import React from 'react';

import { checkDependency } from 'components/EpiForms/EpiForms.dependencies';
import { checkFieldActions } from 'components/EpiForms/EpiForms.actions';
import {
	Field,
	TextArea,
	Select,
	Multiselect,
	RadioButton,
	Checkbox,
	Fieldset,
	Button,
	Label,
	ErrorSpan,
} from 'components/Form';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	Options,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { DescriptionSpan, FieldContainer } from 'components/Form/Labels';

interface Field {
	id: string;
	label: string;
	required?: boolean;
	[htmlAttributes: string]: any;
}

interface RenderFunction {
	(field: Field): JSX.Element | null;
}

export const useFormRenderer = (
	fields: any,
	state: any,
	dependencies: any,
	disableCustomHeadingLogic: boolean,
	options?: Options
) => {
	const { invalidFields, validationMessages, isLoading, values } = state;
	let fieldActionExists = false;

	const renderFormElement = (fieldName: string) => {
		const field = fields[fieldName];
		const { name, text } = fields[fieldName];
		let { type } = fields[fieldName];

		if (type === null) {
			type = 'text';
		}

		const renderAsFieldset =
			(type === 'radio' || type === 'checkbox') && field.options.length > 1;
		const singleCheckboxOrRadio =
			(type === 'radio' || type === 'checkbox') && field.options.length === 1;

		const fieldHasDependency =
			dependencies &&
			dependencies.filter((field: any) => field.fieldName === name).length > 0;

		if (fieldHasDependency) {
			const fieldShouldBeRendered = checkDependency(name, dependencies, values);

			if (!fieldShouldBeRendered) {
				return;
			}
		}

		if (renderAsFieldset) {
			return (
				<FieldContainer field={field} key={name}>
					{renderFieldset(field)}
				</FieldContainer>
			);
		} else if (singleCheckboxOrRadio) {
			return (
				<FieldContainer field={field} key={name}>
					{renderSingleInput(field)}
					{renderFieldError(field.name)}
				</FieldContainer>
			);
		} else if (type === 'paragraphtext') {
			return (
				<FieldContainer field={field}>
					<EpiFragments
						key={name}
						previousBlockType={BlockType.Unknown}
						fragments={field.paragraphText.fragments}
						insideCell={options?.insideCell}
						insideGrid={options?.insideGrid}
						insideInnerGrid={options?.insideInnerGrid}
						headingLevel={
							options?.headingLevel ? options?.headingLevel + 1 : undefined
						}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				</FieldContainer>
			);
		} else if (type === 'ParagraphText') {
			return (
				<FieldContainer field={field}>
					<div key={name} dangerouslySetInnerHTML={{ __html: text }} />
				</FieldContainer>
			);
		} else {
			return (
				<FieldContainer field={field} key={name} id={`form${name}`}>
					{field.type !== 'multiselect' && renderLabel(field)}
					{field.type !== 'multiselect' &&
						renderDescriptionField(field.description)}
					{renderField(field)}
					{renderFieldError(field.name)}
				</FieldContainer>
			);
		}
	};

	const renderLabel: RenderFunction = ({ name, label, required, type }) => {
		if (!label || type === 'submit' || type === 'reset') {
			return null;
		}

		return (
			<Label htmlFor={name} required={required}>
				{label}
			</Label>
		);
	};

	const renderDescriptionField = (description: string) => {
		if (description) {
			return <DescriptionSpan>{description}</DescriptionSpan>;
		}
	};

	const renderFieldError = (id: string) => {
		const validationMessage =
			validationMessages[id] && validationMessages[id].message;
		const isInvalid = invalidFields.indexOf(id) !== -1;

		return (
			<ErrorSpan fieldId={id} invalid={isInvalid}>
				{validationMessage}
			</ErrorSpan>
		);
	};

	const renderField: RenderFunction = ({
		name,
		type,
		required,
		pattern,
		placeHolder,
		disabled,
		description,
		min,
		max,
		label,
		failedMessage,
		patternMessage,
		options,
		fieldActions,
		allowMultiple,
	}) => {
		let buttonText = label || type;

		switch (type) {
			case 'submit':
			case 'reset':
				if (type === 'submit' && isLoading) {
					buttonText = 'Skickar...';
				}

				return (
					<Button type={type} name={name} disabled={isLoading} id={name}>
						{buttonText}
					</Button>
				);
			case 'textarea':
				return (
					<TextArea
						id={name}
						name={name}
						description={description}
						required={required}
						placeholder={placeHolder}
						disabled={disabled}
						label={label}
						validationMessage={failedMessage}
					/>
				);
			case 'select':
				return (
					<Select
						id={name}
						name={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
					/>
				);
			case 'multiselect':
				return (
					<Multiselect
						id={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
						isInvalid={invalidFields.indexOf(name) !== -1}
					/>
				);
			case 'text':
			default:
				let actions: any = {};

				if (fieldActions) {
					actions = checkFieldActions(fieldActions, values);
					fieldActionExists = true;
				}
				return (
					<Field
						type={type}
						id={name}
						name={name}
						pattern={pattern}
						required={(actions && actions.required) || required}
						placeholder={placeHolder}
						description={description}
						label={label}
						min={min}
						max={max}
						disabled={disabled}
						validationMessage={failedMessage}
						patternMessage={patternMessage}
						multiple={allowMultiple}
					/>
				);
		}
	};

	const renderFieldset: RenderFunction = ({
		name,
		type,
		options,
		label,
		required,
		description,
		validationMessage,
	}) => {
		const isInvalid = invalidFields.indexOf(name) !== -1;

		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<Fieldset
				id={name}
				description={description}
				required={required}
				label={label}
				isInvalid={isInvalid}
				validationMessage={validationMessage}
			>
				{options.map((opt: any, index: number) => {
					const element = (
						<FieldComponent
							id={name}
							key={index}
							index={index}
							name={name}
							value={opt.value}
							label={opt.caption}
						/>
					);

					if (type === 'radio') {
						return (
							<div key={`${name}-${index}`} className="radio">
								{element}
								<label htmlFor={`${name}-${index}`}>{opt.caption}</label>
							</div>
						);

						// return (
						// 	<label htmlFor={`${name}-${index}`} key={`${name}-${index}`}>
						// 		{element}
						// 		<span>{opt.caption}</span>
						// 	</label>
						// );
					}

					return element;
				})}
			</Fieldset>
		);
	};

	const renderSingleInput: RenderFunction = ({
		name,
		type,
		options,
		required,
		disabled,
		description,
		validationMessage,
	}) => {
		const fieldLabel = options[0].caption;
		const fieldValue = options[0].value;

		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		const element = (
			<FieldComponent
				id={name}
				name={name}
				value={fieldValue}
				required={required}
				disabled={disabled}
				single={true}
				label={fieldLabel}
				validationMessage={validationMessage}
			/>
		);
		if (type === 'radio') {
			return (
				<label htmlFor={name} title={description}>
					{element}
					<span>
						{fieldLabel}
						{required && <abbr title="Obligatoriskt">*</abbr>}
					</span>
				</label>
			);
		}

		return element;
	};

	return [renderFormElement, fieldActionExists];
};
