/**
 * Highcharts - styles
 */

import { styleForSelect } from 'components/Typography';
import styled, { css } from 'styled-components';
import { ChartVisibleProps, HighchartsLegendItem } from './Highcharts';

// TODO: Hardcoded widh for selected box
export const HighchartsStyle = styled.div`
	select {
		${styleForSelect}
		width: 130px;
		margin-bottom: ${(props) => props.theme.spacing.space1}rem;
	}
`;

export const HighchartsContentStyle = styled.div`
	display: flex;
`;

const showIndex = (chartMode: boolean) => {
	if (chartMode) {
		return css`
			> :nth-child(2) {
				display: none !important;
			}
		`;
	}

	return css`
		> :nth-child(1) {
			display: none !important;
		}
		> :nth-child(2) {
			overflow: auto;
		}
	`;
};

export const HighchartAndTable = styled.div<ChartVisibleProps>`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	> div {
		flex: 1;
	}

	${(p) => showIndex(p.showChartMode)}

	table {
		width: 100%;
		${(props) => props.theme.typography.style11.getStyle()};

		border-collapse: collapse;
		white-space: nowrap;
	}

	.text {
		text-align: left;
	}

	.highcharts-number {
		text-align: right;
		font-weight: normal;
	}

	table,
	td,
	th {
		border: 1px solid ${(p) => p.theme.colors.black}15;
	}

	thead > tr > th {
		text-align: center;
		font-weight: bold;
	}

	tbody > tr > th {
		font-weight: normal;
	}

	td,
	th {
		padding: ${(props) => props.theme.spacing.space1}rem;
	}

	table > caption {
		padding-bottom: ${(props) => props.theme.spacing.space1}rem;
	}

	table > thead {
	}
`;

export const HighchartsDebugStyle = styled.div`
	margin: 10px;
	border: 1px solid red;
`;

export const HighchartsLegendsStyle = styled.div`
	flex: 0 0 auto;

	margin-left: ${(props) => props.theme.spacing.space2}rem;
	& > label {
		display: inline-block;
		${(props) => props.theme.typography.style12.getStyle()};
		text-transform: uppercase;
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}

	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
	}

	li {
		padding: 2px ${(props) => props.theme.spacing.space2}rem;

		label {
			font-size: 14px;
		}
	}

	li:nth-child(odd) {
		background-color: #0000000d;
	}
`;

export const HighchartsLegendsActions = styled.div`
	flex: 1 1 auto;
	> :not(:last-child) {
		margin-right: ${(props) => props.theme.spacing.space1}rem;
	}

	margin-bottom: ${(props) => props.theme.spacing.space4}rem;

	button {
		${(props) => props.theme.typography.style11.getStyle()};
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 8px;
		padding-right: 8px;

		border: 1px solid rgba(0, 0, 0, 0.32);
		border-radius: 8px;

		min-width: 146px;
		justify-content: center;
	}
`;

export const HighchartsLegendsSymbols = styled.div<HighchartsLegendItem>`
	display: inline-block;
	margin-right: 8px;
	font-family: 'Courier New', monospace;
	color: ${(p) => p.symbolColor};
`;
