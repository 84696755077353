import Link from 'components/Boilerplate/Link';
import { H2 } from 'components/Typography';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from 'theme/styles';
import { px2rem } from 'theme/utils';

export const IndexSectionWrapper = styled.div`
	margin-bottom: 2.5rem;
	${mediaQueries.phone} {
		margin-bottom: ${(props) => props.theme.spacing.space4}rem;
	}
`;
export const IndexSection = styled.ul`
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
	${mediaQueries.phone} {
		grid-template-columns: 1fr;
		gap: 8px;
	}

	a {
		height: 100%;
		color: ${(props) => props.theme.palette.text.primary};
	}
`;

export const IndexHeading = styled(H2)`
	${(props) => props.theme.typography.style2.getStyle()};
	margin-bottom: ${(props) => props.theme.spacing.space4}rem;
	${mediaQueries.phone} {
		margin-bottom: ${(props) => props.theme.spacing.space3}rem;
	}
	border-bottom: 1px solid #003366;
	padding-bottom: 0.5rem;
`;

export const IndexSectionItem = styled(Link)`
	display: flex;
	&:focus {
		${accessibilityFocus()};
		> div:nth-child(2) {
			> span {
				text-decoration: underline;
			}
		}
	}
	> div:first-child {
		width:${px2rem(80)};
		min-height:${px2rem(80)};
        padding: 10px;
        background-color:${(props) => props.theme.colors.blue1};
        display:flex;
        justify-content: center;
        align-items: center;
        > img {
				max-height: 60px;
				max-width: 60px;
			}
        }
	}
	> div:nth-child(2) {
		flex-grow:1;
        display:flex;
        justify-content: start;
        padding:${(props) => props.theme.spacing.space3}rem;
        align-items: center;
        background-color:${(props) => props.theme.colors.white};
		${mediaQueries.phone} {
			  padding:${px2rem(18)};
		}
        > span {
			${(props) => props.theme.typography.style4.getStyle()};
			color: ${(props) => props.theme.palette.text.primary};
			margin:0;
			${mediaQueries.tablet} {
				font-size: 22px;
			}
		}
`;
