/**
 * PuffCardMobile - styles
 */

import styled, { css } from 'styled-components';
import { getLinkArrowStyle } from 'theme/styles';
import Link from 'components/Boilerplate/Link';
import { CommonDecalStyle } from 'components/Panels/Panels.styles';
import { Heading } from 'components/Typography/Typography';
import { px2rem } from 'theme/utils';
import { mediaQueries } from 'theme';

export const PuffCardMobileStyle = styled(Link)`
	display: flex;
	flex-direction: row-reverse;
	flex-grow: 1;

	padding-bottom: ${(props) => props.theme.spacing.space1}rem;
	border-bottom: 1px solid ${(props) => props.theme.colors.black}20;
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;

	&:after {
		content: none;
	}

	&:hover {
		text-decoration: none;
	}
`;

// TODO: Do we need object-fit: contain;
export const PuffCardMobileImageStyle = styled.div`
	flex-basis: 123px;
	min-width: 123px;
	align-self: start;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
`;

export const PuffCardMobileTimeStyle = styled.span`
	font-size: ${px2rem(14)};
	color: ${(props) => props.theme.palette.text.primary};
`;

// TODO: Do we need object-fit: contain;
export const PuffCardMobileTextContainerStyle = styled.div<TimeBool>`
	flex-grow: 1;

	padding: 0 ${(props) => props.theme.spacing.space2}rem;
	align-self: center;
	margin-top: ${(props) => props.hasDate && '-10px'};
`;

export const PuffCardMobileDecalStyle = styled(CommonDecalStyle)``;

export type CompactType = {
	compact: boolean;
};

export type TimeBool = {
	hasDate?: boolean;
};

export const getHeaderCompactStyle = (compact: boolean) => {
	if (!compact) {
		return css`
			&:after {
				content: none;
			}
			${PuffCardMobileStyle}:hover &,${PuffCardMobileStyle}:focus & {
				text-decoration: none;
			}
		`;
	}

	return css`
		${getLinkArrowStyle()};

		${PuffCardMobileStyle}:hover &,${PuffCardMobileStyle}:focus & {
			text-decoration: underline;
			&:after {
				opacity: 1;
			}
		}
	`;
};

export const PuffCardMobileHeaderStyle = styled(Heading)<CompactType>`
	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
	}

	${({ compact }) => getHeaderCompactStyle(compact)}
`;

export const PuffCardMobilePreambleStyle = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space1}rem;
	max-width: 65ch;

	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
`;

export const PuffCardMobileMetadataStyle = styled.div`
	margin-top: ${(props) => props.theme.spacing.space2}rem;
`;

export const PuffCardMobileLinkStyle = styled.div`
	${(props) => props.theme.typography.style6.getStyle()};
	color: ${(props) => props.theme.colors.secondaryBlue};
	margin-top: ${(props) => props.theme.spacing.space1}rem;

	${mediaQueries.phone} {
		font-size: ${px2rem(16)};
	}

	${getLinkArrowStyle()};

	${PuffCardMobileStyle}:hover &,${PuffCardMobileStyle}:focus & {
		text-decoration: underline;
		&:after {
			opacity: 1;
		}
	}
`;
