import React, { useEffect, useState, useRef } from 'react';
import {
	AccordionContainer,
	AccordionItem,
	AccordionHeader,
	AccordionContent,
	AccordionIcon,
	AccordianPills,
} from './AccordionFilter.styles';
import Icon from 'components/Boilerplate/Icon';
import {
	FacetModel,
	FacetOption,
	FacetTypeOf,
} from 'components/SearchByListngPage/SearchByListngPage.model';
import { H5 } from 'components/Typography';
import MultiSelectCheckbox from 'components/FilterDropdown/MultiSelectCheckbox';
import { ChildrenWrapper } from 'components/FilterDropdown/MultiSelectCheckbox.styles';
import SingleSelectCheckbox from 'components/FilterDropdown/SingleSelectCheckbox';
import {
	CheckboxesInline,
	TimeIntervalDropdown,
} from 'components/FilterDropdown/FilterDropdown.styles';
import { DateSelectDropdown } from 'components/FilterDropdown/DateSelectDropdown';
import { NumberRange } from 'pages/sharedModelTypes';
import Checkbox from 'components/Checkbox';

interface FacetsTypes {
	[key: string]: string[];
}
interface Props {
	facetModels?: FacetModel[];
	facets: FacetsTypes;
	onChange?: (filter: any, type: FacetTypeOf) => void;
	onDateSelectChanged?: (range: NumberRange, filterTypeOf: string) => void;
	selectedDates?: NumberRange;
	onCheckboxChanged?: (checked: boolean, type: string) => void;
}

const AccordionFilter: React.FC<Props> = ({
	facetModels,
	facets,
	selectedDates,
	onChange,
	onDateSelectChanged,
	onCheckboxChanged,
}) => {
	interface FacetObj {
		[key: string]: boolean;
	}
	/**maintain an object with boolean values for accordian to close and open each section */
	const initFiltersByKey = () => {
		const obj: FacetObj = {};
		facetModels?.forEach((i) => {
			obj[i.key] = false;
		});
		return obj;
	};

	const dateSelectorSSF =
		facetModels
			?.find((facet) => facet.key === 'ReleaseDate')
			?.facetOptions.find(
				(option) => option.key !== 'selectableYears' && option.selected
			)?.key || '';

	/**now we setting initial ssf value expecting there will be only one singleSelect facetmodel - need to rewrite to accomdodate more singleSelect facetmodels */
	const ssfIintial =
		facetModels
			?.find((facet) => facet.facetType === 'singleSelect')
			?.facetOptions.find((option) => option.selected)?.key || '';

	const [fKey, setFKey] = useState(initFiltersByKey());
	const [sSF, setSSF] = useState<string | null>(ssfIintial);
	const [dateSSF, setDateSSF] = useState<string | null>(dateSelectorSSF);
	const wrapperRef = useRef<HTMLDivElement>(null);

	/**function to toggle dropdown options */
	const toggleFOpts = (modelKey: string) => {
		setFKey((prevK) => {
			const updFKey = { ...prevK };
			updFKey[modelKey] = !prevK[modelKey];

			for (const filterKey in updFKey) {
				if (filterKey !== modelKey) {
					updFKey[filterKey] = false;
				}
			}
			return updFKey;
		});
	};

	/* toggle select single filter */
	const toggleSSF = (event: any, type: FacetTypeOf) => {
		const key = event.target.value;
		setSSF((prevKey) => (prevKey === key ? null : key));
		if (onChange) onChange(event, type);
	};

	/* toggle select single filter */
	const toggleDateSSF = (event: any, type: FacetTypeOf) => {
		const key = event.target.value;
		setDateSSF((prevKey) => (prevKey === key ? null : key));
		if (onChange) onChange(event, type);
	};

	const handleDateSelectChanged = (range: any, filterTypeOf: string) => {
		// ask here if you want to close the dropdown
		// ask if there is other filter selected (help from backend)
		setDateSSF('');
		if (onDateSelectChanged) {
			onDateSelectChanged(range, filterTypeOf);
		}
	};

	return (
		<AccordionContainer ref={wrapperRef}>
			{facetModels &&
				facetModels.map((facet) => (
					<AccordionItem key={facet.key}>
						<AccordionHeader
							isOpen={fKey[facet.key]}
							aria-expanded={fKey[facet.key]}
							onClick={() => toggleFOpts(facet.key)}
							activePills={facets[facet.key]?.length > 0}
						>
							<H5>{facet.name}</H5>
							<AccordionIcon
								isOpen={fKey[facet.key]}
								tabIndex={0}
								aria-label={`${fKey[facet.key]} ? exapnd ${
									facet.name
								}: collapse ${facet.name}`}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										toggleFOpts(facet.key);
									}
								}}
							>
								<Icon size={2} aria-hidden={true} icon="chevron" />
							</AccordionIcon>
						</AccordionHeader>
						{!fKey[facet.key] && facets[facet.key]?.length > 0 && (
							<AccordianPills>
								{facets[facet.key]?.map((item, index) => {
									// Check if the facet key is "ReleaseDate" and if timeInterval is not null and atleast one year is selected
									const isReleaseDateWithTimeInterval =
										facet.key === 'ReleaseDate' &&
										facet.facetOptions.some(
											(option) =>
												option.timeInterval !== null &&
												option?.timeInterval?.selectableYears &&
												option.timeInterval.selectableYears.some(
													(year) => year?.selected === true
												) &&
												!facet.facetOptions.some(
													(option) =>
														option.key !== 'selectableYears' &&
														option.selected === true
												)
										);

									// If it's a ReleaseDate with a time interval, parse the item and replace F and T with fromLabel and toLabel
									if (isReleaseDateWithTimeInterval) {
										const timeIntervalOption = facet.facetOptions.find(
											(option) => option.timeInterval
										);

										if (timeIntervalOption && timeIntervalOption.timeInterval) {
											const fromLabel =
												timeIntervalOption.timeInterval.fromLabel ||
												'Från och med';
											const toLabel =
												timeIntervalOption.timeInterval.toLabel ||
												'Till och med';

											const formattedItem =
												item.includes('F') && item.includes('T')
													? item
															.replace(/F(\d{4})/g, ` $1`)
															.replace(/T(\d{4})/g, ` $1`)
															.replace(/-/g, ' - ')
													: item
															.replace(/F(\d{4})/g, `${fromLabel} $1`)
															.replace(/T(\d{4})/g, `${toLabel} $1`)
															.replace(/-/g, ' - ');

											return <span key={index}>{formattedItem}</span>;
										}
									}
									if (facet.key == 'IsOfficialStatistics') {
										const facetOption = facet.facetOptions.find(
											(option) => option.selected
										);
										const displayName = facetOption ? facetOption.name : '';
										return <span key={index}>{displayName}</span>;
									}
									// Default rendering for non-ReleaseDate facets
									const facetOption = facet.facetOptions.find(
										(option) => option.key === item
									);

									const displayName = facetOption ? facetOption.name : item;

									return <span key={index}>{displayName}</span>;
								})}
							</AccordianPills>
						)}
						{fKey[facet.key] && facet.facetType === FacetTypeOf.multiSelect && (
							<AccordionContent>
								{facet.facetOptions &&
									facet.facetOptions.map((option: FacetOption) => (
										<MultiSelectCheckbox
											key={option.key}
											checked={option.selected}
											onCheckedChanged={(event) =>
												onChange && onChange(event, FacetTypeOf.multiSelect)
											}
											id={facet.key}
											value={option.key}
											name={option.key}
											hits={option.hits}
										>
											<ChildrenWrapper>
												<span>{option.name}</span>
												<span>({option.hits})</span>
											</ChildrenWrapper>
										</MultiSelectCheckbox>
									))}
							</AccordionContent>
						)}
						{fKey[facet.key] && facet.facetType === FacetTypeOf.singleSelect && (
							<AccordionContent>
								{facet.facetOptions &&
									facet.facetOptions.map(
										(option: FacetOption, index: number) => (
											<SingleSelectCheckbox
												key={option.key + index.toString()}
												checked={sSF === option.key}
												onCheckedChanged={(event) =>
													toggleSSF(event, FacetTypeOf.singleSelect)
												}
												id={facet.key}
												value={option.key}
												name={option.key}
											>
												<span>{option.name}</span>
											</SingleSelectCheckbox>
										)
									)}
							</AccordionContent>
						)}
						{fKey[facet.key] && facet.facetType === FacetTypeOf.dateSelect && (
							<AccordionContent>
								{facet.facetOptions &&
									facet.facetOptions.map(
										(option: FacetOption, index: number) => {
											const isFirst = index === 0;
											const isLast = index === facet.facetOptions.length - 1;

											return (
												<>
													{!option.timeInterval && (
														<SingleSelectCheckbox
															key={option.name + index.toString() + 'dsf'}
															checked={dateSSF == option.key}
															onCheckedChanged={(event) =>
																toggleDateSSF(event, FacetTypeOf.singleSelect)
															}
															id={facet.key}
															value={option.key}
															name={option.key}
															focusStyle={
																isFirst ? 'first' : isLast ? 'last' : ''
															}
														>
															<span className="name">{option.name}</span>
														</SingleSelectCheckbox>
													)}

													{option.timeInterval && (
														<TimeIntervalDropdown>
															<span>{option.name}</span>

															<DateSelectDropdown
																onSubmit={(range) =>
																	handleDateSelectChanged &&
																	handleDateSelectChanged(range, facet.key)
																}
																timeInterval={option.timeInterval}
																selectedDates={selectedDates}
															/>
														</TimeIntervalDropdown>
													)}
												</>
											);
										}
									)}
							</AccordionContent>
						)}
						{fKey[facet.key] && facet.facetType === FacetTypeOf.bool && (
							<CheckboxesInline>
								<Checkbox
									checked={facet.facetOptions[0].selected}
									onCheckedChanged={(value: boolean) => {
										onCheckboxChanged && onCheckboxChanged(value, facet.key);
									}}
								>
									{facet.facetOptions[0].name}
								</Checkbox>
							</CheckboxesInline>
						)}

						<span className="accordion-border"></span>
					</AccordionItem>
				))}
		</AccordionContainer>
	);
};

export default AccordionFilter;
