/**
 * SiteHeader - styles
 */

import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { ActiveNode } from './SiteHeader';
import { accessibilityFocus, resetAccessibilityFocus } from 'theme/styles';
import Link from 'components/Boilerplate/Link';

export const Div = styled.div``;

export const SiteHeaderContainer = styled.header`
	background-color: ${(props) => props.theme.colors.white};
	border-bottom: 1px solid rgba(151, 151, 151, 0.21);
`;

export const SiteHeaderContent = styled.div`
	display: flex;
	padding-top: ${(props) => props.theme.spacing.space1}rem;
	padding-bottom: ${(props) => props.theme.spacing.space1}rem;
`;

export const SiteHeaderLogoLink = styled(Link)`
	flex: 0 0 auto;
	margin-right: ${(props) => props.theme.spacing.space3}rem;
	img {
		height: 73px;
	}
`;

export const SiterHeaderMenuAndSearch = styled.div`
	display: flex;
	flex: 1 1 auto;
	gap: ${(props) => props.theme.spacing.space1}rem;
	align-items: center;

	${mediaQueries.phone} {
		justify-content: flex-end;
	}

	button {
		text-transform: ${(props) =>
			props.theme.isMicrositeActive ? 'none' : 'uppercase'};

		${mediaQueries.phone} {
			border: none;
			padding: ${(props) => (props.theme.isMicrositeActive ? '2px' : '0')};
		}
	}
`;
export const SiteHeaderMenuAndQuickLinks = styled.nav`
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	gap: ${(props) => props.theme.spacing.space1}rem;

	${mediaQueries.phone} {
		order: 2;
		flex: 0 0 auto;
	}
`;

export const QuickLinksList = styled.ul`
	margin: 0px;
	margin-left: ${(props) => (props.theme.isMicrositeActive ? '16px' : '0')};
	padding: 0px;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	justify-content: ${(props) =>
		props.theme.isMicrositeActive ? 'space-evenly' : 'flex-start'};
`;

function isActiveStyle() {
	return css`
		border-bottom: 5px solid ${(props) => props.theme.colors.primary};
		a:hover {
			text-decoration: none;
		}
	`;
}

export const QuickLinksListItem = styled.li<ActiveNode>`
	display: inline-block;
	align-self: center;
	order: 1;
	text-transform: ${(props) =>
		props.theme.isMicrositeActive ? 'none' : 'uppercase'};

	margin: 0 ${(props) => props.theme.spacing.space1}rem;

	margin-top: 2px; // Quickfix woraround of button error
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;

	${({ isActive }) => isActive && isActiveStyle()};

	> a {
		${(props) =>
			props.theme.isMicrositeActive
				? props.theme.typography.style6.getStyle()
				: props.theme.typography.style11.getStyle()};
		text-decoration: none;
		color: ${(props) => props.theme.palette.text.primary};
		&:focus {
			${resetAccessibilityFocus()}
		}
	}
	&:focus-within {
		${accessibilityFocus()}
	}
`;

export type transitionState = {
	state: any;
};

export const SearchContainer = styled.div<transitionState>`
	position: absolute;
	padding-top: ${(props) => props.theme.spacing.space4}rem;
	padding-bottom: ${(props) => props.theme.spacing.space3}rem;
	left: 0;
	right: 0;
	width: 100vw;
	z-index: 1;
	background-color: ${(props) =>
		props.theme.isMicrositeActive
			? props.theme.colors.commonBgColor
			: props.theme.colors.white};
	box-shadow: 0px 5px 5px #00000020;
	border-bottom: 1px solid rgba(151, 151, 151, 0.21);
	padding-bottom: ${(props) => props.theme.spacing.space5}rem;

	transition: transform 200ms ease-in-out;
	transform: ${({ state }) => {
		switch (state) {
			case 'entering':
			case 'entered':
				return 'translateY(0)';
			case 'exiting':
			case 'exited':
				return 'translateY(-100%)';
		}
	}};
`;

export const SearchOverlay = styled.div<transitionState>`
	position: fixed;
	z-index: 10;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	font-weight: 400 !important;

	transition: background-color 200ms ease-in-out;
	background-color: ${({ state }) => {
		switch (state) {
			case 'entering':
			case 'entered':
				return 'rgba(0, 0, 0, 0.17);';
			case 'exiting':
			case 'exited':
				return 'rgba(0, 0, 0, 0);';
		}
	}};
`;

export const SearchBackgroundOverlayStyle = styled.div<ActiveNode>`
	${({ isActive }) => isActive && 'background-color:rgba(0, 0, 0, 0.17);'};
	transition: background-color 200ms ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
`;

export const BreadcrumbsContainer = styled.div`
	background-color: ${(props) =>
		props.theme.isMicrositeActive
			? props.theme.colors.bgBreadcrumbs
			: props.theme.colors.white};
	border-bottom: 1px solid rgba(151, 151, 151, 0.21);
`;
