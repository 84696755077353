import {
	SectionItem,
	SectionTitleUnderline,
	SideBarSectionContainer,
	SideBarSectionTitle,
} from './JobDetailSideBarItem.styles';
import JobDetailSideBarItemModel from 'pages/NVseJobDetailPage/components/JobDetailSideBarItem/JobDetailSideBarItem.model';
import Button from 'components/Button';

const JobDetailSideBarItem = ({ title, items, button }: JobDetailSideBarItemModel) => {
	if (title != null && items != null) {
		return (
			<SideBarSectionContainer>
				<div>
					<SideBarSectionTitle>{title}</SideBarSectionTitle>
					<SectionTitleUnderline />
				</div>
				<div>
					{items.filter(item => item.description).map((item, index) => (
						<SectionItem key={index}>
							<h3>{item.title}</h3>
							<p>{item.description}</p>
						</SectionItem>
					))}
				</div>
				{button.text && (
					<div>
						<Button to={button.url} showLinkIcon={false}>{button.text}</Button>
					</div>
				)}
			</SideBarSectionContainer>
		);
	}

	return null;
};

export default JobDetailSideBarItem;
