/**
 * EventListItem
 */

import { CommonDecalStyle } from 'components/Panels/Panels.styles';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
	capitalizeFirstLetter,
	getLongWeekdayName,
	getShortTimeName,
} from 'utils/helper-utils';
import {
	CalendarDateContainer,
	CalendarEventItemLinkContainer,
	CalendarEventItemInformationContainer,
	CalendarInformaitonDuration,
	CalendarHeading,
} from './EventListItem.styles';
import { CalendarEvent } from './NVseEventsListingPage';

const EventListItem: React.FC<CalendarEvent> = ({
	decal,
	heading,
	startDate,
	duration,
	link,
	headingLevel,
}) => {

	const themeContext = useContext(ThemeContext);

	return (
		<CalendarEventItemLinkContainer to={link.url} aria-label={heading}>
			<CalendarDateContainer>
				<span>{capitalizeFirstLetter(getLongWeekdayName(startDate))}</span>
				<span>{startDate.getDate()}</span>
				<span>{getShortTimeName(startDate)}</span>
			</CalendarDateContainer>

			<CalendarEventItemInformationContainer>
				{decal && <CommonDecalStyle>{decal}</CommonDecalStyle>}
				{duration && (
					<CalendarInformaitonDuration>
						{duration}
					</CalendarInformaitonDuration>
				)}
				{heading && (
					<CalendarHeading level={headingLevel} zeroBottom={true} styleLevel={4} color={themeContext.colors.secondaryBlue}>
						{heading}
					</CalendarHeading>
				)}

			</CalendarEventItemInformationContainer>
		</CalendarEventItemLinkContainer>
	);
};

export default EventListItem;
