import PuffListItem from 'components/PuffListItem';
import StandardPuff from 'components/StandardPuffModel';
import { LinkType } from 'pages/sharedModelTypes';
import { ViewType } from 'types/enums';
import { StandardPuffModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetStandardPuffElement = (
	standardPuffModel: StandardPuffModel,
	options: Options
) => {
	const newLink: LinkType = {
		url: standardPuffModel.url,
		heading: standardPuffModel.heading,
	};

	// assign default view type in Microsite
	const viewType =
		options.themeContext.isMicrositeActive && options.view === null
			? (options.view = ViewType.List)
			: options.view;

	switch (viewType) {
		default:
		case ViewType.Card: {
			return (
				<StandardPuff
					key={options.index}
					image={standardPuffModel.image}
					heading={standardPuffModel.heading}
					headingLevel={options.headingLevel}
					link={newLink}
					publicationDate={standardPuffModel.publicationDate}
					preamble={standardPuffModel.preamble}
				></StandardPuff>
			);
		}
		case ViewType.List:
		case ViewType.CompactList:
			return (
				<PuffListItem
					key={options.index}
					headingLevel={options.headingLevel}
					preamble={standardPuffModel.preamble}
					link={newLink.url && newLink.heading ? newLink : undefined}
					publicationDate={standardPuffModel.publicationDate}
					image={standardPuffModel.image}
					compact={viewType === ViewType.CompactList}
				></PuffListItem>
			);
	}
};
