import RichText from 'components/Boilerplate/RichText';
import React from 'react';
import { RawFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetRawFragmentElement = (
	fragment: RawFragmentModel,
	options?: Options
) => {
	return (
		<RichText epi={options?.epi}>
			<div
				dangerouslySetInnerHTML={{
					__html: fragment.raw,
				}}
			/>
		</RichText>
	);
};
