import { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React from 'react';
import { SectionHeaderStyle, SectionLinkStyle } from './SectionHeader.styles';

/**
 * SectionHeader
 */
type SectionHeaderProps = {
	heading: string;
	headingLevel?: number;
	styleLevel?: number;
	link?: LinkType | null;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
	heading,
	headingLevel,
	styleLevel = 2,
	link,
}) => {
	if (heading) {
		return (
			<SectionHeaderStyle>
				<Heading zeroBottom level={headingLevel} styleLevel={styleLevel}>
					{heading}
				</Heading>
				{link && (
					<SectionLinkStyle to={link.url} external={link.isFile}>
						{link.heading}
					</SectionLinkStyle>
				)}
			</SectionHeaderStyle>
		);
	}
	return null;
};

export default SectionHeader;
