import { ImageFragmentModel } from 'types/fragments';
import Image from 'components/Boilerplate/Image';
import { Options } from '../EpiFragments';
import { ModelLocalization } from 'types/epi';
import { translate } from 'utils/helper-utils';

export const GetImageElement = (
	imgData: ImageFragmentModel,
	localization: ModelLocalization | undefined,
	options?: Options
) => {
	const title = getTitle(imgData, localization);

	return (
		<Image
			src={imgData.src}
			srcSet={imgData.srcSet}
			alt={imgData.alt}
			figCaption={imgData.caption}
			title={title}
			link={imgData.link}
		/>
	);
};

const getTitle = (
	imgData: ImageFragmentModel,
	localization: ModelLocalization | undefined
) => {
	const photographerLabel = translate(
		'/frontend/image/photographer',
		'Photographer',
		localization
	);

	const agencyLabel = translate(
		'/frontend/image/agency',
		'Agency',
		localization
	);

	const illustratorLabel = translate(
		'/frontend/image/illustrator',
		'Illustrator',
		localization
	);

	let parts: string[] = [];
	let labels: string[] = [];
	const separator = ':';

	if (imgData.photographer) {
		parts = [imgData.photographer ?? '', imgData.agency ?? ''];
		labels = [photographerLabel, agencyLabel];
	}

	if (imgData.illustrator) {
		parts = [imgData.illustrator ?? '', imgData.agency ?? ''];
		labels = [illustratorLabel, agencyLabel];
	}

	if (parts.length != labels.length) {
		return '';
	}

	const sb: string[] = [];

	for (let i: number = 0; i < parts.length; i++) {
		if (parts[i] && labels[i]) {
			sb.push(`${labels[i]}${separator} ${parts[i]}`);
		}
	}

	return sb.join('\n');
};
