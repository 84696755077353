/**
 * FaqPuff - styles
 */

import styled from 'styled-components';

export const StandardFaqPuff = styled.div``;

export const QuestionContainer = styled.div`
	${props => props.theme.typography.style4.getStyle()};		
	margin-bottom: ${props => props.theme.spacing.space1}rem;
`;

export const AnswerContainer = styled.div`
		${props => props.theme.typography.style6.getStyle()};		
`;
