import React, { useContext, useEffect, useState } from 'react';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import { NVseGrantStartPageModel } from './NVseGrantStartPage.model';
import Space from 'components/Boilerplate/Space';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import TargetGroups from 'components/TargetGroups';
import { FragmentModelTypes, GrantPuffModel } from 'types/fragments';
import { ViewType } from 'types/enums';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Bidragssida
 * Modeltype:<code>NVseGrantStartPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1662844940/NVseGrantStartPage+versiktssidan)
 *
 * Startsida för ett specifikt bidrag
 */
const NVseGrantStartPage: React.FC<NVseGrantStartPageModel> = ({
	heading,
	applyLink,
	textAsModel,
	bottomItems,
	targetGroupList,
	targetGroupsText,
	applyScopeText,
	applyPeriodText,
	targetGroupsHeading,
	applyScopeHeading,
	applyPeriodHeading,
	lastReviewed,
	showReviewDate,
	preamble,
	sectionHeading,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const [fixedItems, setFixedItems] = useState<FragmentModelTypes[]>([]);
	const themeContext = useContext(ThemeContext);

	useEffect(() => {
		const tempItems = targetGroupList.items.map((item) => {
			return {
				modelType: 'GrantPuffModel',
				decal: item.decal,
				heading: item.name,
				image: item.image,
				link: item.link,
			} as GrantPuffModel;
		});

		setFixedItems(tempItems);
	}, [targetGroupList, targetGroupList.items]);

	// TODO: hardcoded text
	const showMoreLabel = 'Visa fler';

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_textAsModel: BlockType = lastBlockType;
	let blockTypeBefore_fixedItems: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (targetGroupsText || applyScopeText || applyPeriodText) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_textAsModel = lastBlockType;
		blockTypeBefore_fixedItems = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_fixedItems = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (fixedItems.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceBefore_fixedItems = getSpaceToAddBefore(
		blockTypeBefore_fixedItems,
		BlockType.Element,
		themeContext
	);
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					sectionHeading={sectionHeading}
					headingLevel={1}
					heading={heading}
					lastReviewed={lastReviewed}
					showReviewDate={showReviewDate}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
					applyLink={applyLink}
				></GrantsHeader>
			</Grid>

			{(targetGroupsText || applyScopeText || applyPeriodText) && (
				<Space top={themeContext.spacing.getElement()}>
					<Grid paddingTop={false} paddingBottom={false}>
						<TargetGroups
							targetGroupsHeading={targetGroupsHeading}
							targetGroupsText={targetGroupsText}
							applyScopeHeading={applyScopeHeading}
							applyScopeText={applyScopeText}
							applyPeriodHeading={applyPeriodHeading}
							applyPeriodText={applyPeriodText}
							headingLevel={2}
						></TargetGroups>
					</Grid>
				</Space>
			)}

			<EditableField opeProperty={_properties?.mainBody}>
				<EpiFragments
					previousBlockType={blockTypeBefore_textAsModel}
					epi={epi}
					headingLevel={2}
					fragments={textAsModel.fragments}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			</EditableField>

			{fixedItems.length > 0 && (
				<Space top={spaceBefore_fixedItems}>
					<FragmentPuffList
						initialMaxCount={6}
						showMoreLabel={showMoreLabel}
						options={{
							insideCell: false,
							insideInnerGrid: false,
							insideGrid: false,
							headingLevel: 2, // TODO
							view: ViewType.Card,
							themeContext: themeContext,
						}}
						items={fixedItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					></FragmentPuffList>
				</Space>
			)}

			<EpiFragments
				previousBlockType={blockTypeBefore_bottomItems}
				epi={epi}
				headingLevel={2}
				fragments={bottomItems}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default NVseGrantStartPage;
