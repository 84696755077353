/**
 * SimplePuff - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const SimplePuffStyle = styled.div`
	${mediaQueries.tabletOrLarger} {
		min-height: 208px;
	}
	height: 100%;
	background-color: ${(props) => props.theme.colors.white};
	box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.03);
`;
export const TopPuffHeader = styled.div`
	background-color: ${(props) => props.theme.colors.blue1};
	padding: ${(props) => props.theme.spacing.space3}rem;

	${mediaQueries.phone} {
		padding: ${(props) => props.theme.spacing.space1}rem;
	}

	max-width: unset;
	margin-bottom: 0px;
	text-align: center;
`;
export const TopPuffContent = styled.div`
	padding: ${(props) => props.theme.spacing.space3}rem;
	p {
		${(props) => props.theme.typography.style9.getStyle()};
		margin-bottom: 0px;
	}
`;
