import React, { useContext, useEffect, useState } from 'react';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import { FragmentModelTypes, StandardPuffModel } from 'types/fragments';
import { NVseArticlePageModel } from './NVseArticlePage.model';
import Space from 'components/Boilerplate/Space';
import { TopImage } from './NVseArticlePage.styles';
import BannerLinks from 'components/Banners/BannerLinks';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { ViewType } from 'types/enums';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';

import Button from 'components/Button';
import {
	PageOverviewBanner,
	PageOverviewContent,
} from 'pages/NVseThemePage/NVseThemePage.styles';
import { ButtonVariant } from 'pages/sharedModelTypes';
import {
	getBlockBaseMenuItems,
	getBlockBaseSections,
	OnPageNavigationContent,
} from 'components/Panels/OnPageNavigationContent';
import { NavigationItem } from 'types/epi';
import MegaMenu from 'components/MegaMenu';
import { selectLocalization } from 'store/modules/model';
import { useSelector } from 'react-redux';
import { useImageTitle } from 'hooks/useImageTitle';
import { translate } from 'utils/helper-utils';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Artikelsida
 * Modeltype:<code>NVseArticlePage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1632272427/NVseArticlePage)
 *
 * Webbplatsens artikelsida. Den mest använda sidtypen med möjlighet till rubrik, ingress, text och innehållsyta.
 */
const NVseArticlePage: React.FC<NVseArticlePageModel> = ({
	textAsModel,
	heading,
	lastReviewed,
	showReviewDate,
	preamble,
	puffNavigationList,
	bottomItems,
	siblingsList,
	mainImage,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	// TODO: hardcoded text
	const showMoreLabel = 'Visa fler';

	const [fixedItems, setFixedItems] = useState<FragmentModelTypes[]>([]);

	const themeContext = useContext(ThemeContext);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [sections, setSections] = useState<string[]>([]);
	const [menuIndex, setMenuIndex] = useState<NavigationItem[]>([]);
	const localization = useSelector(selectLocalization);
	const findOnPageLabel = translate(
		'/frontend/pages/findonpage',
		'Find on page',
		localization
	);
	const title = useImageTitle(
		mainImage?.photographer,
		mainImage?.agency,
		mainImage?.illustrator
	);

	const ref = React.createRef<any>();

	const onOpenMenu = () => {
		setIsMenuOpen(true);
	};

	const onCloseMenuEvent = (userClosed: boolean) => {
		setIsMenuOpen(false);
		if (userClosed && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		const expanderModels = getBlockBaseSections([...bottomItems]);
		const sectionStrings = expanderModels.map((item) => item.onPageMenuHeading);

		if (sectionStrings.length > 0) {
			const menuIndexRoot = getBlockBaseMenuItems(sectionStrings);
			setMenuIndex(menuIndexRoot);
			setSections(sectionStrings);
		} else {
			setMenuIndex([]);
			setSections([]);
		}
	}, [bottomItems]);

	useEffect(() => {
		const tempItems = puffNavigationList.items.map((item) => {
			return {
				modelType: 'StandardPuffModel',
				heading: item.heading,
				preamble: item.preamble,
				url: item.url,
				image: item.image,
			} as StandardPuffModel;
		});

		setFixedItems(tempItems);
	}, [puffNavigationList, puffNavigationList.items]);

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;
	let blockTypeBefore_fixedItems: BlockType = lastBlockType;
	let blockTypeBefore_siblingsList: BlockType = lastBlockType;

	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_fixedItems = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (fixedItems.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (siblingsList.items.length > 0) {
		lastBlockType = BlockType.SectionBanner;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);
	const spaceBefore_fixedItems = getSpaceToAddBefore(
		blockTypeBefore_fixedItems,
		BlockType.Element,
		themeContext
	);
	const spaceBefore_siblingsList = getSpaceToAddBefore(
		blockTypeBefore_siblingsList,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			<EditableField opeProperty={_properties?.mainImage}>
				{mainImage && (
					<TopImage
						alt={mainImage.alt}
						src={mainImage.src}
						srcSet={mainImage.srcSet}
						title={title}
					/>
				)}
			</EditableField>

			{sections.length > 1 && (
				<PageOverviewBanner>
					<Grid paddingTop={false} paddingBottom={false}>
						<PageOverviewContent>
							<nav>
								<Button
									ref={ref}
									onClick={onOpenMenu}
									variant={ButtonVariant.Tertiary}
									aria-expanded={isMenuOpen}
									aria-haspopup="dialog"
								>
									{findOnPageLabel}
								</Button>
							</nav>
						</PageOverviewContent>
					</Grid>
				</PageOverviewBanner>
			)}

			<Space top={themeContext.spacing.getElement()} bottom={spaceAfterLast}>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						columns={8}
						headingLevel={1}
						heading={heading}
						lastReviewed={lastReviewed}
						showReviewDate={showReviewDate}
						preamble={preamble}
						heading_htmlAttributes={_properties?.heading}
						preamble_htmlAttributes={_properties?.preamble}
					></GrantsHeader>
				</Grid>

				<EditableField opeProperty={_properties?.mainBody}>
					{textAsModel && textAsModel.fragments.length > 0 && (
						<EpiFragments
							previousBlockType={BlockType.Element}
							headingLevel={2}
							epi={epi}
							fragments={textAsModel.fragments}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)}
				</EditableField>

				{fixedItems.length > 0 && (
					<Space top={spaceBefore_fixedItems}>
						<FragmentPuffList
							initialMaxCount={6}
							options={{
								insideCell: false,
								insideInnerGrid: false,
								insideGrid: false,
								headingLevel: 2, // TODO:
								view: ViewType.Card,
								themeContext: themeContext,
							}}
							showMoreLabel={showMoreLabel}
							items={fixedItems}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						></FragmentPuffList>
					</Space>
				)}

				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>

				{siblingsList.items?.length > 0 && (
					<Space top={spaceBefore_siblingsList}>
						<BannerLinks
							headingLevel={2}
							heading={siblingsList.heading}
							links={siblingsList.items}
						></BannerLinks>
					</Space>
				)}
			</Space>

			{isMenuOpen && (
				<MegaMenu
					items={menuIndex}
					heading={heading}
					onClose={onCloseMenuEvent}
				/>
			)}
		</>
	);
};

export default NVseArticlePage;
