import React, { useContext } from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { NVseGuidanceCategoryPageModel } from './NVseGuidanceCategoryPage.model';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { ViewType } from 'types/enums';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Kategorisida för vägledningar
 * Modeltype:<code>NVseGuidanceCategoryPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1819672636/NVseGuidanceCategoryPage)
 *
 * Sida för en unik vägledningskategori
 */
const NVseGuidanceCategoryPage: React.FC<NVseGuidanceCategoryPageModel> = ({
	heading,
	preamble,
	textAsModel,
	guidanceItemsHeading,
	allGuidanceItemsText,
	guidanceItems,
	epi,
	bottomItems,
	_properties = {},
	disableCustomHeadingLogic,
	initialMaxCount,
}) => {
	const themeContext = useContext(ThemeContext);

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_guidanceItems: BlockType = lastBlockType;
	let blockTypeBefore_textAsModel: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (guidanceItems.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_textAsModel = lastBlockType;
	}

	if (textAsModel && textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);
	const spaceBefore_guidanceItems = getSpaceToAddBefore(
		blockTypeBefore_guidanceItems,
		BlockType.Element,
		themeContext
	);

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					columns={8}
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			{guidanceItems.length > 0 && (
				<Space top={spaceBefore_guidanceItems}>
					<FragmentPuffList
						initialMaxCount={initialMaxCount}
						heading={guidanceItemsHeading}
						showMoreLabel={allGuidanceItemsText}
						items={guidanceItems}
						options={{
							insideCell: false,
							insideInnerGrid: false,
							insideGrid: false,
							headingLevel: 2, // TODO
							view: ViewType.Card,
							themeContext: themeContext,
						}}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					></FragmentPuffList>
				</Space>
			)}

			<EditableField opeProperty={_properties?.mainBody}>
				{textAsModel && textAsModel.fragments.length > 0 && (
					<EpiFragments
						previousBlockType={blockTypeBefore_textAsModel}
						epi={epi}
						headingLevel={2}
						fragments={textAsModel?.fragments}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				)}
			</EditableField>

			<EpiFragments
				previousBlockType={blockTypeBefore_bottomItems}
				epi={epi}
				headingLevel={2}
				fragments={bottomItems}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
		</Space>
	);
};

export default NVseGuidanceCategoryPage;
