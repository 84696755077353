/**
 * FormButtons
 */

import React from 'react';
import { Button } from 'components/Form';
import StandardButton from 'components/Boilerplate/Button';

interface NextButtonProps {
	nextId?: string;
	isExpanded: boolean;
	id: string | number;
}

interface PrevButtonProps {
	previousIndex?: number;
	previousId?: string;
	isExpanded: boolean;
	id: string | number;
	onPrevious: any;
}

const NextButton: React.FC<NextButtonProps> = ({ nextId, isExpanded, id }) => {
	return (
		<Button
			type="submit"
			name="submit"
			aria-controls={nextId}
			aria-expanded={isExpanded}
			id={id}
		>
			Nästa
		</Button>
	);
};

const PreviousButton: React.FC<PrevButtonProps> = ({
	previousIndex,
	previousId,
	isExpanded,
	id,
	onPrevious,
}) => (
	<StandardButton
		type="button"
		color="blue"
		id={id}
		aria-controls={previousId}
		aria-expanded={isExpanded}
		onClick={() =>
			onPrevious({
				currentStep: previousIndex,
				invalidFields: [],
			})
		}
	>
		Tillbaka
	</StandardButton>
);

export { PreviousButton, NextButton };
