/**
 * NVseJobListPage
 */

import React, {
	useState,
	useEffect,
	ReactNode,
	ReactComponentElement,
	ReactHTMLElement,
	useContext,
} from 'react';
import {
	JobList,
	JobListItem,
	NVseJobListPageModel,
} from './NVseJobListPage.model';
import Space from 'components/Boilerplate/Space';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { FragmentModelTypes } from 'types/fragments';
import {
	CategoryDropdown,
	CategoryDropdownProps,
} from 'pages/NVseRegulationsListingPage/CategoryDropdown';
import JobListPage from 'components/JobListPage';
import BannerLinks from 'components/Banners/BannerLinks';
import { Grid } from 'components/Boilerplate/Grid';
import { SortContainer } from './NVseJobListPage.styles';
import {
	Categories,
	CategoryOptions,
} from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.model';
import { breakpointsNumber } from 'theme/media-queries';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';

/** Main description for this component. */
const NVseJobListPage: React.FC<NVseJobListPageModel> = ({
	modelType,
	mediaBanner,
	categories,
	jobList,
	bottomItems,
	siblingsList,
	listEmpty,
	epi = undefined,
	disableCustomHeadingLogic,
}) => {
	const [jobListSorted, setJobListSorted] = useState<JobListItem[]>(
		jobList.jobListItem
	);
	const [orderByItemKey, setOrderByItemKey] = useState('0');
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const themeContext = useContext(ThemeContext);

	const renderCatOption = () => {
		if (categories) {
			if (isPhone) {
				let jobCategories = categories;
				let result = [
					{
						name: '',
						categoryOptions: [
							{
								id: 0,
								text: '',
							},
						],
					},
				];

				jobCategories.map((catItem: Categories, index: number) => {
					result = [
						{
							name: catItem.name,
							categoryOptions: catItem.categoryOptions.filter(
								(word) => word.id !== 2
							),
						},
					];
				});

				return result.map((catItem: Categories, index: number) => {
					return (
						<CategoryDropdown
							id={`cat-${index}`}
							key={index}
							category={catItem}
							selectedId={orderByItemKey}
							allowEmpty={false}
							flexRow={true}
							onSelectChanged={(id) => onCategorySelected(id)}
						/>
					);
				});
			} else {
				return categories.map((catItem: Categories, index: number) => {
					return (
						<CategoryDropdown
							id={`cat-${index}`}
							key={index}
							category={catItem}
							selectedId={orderByItemKey}
							allowEmpty={false}
							flexRow={true}
							onSelectChanged={(id) => onCategorySelected(id)}
						/>
					);
				});
			}
		}
	};

	const compare = (keyName: string) => {
		let listOrdered;
		listOrdered = jobListSorted.slice().sort(sortSiteSelection);
		setJobListSorted(listOrdered);

		function sortSiteSelection(a: any, b: any) {
			if (a[keyName] < b[keyName]) {
				return -1;
			}
			if (a[keyName] > b[keyName]) {
				return 1;
			}
			return 0;
		}
	};

	const onCategorySelected = (catId: string) => {
		let keyName = '';
		switch (catId) {
			case '0':
				setOrderByItemKey(catId);
				keyName = 'title';
				compare(keyName);
				break;

			case '1':
				setOrderByItemKey(catId);
				keyName = 'areas';
				compare(keyName);
				break;

			case '2':
				setOrderByItemKey(catId);
				keyName = 'employment';
				compare(keyName);
				break;

			case '3':
				setOrderByItemKey(catId);
				keyName = 'lastApplyDate';
				compare(keyName);
				break;
			default:
				break;
		}
	};

	let lastBlockType = BlockType.Element;
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space>
			<EpiFragments
				modelType={modelType}
				previousBlockType={BlockType.Element}
				epi={epi}
				headingLevel={1}
				fragments={mediaBanner as FragmentModelTypes[]}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>
			{jobList?.jobListItem.length ? (
				<>
					<Grid>
						<SortContainer>{renderCatOption()}</SortContainer>
					</Grid>

					<JobListPage
						hdAd={jobList?.hdAd}
						hdCity={jobList?.hdCity}
						hdEmployment={jobList?.hdEmployment}
						hdApplyDate={jobList?.hdApplyDate}
						displayOfTotalLabel={jobList?.displayOfTotalLabel}
						seeAllLabel={jobList?.seeAllLabel}
						data={jobListSorted}
					/>
				</>
			) : (
				<Space top={themeContext.spacing.space1} bottom={spaceAfterLast}>
					<EpiFragments
						modelType={modelType}
						previousBlockType={BlockType.Element}
						epi={epi}
						headingLevel={2}
						fragments={listEmpty.fragments as FragmentModelTypes[]}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
						htmlAttributes={'style: margin-bottom: 80px;'}
					/>
				</Space>
			)}
			<EpiFragments
				modelType={modelType}
				previousBlockType={BlockType.Element}
				epi={epi}
				headingLevel={2}
				fragments={bottomItems as FragmentModelTypes[]}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>

			{siblingsList.items?.length > 0 && (
				<Space>
					<BannerLinks
						headingLevel={2}
						heading={siblingsList.heading}
						links={siblingsList.items}
					></BannerLinks>
				</Space>
			)}
		</Space>
	);
};

export default NVseJobListPage;
