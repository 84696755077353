import Button from 'components/Button';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const ListPageShowMoreButton = styled(Button)`
	padding: ${px2rem(12)} ${px2rem(50)};
`;

export const ListPageShowMoreButtonContainer = styled.div`
	justify-content: center;
	display: flex;
	padding-top: ${(props) => props.theme.spacing.space5}rem;
	${mediaQueries.phone} {
		padding-top: ${(props) => props.theme.spacing.space6}rem;
	}
	button {
		${mediaQueries.phone} {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
`;
