import { css } from 'styled-components';
import { mediaQueries } from 'theme';

export const getLinkArrowStyle = () => {
	return css`
		&::after {
			content: '\u00a0\u00a0\u203a';
			margin-left: 0px;
			opacity: 0.6;
			margin-top: 6px;
		}
	`;
};

export const styleForLink = css`
	${(props) => props.theme.typography.style6.getStyle()};
	color: ${(props) =>
		props.theme.isMicrositeActive ? props.theme.colors.primary : '#0039A2'};
	text-decoration: none;
	outline-color: transparent;

	&:hover {
		text-decoration: underline;

		&::after {
			opacity: 1;
		}
	}

	${mediaQueries.phone} {
		${(props) =>
			props.theme.isMicrositeActive
				? props.theme.typography.style10.getStyle()
				: props.theme.typography.style6.getStyle()}
	}

	${getLinkArrowStyle()}
`;

export const styleForLinkDark = css`
	${(props) => props.theme.typography.style5.getStyle()};
	color: ${(props) => props.theme.colors.white};
	text-decoration: none;
	outline-color: transparent;
`;

// outline: 2px transparent solid;
// outline-color: currentColor;
// box-shadow: ${direction} 0 0 0 2px black, 0 0 1px 5px white;

export const accessibilityFocus = (direction?: string) => {
	return css`
		outline: 2px solid;
		outline-offset: 2px;
		outline-color: currentColor;
		transition: outline-color 200ms ease-in-out;
		//		transition: all .3s ease;
		//		transition: color 1s, outline-color 1s ease-out;
	`;
};

export const resetAccessibilityFocus = (direction?: string) => {
	return css`
		outline: unset;
		box-shadow: unset;
	`;
};
