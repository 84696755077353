import styled from 'styled-components';

export const RegulationHistoryNarrowStyle = styled.ul`
	border-collapse: collapse;
	background-color: ${props => props.theme.colors.blue4};
	${props => props.theme.typography.style9.getStyle()};		
	color: ${props => props.theme.palette.text.primary};			

	list-style-type: none;
	padding: 0px;
	margin: 0px;

	label {
		display: block;
		font-weight: bold;
		text-transform: uppercase;
	}

	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
	}

	li {
		margin-bottom: ${props => props.theme.spacing.space2}rem;
	}

	> li {
		padding: ${props => props.theme.spacing.space2}rem;
	}

	> li:last-child {
		margin-bottom: 0rem;
	}

	> li:nth-child(odd) {
		background-color: ${props => props.theme.colors.blue2};
	}
`;
