/**
 * GrantsHeader
 */

import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import Typography, { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
	GrantsHeaderCategoriesStyle,
	GrantsHeaderDecalStyle,
	GrantsHeaderLastReviwedStyle,
} from './GrantsHeader.styles';
import {
	translate,
	convertDateWithMonthWrittenOut,
	formatCategories,
} from 'utils/helper-utils';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import EditableField from 'components/EditableField/EditableField';
import { Category } from 'pages/microsite/MicrositeArticlePage/MicrositeArticlePage.model';

type GrantsHeaderProps = {
	sectionHeading?: string | null;
	headingLevel?: number;
	heading?: string | null;
	lastReviewed?: string | null;
	showReviewDate?: boolean | null;
	cameFromSectionContent?: boolean;
	preamble?: string | null;
	applyLink?: LinkType | null;
	columns?: number;
	heading_htmlAttributes?: any;
	preamble_htmlAttributes?: any;
	publicationDate?: string;
	categories?: Category[];
};

export const GrantsHeader: React.FC<GrantsHeaderProps> = ({
	sectionHeading,
	headingLevel,
	heading,
	lastReviewed,
	showReviewDate,
	cameFromSectionContent = false,
	preamble,
	applyLink,
	columns,
	heading_htmlAttributes = {},
	preamble_htmlAttributes = {},
	publicationDate,
	categories,
}) => {
	const themeContext = useContext(ThemeContext);
	const localization = useSelector(selectLocalization);
	const lastReviewedText = translate(
		'/frontend/puff/lastreviewed',
		'Last Reviewed', // Granskad
		localization
	);

	const content = (
		<>
			{sectionHeading && (
				<GrantsHeaderDecalStyle>{sectionHeading}</GrantsHeaderDecalStyle>
			)}
			<EditableField opeProperty={heading_htmlAttributes}>
				{heading && (
					<Heading zeroBottom level={headingLevel}>
						{heading}
					</Heading>
				)}
			</EditableField>

			{showReviewDate && lastReviewedText && lastReviewed && (
				<GrantsHeaderLastReviwedStyle
					cameFromSectionContent={cameFromSectionContent}
				>
					{lastReviewedText}:{' '}
					{convertDateWithMonthWrittenOut(lastReviewed, lastReviewedText)}
				</GrantsHeaderLastReviwedStyle>
			)}

			{publicationDate && categories?.length && (
				<GrantsHeaderCategoriesStyle>
					{formatCategories(categories)}: {publicationDate}
				</GrantsHeaderCategoriesStyle>
			)}

			<Space bottom={0}>
				<EditableField opeProperty={preamble_htmlAttributes}>
					{preamble && (
						<Space
							top={
								themeContext.isMicrositeActive
									? themeContext.spacing.space1
									: themeContext.spacing.space2
							}
							bottom={0}
						>
							<Typography as="preamble" {...preamble_htmlAttributes}>
								{preamble}
							</Typography>
						</Space>
					)}
				</EditableField>

				{applyLink && (
					<Button
						variant={ButtonVariant.Primary}
						to={applyLink.url}
						external={applyLink.isFile}
					>
						{applyLink.heading}
					</Button>
				)}
			</Space>
		</>
	);

	if (columns) {
		return (
			<Grid inner={true}>
				<Cell span={columns}>{content}</Cell>
			</Grid>
		);
	}

	return content;
};
