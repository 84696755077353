/**
 * EventListItem - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import Link from 'components/Boilerplate/Link';
import { getLinkArrowStyle } from 'theme/styles';
import { Heading } from 'components/Typography/Typography';
import { px2rem } from 'theme/utils';

export const CalendarEventItemLinkContainer = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items:center;
	background-color: ${props => props.theme.colors.white};

	margin-bottom: ${props => props.theme.spacing.space2}rem;

	&:after {
		content:none;
	};	 

	&:after {
		content:none;
	};	 

	&:hover {
		text-decoration: none;
	}	
		
`;

export const CalendarDateContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: normal;

	border-right: 1px solid ${props => props.theme.colors.black}20;

	margin: ${props => props.theme.spacing.space2}rem 0;
	flex: 0 0 122px;

	> :nth-child(1) {
		${props => props.theme.typography.style9.getStyle()};		
	}
	> :nth-child(2) {
		${props => props.theme.typography.style2.getStyle()};		
		color: ${props => props.theme.palette.text.primary};			
		font-weight: 700;
	}
	> :nth-child(3) {
		${props => props.theme.typography.style9.getStyle()};		
	}
`;

export const CalendarEventItemInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	padding:${props => props.theme.spacing.space2}rem ${props => props.theme.spacing.space4}rem;

`;


export const CalendarInformaitonDuration = styled.div`
		${props => props.theme.typography.style9.getStyle()};		
	margin-bottom: ${props => props.theme.spacing.space1}rem;
`;


export const CalendarHeading = styled(Heading)`

	${mediaQueries.phone} {
		font-size: ${px2rem(16)};
	}	

	${getLinkArrowStyle()}

	${CalendarEventItemLinkContainer}:hover &,${CalendarEventItemLinkContainer}:focus & {
		text-decoration: underline;
		&:after {
			opacity: 1;
        }		
  	}	
`;
