/**
 * Gallery - styles
 */
import styled from 'styled-components';
import { mediaQueries } from 'theme';

interface ListProps {
	columns: number;
	transition: number;
	myTranslate: number;
}

interface ListWidthProps {
	width: number;
}

export const GalleryContainer = styled.div`
	overflow: hidden;
`;

export const GalleryListStyle = styled.ul<ListProps>`
	list-style-type: none;
	display: inline-flex;
		
	transform: translateX(-${(p) => p.myTranslate}%);
	transition: transform ease-out ${(p) => p.transition}s;

	${mediaQueries.phone} {
		column-count: ${(p) => p.columns};
	}

	position: relative;
	width: ${(p) => p.columns * 100}%;
	margin: 0;
	padding: 0;

`;

/**
 * TODO: QUICKFIX: Harcoded values for li width 1100px and item width.
 */
export const GalleryListItemStyle = styled.li<ListWidthProps>`
	width: ${(p) => p.width}%;
	
	& >:focus-within {
		outline-offset: -2px;
		outline: 2px solid;
		outline-color:currentColor;
	}			

	${mediaQueries.tabletOrLarger} {
		display: inline-flex;

		 & > a {
			flex-basis: 356px;
			min-width: 356px;
		 } 

		 & > *:not(:last-child) {
			margin-right: ${props => props.theme.spacing.space2}rem;
		}  
	}
`;
