import React from 'react';
import { StatisticsBlockModel } from 'types/fragments';
import { Options, wrapElementInGrid } from '../EpiFragments';
import Highcharts from 'components/Highcharts/Highcharts';
import styled from 'styled-components';
import {
	getThemeBackground,
	getThemeHeadingColor,
	PuffTheme,
} from 'pages/sharedModelTypes';
import { ThemeProps } from 'components/Banner/Banner';
import ListHeader from 'components/ListHeader';
import Space from 'components/Boilerplate/Space';

export const GetStatisticsBlockModelElement = (
	model: StatisticsBlockModel,
	options: Options
) => {
	const highChartPptions = JSON.parse(model.highChartsOptions);

	const useShortDate = model.useShortDate;

	const classificationList = model.classificationList;

	const theme = PuffTheme.NeutralWhite;
	const showHeader = model.heading ? true : false;
	const element = (
		<>
			<HighchartsContainer themeName={theme}>
				{showHeader && (
					<Space bottom={options.themeContext.spacing.space2}>
						<ListHeader
							heading={model.heading}
							headingLevel={options.headingLevel}
							link={model.link}
							color={getThemeHeadingColor(options.themeContext, theme)}
						></ListHeader>
					</Space>
				)}
				<Highcharts
					showLegends={false}
					data={highChartPptions}
					useShortDate={useShortDate}
					classificationList={classificationList}
				></Highcharts>
			</HighchartsContainer>
		</>
	);

	if (options.insideGrid) {
		return element;
	}

	return wrapElementInGrid(element);
};

export const HighchartsContainer = styled.div<ThemeProps>`
	padding: ${(props) => props.theme.spacing.space4}rem;
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
`;
