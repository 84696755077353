/**
 * LogoBanner - styles
 */

import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import Link from 'components/Boilerplate/Link';

export const LogoBannerStyle = styled.div<ThemeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	padding: ${props => props.theme.spacing.space4}rem 0;
`;

export const LogoBannerImageContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const LogoBannerImage = styled.img`
	margin: ${props => props.theme.spacing.space2}rem ${props => props.theme.spacing.space3}rem;
	display: block;
	height: 50px;
	object-fit: cover;
	object-position: 50% 50%;
`;

export const LogoBannerFooter = styled.div`
	margin-top: ${props => props.theme.spacing.space2}rem;
	display: flex;
	justify-content: center;
`;


export const LogoBannerLink = styled(Link) <ThemeProps>`
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};

`;
