/**
 * DashboardBlock - styles
 */

import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const DashboardBlockStyle = styled.div<ThemeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	display: flex;
	flex-direction: column;
`;

export const DashboardLineStyle = styled.div<ThemeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;

	> :not(:last-child) {
		border-right: 1px solid ${(p) => getThemeTextColor(p.theme, p.themeName)}65;
	}

	padding: ${props => props.theme.spacing.space4}rem 0;

	${mediaQueries.tablet} {
		grid-template-columns: 50% 50%;

		> :nth-child(2) {
			border-right: none;
		}
		> :nth-child(4) {
			border-right: none;
		}

		> :nth-child(-n + 2) {
			margin-bottom: ${props => props.theme.spacing.space4 * 2}rem;
		}

		> :nth-child(1)::after {
			content: '';
			display: inline-block;
			position: relative;
			margin-left: 50px;
			top: ${props => props.theme.spacing.space4}rem;
			height: 1px;
			background: ${(p) => getThemeTextColor(p.theme, p.themeName)}45;
		}

		> :nth-child(2)::after {
			content: '';
			display: inline-block;
			position: relative;
			margin-right: 50px;
			top: ${props => props.theme.spacing.space4}rem;
			height: 1px;
			background: ${(p) => getThemeTextColor(p.theme, p.themeName)}45;
		}
	}
	// TODO: this is a quick fix for making dashboards render two-and-two in mobile. Not the best fix.
	${mediaQueries.phone} {
		grid-template-columns: 50% 50%;

		> :nth-child(2) {
			border-right: none;
		}
		> :nth-child(4) {
			border-right: none;
		}

		> :nth-child(-n + 2) {
			margin-bottom: ${props => props.theme.spacing.space4 * 2}rem;
		}

		> :nth-child(1)::after {
			content: '';
			display: inline-block;
			position: relative;
			margin-left: 25px;
			top: ${props => props.theme.spacing.space4}rem;
			height: 1px;
			background: ${(p) => getThemeTextColor(p.theme, p.themeName)}45;
		}

		> :nth-child(2)::after {
			content: '';
			display: inline-block;
			position: relative;
			margin-right: 25px;
			top: ${props => props.theme.spacing.space4}rem;
			height: 1px;
			background: ${(p) => getThemeTextColor(p.theme, p.themeName)}45;
		}
	}
`;

export const DashboardLineDeviderStyle = styled.div<ThemeProps>`
	opacity: 0.25;
	height: 1px;
	background-color: ${(p) => getThemeTextColor(p.theme, p.themeName)};
	justify-self: center;
	margin: 0 ${props => props.theme.spacing.space5}rem;
`;
