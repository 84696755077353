import YouTube from 'components/YouTube';
import React from 'react';
import { YouTubeFragmentModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetYouTubeModelElement = (
	youtubeData: YouTubeFragmentModel,
	options?: Options
) => {
	return (
		<YouTube
			title={youtubeData.title}
			videoId={youtubeData.videoID}
			allowfullscreen={youtubeData.allowFullScreen}
		/>
	);
};
