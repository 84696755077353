/**
 * YouTube - styles
 */

import { PlayIconProps } from 'components/Button/Button';
import Icon from 'components/Boilerplate/Icon';
import styled from 'styled-components';

export const YoutubeContainer = styled.div`
	overflow: hidden;
	position: relative;
	width: 100%;

	::after {
		padding-top: 56.25%;
		display: block;
		content: '';
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const Overlay = styled.img`
	position: absolute;
	display: block;

	width: 100%;
	height: 100%;
	object-fit: cover;

	cursor: pointer;
`;

export const PlayIconArea = styled.div<PlayIconProps>`
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	${(props) => props.theme.typography.style11.getStyle()};

	button > span {
		margin: 0;
		display: flex;
		align-items: center;
	}

	${(p) =>
		p.hover === true &&
		`
		button, button:hover {
			color:white;
			transition: background-color 200ms ease;
			background-color:  ${p.theme.colors.secondaryBlue};
		}
	`};
`;

export const PlayIcon = styled(Icon)`
	width: 1rem;
	height: 1rem;
`;
