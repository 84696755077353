import { useEffect, useRef, useState } from 'react';
import { AppProps, getLocation, routePatterns } from 'App';
import { selectMicrosite } from 'store/modules/model';
import { useSelector } from 'react-redux';
import { MicrositeGlobalStyle } from 'theme/micrositeGlobal';
import Breadcrumbs, {
	BreadcrumbItemtype,
} from 'components/Breadcrumbs/Breadcrumbs';
import { Route, Switch } from 'react-router-dom';
import { RouteHandler } from 'routeHandler';
import MegaMenu from 'components/MegaMenu';
import MenuMobile from 'components/MenuMobile';
import MicrositeHeader from 'components/microsite/MicrositeHeader';
import MicrositeFooter from 'components/microsite/MicrositeFooter';
import MicrositeThemeProvider from 'theme/MicrositeTheme';
import { BreadcrumbsContainer } from 'components/SiteHeader/SiteHeader.styles';
import { Grid } from 'components/Boilerplate/Grid';

const MicrositeApp = ({
	isNarrowDisplay,
	Router,
	breadcrumbs,
	navigation,
	quickLinks,
	skipToContent,
	lang,
	footer,
	pageContent,
}: AppProps) => {
	const microsite = useSelector(selectMicrosite);

	const [headerLogo, setHeaderLogo] = useState<any>();
	const [footerLogo, setFooterLogo] = useState<any>();

	const location = getLocation(pageContent);
	const showBreadcrumbs: boolean = location !== '/' && location !== '/en';

	const menuButtonRef = useRef<any>(null);

	const [menuComponent, setMenuComponent] = useState<any>();
	const [fixedBreadcrumbs, setFixedBreadcrumbs] = useState<
		BreadcrumbItemtype[]
	>([]);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	useEffect(() => {
		const fixed = breadcrumbs
			? breadcrumbs.filter((item) => {
					return item.url;
			  })
			: [];

		setFixedBreadcrumbs(fixed);
	}, [breadcrumbs]);

	useEffect(() => {
		const onCloseMenuEvent = (focusOrigin: boolean) => {
			if (focusOrigin) {
				if (menuButtonRef && menuButtonRef.current) {
					menuButtonRef.current.focus();
				}
			}
			setIsMenuOpen(false);
		};

		const menuRoot = navigation?.items ? navigation?.items : [];

		let menuComponent2 = (
			<MegaMenu items={menuRoot} onClose={onCloseMenuEvent} />
		);

		if (isNarrowDisplay && menuRoot) {
			menuComponent2 = (
				<MenuMobile
					onClose={onCloseMenuEvent}
					items={menuRoot}
					micrositeLogo={microsite?.headerLogo?.mobile}
				/>
			);
		}

		setMenuComponent(menuComponent2);
	}, [navigation?.items, isNarrowDisplay]);

	useEffect(() => {
		if (isNarrowDisplay) {
			setHeaderLogo(microsite?.headerLogo.mobile);
			setFooterLogo(microsite?.footerLogo.mobile);
		} else {
			setHeaderLogo(microsite?.headerLogo.desktop);
			setFooterLogo(microsite?.footerLogo.desktop);
		}
	}, [microsite]);

	const onOpenMenu = (isOpen: boolean) => {
		setIsMenuOpen(isOpen);
	};

	const onOpenSearchMenu = (isOpen: boolean) => {
		setIsSearchOpen(isOpen);
	};

	return (
		<MicrositeThemeProvider>
			<Router location={location} context={{}}>
				<a className="microsite-skip-to-content-link" href="#main">
					{skipToContent ?? 'Gå till innehåll'}
				</a>

				<MicrositeHeader
					ref={menuButtonRef}
					home={navigation?.home}
					quickLinks={quickLinks}
					lang={lang}
					isPopupMenuOpen={false}
					onOpenSearchMenu={onOpenSearchMenu}
					onOpenMenu={onOpenMenu}
					isSearchOpen={isSearchOpen}
					isMenuOpen={isMenuOpen}
					micrositeLogo={headerLogo}
				/>

				{showBreadcrumbs && fixedBreadcrumbs.length > 0 && (
					<BreadcrumbsContainer>
						<Grid paddingTop={false} paddingBottom={false}>
							<Breadcrumbs items={fixedBreadcrumbs} />
						</Grid>
					</BreadcrumbsContainer>
				)}

				<Switch>
					{routePatterns.map((routePattern) => (
						<Route key={routePattern} path={routePattern}>
							<RouteHandler />
						</Route>
					))}
				</Switch>

				{footer && (
					<MicrositeFooter
						footerGroups={footer.footerGroups}
						customerService={footer.customerService}
						micrositeLogo={footerLogo}
					/>
				)}

				{isMenuOpen && menuComponent}

				<MicrositeGlobalStyle
					isOpen={isMenuOpen || isSearchOpen ? true : false}
				/>
			</Router>
		</MicrositeThemeProvider>
	);
};

export default MicrositeApp;
