/**
 * RichText
 */

import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RichText as RichTextStyle } from 'components/Typography';
import { EpiEvent } from 'types/epi';

type Props = {
	epi?: EpiEvent;
};

/** Richtext components which handles links. */
const RichText: React.FC<Props> = ({ epi, children }) => {
	const history = useHistory();
	const nodeRef = useRef<HTMLDivElement>(null);

	function handleLinkClick(this: HTMLAnchorElement, event: MouseEvent): void {
		const href = this.getAttribute('href');
		const target = this.getAttribute('target');
		const isFile = this.getAttribute('data-type') === 'file';
		if (href && href.charAt(0) === '/' && target !== '_blank' && !isFile) {
			const isAImage = /\.(jpe?g|png|gif|bmp|svg)$/i.test(href) ? true : false;
			if (!isAImage) {
				event.preventDefault();
				history.push(href);
			}
		}
	}

	useEffect(() => {
		const nodeRefRef = nodeRef;
		if (nodeRef.current && !epi?.inEditMode) {
			nodeRef.current.querySelectorAll('a').forEach((a) => {
				a.addEventListener('click', handleLinkClick);
			});
		}

		return () => {
			if (nodeRefRef.current) {
				nodeRefRef.current.querySelectorAll('a').forEach((a) => {
					a.removeEventListener('click', handleLinkClick);
				});
			}
		};
		// eslint-disable-next-line
	}, []);

	return <RichTextStyle ref={nodeRef}>{children}</RichTextStyle>;
};

export default RichText;
