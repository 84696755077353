/**
 * NVseStatisticsPage - styles
 */

import styled from 'styled-components';

export const Div = styled.div``;

export const CustomBackground = styled.div`

	padding-top: ${props => props.theme.spacing.getPageTopPadding()}rem;
	padding-bottom: ${props => props.theme.spacing.getPageTopPadding()}rem;

	background-color: ${props => props.theme.colors.white};
`;
