import HighlightBlock from 'components/HighlightBlock/HighlightBlock';
import { HighlightModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetHighlightModelElement = (
	data: HighlightModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	return <HighlightBlock textAsModel={data.textAsModel} options={options} disableCustomHeadingLogic={disableCustomHeadingLogic}/>;
};
