/**
 * Puff
 */

import React from 'react';
import { PuffWithImageModel } from 'types/blocks';
import { Block } from './Puff.styles';

import Link from 'components/Boilerplate/Link';
import Image from 'components/Boilerplate/Image';
import Typography from 'components/Typography';

/** Puff with image, text and link. */
export const PuffWithImage: React.FC<PuffWithImageModel> = ({
	id,
	heading,
	image,
	url,
	linkText,
	text,
	_properties = {},
}) => {
	return (
		<Block id={id} key={id} {..._properties?.block}>
			<div {..._properties?.image}>
				<Image src={image} alt={heading} />
			</div>
			<Typography as="h2" {..._properties?.heading}>
				{heading}
			</Typography>
			<Typography as="p" {..._properties?.text}>
				{text}
			</Typography>

			{url && linkText && (
				<Link to={url} {..._properties?.url}>
					{linkText}
				</Link>
			)}

			{_properties?.linkText && (
				<Typography as="p" {..._properties?.linkText}>
					{linkText}
				</Typography>
			)}
		</Block>
	);
};

export default PuffWithImage;
