/**
 * HeroBanner
 */
import { Cell, Grid } from 'components/Boilerplate/Grid';
import Link from 'components/Boilerplate/Link';
import Typography from 'components/Typography';
import { Heading } from 'components/Typography/Typography';
import { useImageTitle } from 'hooks/useImageTitle';
import { LinkType } from 'pages/sharedModelTypes';
import React from 'react';
import { ImageFragmentModel } from 'types/fragments';
import { HeroBannerStyle, HeroBannerTextBlockStyle } from './HeroBanner.styles';

export interface HeroBannerProps {
	textToRight: boolean;
	image: ImageFragmentModel;
	/**
	 * Heading level
	 */
	headingLevel?: number;
	heading: string;
	text: string;
	link: LinkType;
}

/** This is the banner component */
const HeroBanner: React.FC<HeroBannerProps> = ({
	textToRight,
	image,
	headingLevel,
	heading,
	text,
	link,
}) => {
	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	return (
		<HeroBannerStyle {...image} title={title}>
			<Grid paddingTop={false} paddingBottom={false}>
				<Grid inner={true}>
					{textToRight && <Cell span={7} tablet={3} phone={0}></Cell>}

					<Cell span={5} tablet={5} phone={4}>
						<HeroBannerTextBlockStyle>
							{heading && (
								<Heading level={headingLevel} styleLevel={3}>
									{heading}
								</Heading>
							)}
							{text && <Typography as="p">{text}</Typography>}
							{link && (
								<Link to={link.url} external={link.isFile}>
									{link.heading}
								</Link>
							)}
						</HeroBannerTextBlockStyle>
					</Cell>
				</Grid>
			</Grid>
		</HeroBannerStyle>
	);
};

export default HeroBanner;
