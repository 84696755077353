import Link from 'components/Boilerplate/Link';
import {
	SubjectAreaListingPageItem,
	SubjectAreaListingPageItemsModel,
} from 'pages/sharedModelTypes';
import React from 'react';
import {
	IndexHeading,
	IndexSection,
	IndexSectionItem,
	IndexSectionWrapper,
} from './ListingPageIndexPanel.styles';

/**
 * Listing Page IndexPanel
 */

type ListingPageIndexPanelProps = {
	items: SubjectAreaListingPageItemsModel;
};

export const ListingPageIndexPanel: React.FC<ListingPageIndexPanelProps> = ({
	items,
}) => {
	return (
		<>
			{items && (
				<div>
					{Object.entries(items).map(([letter, sectionItems]) => (
						<IndexSectionWrapper key={letter}>
							<IndexHeading>{letter}</IndexHeading>
							<IndexSection>
								{sectionItems.map((item: SubjectAreaListingPageItem, index) => (
									<li>
										<IndexSectionItem
											key={index}
											showLinkIcon={false}
											to={item.url}
											external={false}
										>
											<div>
												{item.image && (
													<img
														src={item.image.src}
														srcSet={item.image.srcSet}
														alt={item.image.alt}
													/>
												)}
											</div>
											<div>
												<span>{item.name}</span>
											</div>
										</IndexSectionItem>
									</li>
								))}
							</IndexSection>
						</IndexSectionWrapper>
					))}
				</div>
			)}
		</>
	);
};
