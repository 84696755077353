import HeroBanner from 'components/Banners/HeroBanner';
import { HeroBannerProps } from 'components/Banners/HeroBanner/HeroBanner';
import React from 'react';
import { HeroBannerModel } from 'types/fragments';
import { Options } from '../EpiFragments';

export const GetHeroBannerModelElement = (bannerData: HeroBannerModel, options: Options) => {


	const heroBannerProps = { ...bannerData, headingLevel: options.headingLevel } as HeroBannerProps;


	return <HeroBanner {...heroBannerProps}></HeroBanner>;
};
