import styled from 'styled-components';

export const PageOverview = styled.aside`
	background-color: ${props => props.theme.colors.white};
	padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space3}rem;

	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
		> :not(:last-child) {
			margin-bottom: ${props => props.theme.spacing.space2}rem;
		}
	}
`;


export const ElementContainer = styled.div`
	scroll-margin-top: 80px;
`;
