import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { selectLocalization } from 'store/modules/model';
import { ThemeContext } from 'styled-components';
import { breakpointsNumber } from 'theme/media-queries';
import { generateAnchorId } from 'theme/utils';
import { NavigationItem } from 'types/epi';
import {
	BlockBaseFragmentModelTypes,
	FragmentListModel,
	FragmentModelTypes,
} from 'types/fragments';
import { translate } from 'utils/helper-utils';
import { PageOverviewPuff } from './PageOverviewPuff';
import { PageSectionContent } from './PageSectionContent';
import { SidebarRelatedPanel } from './SidebarRelatedPanel';
import { Category } from 'pages/microsite/MicrositeArticlePage/MicrositeArticlePage.model';

type OnPageNavigationContentProps = {
	headingLevel: number;
	heading?: string | null;
	heading_htmlAttributes?: any;
	lastReviewed?: string | null;
	showReviewDate?: boolean | null;
	preamble?: string | null;
	preamble_htmlAttributes?: any;
	textAsModel?: FragmentListModel | null;
	textAsModel_htmlAttributes?: any;
	sections?: string[];
	relatedEnvironmentGoalsLabel?: string;
	relatedEnvironmentGoals?: string[];
	relatedSustainabilityGoalsLabel?: string;
	relatedSustainabilityGoals?: string[];
	disableCustomHeadingLogic: boolean;
	publicationDate?: string;
	categories?: Category[];
};
/**
 * OnPageNavigationContent (Helper)
 */
export const OnPageNavigationContent: React.FC<OnPageNavigationContentProps> = ({
	heading,
	headingLevel,
	heading_htmlAttributes = {},
	lastReviewed,
	showReviewDate,
	preamble,
	preamble_htmlAttributes = {},
	textAsModel,
	textAsModel_htmlAttributes,
	sections = [],
	relatedEnvironmentGoalsLabel,
	relatedEnvironmentGoals,
	relatedSustainabilityGoalsLabel,
	relatedSustainabilityGoals,
	disableCustomHeadingLogic,
	publicationDate,
	categories,
}) => {
	const themeContext = useContext(ThemeContext);

	const localization = useSelector(selectLocalization);
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const findOnPageLabel = translate(
		'/frontend/pages/findonpage',
		'Find on page',
		localization
	);

	const showRelatedPanel =
		(relatedEnvironmentGoals && relatedEnvironmentGoals.length > 0) ||
		(relatedSustainabilityGoals && relatedSustainabilityGoals.length > 0);

	const related = [
		{
			label: relatedEnvironmentGoalsLabel ? relatedEnvironmentGoalsLabel : '',
			items: relatedEnvironmentGoals ? relatedEnvironmentGoals : [],
		},
		{
			label: relatedSustainabilityGoalsLabel
				? relatedSustainabilityGoalsLabel
				: '',
			items: relatedSustainabilityGoals ? relatedSustainabilityGoals : [],
		},
	];

	const renderLeftcolumn = !isPhone || showRelatedPanel;

	return (
		<Grid paddingTop={false} paddingBottom={false}>
			{sections.length > 1 ? (
				<Grid inner={true}>
					{renderLeftcolumn && (
						<>
							<Cell span={4} phone={0}>
								{!isPhone && (
									<PageOverviewPuff
										headingLevel={headingLevel}
										heading={findOnPageLabel}
										items={sections}
									></PageOverviewPuff>
								)}

								{showRelatedPanel &&
									related.map((item, index) => {
										return (
											<Space
												key={index}
												top={isPhone ? 0 : themeContext.spacing.space5}
											>
												<SidebarRelatedPanel
													label={item.label}
													items={item.items}
												></SidebarRelatedPanel>
											</Space>
										);
									})}
							</Cell>
							<Cell span={1}> </Cell>
						</>
					)}
					<Cell span={7}>
						<PageSectionContent
							headingLevel={headingLevel}
							heading={heading}
							heading_htmlAttributes={heading_htmlAttributes}
							lastReviewed={lastReviewed}
							showReviewDate={showReviewDate}
							preamble={preamble}
							preamble_htmlAttributes={preamble_htmlAttributes}
							insideGrid={true}
							insideInnerGrid={true}
							insideCell={true}
							textAsModel={textAsModel}
							textAsModel_htmlAttributes={textAsModel_htmlAttributes}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
							publicationDate={publicationDate}
							categories={categories}
						></PageSectionContent>
					</Cell>
				</Grid>
			) : (
				<PageSectionContent
					headingLevel={headingLevel}
					heading={heading}
					heading_htmlAttributes={heading_htmlAttributes}
					lastReviewed={lastReviewed}
					showReviewDate={showReviewDate}
					preamble={preamble}
					preamble_htmlAttributes={preamble_htmlAttributes}
					insideGrid={true}
					insideInnerGrid={false}
					insideCell={false}
					textAsModel={textAsModel}
					textAsModel_htmlAttributes={textAsModel_htmlAttributes}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
					publicationDate={publicationDate}
					categories={categories}
				></PageSectionContent>
			)}
		</Grid>
	);
};

const instanceOfBlockBase = (item: FragmentModelTypes): boolean => {
	return (
		item.hasOwnProperty('onPageMenuVisibility') &&
		item.hasOwnProperty('onPageMenuHeading')
	);
};
const convertToBlockBaseInstance = (
	item: FragmentModelTypes
): BlockBaseFragmentModelTypes | null => {
	if (instanceOfBlockBase(item)) {
		return item as BlockBaseFragmentModelTypes;
	}

	return null;
};

export const getBlockBaseSections = (
	items: FragmentModelTypes[]
): BlockBaseFragmentModelTypes[] => {
	return items.filter((item) => {
		// Check if item is of BlockBaseFragmentModelType
		const blockBaseModel = convertToBlockBaseInstance(item);
		if (blockBaseModel) {
			return (
				blockBaseModel.onPageMenuVisibility && blockBaseModel.onPageMenuHeading
			);
		}
		return false;
	}) as BlockBaseFragmentModelTypes[];
};

export const getBlockBaseMenuItems = (items: string[]): NavigationItem[] => {
	return items.map((heading) => {
		return {
			url: '#' + generateAnchorId(heading),
			text: heading,
			isActive: false,
			style: 3,
			children: [],
			isRedirect: false,
		} as NavigationItem;
	});
};
