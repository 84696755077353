import React, { useContext } from 'react';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import { NVseGrantPageModel } from './NVseGrantPage.model';
import Space from 'components/Boilerplate/Space';
import TargetGroups from 'components/TargetGroups';
import BannerLinks from 'components/Banners/BannerLinks';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { ThemeContext } from 'styled-components';
import EditableField from 'components/EditableField/EditableField';

/**
 * # Målgruppssida för bidrag
 * Modeltype:<code>NVseGrantPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1663008769/NVseGrantPage+M+lgruppssida)
 *
 * Målgruppsspecifik sida för ett bidrag
 */
const NVseGrantPage: React.FC<NVseGrantPageModel> = ({
	heading,
	applyLink,
	textAsModel,
	lastReviewed,
	showReviewDate,
	siblingsList,
	bottomItems,
	targetGroupsText,
	applyScopeText,
	applyPeriodText,
	targetGroupsHeading,
	applyScopeHeading,
	applyPeriodHeading,
	preamble,
	sectionHeading,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;
	let blockTypeBefore_textAsModel: BlockType = lastBlockType;
	let blockTypeBefore_siblingsList: BlockType = lastBlockType;

	if (targetGroupsText || applyScopeText || applyPeriodText) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_textAsModel = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (siblingsList.items.length > 0) {
		lastBlockType = BlockType.SectionBanner;
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);
	const spaceBefore_siblingsList = getSpaceToAddBefore(
		blockTypeBefore_siblingsList,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					sectionHeading={sectionHeading}
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
					applyLink={applyLink}
					lastReviewed={lastReviewed}
					showReviewDate={showReviewDate}
				></GrantsHeader>
			</Grid>

			{(targetGroupsText || applyScopeText || applyPeriodText) && (
				<Space top={themeContext.spacing.getElement()}>
					<Grid paddingTop={false} paddingBottom={false}>
						<TargetGroups
							targetGroupsHeading={targetGroupsHeading}
							targetGroupsText={targetGroupsText}
							applyScopeHeading={applyScopeHeading}
							applyScopeText={applyScopeText}
							applyPeriodHeading={applyPeriodHeading}
							applyPeriodText={applyPeriodText}
							headingLevel={2}
						></TargetGroups>
					</Grid>
				</Space>
			)}

			<EditableField opeProperty={_properties?.mainBody}>
				<EpiFragments
					previousBlockType={blockTypeBefore_textAsModel}
					headingLevel={2}
					epi={epi}
					fragments={textAsModel.fragments}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			</EditableField>

			<EpiFragments
				previousBlockType={blockTypeBefore_bottomItems}
				epi={epi}
				headingLevel={2}
				fragments={bottomItems}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			/>

			{siblingsList.items?.length > 0 && (
				<Space top={spaceBefore_siblingsList}>
					<BannerLinks
						headingLevel={2}
						heading={siblingsList.heading}
						links={siblingsList.items}
					></BannerLinks>
				</Space>
			)}
		</Space>
	);
};

export default NVseGrantPage;
