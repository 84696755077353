import React, { createContext, useContext, useReducer } from 'react';

const FORM_UPDATE = 'FORM_UPDATE';
const FIELD_INVALID = 'FIELD_INVALID';
const FIELD_VALID = 'FIELD_VALID';

type InitialState = {
	values: any;
	validationMessages: any;
	invalidFields: string[];
};

interface Props {
	children?: any;
	initialState: InitialState;
}

type State = {
	values: any;
	validationMessages: any;
	invalidFields: string[];
};

type Action =
	| { type: 'FORM_UPDATE'; values: any }
	| { type: 'FIELD_INVALID'; field: any }
	| { type: 'FIELD_VALID'; field: any };

const FormReducer = (state: State, action: Action) => {
	switch (action.type) {
		case FORM_UPDATE:
			return {
				...state,
				values: action.values,
			};
		case FIELD_INVALID:
			const validationMessageHasNotChanged =
				state.validationMessages.hasOwnProperty(action.field.id) &&
				state.validationMessages[action.field.id].message &&
				state.validationMessages[action.field.id].message ===
					action.field.message;

			if (
				state.invalidFields.includes(action.field.id) &&
				validationMessageHasNotChanged
			) {
				return state;
			}

			const currentInvalidFields = state.invalidFields
				.slice(0)
				.filter((item: any) => {
					return item !== action.field.id;
				});

			return {
				...state,
				invalidFields: [...currentInvalidFields, action.field.id],
				validationMessages: {
					...state.validationMessages,
					[action.field.id]: action.field,
				},
			};
		case FIELD_VALID:
			const updatedFields = state.invalidFields.slice(0).filter((item: any) => {
				return item !== action.field.id;
			});

			const updatedMessages = { ...state.validationMessages };

			if (updatedMessages[action.field.id]) {
				delete updatedMessages[action.field.id];
			}

			return {
				...state,
				invalidFields: updatedFields,
				validationMessages: updatedMessages,
			};
		default:
			return state;
	}
};

export const FormContext = createContext<Partial<any[]>>([]);

export const FormProvider: React.FC<Props> = ({ initialState, children }) => (
	<FormContext.Provider value={useReducer(FormReducer, initialState)}>
		{children}
	</FormContext.Provider>
);

export const useFormValue = () => useContext(FormContext);
