import React, { useContext } from 'react';
import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { PaginationContainer } from './Pagination.styles';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { translate } from 'utils/helper-utils';
import { ThemeContext } from 'styled-components';

export interface Props {
	forwardButtonText?: string;
	backwardButtonText?: string;
	onPreviousSlide: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onNextSlide: (event: React.MouseEvent<HTMLButtonElement>) => void;
	inactivateForwardButton: boolean;
	inactivateBackwardButton: boolean;
}

const Pagination = ({
	forwardButtonText,
	backwardButtonText,
	onPreviousSlide,
	onNextSlide,
	inactivateForwardButton,
	inactivateBackwardButton,
}: Props) => {
	const localization = useSelector(selectLocalization);
	const themeContext = useContext(ThemeContext);
	const paginationNextLabel = forwardButtonText
		? forwardButtonText
		: translate('/frontend/components/pagination/next', 'Next', localization);
	const paginationPreviousLabel = backwardButtonText
		? backwardButtonText
		: translate(
				'/frontend/components/pagination/prev',
				'Previous',
				localization
		  );

	return (
		<PaginationContainer>
			<Button
				variant={
					themeContext.isMicrositeActive
						? ButtonVariant.Microsite
						: ButtonVariant.Tertiary
				}
				onClick={onPreviousSlide}
				disabled={inactivateBackwardButton}
			>
				{paginationPreviousLabel}
			</Button>

			<Button
				variant={
					themeContext.isMicrositeActive
						? ButtonVariant.Microsite
						: ButtonVariant.Tertiary
				}
				onClick={onNextSlide}
				disabled={inactivateForwardButton}
			>
				{paginationNextLabel}
			</Button>
		</PaginationContainer>
	);
};

export default Pagination;
