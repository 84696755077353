import { ThemeProps } from 'components/Banner/Banner';
import Space from 'components/Boilerplate/Space';
import Button from 'components/Button/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import ExpanderPrototype from 'components/ExpanderPrototype';
import { Heading } from 'components/Typography/Typography';
import { getThemeHeadingColor, PuffTheme } from 'pages/sharedModelTypes';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { px2rem } from 'theme/utils';
import { LegalBoxModel } from 'types/fragments';
import EpiFragments, { Options } from '../EpiFragments';
import { IconInfo } from 'components/Boilerplate/Icon/IconCollection';

export const GetLegalBoxModelElement = (
	legalBoxData: LegalBoxModel,
	options: Options,
	disableCustomHeadingLogic: boolean
) => {
	const themeContext = useContext(ThemeContext);

	const themeName = PuffTheme.BrownLight;

	const headingElement = (
		<LegalBoxHeaderContainer>
			<LegalParagraphStyle themeName={themeName}>
				{legalBoxData.showIcon ? <IconInfo width="36" height="36" /> : '§'}
			</LegalParagraphStyle>
			<Heading
				level={options.headingLevel}
				color={getThemeHeadingColor(themeContext, themeName)}
				styleLevel={4}
				zeroBottom={true}
			>
				{legalBoxData.name}
				{legalBoxData.sfsNumber && <br />}
				{legalBoxData.sfsNumber && legalBoxData.sfsNumber}
			</Heading>
		</LegalBoxHeaderContainer>
	);
	return (
		<ExpanderPrototype
			heading={headingElement}
			themeHeader={themeName}
			themeContent={PuffTheme.BrownLight}
			isOpen={!legalBoxData.showAsClosed}
		>
			<EpiFragments
				fragments={legalBoxData.textAsModel.fragments}
				insideCell={options.insideCell}
				insideGrid={options.insideGrid}
				insideInnerGrid={options.insideInnerGrid}
				headingLevel={options.headingLevel}
				disableCustomHeadingLogic={disableCustomHeadingLogic}
			></EpiFragments>

			{legalBoxData.link && (
				<Space top={themeContext.spacing.space5}>
					<Button variant={ButtonVariant.Secondary} to={legalBoxData.link.url}>
						{legalBoxData.link.heading}
					</Button>
				</Space>
			)}
		</ExpanderPrototype>
	);
};

const LegalBoxHeaderContainer = styled.div`
	display: flex;
`;

const LegalParagraphStyle = styled.div<ThemeProps>`
	${(props) => props.theme.typography.style5.getStyle()};
	line-height: unset;
	font-size: ${px2rem(52)};
	color: ${(p) => getThemeHeadingColor(p.theme, p.themeName)};

	margin-right: ${(props) => props.theme.spacing.space3}rem;

	svg {
		transform: rotate(0deg);
	}
`;
