/**
 * Tab
 */

import Icon from 'components/Boilerplate/Icon';
import React, { useEffect, useRef, useState } from 'react';

import {
	TabListActionsStyle,
	TabListItemContainerStyle,
	TabListStyle,
	TabPanelStyle,
} from './Tab.styles';

export type TabProps = {
	defaultTabId: string;
	label: string;
	autoSelect?: boolean;
	onSelect?: (panelId: string) => void;
	items: TabItem1[];
};

export type TabItem1 = {
	heading: string;
	id: string;
	iconName: any;
};

/** A Tab Component */
const Tab: React.FC<TabProps> = ({
	defaultTabId,
	label,
	autoSelect = false,
	items = [],
	onSelect,
	children,
}) => {
	const onTabListClick = (panelId: string) => {
		if (onSelect) {
			onSelect(panelId);
		}
	};

	return (
		<TabList
			autoSelect={autoSelect}
			selectedId={defaultTabId}
			label={label}
			onClick={onTabListClick}
		>
			{items.map((item, index) => {
				return (
					<TabItem key={index} panelId={item.id} text={item.heading}>
						{item.iconName && <Icon size={2} icon={item.iconName} />}
					</TabItem>
				);
			})}
			{children && <TabListActionsStyle>{children}</TabListActionsStyle>}
		</TabList>
	);
};

export default Tab;

/**
 * TabList
 */

type TabListProps = {
	label: string;
	onClick?: (panelId: string) => void;
	selectedId: string;
	autoSelect: boolean;
};

const TabList: React.FC<TabListProps> = ({
	onClick,
	selectedId,
	label,
	autoSelect,
	children,
}) => {
	const [selectedPanelId, setSelectedPanelId] = useState<string | undefined>(
		selectedId
	);
	const [focusedPanelId, setFocusedPanelId] = useState<string | undefined>();
	const [panelIds, setPanelIds] = useState<string[]>([]);
	const [elements, setElements] = useState<any>([]);

	useEffect(() => {
		setSelectedPanelId(selectedId);
	}, [selectedId]);

	useEffect(() => {
		const onTabItemClick = (panelId: string) => {
			setSelectedPanelId(panelId);

			if (onClick) {
				onClick(panelId);
			}
		};

		const handleChildGotFocus = (panelId: string) => {
			setFocusedPanelId(panelId);
		};
		const handleChildOnFocused = (panelId: string, value: boolean) => {
			if (focusedPanelId === panelId && !value) {
				setFocusedPanelId(undefined);
			}
		};

		const updateChildren = () => {
			const panels: string[] = [];
			const result = React.Children.map(
				children as any,
				(child: React.ReactElement) => {
					if (!child) return child;
					if (!child.props.panelId) {
						return child;
					}

					panels.push(child.props.panelId);
					const newChild = React.cloneElement(child as any, {
						selected: selectedPanelId === child.props.panelId,
						focused: focusedPanelId === child.props.panelId,
						onClick: onTabItemClick,
						gotFocus: handleChildGotFocus,
						onFocused: handleChildOnFocused,
					});
					return newChild;
				}
			);

			setPanelIds(panels);
			setElements(result);
		};

		updateChildren();
	}, [focusedPanelId, selectedPanelId, children, onClick]);

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		if (panelIds.length === 0) return;
		if (focusedPanelId === undefined) return;
		let currentFocusIndex = -1;
		let newFocusIndex = 0;

		// TODO: Only handle key events when focus in on a tab

		switch (event.key) {
			case 'Enter':
			case ' ':
				event.preventDefault();

				setSelectedPanelId(focusedPanelId);
				setFocusedPanelId(focusedPanelId);

				if (onClick && focusedPanelId) {
					onClick(focusedPanelId);
				}

				break;
			case 'Home':
				event.preventDefault();
				setFocusedPanelId(panelIds[0]);
				if (autoSelect) {
					setSelectedPanelId(panelIds[newFocusIndex]);
					if (onClick) {
						onClick(panelIds[newFocusIndex]);
					}
				}
				break;
			case 'End':
				event.preventDefault();
				setFocusedPanelId(panelIds[panelIds.length - 1]);
				if (autoSelect) {
					setSelectedPanelId(panelIds[newFocusIndex]);
					if (onClick) {
						onClick(panelIds[newFocusIndex]);
					}
				}
				break;

			case 'ArrowLeft':
				event.preventDefault();

				currentFocusIndex = focusedPanelId
					? panelIds.indexOf(focusedPanelId)
					: -1;
				newFocusIndex = 0;

				if (currentFocusIndex !== -1) {
					if (currentFocusIndex === 0) {
						newFocusIndex = panelIds.length - 1;
					} else {
						newFocusIndex = currentFocusIndex - 1;
					}
				}

				setFocusedPanelId(panelIds[newFocusIndex]);
				if (autoSelect) {
					setSelectedPanelId(panelIds[newFocusIndex]);
					if (onClick) {
						onClick(panelIds[newFocusIndex]);
					}
				}
				break;
			case 'ArrowRight':
				event.preventDefault();
				currentFocusIndex = focusedPanelId
					? panelIds.indexOf(focusedPanelId)
					: -1;
				newFocusIndex = 0;

				if (currentFocusIndex !== -1) {
					if (currentFocusIndex < panelIds.length - 1) {
						newFocusIndex = currentFocusIndex + 1;
					}
				}

				setFocusedPanelId(panelIds[newFocusIndex]);
				if (autoSelect) {
					setSelectedPanelId(panelIds[newFocusIndex]);
					if (onClick) {
						onClick(panelIds[newFocusIndex]);
					}
				}
				break;

			default:
				break;
		}
	};

	return (
		<>
			<TabListStyle
				onKeyDown={handleContainerKeyPress}
				role="tablist"
				aria-label={label}
			>
				{elements}
			</TabListStyle>
		</>
	);
};

/**
 * TabItem
 */

export type SelectedItem = {
	selected: boolean;
};

export type TabItemProps = {
	panelId: string;
	text: string;
	selected?: boolean;
	focused?: boolean;
	onClick?: (panelId: string) => void;
	gotFocus?: (panelId: string) => void;
	onFocused?: (panelId: string, value: boolean) => void;
};

export const TabItem: React.FC<TabItemProps> = ({
	children,
	text,
	panelId,
	selected = false,
	focused = false,
	onClick,
	onFocused,
	gotFocus,
}) => {
	const [isSelected, setIsSelected] = useState(selected);
	const [hasFocus, setHasFocus] = useState(focused);

	const prevAmount = useRef({ isSelected, hasFocus }).current;

	let reference = React.createRef<HTMLDivElement>();

	useEffect(() => {
		if (prevAmount.isSelected !== isSelected) {
			// nothing
		}
		if (prevAmount.hasFocus !== hasFocus) {
			if (hasFocus) {
				if (gotFocus) {
					gotFocus(panelId);
				}
				(reference.current as any).focus();
			}
		}
		return () => {
			prevAmount.isSelected = isSelected;
			prevAmount.hasFocus = hasFocus;
		};
	}, [
		gotFocus,
		reference,
		panelId,
		prevAmount,
		isSelected,
		hasFocus,
		onFocused,
	]);

	useEffect(() => {
		setIsSelected(selected);
		setHasFocus(focused);
	}, [selected, focused]);

	const onTabItemClick = () => {
		if (onClick) {
			onClick(panelId);
		}
	};

	const handleOnFocus = () => {
		setHasFocus(true);
		if (onFocused) {
			onFocused(panelId, true);
		}
	};

	const handleOnBlur = (event: any) => {
		setHasFocus(false);
		if (onFocused) {
			onFocused(panelId, false);
		}
	};

	return (
		<TabListItemContainerStyle
			ref={reference}
			selected={selected ? true : false}
			onClick={onTabItemClick}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			tabIndex={selected ? 0 : -1}
			role="tab"
			aria-selected={selected}
			aria-controls={panelId}
		>
			<>
				{children}
				{text}
			</>
		</TabListItemContainerStyle>
	);
};

/**
 * TabPanel
 *
 * When active or selected
 *  aria-expanded : true
 *  hidden : false
 * otherwise the reverse
 */
type TabPanelProps = {
	panelId: string;
	selected?: boolean;
};
export const TabPanel: React.FC<TabPanelProps> = ({
	panelId,
	selected = false,
	children,
}) => {
	return (
		// aria-expanded={selected}

		<TabPanelStyle id={panelId} role="tabpanel" hidden={!selected}>
			{children}
		</TabPanelStyle>
	);
};

type TabListMobileProps = {
	panelId: string;
	selected?: boolean;
};
export const TabListMobile: React.FC<TabListMobileProps> = ({
	panelId,
	selected = false,
	children,
}) => {
	return (
		// aria-expanded={selected}

		<TabPanelStyle id={panelId} role="tabpanel" hidden={!selected}>
			{children}
		</TabPanelStyle>
	);
};
