/**
 * DateSelectDropdown - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const DateSelectDropdownStyle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 16px 0;
`;

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SelectLabel = styled.span`
	${(props) => props.theme.typography.style13.getStyle()};
	font-weight: 400;
`;

export const Separator = styled.div`
	background-color: #979797;
	height: 2px;
	width: 25px;
	margin: 16px 10px 0 0;
	${mediaQueries.phone} {
		margin: 16px 10px 0 10px;
	}
`;

export const CTAButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-bottom: 8px;
	padding-right: 14px;
	${mediaQueries.phone} {
		padding-right: 4px;
	}

	.btnResetDisabledStyle {
		background-color: ${(props) => props.theme.colors.white};
		color: ${(props) => props.theme.colors.grey3};
		cursor: not-allowed;
		${mediaQueries.phone} {
			border: 1px solid ${(props) => props.theme.colors.grey3};
		}
	}

	.btnAddDisabledStyle {
		background-color: ${(props) => props.theme.colors.white};
		color: ${(props) => props.theme.colors.grey3};
		border: 1px solid ${(props) => props.theme.colors.grey3};
		cursor: not-allowed;
		&:hover {
			background-color: ${(props) => props.theme.colors.white};
			color: ${(props) => props.theme.colors.grey3};
			border: 1px solid ${(props) => props.theme.colors.grey3};
		}
	}

	.btnAddStyle {
		font-weight: 700;
		font-size: 12px;
		width: 83px;
		height: 36px;
		padding: 0;
		padding-left: 16px;
		span {
			margin: 0;
			line-height: 0;
		}
	}
`;

export const ButtonReset = styled.button`
	${(props) => props.theme.typography.style13.getStyle()};
	font-weight: 400;
	color: ${(props) => props.theme.colors.secondaryBlue};
	border: none;
	background-color: transparent;
	margin-right: 8px;
	cursor: pointer;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	${mediaQueries.phone} {
		color: ${(props) => props.theme.palette.text.primary};
		border: 1px solid ${(props) => props.theme.colors.grey1};
		padding: 6px 24px;
		border-radius: 25px;
		margin-right: 4px;
	}
`;
