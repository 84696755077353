import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const MainContainer = styled.div`
    background-color: #accfdb;
    display: flex;
    padding: 1rem 2rem;
`

export const Content = styled.p`
    p{
        margin: 0;
        font-size: 1.125rem;
        font-weight: 400;
        font-family: 'Tiempos Text', 'Times New Roman';
    }
`

export const ContentWrapper = styled.div`
    max-width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        margin-right: 1rem;
    }

    ${mediaQueries.phone} {        
        max-width: 95%;
        flex-direction: column;
        
        button {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0.75rem;
            span {
                text-align: center;
                width: 100%;
            }        
        }
	}
`

export const CloseButton = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {        
        svg  {
            fill: white;
        }
    }
`

export default {
    MainContainer,
    Content,
    ContentWrapper,
    CloseButton
}