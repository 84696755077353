/**
 * StandardPuff - styles
 */

import Image from 'components/Boilerplate/Image';
import Link from 'components/Boilerplate/Link';
import { Heading } from 'components/Typography/Typography';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { gridMaxWidth } from 'theme/grid';
import { getLinkArrowStyle } from 'theme/styles';
import { px, px2rem } from 'theme/utils';

export const StandardPuffStyle = styled(Link)`
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;
	flex-basis: 300px;
	flex-shrink: 1;
	flex-grow: 1;

	${mediaQueries.tabletOrLarger} {
		flex-basis: calc(33.33% - 16px);
		min-width: 300px;
		flex-shrink: 0;
		flex-grow: 0;
	}

	&:after {
		content: none;
	}

	&:hover {
		text-decoration: none;
	}
`;

export const StandardPuffImageStyle = styled.div`
	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
`;

export const StandardPuffColumnReverse = styled.div`
	display: flex;
	flex-direction: column-reverse;
	flex-grow: 1;
`;

export const StandardPuffTextContainerStyle = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const StandardPuffTimeStyle = styled.span<HasImgBool>`
	background: ${(props) => props.theme.colors.commonBgColor};
	font-family: ${(props) => props.theme.typography.primaryFontFamily};
	font-size: ${px2rem(16)};
	color: ${(props) => props.theme.palette.text.primary};
	${(props) =>
		props.hasImage
			? css`
					position: absolute;
					padding: 2px 5px;
					left: 8px;
					top: -1.5rem;
			  `
			: css`
					position: relative;
			  `};
`;

export type HasImgBool = {
	hasImage?: boolean;
};

export const StandardPuffHeaderStyle = styled(Heading)`
	margin-top: ${(props) => props.theme.spacing.space2}rem;

	${getLinkArrowStyle()}

	${StandardPuffStyle}:hover &,${StandardPuffStyle}:focus & {
		text-decoration: underline;
		&:after {
			opacity: 1;
		}
	}
`;
export const StandardPuffPreambleStyle = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space2}rem;
	max-width: 65ch;
`;
export const StandardPuffMetadataStyle = styled.div``;

//-------
export type puffContent = {
	preamble: string | null;
};
export const StandardPuffContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: ${(props) =>
		(px(gridMaxWidth - 16 * 2) - props.theme.spacing.space2 * 2) / 3}rem;

	${mediaQueries.phone} {
		flex-direction: row;
		min-height: unset;
		border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
		align-items: flex-start;
		padding: ${(props) => props.theme.spacing.space2}rem 0
			${(props) => props.theme.spacing.space2}rem 0;
		width: 100%;
	}
`;

export const StandardPuffImage = styled(Image)<puffContent>`
	margin: 0px;
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;

	${mediaQueries.phone} {
		margin-top: ${(props) => props.theme.spacing.space1}rem;
		flex: 1;
		height: unset;
		width: 122px;
		${(p) =>
			!p.preamble &&
			`
		margin: 0; 
		`};
	}
`;

export const StandardPuffContent = styled.div<puffContent>`
	${mediaQueries.phone} {
		${(p) =>
			!p.preamble &&
			`
		align-self: center; 
		`};
		flex: 2;
		padding: 0 0 0 ${(props) => props.theme.spacing.space2}rem;
	}
`;

export const StandardPuffTitleLink = styled(Link)`
	${(props) => props.theme.typography.style4.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
`;

export const StandardPuffPreamble = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	margin-top: ${(props) => props.theme.spacing.space1}rem;
	padding: 0;
`;
