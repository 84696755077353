import { createGlobalStyle } from 'styled-components';
import {
	styleForInput,
	styleForLabel,
	styleForP,
	styleForSelect,
} from 'components/Typography';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from './styles';

// Microsite Fonts for Delegationen För Circulär Ekonomi
import gtWalsheimRegularWoff from 'fonts/GT-Walsheim-Regular.woff';
import gtWalsheimRegularWoff2 from 'fonts/GT-Walsheim-Regular.woff2';
import gtWalsheimMediumWoff from 'fonts/GT-Walsheim-Medium.woff';
import gtWalsheimMediumWoff2 from 'fonts/GT-Walsheim-Medium.woff2';
import gtWalsheimBoldWoff from 'fonts/GT-Walsheim-Bold.woff';
import gtWalsheimBoldWoff2 from 'fonts/GT-Walsheim-Bold.woff2';

import notoSansRegularWoff from 'fonts/NotoSans-Regular.woff';
import notoSansRegularWoff2 from 'fonts/NotoSans-Regular.woff2';
import notoSansBoldWoff from 'fonts/NotoSans-Bold.woff';
import notoSansBoldWoff2 from 'fonts/NotoSans-Bold.woff2';

// Microsite Fonts for Fjallsakerhetsradet.se
import libreFranklinLight from 'fonts/LibreFranklin-Light.ttf';
import playfairDisplay from 'fonts/PlayfairDisplay-Bold.ttf';
import oswaldLight from 'fonts/Oswald-Light.ttf';
import oswaldSemiBold from 'fonts/Oswald-SemiBold.ttf';

export type modalWindow = {
	isOpen?: boolean;
};

export const MicrositeGlobalStyle = createGlobalStyle<any>`	

	@font-face {
		font-family: 'Noto Sans';
		src: url(${notoSansRegularWoff2}) format('woff2'),
			url(${notoSansRegularWoff}) format('woff');
		font-weight: 400;
		font-style: normal;
	}
	
	@font-face {
		font-family: 'Noto Sans';
		src: url(${notoSansBoldWoff2}) format('woff2'),
			url(${notoSansBoldWoff}) format('woff');
		font-weight: 700;
		font-style: normal;
	}

	@font-face {
		font-family: 'GT Walsheim';
		src: url(${gtWalsheimRegularWoff2}) format('woff2'),
			url(${gtWalsheimRegularWoff}) format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'GT Walsheim';
		src: url(${gtWalsheimMediumWoff2}) format('woff2'),
			url(${gtWalsheimMediumWoff}) format('woff');
		font-weight: 500;
		font-style: normal;
	}

    @font-face {
		font-family: 'GT Walsheim';
		src: url(${gtWalsheimBoldWoff2}) format('woff2'),
			url(${gtWalsheimBoldWoff}) format('woff');
		font-weight: 700;
		font-style: normal;
	}

	@font-face {
		font-family: 'Libre Franklin';
		src: url(${libreFranklinLight}) format('truetype');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Playfair Display';
		src: url(${playfairDisplay})  format('truetype');
		font-weight: 700;
		font-style: normal;
	}
	
	@font-face {
		font-family: 'Oswald';
		src: url(${oswaldSemiBold})  format('truetype');
		font-weight: 600;
		font-style: normal;
	}
	
	@font-face {
		font-family: 'Oswald-Light';
		src: url(${oswaldLight}) ) format('truetype');
		font-weight: 100;
		font-style: normal;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
	html {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		width: 100vw;
		overflow-x: hidden;

		/* Font smoothing */
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smooth: antialiased;
		-webkit-font-smoothing: antialiased;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
	}
	body {
		margin: 0;
		padding: 0;
		background-color: ${(props) => props.theme.colors.commonBgColor}; 
		color: ${(props) => props.theme.palette.text.primary};

		${(p) => p.isOpen && `top: -${window.scrollY}px`};
		${(p) => p.isOpen && 'position: fixed; right:0;left:0; overflow-y: hidden'};

		& label,& input, & textarea, & a, & select, & button, & li {
			outline-color:transparent;
		}

		& input:focus, & textarea:focus, & a:focus, & select:focus, & button:focus {
			${accessibilityFocus()};
		}

	}
	h1,h2,h3,h4,h5,h6,a,p {
		word-break: normal;
		overflow-wrap: break-word;
		hyphens: manual;
	}

	img {
		border: 0 none;
		height: auto;
		max-width: 100%;
		vertical-align: middle;
	}
	.usability--tab-focus {
		outline: 0.2rem solid ${(props) => props.theme.colors.primary};
	}
				
					
	.microsite-skip-to-content-link {
		${(props) => props.theme.typography.style8.getStyle()};
		color: ${(props) => props.theme.palette.text.secondary};
		padding: 8px;
		position: absolute;
		background-color: ${(props) => props.theme.colors.primary};
		left: 0%;
		transform: translateY(-100%);
		transition: transform 200ms;
		clip: rect(0 0 0 0);
		outline: none;
	}	
	.microsite-skip-to-content-link:focus {
  		transform: translateY(0%);
		 width: 100%;
    	text-align: center;		  
	    z-index: 1;		  
		clip: auto;
		outline: 2px solid;
    	outline-offset: 0px;
    	outline-color: white;
	}						

	form {

		header {
			margin-bottom: ${(props) => props.theme.spacing.space3}rem;
		}

		button {
			${(props) => props.theme.typography.style11.getStyle()};
			border-radius: 24px;
			border: 1px solid transparent;
			padding:0;

			padding-top: 16px;
			padding-bottom: 11px;

			padding-left: 20px;
			padding-right: 20px;
			background-color: ${(props) => props.theme.colors.white};
			color: ${(props) => props.theme.palette.primary};

			&:hover {
				background-color: ${(props) => props.theme.colors.primary};
			}
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}

		input, textarea {
			${styleForInput}			
			border: 1px solid ${(props) => props.theme.colors.commonAccentHue};
		}

		input[type="file"] {
			${styleForInput}
			border: none;
			padding: 0;
			width: 100%;
		}		

		fieldset {
			border: none;
	    	padding: 0;		
		}

		abbr[title], acronym[title] {
			text-decoration: unset;
		}

		label, legend { 
			display: block;
			text-align: left;
			${(props) => props.theme.typography.style11.getStyle()};
			color: ${(props) => props.theme.palette.text.primary};
			
			margin-right: ${(props) => props.theme.spacing.space1}rem;
			margin-bottom: ${(props) => props.theme.spacing.space1}rem;
		}

		select {
			${styleForSelect}
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}

		.usability--tab-focus {

		}

		.radio {
		
			position: relative;
			display: flex;

			&:focus-within {
				${accessibilityFocus()};
			}

			label {
				${(props) => props.theme.typography.style6.getStyle()};
    			display: block;
    			padding: 0px 0 0px 32px;
				margin:0px 0px 5px 0px;
	
			}
	


			/*style and hide original checkbox*/
			input {
				height: 10px;
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				width: 10px;
			}

			/*position new box*/
			input + label::before {
				border: 2px solid ${(props) => props.theme.colors.primary};
				content: "";
				height: 24px;
				left: 0;
				position: absolute;
				top: 0;
				width: 24px;
				border-radius: 50%;
			}

			/*radio pseudo element styles*/
			input + label::after {
				content: "";
				opacity: 0;
				border: 5px solid ${(props) => props.theme.colors.primary};
				border-radius: 50%;
				position: absolute;
				left: 7px;
				top: 7px;
				transition: opacity 0.2s ease-in-out;
			}

			/*reveal check for 'on' state*/
			input:checked + label::after {
				opacity: 1;
			}
		}

		.block {
			display:block;
		}

		.text-description {
			${(props) => props.theme.typography.style9.getStyle()};
			margin-bottom:${(props) => props.theme.spacing.space1}rem;

		}
		
		// Epi forms
		.text-red-600 {
			${(props) => props.theme.typography.style9.getStyle()};
			color: ${(props) => props.theme.palette.text.error};
			margin-top:${(props) => props.theme.spacing.space1}rem;
			${mediaQueries.phone} {
				color: ${(props) => props.theme.palette.text.error};
			}

		}
		.warning-symbol::before {
			content:"\u2022\u00a0"
		}

		.border-red-400 {
			padding: ${(props) => props.theme.spacing.space3}rem;
			border: 2px solid ${(props) => props.theme.colors.accentRed};
			border-left: 8px solid ${(props) => props.theme.colors.accentRed};
			background-color: ${(props) => props.theme.colors.white};
			display: inline-block;
			box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.03);
		}

		.border-green-400 {
			padding: ${(props) => props.theme.spacing.space3}rem;
			border: 2px solid ${(props) => props.theme.colors.primary};
			border-left: 8px solid ${(props) => props.theme.colors.primary};
			background-color: ${(props) => props.theme.colors.white};
			display: block;
			box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.03);
		}

		.border-green-400 > div {
			${styleForP}
		}

		// Epi forms error ul list
		ul {
			list-style-position: outside;
			margin: 0;
			padding: 0;
			padding-left: ${(props) => props.theme.spacing.space2 + 0.25}rem;
		}

		li {
			${styleForP}
			margin-bottom: 0;
		}


		li > span {
			color: ${(props) => props.theme.palette.text.error};
			${mediaQueries.phone} {
				color: ${(props) => props.theme.palette.text.error};
			}

		}
}


`;
