import Link from 'components/Boilerplate/Link';
import React from 'react';
import { RegulationHistoryDescription } from './RegulationHistoryDescription';
import { RegulationHistoryNarrowStyle } from './RegulationHistoryNarrow.styles';
import { RegulationHistoryTableProps } from './RegulationHistoryTable';

/**
 * RegulationHistoryNarrow
 * (mobile view)
 */
export const RegulationHistoryNarrow: React.FC<RegulationHistoryTableProps> = ({
	titleLabel,
	validFromDateLabel,
	downloadPdfLinkLabel,
	items,
}) => {
	return (
		<RegulationHistoryNarrowStyle>
			{items.map((item, index) => {
				return (
					<li key={index}>
						<label>{titleLabel}</label>
						<RegulationHistoryDescription
							{...item}
						></RegulationHistoryDescription>
						<label>{validFromDateLabel}</label>
						{new Date(item.validFromDate).toLocaleDateString()}
						<label>{downloadPdfLinkLabel}</label>
						{item.downloadPdfLink && (
							<Link
								to={item.downloadPdfLink.url}
								external={item.downloadPdfLink.isFile}
							>
								{item.downloadPdfLink.heading}
							</Link>
						)}
					</li>
				);
			})}
		</RegulationHistoryNarrowStyle>
	);
};
