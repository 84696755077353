import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { grid, mediaQueries } from 'theme';

export const FooterLinksContainer = styled.div<ThemeProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};

	padding-top: ${props => props.theme.spacing.space7}rem;
	padding-bottom: ${props => props.theme.spacing.space7}rem;

	${mediaQueries.phone} {
		padding-top: ${grid.gutter.phone};
		padding-bottom: ${grid.gutter.phone};
	}
`;
