import React from 'react';
import { LinkExtendedType, LinkType, PuffTheme } from 'pages/sharedModelTypes';
import { BannerFragmentModel } from 'types/fragments';
import BannerTextLinks from 'components/Banners/BannerTextLinks';
import BannerPuffText from 'components/Banners/BannerPuffText';
import { Options } from '../EpiFragments';

export const GetBannerModelElement = (
	bannerData: BannerFragmentModel,
	options: Options
) => {
	if (bannerData.linkList && bannerData.linkList.length > 0) {
		const links = bannerData.linkList.map((item: LinkExtendedType) => {
			return {
				heading: item.text,
				url: item.url,
				isFile: item.isFile,
				target: item.target,
			} as LinkType;
		});

		return (
			<BannerTextLinks
				themeName={bannerData.theme as PuffTheme}
				headingLevel={options.headingLevel}
				heading={bannerData.heading}
				preamble={bannerData.preamble}
				link={bannerData.link}
				links={links}
			></BannerTextLinks>
		);
	}

	return (
		<BannerPuffText
			themeName={bannerData.theme as PuffTheme}
			headingLevel={options.headingLevel}
			heading={bannerData.heading}
			preamble={bannerData.preamble}
			link={bannerData.link}
		></BannerPuffText>
	);
};
