/**
 * BannerPanel
 */
import React, { useContext } from 'react';
import {
	BannerPanelHeaderStyle,
	BannerPanelStyle,
	DecalStyle,
} from './BannerPanel.styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import {
	getThemeHeadingColor,
	getThemePrimaryButton,
	LinkType,
	PuffTheme,
} from 'pages/sharedModelTypes';
import { Heading } from 'components/Typography/Typography';
import { ThemeContext } from 'styled-components';

/** Text panel with header, text and an action */
export type BannerPanelProps = {
	themeName?: PuffTheme | null;
	/**
	 * Decal
	 */
	decal?: string | null;

	/**
	 * Heading level
	 */
	headingLevel?: number;

	/**
	 * Heading text
	 */
	heading?: string | null;
	/**
	 * text
	 */
	text?: string | null;
	/**
	 * Link
	 */
	link?: LinkType | null;

	/**
	 * Link to bottom
	 */
	alignbottom?: boolean;

	/**
	 * Center text
	 */
	center?: boolean;
};

/** This is the HeaderTextActionPanel component */
export const BannerPanel: React.FC<BannerPanelProps> = ({
	themeName,
	decal,
	headingLevel,
	heading,
	text,
	link,
	center,
	alignbottom,
}) => {
	const themeContext = useContext(ThemeContext);
	const primaryButtonVariant = getThemePrimaryButton(themeName);

	return (
		<BannerPanelStyle
			alignbottom={alignbottom}
			themeName={themeName}
			center={center}
		>
			<BannerPanelHeaderStyle>
				{decal && <DecalStyle themeName={themeName}>{decal}</DecalStyle>}
				{heading && (
					<Heading
						level={headingLevel}
						styleLevel={2}
						color={getThemeHeadingColor(themeContext, themeName)}
					>
						{heading}
					</Heading>
				)}
				{text && <Typography as="p">{text}</Typography>}
			</BannerPanelHeaderStyle>
			{alignbottom && <span></span>}
			{link && link.url && link.heading && (
				<Button
					variant={primaryButtonVariant}
					to={link.url}
					external={link.isFile ?? false}
				>
					{link.heading}
				</Button>
			)}
		</BannerPanelStyle>
	);
};

export default BannerPanel;
