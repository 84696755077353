import { Heading } from 'components/Typography/Typography';
import React from 'react';

/**
 * SubHeader
 */
type SubHeaderProps = {
	headingLevel?: number;
	heading: string;
	zeroBottomMargin?: boolean;
};

export const SubHeader: React.FC<SubHeaderProps> = ({
	heading,
	headingLevel,
	zeroBottomMargin,
}) => {
	if (heading) {
		return (
			<Heading
				level={headingLevel}
				styleLevel={4}
				zeroBottom={zeroBottomMargin}
			>
				{heading}
			</Heading>
		);
	}
	return null;
};
