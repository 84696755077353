import styled from 'styled-components';
import { mediaQueries } from 'theme';

export interface GrantsHeaderProps {
	cameFromSectionContent: boolean;
}

export const GrantsHeaderDecalStyle = styled.div`
	${(props) => props.theme.typography.style5.getStyle()};
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;
`;

export const GrantsHeaderLastReviwedStyle = styled.div<GrantsHeaderProps>`
	${(props) => props.theme.typography.style9.getStyle()};
	padding-top: ${(props) =>
		props.cameFromSectionContent ? '0' : props.theme.spacing.space2}rem;

	${mediaQueries.phone} {
		padding-top: ${(props) =>
			props.cameFromSectionContent ? '0' : props.theme.spacing.space1}rem;
	}
`;

export const GrantsHeaderCategoriesStyle = styled.p`
	margin: 0;
	${(props) =>
		props.theme?.isMicrositeActive
			? props.theme.typography.style6.getStyle()
			: props.theme.typography.style7.getStyle()};

	${mediaQueries.phone} {
		padding-top: ${(props) => props.theme.spacing.space1}rem;
	}
`;
