/**
 * MegaMenu - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { breakpointsNumber } from 'theme/media-queries';
import { accessibilityFocus } from 'theme/styles';
import { isRedirectProps, selectedProps } from './MegaMenu';

export const BackgroundOverlayStyle = styled.div<any>`
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;

	transition: background-color 200ms ease-in-out;
	background-color: ${({ state }) => {
		switch (state) {
			case 'entering':
			case 'entered':
				return 'rgba(0, 0, 0, 0.17);';
			case 'exiting':
			case 'exited':
				return 'rgba(0, 0, 0, 0);';
		}
	}};
`;

export const MegaMenuListsContainer = styled.div<any>`
	display: flex;
	height: 100vh;

	> *:not(:last-child) {
		border-right: 1px solid #eeeeee;
	}

	> div > div > button {
		text-transform: ${(props) =>
			props.theme.isMicrositeActive ? 'none' : 'uppercase'};
		margin-top: ${(props) => props.theme.spacing.space4}rem;
		margin-left: ${(props) => props.theme.spacing.space3}rem;
	}

	flex: 1;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;

	transition: transform 200ms ease-in-out;
	transform: ${({ state }) => {
		switch (state) {
			case 'entering':
			case 'entered':
				return 'translateX(0px)';
			case 'exiting':
			case 'exited':
				return 'translateX(-100%)';
		}
	}};
`;

export const MegaMenuHeaderContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 0px;
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;
`;

export const MegaMenuCloseContainer = styled.div`
	height: ${(props) => props.theme.spacing.space8}rem;
`;

export const MegaMenuListContainer = styled.div<any>`
	${(props) => props.theme.typography.style4.getStyle()};
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 30vw;
	max-width: 360px;

	${mediaQueries.phone} {
		width: 100vw;
		min-width: ${breakpointsNumber.phone}px;
		max-width: none;
	}

	${mediaQueries.tablet} {
		width: 100vw;
		min-width: ${breakpointsNumber.tablet}px;
		max-width: 50vw;
	}

	background-color: ${(props) => props.theme.colors.white};
	padding-bottom: ${(props) => props.theme.spacing.space5}rem;

	transition: transform 200ms ease-in-out;
	transform: ${({ state }) => {
		switch (state) {
			case 'entering':
			case 'entered':
				return 'translateX(0)';
			case 'exiting':
			case 'exited':
				return 'translateX(-100%)';
		}
	}};

	overflow: hidden;
	nav {
		overflow: auto;
	}
	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
		overflow-y: auto;
		flex: 1;
	}
`;

/**
 * Root (first level) menu list item that has children
 */
export const MegaMenuRootItem = styled.li<selectedProps>`
	display: flex;

	button {
		cursor: pointer;
		flex: 1;
		display: flex;
		text-align: left;
		border-radius: 0px;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 11px;
		padding-bottom: 12px;
		> *:first-child {
			flex: 1;
		}
		background-color: transparent;
		border: none;
		color: unset;
		${(props) => props.theme.typography.style4.getStyle()};
		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}

		> span {
			flex: 1;
			display: inline-block;
			width: 1px;
			font-weight: 400;
		}
	}

	background-color: ${(p) => (p.selected ? p.theme.colors.primary : 'white')};
	color: ${(p) =>
		p.selected ? p.theme.palette.text.secondary : p.theme.palette.text.primary};
	${(p) =>
		!p.selected && `&:hover {background-color: ${p.theme.colors.grey4}}`};

	svg {
		align-self: center;
		fill: ${(p) =>
			p.selected
				? p.theme.palette.text.secondary
				: p.theme.palette.text.primary};
	}
`;

export const MicrositeMegaMenuRootItem = styled.li<selectedProps>`
	display: flex;

	div {
		background-color: ${(p) =>
			p.selected ? p.theme.colors.secondary : 'white'};
		width: 5px;
	}

	button {
		cursor: pointer;
		flex: 1;
		display: flex;
		text-align: left;
		border-radius: 0px;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 11px;
		padding-bottom: 12px;
		> *:first-child {
			flex: 1;
		}
		background-color: transparent;
		border: none;
		color: unset;
		${(props) => props.theme.typography.style12.getStyle()};
		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}

		> span {
			flex: 1;
			display: inline-block;
			width: 1px;
		}
	}

	background-color: ${(p) => (p.selected ? p.theme.colors.tertiary : 'white')};
	color: ${(p) => p.theme.palette.text.primary};
	${(p) =>
		!p.selected && `&:hover {background-color: ${p.theme.colors.grey4}}`};

	svg {
		align-self: center;
		fill: ${(p) => p.theme.palette.text.primary};
	}
`;

/**
 * Root (first level) menu list link item
 */
export const MegaMenuRootLinkItem = styled.li<selectedProps>`
	a {
		display: block;
		${(props) => props.theme.typography.style4.getStyle()};
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 11px;
		padding-bottom: 12px;
		color: ${(props) => props.theme.palette.text.primary};
		text-decoration: none !important;

		width: 100%;

		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}
	}

	&:hover {
		background-color: ${(props) => props.theme.colors.grey4};
	}
`;

export const MegaMenuSiblingItem = styled(MegaMenuRootItem)`
	button {
		${(props) => props.theme.typography.style12.getStyle()};
		padding-top: 11px;
		padding-bottom: 10px;
		> span {
			flex: 1;
			display: inline-block;
			width: 1px;
		}
	}
`;

export const MegaMenuSiblingLinkItem = styled.li<isRedirectProps>`
	a {
		display: inline-block;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 6px;
		padding-bottom: 6px;
		vertical-align: middle;
		width: 100%;
		${({ color }) => color && `color: ${color};`}

		${({ isRedirect }) =>
			isRedirect &&
			`
		font-weight: 700;
		`}

		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}
	}
`;

export const MicrositeMegaMenuSiblingLinkItem = styled.li`
	a {
		display: inline-block;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 6px;
		padding-bottom: 6px;
		vertical-align: middle;
		width: 100%;
		color: ${(props) => props.theme.palette.text.primary};
		${(props) => props.theme.typography.style12.getStyle()};

		&:focus {
			${accessibilityFocus('inset')};
			outline-offset: -2px;
		}
	}
`;

export const MegaMenuAlternativSiblingLinkItem = styled.li`
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
	margin-top: 4px;

	a {
		display: inline-block;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 3px;
		padding-bottom: 2px;
		font-weight: bold;
		max-width: 100%;
		background-color: ${(props) => props.theme.colors.blue1};
		color: ${(props) => props.theme.colors.primary};
		border-radius: 16px;
	}
`;

export const MegaMenuErrorItem = styled.div`
	background-color: ${(props) => props.theme.colors.accentRed};
	color: ${(props) => props.theme.colors.white};
	padding-top: 7px;
	padding-bottom: 6px;
	padding-left: 25px;
	padding-right: 25px;
`;
