import styled from 'styled-components';

export const MessageContainer = styled.div`
	background-color: #ffc017;
	font-family: 'Tiempos Text', 'Times New Roman';
`;

export const TopBorder = styled.div`
	border-top: 1px solid #ffe69b;
`;

export const HeaderContainer = styled.div`
	padding: 1rem;
	margin: 0 auto;
	max-width: 1132px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MessageHeading = styled.h2`
	margin: 0;
	font-size: 1.17rem;
`;

export const BackgroundWrapper = styled.div`
	margin: 0 0 0 0;
	background-color: #ffe69b;
	display: flex;
	flex-direction: column;
`;

export const TextWrapper = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
	max-width: 1132px;
	padding: 0 1rem;
`;

export const LinkWrapper = styled.div`
	padding: 0 0 0.75rem 0;
`;

export const Text = styled.p``;
