import React from 'react';
import { LabelProps, ErrorSpanProps, DescriptionSpanProps } from './types';
import clsx from 'clsx';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const Label: React.FC<LabelProps> = ({
	htmlFor,
	required,
	children,
}) => (
	<label className="block font-bold mb-1" htmlFor={htmlFor}>
		{children}
		{required && <abbr title="Obligatoriskt">*</abbr>}
	</label>
);

export const ErrorSpan: React.FC<ErrorSpanProps> = ({
	fieldId,
	invalid,
	children,
	className,
	...props
}) => (
	<span
		className={clsx(className, `text-red-600`, 'warning-symbol')}
		id={`form${fieldId}__desc`}
		aria-live="polite"
		hidden={!invalid}
		{...props}
	>
		{children}
	</span>
);

export const DescriptionSpan: React.FC<DescriptionSpanProps> = ({
	className,
	children,
	...props
}) => (
	<span className={clsx(className, 'block', 'text-description')} {...props}>
		{children}
	</span>
);

type FieldContainerProps = {
	field: any;
	id?: string;
};

export const FieldContainer: React.FC<FieldContainerProps> = ({
	field,
	id,
	children,
}) => {
	const preferedWidth = field.displayOptions?.WideScreenColspan
		? field.displayOptions.WideScreenColspan
		: '100%';

	return (
		<FieldContainerStyle id={id} preferedWidth={preferedWidth}>
			{children}
		</FieldContainerStyle>
	);
};

type FieldContainerStyleProp = {
	preferedWidth: string;
};

const FieldContainerStyle = styled.div<FieldContainerStyleProp>`
	display: flex;
	flex-direction: column;
	margin-bottom: ${props => props.theme.spacing.space3}rem;
	flex-basis: ${(p) => p.preferedWidth};

	${mediaQueries.phone} {
		flex-basis: 100%;
		margin-right: 0;
	}
`;
