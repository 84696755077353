/**
 * JobListPage
 */

import React, { useState } from 'react';
import { Div, StyledTable, SeeMore, AdsDisplayed } from './JobListPage.styles';
import { breakpointsNumber } from 'theme/media-queries';
import { useMediaQuery } from 'react-responsive';

import { JobListItem } from '../../pages/NVseJobListPage/NVseJobListPage.model';
import { wrapElementInGrid } from 'components/Boilerplate/EpiFragments/EpiFragments';
import { IconChevron } from 'components/Boilerplate/Icon/IconCollection';

interface Props {
	/** Description of this property */
	hdAd: string;
	hdCity: string;
	hdEmployment: string;
	hdApplyDate: string;
	displayOfTotalLabel: string;
	seeAllLabel: string;
	data?: JobListItem[];
}

/** Main description for this component. */
const JobListPage: React.FC<Props> = ({
	hdAd,
	hdCity,
	hdEmployment,
	hdApplyDate,
	displayOfTotalLabel,
	seeAllLabel,
	data,
}) => {
	const [showMore, setShowMore] = useState(false);
	const [adsDisplayed, setAdsDisplayed] = useState(8);
	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const onClickSeeMore = (): void => {
		if (data) {
			setAdsDisplayed(data?.length);
			setShowMore(true);
		}
	};

	let table = (
		<>
			{isPhone && (
				<AdsDisplayed>
					{displayOfTotalLabel
						.replace('{displayed}', adsDisplayed.toString())
						.replace('{total}', data?.length.toString() ?? '0')}
				</AdsDisplayed>
			)}
			<StyledTable aria-label="jobb list">
				<thead>
					<tr>
						<th>{hdAd}</th>
						<th>{hdCity}</th>
						<th>{hdEmployment}</th>
						<th>{hdApplyDate}</th>
					</tr>
				</thead>

				<tbody>
					{isPhone && data
						? data.slice(0, showMore ? data.length : 8).map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<a href={`${item.url}`}>{item.title}</a>
										</td>
										<td>{item.areas}</td>
										<td>
											<div>
												<span>{hdApplyDate}</span>
												<span>{item.lastApplyDate}</span>
											</div>
										</td>
									</tr>
								);
						  })
						: data &&
						  data.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<a href={`${item.url}`}>{item.title}</a>
										</td>
										<td>{item.areas}</td>
										<td>{item.employment}</td>
										<td>
											{item.lastApplyDate}
										</td>
									</tr>
								);
						  })}
				</tbody>
			</StyledTable>
			{isPhone && !showMore && (
				<SeeMore onClick={onClickSeeMore}>
					{seeAllLabel}
					<IconChevron width="24px" height="24px" fill="#003366" />
				</SeeMore>
			)}
		</>
	);
	let element = wrapElementInGrid(table);
	return <Div>{element}</Div>;
};

export default JobListPage;
