/**
 * Puff - styles
 */

import styled from 'styled-components';

export const Block = styled.div`
	display: block;
	padding: 1rem;
	word-wrap: break-word;
`;
