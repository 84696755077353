/**
 * EnvironmentToaster - styles
 */

import styled from 'styled-components';

export const EnvironmentToasterContainer = styled.div`
	display: flex;
	position: relative;
	top: 0;

	justify-content: space-between;
	background-color: #f0f0f0;

	font-family: 'Courier New', Courier, monospace;
	font-size: 11px;
	line-height: normal;

	margin: 0px 0px;
	padding: 5px 18px;
	border-bottom: 1px solid #b0b0b0;
`;
