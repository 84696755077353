/**
 * EpiForms
 */

import React, { useReducer, useRef, useEffect, useContext } from 'react';

//import { useForceUpdate, useFormRenderer, usePrevious } from 'hooks';
import { getInitialFormValues, getStepFields } from './EpiForms.helpers';
import { submitForm } from './EpiForms.submit';

import { Form } from 'components/Form';
import FormStep from './FormStep';
import FormMessage from './FormMessage';
import { useForceUpdate } from 'hooks/useForceUpdate';
import { useFormRenderer } from 'hooks/useFormRenderer';
import { usePrevious } from 'hooks/usePrevious';
import Typography, { Heading } from 'components/Typography/Typography';
import { getThemeHeadingColor, PuffTheme } from 'pages/sharedModelTypes';
import { Options } from 'components/Boilerplate/EpiFragments/EpiFragments';
import { ThemeContext } from 'styled-components';

export interface EpiFormsProps {
	/** Form id */
	id: string;

	/** Fields within the Form */
	fields: any;

	/** Form steps */
	steps: any[];

	/** Message to show when successfully submitted */
	confirmationMessage: string | null;

	/** Form method */
	method?: string;

	/** Form action */
	action?: string;

	/** Form encoding type */
	encodingType?: string;

	/** Form dependencies */
	dependencies?: any[];

	/** Form title h(n) */
	title?: string | null;

	/** Form title heading level h(n)*/
	headingLevel?: number;

	/** Form description (p) */
	description?: string | null;

	/** Url to redirect to when successfully submitted */
	redirectUrl?: string;

	/** Epi properties for on-page editing */
	_properties?: any;

	options?: Options;

	[htmlAttributes: string]: any;
}

const initialState = {
	isLoading: false,
	invalidFields: [],
	validationMessages: {},
	successMessage: '',
	currentStep: 0,
	values: {},
	submissionId: '',
	serverValidationError: false,
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'UPDATE_STATE':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

/** Rendering of an EpiForms block. */
const EpiForms: React.FC<EpiFormsProps> = ({
	fields,
	method = 'GET',
	action,
	encodingType = 'multipart/form-data',
	steps,
	dependencies,
	id,
	title,
	description,
	confirmationMessage,
	redirectUrl,
	options,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { currentStep, successMessage, submissionId, invalidFields } = state;
	const themeContext = useContext(ThemeContext);

	const forceUpdate = useForceUpdate();
	const [renderFormElement, fieldActionExists] = useFormRenderer(
		fields,
		state,
		dependencies,
		disableCustomHeadingLogic,
		options
	);

	const showForm = !confirmationMessage && !successMessage;
	const formValues = getInitialFormValues(fields, currentStep);

	const messageHolderRef = useRef<any>(null);
	const prevInvalidFields = usePrevious(invalidFields);
	let prevStep = usePrevious(currentStep);

	if (prevStep === undefined) {
		prevStep = 0;
	}

	// Focus messageHolderRef if success or error
	useEffect(() => {
		const successfullySubmitted =
			invalidFields.length > 0 || confirmationMessage || successMessage;
		const formMessageElement = messageHolderRef && messageHolderRef.current;

		if (
			(successfullySubmitted && formMessageElement) ||
			prevInvalidFields !== invalidFields
		) {
			messageHolderRef.current.focus();
		}
		// eslint-disable-next-line
	}, [invalidFields, messageHolderRef, successMessage, confirmationMessage]);

	// Jump back to top if step changes
	useEffect(() => {
		if (window && prevStep !== currentStep && steps.length > 2) {
			window.location.href = `#${id}`;
		}
		// eslint-disable-next-line
	}, [currentStep]);

	// If we have dependencies (conditional fields), we re-render.
	const onChange = (field: any, values: any) => {
		if ((dependencies && dependencies.length > 0) || fieldActionExists) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: { values },
			});

			if (dependencies && dependencies.length > 0) {
				forceUpdate();
			}
		}
	};

	const onSubmit = (
		values: any,
		stepIndex: number,
		currentValidationMessages: any,
		currentInvalidFields: string[]
	) => {
		if (currentInvalidFields && currentInvalidFields.length > 0) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: {
					invalidFields: currentInvalidFields,
					validationMessages: currentValidationMessages,
				},
			});
			return;
		}

		submitForm(values, stepIndex, dispatch, steps, {
			currentStep,
			submissionId,
			action,
			method,
			redirectUrl,
		});
	};

	return (
		<Form
			initialValues={formValues}
			sendDataType="formdata"
			method={method}
			action={action}
			enctype={encodingType}
			onSubmit={onSubmit}
			onChange={onChange}
			multiStep={steps.length > 2}
			currentStep={currentStep}
			getStepFields={getStepFields}
			id={id}
		>
			<header>
				{title && (
					<Heading
						level={options?.headingLevel}
						styleLevel={2}
						color={getThemeHeadingColor(themeContext, PuffTheme.NeutralWhite)}
					>
						{title}
					</Heading>
				)}
				{description && <Typography as="p">{description}</Typography>}

				<FormMessage
					ref={messageHolderRef}
					headingLevel={
						options?.headingLevel ? options?.headingLevel + 1 : undefined
					}
					confirmationMessage={confirmationMessage}
					state={state}
				/>
			</header>

			{showForm && (
				<div
					style={{ display: 'flex', flexWrap: 'wrap' }}
					{..._properties?.fields}
				>
					{steps.map((step: any, i: number) =>
						step.index === -1 ? (
							<div id={`form-${id}-hiddenfields`} key="-1" hidden>
								{step.fields.map(renderFormElement)}
							</div>
						) : steps.length === 2 ? (
							step.fields.map(renderFormElement)
						) : (
							<FormStep
								key={i}
								step={step}
								nrOfSteps={steps.length}
								formId={id}
								currentStep={currentStep}
								onPrevious={(state: any) => {
									dispatch({
										type: 'UPDATE_STATE',
										payload: state,
									});
								}}
							>
								{step.fields.map(renderFormElement)}
							</FormStep>
						)
					)}
				</div>
			)}
		</Form>
	);
};

export default EpiForms;
