import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	FragmentModelTypes,
	ImageFragmentModel,
	StandardPuffModel,
} from 'types/fragments';
import { NVseNavigationPageModel } from './NVseNavigationPage.model';
import Space from 'components/Boilerplate/Space';
import { TopImage } from 'pages/NVseArticlePage/NVseArticlePage.styles';
import { ViewType } from 'types/enums';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { formatText } from 'utils/helper-utils';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { SubHeader } from 'components/Panels/SubHeader';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { ThemeContext } from 'styled-components';
import { useImageTitle } from 'hooks/useImageTitle';
import EditableField from 'components/EditableField/EditableField';

// Move to shared types
export type puffNavigationListItem = {
	heading: string;
	preamble: string;
	url: string;
	image: ImageFragmentModel;
};

// Move to shared types
export type puffNavigationList = {
	items: puffNavigationListItem[];
};

/**
 * # Navigeringssida
 * Modeltype:<code>NVseNavigationPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1631977500/NVseNavigationPage)
 *
 * Webbplatsens navigeringssida. Översiktssida där man på ett enkelt sätt kan länka vidare till andra sidor.
 */
const NVseNavigationPage: React.FC<NVseNavigationPageModel> = ({
	textAsModel,
	heading,
	preamble,
	puffNavigationList,
	displayedNavigationsText,
	puffNavigationListSizeOnLoad,
	puffNavigationListViewOnLoad,
	bottomItems,
	mainImage,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	// TODO: hardcoded text
	const showMoreLabel = 'Visa fler';

	const [fixedItems, setFixedItems] = useState<FragmentModelTypes[]>([]);
	const [selectedViewType, setSelectedViewType] = useState<ViewType>(
		ViewType.Card
	);
	const title = useImageTitle(
		mainImage?.photographer,
		mainImage?.agency,
		mainImage?.illustrator
	);

	useEffect(() => {
		switch (puffNavigationListViewOnLoad) {
			default:
			case 'card':
				setSelectedViewType(ViewType.Card);
				break;
			case 'list':
				setSelectedViewType(ViewType.List);
				break;
			case 'compact':
				setSelectedViewType(ViewType.CompactList);
				break;
		}
	}, [puffNavigationListViewOnLoad]);

	useEffect(() => {
		const tempItems = puffNavigationList.items.map((item) => {
			return {
				modelType: 'StandardPuffModel',
				heading: item.heading,
				preamble: item.preamble,
				url: item.url,
				image: item.image,
			} as StandardPuffModel;
		});

		setFixedItems(tempItems);
	}, [puffNavigationList, puffNavigationList.items]);

	const formattedHeader =
		displayedNavigationsText != null
			? formatText(
					displayedNavigationsText,
					fixedItems.length,
					fixedItems.length
			  )
			: null;

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_fixedItems: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			fixedItems,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_fixedItems = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}
	if (fixedItems.length > 0) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	const spaceBefore_fixedItems = getSpaceToAddBefore(
		blockTypeBefore_fixedItems,
		BlockType.Element,
		themeContext
	);
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			<EditableField opeProperty={_properties?.mainImage}>
				{mainImage && (
					<TopImage
						alt={mainImage.alt}
						src={mainImage.src}
						srcSet={mainImage.srcSet}
						title={title}
					/>
				)}
			</EditableField>

			<Space top={themeContext.spacing.getElement()} bottom={spaceAfterLast}>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						columns={8}
						headingLevel={1}
						heading={heading}
						preamble={preamble}
						heading_htmlAttributes={_properties?.heading}
						preamble_htmlAttributes={_properties?.preamble}
					></GrantsHeader>
				</Grid>

				<EditableField opeProperty={_properties?.mainBody}>
					{textAsModel && textAsModel.fragments.length > 0 && (
						<EpiFragments
							previousBlockType={BlockType.Element}
							epi={epi}
							headingLevel={2}
							fragments={textAsModel.fragments}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)}
				</EditableField>

				<Space top={spaceBefore_fixedItems}>
					<Grid paddingTop={false} paddingBottom={false}>
						{formattedHeader && (
							<SubHeader
								zeroBottomMargin={true}
								heading={formattedHeader}
								headingLevel={2}
							></SubHeader>
						)}

						{fixedItems && (
							<Space top={themeContext.spacing.space3}>
								<FragmentPuffList
									initialMaxCount={
										puffNavigationListSizeOnLoad
											? puffNavigationListSizeOnLoad
											: 3
									}
									options={{
										insideCell: false,
										insideInnerGrid: false,
										insideGrid: true,
										headingLevel: 2, // TODO:
										view: selectedViewType,
										themeContext: themeContext,
									}}
									showMoreLabel={showMoreLabel}
									items={fixedItems}
									disableCustomHeadingLogic={disableCustomHeadingLogic}
								></FragmentPuffList>
							</Space>
						)}
					</Grid>
				</Space>

				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			</Space>
		</>
	);
};

export default NVseNavigationPage;
