/**
 * Quiz - styles
 */

import styled from 'styled-components';

export const QuizOption = styled.li`
	list-style-type: none;
	margin-bottom: 1rem;
	max-width: 100%;
`;
export const QuizLabel = styled.label`
	display: flex;
	text-align: left;
	cursor: pointer;
	align-items: center;
	line-height: 1.5;
	border: 2px solid ${(props) => props.theme.colors.primary};

	&:focus {
		outline: currentcolor solid 2px;
		outline-offset: 2px;
		transition: outline-color 200ms ease-in-out 0s;
	}
`;

export const QuizText = styled.span`
	flex-grow: 1;
	padding: 0.75rem;
	border-left: inherit;
`;

export const QuizInput = styled.input`
	-webkit-appearance: auto;
	margin: 1em;
	min-width: 1.5em;
	display: inline-block;
	width: auto !important;
	height: 1rem;
	box-shadow: none;

	&:focus {
		border-color: white !important;
		box-shadow: none !important;
	}
`;

export const QuizList = styled.ul`
	padding: 0;
	margin-top: 2rem;
`;
