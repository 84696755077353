/**
 * BannerTextWidget - styles
 * https://styled-components.com/docs/advanced#referring-to-other-components
 */

import { ThemeProps } from 'components/Banner/Banner';
import {
	getThemeBackground,
	getThemeHeadingColor,
	getThemeTextColor,
} from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const BannerTextWidgetStyle = styled.div<ThemeProps>`
	padding: ${props => props.theme.spacing.space6}rem 0rem;
	${mediaQueries.tablet} {
		padding: ${props => props.theme.spacing.space4}rem ${props => props.theme.spacing.space2}rem;
	}
	${mediaQueries.phone} {
		padding: ${props => props.theme.spacing.space4}rem 0;
	}

	background-color: ${(p) => getThemeBackground(p.theme, p.themeName)};
`;

export const BannerWidgetContainerStyle = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${props => props.theme.spacing.space2}rem;
	text-align: center;
	justify-content: center;
`;

export const BannerWidgetSubTextStyle = styled.div<ThemeProps>`
		${props => props.theme.typography.style5.getStyle()};		
	color: ${(p) => getThemeTextColor(p.theme, p.themeName)};

	${mediaQueries.phone} {
		margin-top: ${props => props.theme.spacing.space2}rem;
	}

`;

export const BannerWidgetTextStyle = styled.div<ThemeProps>`
		${props => props.theme.typography.style2.getStyle()};		
	font-size: ${px2rem(132)};
	letter-spacing: 1px;
	line-height: unset;
	padding: 0px;
	${mediaQueries.phone} {
		font-size: ${px2rem(60)};
	}

	color: ${(p) => getThemeHeadingColor(p.theme, p.themeName)};
`;
