import ContactBlock from 'components/ContactBlock/ContactBlock';
import { ContactBlockModel } from 'types/fragments';

export const GetContactModelElement = (
	data: ContactBlockModel,
) => {
	return (
		<ContactBlock
			image={data.image}
			fullName={data.fullName}
			jobTitle={data.jobTitle}
		/>
	);
};
