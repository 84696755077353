/**
 * StandardPuff
 */
import React, { useContext } from 'react';
import { ImageFragmentModel } from 'types/fragments';
import {
	StandardPuffColumnReverse,
	StandardPuffHeaderStyle,
	StandardPuffImageStyle,
	StandardPuffMetadataStyle,
	StandardPuffPreambleStyle,
	StandardPuffStyle,
	StandardPuffTextContainerStyle,
	StandardPuffTimeStyle,
} from './StandardPuff.styles';
import { LinkType } from 'pages/sharedModelTypes';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import PuffCardMobile from 'components/PuffCardMobile';
import { ThemeContext } from 'styled-components';
import { useImageTitle } from 'hooks/useImageTitle';

export interface StandardPuffProps {
	/**
	 * Heading
	 */
	heading?: string | undefined;
	/**
	 * Heading level
	 */
	headingLevel?: number | undefined;
	/**
	 * Link
	 */
	link?: LinkType | null;
	/**
	 * Preamble
	 */
	preamble?: string | null;
	/**
	 * Image
	 */
	image?: ImageFragmentModel | null;
	/**
	 * Compact view
	 * (no bottom link on 'mobile')
	 */
	compact?: boolean;

	/**
	 * optional for microsite
	 */
	publicationDate?: string;
}

const StandardPuff: React.FC<StandardPuffProps> = ({
	image,
	heading,
	headingLevel,
	link,
	preamble,
	compact,
	children,
	publicationDate,
}) => {
	const themeContext = useContext(ThemeContext);

	const isPhone = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	if (!link) return null;

	if (isPhone) {
		return (
			<PuffCardMobile
				image={image}
				headingLevel={headingLevel}
				heading={heading}
				preamble={preamble}
				compact={false}
				link={link}
				publicationDate={publicationDate}
			>
				{children}
			</PuffCardMobile>
		);
	}

	return (
		<StandardPuffStyle
			to={link.url}
			aria-label={link?.heading}
			external={link.isFile}
		>
			<StandardPuffColumnReverse>
				<StandardPuffTextContainerStyle>
					{publicationDate && (
						<StandardPuffTimeStyle
							hasImage={image !== null && image !== undefined}
						>
							<time dateTime={publicationDate}>{publicationDate}</time>
						</StandardPuffTimeStyle>
					)}
					<StandardPuffHeaderStyle
						color={themeContext.palette.text.primary}
						zeroBottom={true}
						styleLevel={4}
						level={headingLevel}
					>
						{link.heading}
					</StandardPuffHeaderStyle>
					{preamble && (
						<StandardPuffPreambleStyle>{preamble}</StandardPuffPreambleStyle>
					)}
				</StandardPuffTextContainerStyle>

				{image && (
					<StandardPuffImageStyle>
						<img
							src={image.src}
							srcSet={image.srcSet}
							alt={image.alt}
							title={title}
						/>
					</StandardPuffImageStyle>
				)}
			</StandardPuffColumnReverse>

			{children && (
				<StandardPuffMetadataStyle>{children}</StandardPuffMetadataStyle>
			)}
		</StandardPuffStyle>
	);
};

export default StandardPuff;
