import React, { useContext, useState } from 'react';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { FragmentModelTypes } from 'types/fragments';
import { NVseStatisticsPageModel } from './NVseStatisticsPage.model';
import Tab from 'components/Tab';
import { TabPanel, TabProps } from 'components/Tab/Tab';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
	Options,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { CustomBackground } from './NVseStatisticsPage.styles';
import BannerLinks from 'components/Banners/BannerLinks';
import Highcharts from 'components/Highcharts/Highcharts';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { SidebarRelatedPanel } from 'components/Panels/SidebarRelatedPanel';
import { ColumnPageWithSidebar } from 'components/Panels/ColumnPageWithSidebar';
import ListHeader from 'components/ListHeader';
import { ThemeContext } from 'styled-components';

/**
 * # Statistiksida
 * Modeltype:<code>NVseStatisticsPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1812103169/NVseStatisticsPage)
 *
 * Sida för att visa miljödata på ett visuellt sätt
 */
const NVseStatisticsPage: React.FC<NVseStatisticsPageModel> = ({
	heading,
	lastReviewed,
	showReviewDate,
	preamble,
	tabHeading,
	tabItems,
	bottomItems,
	showAsChartLabel,
	showAsTableLabel,
	downloadLabel,
	exportAsLabel,
	legendHeading,
	legendSelectAllLabel,
	legendSelectNoneLabel,
	highChartsOptions,
	relatedEnvironmentGoalsLabel,
	relatedEnvironmentGoals,
	relatedSustainabilityGoalsLabel,
	relatedSustainabilityGoals,
	responsibleAuthorityLabel,
	responsibleAuthority,
	useShortDate,
	statisticsClassificationsLink,
	classificationList,
	siblingsList,
	dataSource,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);

	const options = JSON.parse(highChartsOptions);

	const [selectedPagePanel, setSelectedPagePanel] = useState<string>('tab0');

	const related = [
		{ label: relatedEnvironmentGoalsLabel, items: relatedEnvironmentGoals },
		{
			label: relatedSustainabilityGoalsLabel,
			items: relatedSustainabilityGoals,
		},
		{
			label: responsibleAuthorityLabel,
			items: responsibleAuthority ? [responsibleAuthority] : [],
		},
	];

	const pages = tabItems.map((item, index) => {
		return {
			heading: item.heading,
			id: 'tab' + index,
			iconName: null,
		};
	});

	const tabPagesPops = {
		label: 'Sidor',
		defaultTabId: selectedPagePanel,
		autoSelect: false,
		items: pages,
	} as TabProps;

	const onPagePanelSelected = (panelId: string) => {
		setSelectedPagePanel(panelId);
	};

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;
	let blockTypeBefore_siblingsList: BlockType = lastBlockType;

	if (dataSource) {
		lastBlockType = BlockType.Element;
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}

	const selectedTabIndex = tabPagesPops.items.findIndex((item) => {
		return item.id === selectedPagePanel;
	});

	if (selectedTabIndex !== -1) {
		lastBlockType = getLastItemBlockType(
			[
				...tabItems[selectedTabIndex].textAsModel.fragments,
				...tabItems[selectedTabIndex].bottomItems,
			],
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
		blockTypeBefore_siblingsList = lastBlockType;
	}

	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(
			bottomItems,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_siblingsList = lastBlockType;
	}
	if (siblingsList.items.length > 0) {
		lastBlockType = BlockType.SectionBanner;
	}

	const spaceBefore_siblingsList = getSpaceToAddBefore(
		blockTypeBefore_siblingsList,
		BlockType.SectionBanner,
		themeContext
	);

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<Space top={0} bottom={spaceAfterLast}>
			<CustomBackground>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						columns={8}
						headingLevel={1}
						heading={heading}
						lastReviewed={lastReviewed}
						showReviewDate={showReviewDate}
						preamble={preamble}
						heading_htmlAttributes={_properties?.heading}
						preamble_htmlAttributes={_properties?.preamble}
					></GrantsHeader>
				</Grid>

				<Space top={themeContext.spacing.space4}>
					<Grid paddingTop={false} paddingBottom={false}>
						<Highcharts
							data={options}
							showAsChartLabel={showAsChartLabel}
							showAsTableLabel={showAsTableLabel}
							downloadLabel={downloadLabel}
							exportAsLabel={exportAsLabel}
							legendHeading={legendHeading}
							legendSelectAllLabel={legendSelectAllLabel}
							legendSelectNoneLabel={legendSelectNoneLabel}
							useShortDate={useShortDate}
							classificationList={classificationList}
							statisticsClassificationsLink={statisticsClassificationsLink}
						></Highcharts>
					</Grid>
				</Space>
			</CustomBackground>

			{dataSource && (
				<EpiFragments
					previousBlockType={BlockType.SectionBanner}
					epi={epi}
					headingLevel={2}
					fragments={[dataSource] as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}

			{tabPagesPops.items.length > 0 && (
				<div>
					<Space top={themeContext.spacing.getSection()}>
						<Grid paddingTop={false} paddingBottom={false}>
							{tabHeading && (
								<Space bottom={themeContext.spacing.getElement()}>
									<ListHeader
										heading={tabHeading}
										sectionStyle
										headingLevel={2}
									></ListHeader>
								</Space>
							)}
							<Tab onSelect={onPagePanelSelected} {...tabPagesPops}></Tab>
						</Grid>
					</Space>

					{tabItems.map((item, index) => {
						const options = {
							epi: epi,
							headingLevel: 2,
							insideGrid: true,
						} as Options;

						return (
							<TabPanel
								key={index}
								panelId={'tab' + index}
								selected={selectedPagePanel === 'tab' + index}
							>
								<Grid paddingTop={false} paddingBottom={false}>
									<ColumnPageWithSidebar
										options={options}
										textAsModel={item.textAsModel}
										link={item.link}
										bottomItems={item.bottomItems}
										disableCustomHeadingLogic={disableCustomHeadingLogic}
									>
										{related.map((item, index) => {
											return (
												<Space
													key={index}
													top={index === 0 ? 0 : themeContext.spacing.space5}
												>
													<SidebarRelatedPanel
														label={item.label}
														items={item.items}
													></SidebarRelatedPanel>
												</Space>
											);
										})}
									</ColumnPageWithSidebar>
								</Grid>
							</TabPanel>
						);
					})}
				</div>
			)}

			{bottomItems && bottomItems.length > 0 && (
				<EpiFragments
					previousBlockType={blockTypeBefore_bottomItems}
					epi={epi}
					headingLevel={2}
					fragments={bottomItems as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}

			{siblingsList.items?.length > 0 && (
				<Space top={spaceBefore_siblingsList}>
					<BannerLinks
						headingLevel={2}
						heading={siblingsList.heading}
						links={siblingsList.items}
					></BannerLinks>
				</Space>
			)}
		</Space>
	);
};

export default NVseStatisticsPage;
