/**
 * Icon
 */

import React from 'react';
import { iconSize } from './Icon.styles';
import {
	IconArrow,
	IconArrowDown,
	IconCardView,
	IconChevron,
	IconClock,
	IconCross,
	IconLink,
	IconListview,
	IconMenu,
	IconPlus,
	IconSearch,
	IconWarning,
	IconPlay,
	IconCopy,
	IconArrowUp,
	IconCheck,
} from './IconCollection';

export type IconProps = {
	width?: string;
	height?: string;
	fill?: string;
	stroke?: string;
};

interface Props {
	/** Set the size of the icon. <br/>1 = 24px<br/> 2 = 32px<br/> 3 = 40px <br/> 4 = 48px*/
	size?: 0.5 | 1 | 2 | 3 | 4;

	/** Icon name */
	icon: IconNames;

	/** Set the color of the icon */
	color?: string;
}

/** This is the icon component that handles all the icons in the project. <br /> The user will be able to set the <strong>color</strong>, <strong>size</strong>, <strong>direction</strong> and <strong>animation</strong> for all the icons. */
const Icon: React.FC<Props> = ({
	icon,
	color = 'black',
	size = 1,
	...extraProps
}) => {
	const iProp = {
		width: iconSize[size],
		height: iconSize[size],
		fill: color,
		stroke: 'none',
		...extraProps,
	} as IconProps;

	return React.createElement(IconCollection[icon], {
		'aria-hidden': 'true',
		...iProp,
	});
};

export default Icon;

export type IconNames =
	| 'listview'
	| 'cardview'
	| 'link'
	| 'arrowdown'
	| 'arrowup'
	| 'cross'
	| 'chevron'
	| 'arrow'
	| 'plus'
	| 'search'
	| 'warning'
	| 'menu'
	| 'play'
	| 'clock'
	| 'copy'
	| 'check';

export const IconCollection = {
	arrow: IconArrow,
	listview: IconListview,
	cardview: IconCardView,
	chevron: IconChevron,
	link: IconLink,
	arrowdown: IconArrowDown,
	arrowup: IconArrowUp,
	cross: IconCross,
	plus: IconPlus,
	search: IconSearch,
	warning: IconWarning,
	menu: IconMenu,
	clock: IconClock,
	play: IconPlay,
	copy: IconCopy,
	check: IconCheck,
};
