import { PuffCardProps } from 'components/PuffCard/PuffCard';
import { useImageTitle } from 'hooks/useImageTitle';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
	PuffCardMobileDecalStyle,
	PuffCardMobileHeaderStyle,
	PuffCardMobileImageStyle,
	PuffCardMobileLinkStyle,
	PuffCardMobileMetadataStyle,
	PuffCardMobilePreambleStyle,
	PuffCardMobileStyle,
	PuffCardMobileTextContainerStyle,
	PuffCardMobileTimeStyle,
} from './PuffCardMobile.styles';

/**
 * # PuffCard
 *
 * Puff List item
 */
export const PuffCardMobile: React.FC<PuffCardProps> = ({
	decal,
	headingLevel,
	heading,
	link,
	preamble,
	image,
	compact,
	children,
	publicationDate,
}) => {
	const themeContext = useContext(ThemeContext);

	const title = useImageTitle(
		image?.photographer,
		image?.agency,
		image?.illustrator
	);

	if (!link) return null;

	return (
		<PuffCardMobileStyle
			to={link.url}
			aria-label={link?.heading}
			external={link.isFile}
		>
			<PuffCardMobileTextContainerStyle
				hasDate={typeof publicationDate === 'string'}
			>
				{decal && <PuffCardMobileDecalStyle>{decal}</PuffCardMobileDecalStyle>}
				{publicationDate && (
					<PuffCardMobileTimeStyle>
						<time dateTime={publicationDate}>{publicationDate}</time>
					</PuffCardMobileTimeStyle>
				)}
				{heading && (
					<PuffCardMobileHeaderStyle
						compact={compact ? compact : false}
						color={compact ? undefined : themeContext.palette.text.primary}
						zeroBottom={true}
						styleLevel={4}
						level={headingLevel}
					>
						{heading}
					</PuffCardMobileHeaderStyle>
				)}
				{!compact && preamble && (
					<PuffCardMobilePreambleStyle>{preamble}</PuffCardMobilePreambleStyle>
				)}

				{children && (
					<PuffCardMobileMetadataStyle>{children}</PuffCardMobileMetadataStyle>
				)}
			</PuffCardMobileTextContainerStyle>
			{image && (
				<PuffCardMobileImageStyle>
					<img
						src={image.src}
						srcSet={image.srcSet}
						alt={image.alt}
						title={title}
					/>
				</PuffCardMobileImageStyle>
			)}
		</PuffCardMobileStyle>
	);
};

export default PuffCardMobile;
