/**
 * Breadcrumbs - styles
 */

import Link from 'components/Boilerplate/Link';
import styled, { css } from 'styled-components';
import { MediaQueryProps } from './Breadcrumbs';

export const BreadcrumbStyled = styled.ol`
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
	padding-top: 12px;
	padding-bottom: 12px;
	margin: 0px;
	list-style: none;
`;

type ItemCustomProps = {
	$active: boolean;
};

const addAfterSymbol = () => {
	return css`
		&:first-child::before {
			${getSymbol()};
			transform: rotate(225deg);
			margin-left: 0;
			margin-right: 8px;
		}
	`;
};
const addBeforeSymbol = () => {
	return css`
		&:not(:first-child)::before {
			${getSymbol()}
		}
	`;
};

const getSymbol = () => {
	return css`
		display: inline-block;
		color: ${(props) => props.theme.palette.text.primary};
		content: '';
		width: 0.55em;
		height: 0.55em;
		border-right: 0.15em solid ${(props) => props.theme.palette.text.primary};
		border-top: 0.15em solid ${(props) => props.theme.palette.text.primary};
		transform: rotate(45deg);
		margin-left: 0.8em;
		margin-right: 1em;
		margin-bottom: 0.1em;
	`;
};

export const BreadcrumbItemWarpper = styled.li<MediaQueryProps>`
	${(p) => p.isPhone && addAfterSymbol()};
	${(p) => !p.isPhone && addBeforeSymbol()};
`;

export const BreadcrumbLink = styled(Link)<
	ItemCustomProps & React.HTMLProps<HTMLInputElement>
>`
	font-size: 18px;
	${(props) =>
		props.theme.isMicrositeActive ? props.theme.typography.style8 : null};

	text-decoration: none;

	& + &:hover::before {
		text-decoration: none;
	}

	${({ $active }) => $active && `aria-current:page;`}

	color: ${({ $active, theme }) =>
		$active
			? theme.isMicrositeActive
				? theme.colors.commonAccentHue
				: theme.palette.text.primary
			: theme.colors.secondaryBlue};

	pointer-events: ${({ $active }) => ($active ? 'none' : 'auto')};
	cursor: ${({ $active }) => ($active ? 'default' : 'pointer')};

	text-decoration: none;
`;
