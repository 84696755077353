import { LabelContainer, LabelWrapper } from 'components/Panels/Panels.styles';
import { Categories } from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.model';
import React from 'react';

/**
 * CategoryDropdown
 */
export type CategoryDropdownProps = {
	category: Categories;
	selectedId: number | string;
	id: string;
	allowEmpty?: boolean;
	flexRow?: boolean;
	onSelectChanged?: (id: string) => void;
};

export const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
	category,
	selectedId,
	allowEmpty,
	id,
	flexRow = false,
	onSelectChanged,
}) => {
	const onCategorySelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedId = event.target.value;
		if (onSelectChanged) {
			onSelectChanged(selectedId);
		}
	};

	return (
		<LabelContainer flexRow={flexRow}>
			<LabelWrapper>
				<legend aria-label={category.name}>{category.name}</legend>
			</LabelWrapper>
			<select
				id={id}
				value={selectedId ? selectedId : ''}
				onChange={(e): void => onCategorySelected(e)}
				aria-label={category.name}
			>
				{allowEmpty && <option value="">{category.name}</option>}
				{category.categoryOptions.map((item, index) => {
					return (
						<option key={index} value={item.id.toString()}>
							{item.text}
						</option>
					);
				})}
			</select>
		</LabelContainer>
	);
};
