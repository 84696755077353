import styled from 'styled-components';

export const MetadataContainer = styled.div`
	background-color: ${props => props.theme.colors.white};
	padding: ${props => props.theme.spacing.space7}rem 0;
	scroll-margin-top: 29px;
`;

export const MetadataThumbnailContainer = styled.div`
	background-color: ${props => props.theme.colors.grey5};
	padding: ${props => props.theme.spacing.space5}rem ${props => props.theme.spacing.space6}rem;
`;

export const MetadataThumbnail = styled.img`
	width: 100%;
`;
