/**
 * Pills - styles
 */

import styled from 'styled-components';

export const PillWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const PillButton = styled.button`
	display: flex;
	align-items: center;
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.colors.black};
	margin-right: ${(props) => props.theme.spacing.space1}rem;
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
	padding: 4px 16px 4px 8px;
	cursor: pointer;
	background-color: ${(props) => props.theme.colors.grey5};
	border: 1px solid transparent;

	span {
		padding-top: 3px;
	}

	button::after {
		content: none;
	}
	&:hover {
		background-color: ${(props) => props.theme.colors.blue2};
		border: 1px solid ${(props) => props.theme.colors.grey5};
	}
`;
